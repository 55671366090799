.print-home-value-changed {
  .dashboard-palmtree-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 97%;
    width: 54%;
  }
  .dashboard-iris-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 97%;
    width: 54%;
  }
  .dashboard-header {
    position: absolute;
    top: 70px;
    width: 100%;
    margin: auto;
    display: flex;

    justify-content: center;
    align-items: center;
    .dashboard-header-content {
      width: 89%;
    }
    .dashboard-title {
      width: 100%;
      display: flex;

      justify-content: space-between;
      margin: auto;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 37px;
      letter-spacing: 0.16em;
      .dashboard-title-light {
        color: white;
        width: 53.5%;
        text-align: right;
      }
      .dashboard-title-dark {
        color: black;
        width: 100%;

        text-align: right;
        font-family: "playfair-display", serif;
        font-weight: 400;
        font-style: normal;
        font-size: 30px;
        letter-spacing: 0.11em;
      }
    }
    .dashboard-subtitle-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .dashboard-subtitle-container {
        width: 61%;

        .dashboard-subtitle-header {
          display: flex;
          justify-content: flex-end;
          .dashboard-subtitle-dark,
          .dashboard-subtitle-light {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 17px;
            letter-spacing: 0.2em;
          }
          .dashboard-subtitle-light {
            margin-right: 20px;
            color: white;
          }
          .dashboard-subtitle-dark {
            color: black;
            text-align: right;
          }
        }
      }
      .dashboard-info {
        margin-top: 10px;

        color: black;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: justify;
        text-justify: inter-word;
        width: 315px;
        text-align: justify;
        text-justify: inter-word;
        .bolded-dashboard-header-area-name {
          font-weight: 500;
        }
      }
    }
  }
  .dashboard-body {
    position: absolute;
    top: 265px;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .dashboard-body-range {
      height: 186px;
      display: flex;
      position: relative;
      width: 100%;
      .dashboard-body-divider {
        height: 1px;
        min-height: 1px;
        width: 570px;

        background-color: black;
        position: absolute;
        top: 80px;
        left: 0px;
        z-index: 1;
      }
      .price-range-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 37%;
        z-index: 2;
        .price-range-inner-container {
          width: 82%;
          margin: -7px auto 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: 37%;
          .price-range-text {
            margin: -20px 0 10px 0;
            font-family: objektiv-mk2, sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 29.5px;
            color: black;
          }
          .price-range-number {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 15px;
            color: black;
          }
        }
      }
      .range-details-img {
        display: flex;
        width: 64%;
        height: 105%;
        justify-content: center;
      }
      .range-details-col-with-image {
        display: flex;
        width: 64%;
        height: 100%;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
      }
      .range-details-col,
      .range-details-col-with-image {
        .range-details-inner-container {
          width: 85%;
          height: 100%;
          margin: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .range-property-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: 35%;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: italic;
          font-size: 21px;
          color: black;
          margin-top: -25px;
        }
        .property-attributes {
          display: flex;
          flex-direction: column;
          .property-attributes-title {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 600;
            font-style: normal;
            margin-bottom: 5px;
            font-size: 15px;
            color: black;
          }
          .property-attributes-text {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: black;
          }
          .property-attributes-disclaimer-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .property-attributes-disclaimer {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 300;
              font-style: normal;
              font-size: 11px;
              display: flex;
              justify-content: center;
              color: black;
              margin-top: 8px;
              width: 48%;
            }
          }
        }
      }
    }
  }
  .qr-code-section {
    position: absolute;
    bottom: 51px;
    right: 0;
    height: 170px;
    width: 88%;
    background-color: rgba(255, 255, 255, 0.8);

    display: flex;
    .qr-code-col1 {
      width: 18%;
      margin-top: 10px;
      .bubble-arrow-container {
        width: 60%;
        height: 100%;
        margin: auto;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
          height: 50px;
          width: auto;
        }
      }
    }
    .qr-code-col2 {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin-top: 10px;
      .qr-code-title {
        height: 16%;
        display: flex;
        align-items: center;
        p {
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 500;
          font-style: italic;
          font-size: 19px;
        }
        .qr-code-space {
          margin-right: 9px;
          font-style: normal;
          font-weight: 600;
          color: rgb(0, 79, 172);
        }
      }
      .qr-code-content-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 80%;
        .bubble-qr-container {
          width: 20%;

          img {
            height: 116px;
            width: 116px;
          }
        }

        .qr-code-text {
          width: 80%;
          display: flex;
          align-items: center;
          p {
            font-family: "objektiv-mk2", sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 17px;
            line-height: 23px;
          }
        }
        .qr-code-link {
          text-decoration: underline;
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 600;
          font-style: italic;
          font-size: 17px;
          letter-spacing: 0.015em;
          line-height: 29px;
          color: rgb(0, 79, 172);
          margin-right: 5px;
        }
      }
    }
  }
  .dashboard-valuation {
    position: absolute;
    bottom: 51px;
    right: 0;
    height: 170px;
    width: 95%;
    background-color: rgba(255, 255, 255, 0.8);
    .valuation-title {
      display: flex;
      justify-content: center;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 16px;
      letter-spacing: 0.025em;
      color: black;
      margin: 20px 0 0 0;
    }
    .valuation-section-row {
      display: flex;
      justify-content: space-between;
      width: 95%;
      margin: 8px auto 0 auto;
    }
    .valuation-section {
      width: 30%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .valuation-number {
        line-height: 90px;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 80px;
        width: 45px;
        &.light,
        &.medium,
        &.dark {
          color: #a3b6bd;
        }
      }
      .valuation-data {
        margin-top: 10px;
        width: 77%;
        text-align: left;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: italic;
        font-size: 13px;
        line-height: 24px;
        color: black;
      }
    }
  }
}
