.editor-first-left-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 10px;
  right: 10px;
  height: 30px;
  z-index: 2;
  .logo-address {
    .agentLogo1-left {
      height: 90px;
      width: 250px;
      position: absolute;
      top: 30px;
      left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: auto;
        max-height: 90px;
        width: auto;
        max-width: 250px;
      }
    }
  }
  .return-address {
    position: absolute;
    top: 30px;
    left: 360px;
    width: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    p {
      font-family: objektiv-mk2, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 7px;
      text-transform: uppercase;
      color: black;
      line-height: 12px;
      letter-spacing: 0.01em;
    }
  }

  .postage {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30px;
    right: 37px;
    // height: 40px;
    width: 180px;
    justify-content: space-around;
    align-items: flex-end;
    p {
      font-family: objektiv-mk2, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 7px;
      text-transform: uppercase;
      color: black;
      line-height: 12px;
      letter-spacing: 0.01em;
    }
  }
}
