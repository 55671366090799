.print-qv3-economists {
  border: 1px solid red;
  position: absolute;
  //top: 370px;
  top: 380px;
  width: 100%;
  height: 500px;
  z-index: 5;
  .qv3-economists-title-container {
    display: flex;
    flex-direction: column;
    .qv3-economists-title-text {
      color: #3c4c55;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 400;
      font-style: normal;
      text-transform: uppercase;
      font-size: 38px;
      letter-spacing: 0px;
      margin-left: 50px;
      margin-bottom: -5px;
      margin-top: 5px;
    }
    .qv3-economists-title-divider {
      width: 576px;
      height: 2.66px;
      background-color: #f3d3ae;
      margin-top: -2px;
    }
    .qv3-economists-title-subtitle {
      font-family: objektiv-mk2, sans-serif;
      font-weight: 300;
      font-style: italic;
      font-size: 20px;
      color: #3c4c55;
      margin-left: 420px;
      margin-top: 10px;
      letter-spacing: 0.025em;
    }
  }
  .qv3-economists-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px;
    .qv3-economists-section:nth-child(3),
    .qv3-economists-section:nth-child(4) {
      margin-top: 20px;
    }
    .qv3-economists-section {
      width: 44%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .qv3-economists-section-title {
        color: #ffffff;
        text-transform: uppercase;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 26px;
        text-transform: uppercase;
        letter-spacing: 0px;
        margin-bottom: 5px;
      }
      .qv3-economists-section-content {
        margin-bottom: 4px;
        p {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 300;
          font-style: normal;
          font-size: 16px;
          line-height: 21px;
          color: #3c4c55;
          text-align: center;
        }
      }
      .qv3-economists-section-author {
        margin-top: 4px;
        p {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 300;
          font-style: normal;
          font-size: 11px;
          line-height: 15px;
          color: #3c4c55;
          text-align: center;
        }
      }
    }
  }
}
