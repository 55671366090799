/* Variables

 */
.quarterly-print .qv2-print-page .qv2-print-page-container {
  position: relative;
  width: 1698px;
  height: 1140px;
  margin: 0;
  background: white;
  background-size: contain;
  display: flex;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-right,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-right {
  width: 50%;
  position: relative;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 730px;
  z-index: 3;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -2;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-bg img {
  width: 650px;
  height: 691px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-title-container {
  width: 889px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-title-container .quick-to-market-trends-title {
  margin-left: 65px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 48px;
  line-height: 30.5px;
  letter-spacing: 0.2em;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-title-container .quick-to-market-subtitle {
  margin-top: 10px;
  margin-left: 213px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  letter-spacing: 0.025em;
  text-align: left;
  z-index: 3;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-body {
  width: 90%;
  height: 514px;
  margin: 31px auto 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section-container {
  width: 46.8%;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section.short {
  height: 230px;
  margin-top: -65px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section.shorter {
  height: 270px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section .quick-to-market-trends-section-title {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section .quick-to-market-trends-section-text {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section .quick-to-market-trends-author {
  color: #9a5f67;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-cta {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 4px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-cta p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  color: #8f555c;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .quick-to-market-trends .quick-to-market-cta span {
  padding-left: 6px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales {
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 100%;
  height: 310px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .top-sales-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .top-sales-content {
  position: absolute;
  bottom: 35px;
  width: 100%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .top-sales-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  z-index: 2;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .top-sales-title-container .top-sales-title-section {
  margin-left: 25px;
  display: flex;
  padding-left: 15px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  text-transform: uppercase;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .top-sales-title-container .top-sales-title-divider {
  margin-left: -7px;
  height: 2.5px;
  width: 498px;
  background-color: rgba(246, 204, 108, 0.8);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .top-sales-title-container .top-sales-subtitle {
  margin-top: 6px;
  margin-left: 0px;
  width: 62%;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .top-sales-footer {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #00305e;
  height: 20px;
  width: 94.2%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .top-sales-footer .top-sales-footer-lowercase {
  margin: 4px 5px 0 5px;
  text-transform: lowercase;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table {
  width: 100%;
  border-collapse: collapse;
  margin: auto;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table thead tr {
  align-items: flex-end;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table thead tr th {
  font-size: 11px;
  text-align: center;
  vertical-align: middle;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #00305e;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table thead tr th p {
  line-height: 13px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table thead tr th:first-child {
  padding-left: 20px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table thead tr th:last-child {
  padding-right: 26px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 20px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #475862;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table tbody tr td:first-child {
  padding-left: 20px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table tbody tr td:last-child {
  padding-right: 30px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table tbody tr .top-sales-date {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table tbody tr .top-sales-date-divider {
  height: 1px;
  width: 1px;
  border-radius: 50%;
  background-color: #475862;
  margin: 2px 3px 0 3px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .qv2-top-sales .regular-sales-table tbody tr .status-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  color: #475862;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .backpage-bg {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 760px;
  z-index: 0;
  background-color: black;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 270px;
  width: 100%;
  z-index: 1;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-body {
  position: absolute;
  bottom: 50px;
  margin: auto;
  width: 100%;
  z-index: 2;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-header {
  width: 96%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-header .home-value-title {
  margin-left: 70px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 28.25px;
  letter-spacing: 0.16em;
  color: #475862;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-header .home-value-divider {
  height: 2.66px;
  width: 645px;
  background-color: rgba(246, 204, 108, 0.8);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-header .home-value-subtitle {
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.05em;
  color: #53626c;
  margin-top: 6px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto 0 auto;
  table-layout: auto;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table thead th {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #8a927c;
  font-size: 15px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  line-height: 18px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table thead th:first-child {
  padding-left: 90px;
  text-align: left;
  width: 320px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table thead th:nth-child(2),
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table thead th:nth-child(3) {
  width: 130px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table thead th:last-child {
  padding-right: 70px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table tbody tr {
  border-bottom: 1px solid rgb(205.4655172414, 214.7896551724, 220.0344827586);
  height: 27px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table tbody td {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #53626c;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table tbody td:first-child {
  padding-left: 90px;
  text-align: left;
  font-size: 16px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 52%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p {
  width: auto;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table tbody td:last-child {
  padding-right: 70px;
  font-size: 15px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-table tbody td:last-child p {
  margin: 0 0 0 30%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-first-left .home-value .home-value-source {
  width: 90%;
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  position: relative;
  z-index: 4;
  top: 15px;
  color: white;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left {
  width: 50%;
  position: relative;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .dashboard-bg {
  position: absolute;
  margin: auto;
  left: 5%;
  right: 5%;
  top: 6.2%;
  width: 90%;
  height: 947px;
  z-index: 0;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 69px;
  margin-top: 37px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-header .db-header-main {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 27.5px;
  line-height: 32px;
  letter-spacing: 0.3em;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body {
  position: absolute;
  top: 112px;
  width: 100%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-body-header {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px auto 0px auto;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-body-header .db-body-header-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-align: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-body-subheader {
  width: 280px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container {
  margin-top: -34px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box {
  right: 0px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider {
  right: 0px;
  width: 655px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation {
  width: 85%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container {
  width: 85%;
  margin-top: 10px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage {
  display: flex;
  justify-content: flex-start;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box {
  left: 0px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider {
  left: 0px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation {
  width: 85%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container {
  width: 85%;
  margin-top: 10px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage {
  display: flex;
  justify-content: flex-end;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container {
  clear: both;
  display: inline-block;
  width: 100%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator {
  height: 203px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .keep-hyphen {
  white-space: nowrap;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 1.3px;
  width: 612px;
  position: absolute;
  top: 69px;
  z-index: 3;
  background-color: black;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
  height: 210px;
  z-index: 1;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .indicator-header-num {
  width: 80px;
  color: #fff2c4;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 50px;
  line-height: 32px;
  margin-top: -172px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 781px;
  height: 85%;
  position: absolute;
  z-index: 2;
  margin-top: 23px;
  background-color: rgba(245, 251, 246, 0.9);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .db-box-inner-container {
  width: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .db-box-inner-container.box-inner-odd {
  margin-left: 50px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .db-box-inner-container.box-inner-even {
  margin-left: -20px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
  height: 40px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  width: 100%;
  color: #3e71a0;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23px;
  line-height: 36px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  color: rgb(34.9378378378, 63.677027027, 90.1621621622);
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 13px;
  line-height: 19px;
  text-align: justify;
  text-justify: inter-word;
  margin-left: 4px;
  display: inline-block;
  float: right;
  letter-spacing: 0.025em;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage {
  margin-top: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15.8px;
  line-height: 19px;
  text-align: justify;
  text-justify: inter-word;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong {
  font-weight: normal !important;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: -100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-weight: 700;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-bottom-line .db-bottom-line-text,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 17px;
  line-height: 19px;
  letter-spacing: 0.075em;
  color: #636d7b;
  width: 90%;
  margin: auto;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv3-second-left .db-body .db-bottom-line .db-signoff {
  display: inline-block;
  float: right;
  margin-right: 34px;
  width: auto;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table {
  width: 100%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 30px 0 30px 41px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: #3c4c55;
  margin: -8px 8px 0 8px;
  height: 5px;
  width: 5px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 5px;
  height: 5px;
  width: 5px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: #3c4c55;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 26px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 31px;
  line-height: 26px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 28px;
  line-height: 26px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 25px;
  line-height: 26px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 30px;
  line-height: 26px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: #657460;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row .golden-divider {
  height: 1.333px;
  margin-top: 2px;
  width: 500px;
  background-color: #abbc87;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 319px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 430px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 7px;
  display: flex;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row .right-subtitle-section p {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-table-containers {
  margin-top: -1px;
  min-height: 500px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 552px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table thead tr th {
  font-size: 11px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #3c4c55;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table thead tr th p {
  line-height: 13px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 23px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 33px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 18.5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #3c4c55;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 64px;
  text-align: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 59px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 120px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 159px;
  text-align: left;
  font-weight: 400;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  width: 92px;
  font-weight: 400;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 73px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 41px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 100px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 63px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 10px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .sold-overflow-tb,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .sold-overflow-tb .sales-table-row,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: #475862;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .sales-dates p {
  font-size: 8px;
  color: black;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .sales-legend {
  border: 1px solid gray;
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 9px;
  color: black;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 21px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-second-left-middle-section {
  height: 935px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators {
  width: 100%;
  height: 165px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: -7px;
  height: 1.33px;
  width: 500px;
  background-color: #abbc87;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -4px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section {
  font-size: 13px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.2);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.35);
  height: 50px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -8px;
  width: 100px;
  height: 1.5px;
  background-color: #abbc87;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 30.5px;
  color: #53626c;
  text-align: center;
  margin-right: 20px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section .qv2-indicator-arrow-up {
  width: 35px;
  height: 35px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section .qv2-indicator-arrow-down {
  width: 35px;
  height: 35px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .qv2-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: #53626c;
  margin-bottom: -4px;
  text-align: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-palmtree-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 97%;
  width: 54%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header {
  position: absolute;
  top: 100px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-header-content {
  width: 89%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 37px;
  letter-spacing: 0.16em;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-title .dashboard-title-light {
  color: white;
  width: 53.5%;
  text-align: right;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-title .dashboard-title-dark {
  color: #3c4c55;
  width: 44.5%;
  text-align: left;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-subtitle-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container {
  width: 61%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header {
  display: flex;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.2em;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  margin-right: 20px;
  color: white;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark {
  color: #3c4c55;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info {
  margin-top: 15px;
  color: rgb(70.2098765432, 76.5925925926, 64.4074074074);
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  width: 315px;
  text-align: justify;
  text-justify: inter-word;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body {
  position: absolute;
  top: 300px;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range {
  height: 186px;
  display: flex;
  position: relative;
  width: 100%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider {
  height: 2.66px;
  min-height: 2.66px;
  width: 570px;
  background-color: #f3d3ae;
  position: absolute;
  top: 80px;
  left: 0px;
  z-index: 1;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .price-range-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 37%;
  z-index: 2;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container {
  width: 82%;
  margin: -7px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 37%;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text {
  margin: -20px 0 10px 0;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 29.5px;
  color: white;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 15px;
  color: white;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-img {
  display: flex;
  width: 64%;
  height: 105%;
  justify-content: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image {
  display: flex;
  width: 64%;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 35%;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 21px;
  color: #3c4c55;
  margin-top: -25px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes {
  display: flex;
  flex-direction: column;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 5px;
  font-size: 15px;
  color: #3c4c55;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #3c4c55;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  text-align: center;
  color: rgb(28.1479289941, 34.8875739645, 38.8520710059);
  margin-top: 8px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-valuation {
  position: absolute;
  bottom: 30px;
  right: 0;
  height: 170px;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.8);
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-valuation .valuation-title {
  display: flex;
  justify-content: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #3c4c55;
  margin: 20px 0 0 0;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-valuation .valuation-section-row {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 8px auto 0 auto;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-valuation .valuation-section {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-valuation .valuation-section .valuation-number {
  line-height: 90px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-valuation .valuation-section .valuation-number.light, .quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-valuation .valuation-section .valuation-number.medium, .quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-valuation .valuation-section .valuation-number.dark {
  color: #939d87;
}
.quarterly-print .qv2-print-page .qv2-print-page-container .qv2-second-left .dashboard-valuation .valuation-section .valuation-data {
  margin-top: 10px;
  width: 83%;
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
  line-height: 24px;
  color: #3c4c55;
}