@charset "UTF-8";
/* Variables

 */
@font-face {
  font-family: "KeplerStdSemiboldItalic";
  src: url("Kepler_Std_Semibold_Italic.otf");
}
@font-face {
  font-family: "Droid-Serif-Regular";
  src: url("../Fonts/DroidSerif-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lucida-Bright-Regular";
  src: url("../Fonts/Lucida-Bright-Regular.ttf");
}
@font-face {
  font-family: "MrsEavesXLModOT-Reg";
  src: url("MrsEavesXLModOT-Reg.ttf");
}
@font-face {
  font-family: "OpenSansCondensedLight";
  src: url("../Fonts/OpenSansCondensed-Light.ttf");
}
@font-face {
  font-family: "OpenSansCondensedLightItalic";
  src: url("../Fonts/OpenSansCondensed-LightItalic.ttf");
}
@font-face {
  font-family: "OpenSansRegular";
  src: url("../Fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSansItalic";
  src: url("../Fonts/OpenSans-Italic.ttf");
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("../Fonts/OpenSans-SemiBold.ttf");
}
@font-face {
  font-family: "usps4cb";
  src: url("usps4cb.eot");
  src: local("☺"), url("usps4cb.woff") format("woff"), url("usps4cb.ttf") format("truetype"), url("usps4cb.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.photo-edit-property-container {
  width: 100%;
}
.photo-edit-property-container .photo-edit-property-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #3c4c55;
}
.photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-title-divider {
  margin-top: 0px;
  width: 100%;
  background-color: #4e85af;
  height: 3px;
}
.photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-subtitle-text {
  margin-top: 5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 19px;
  color: #00558f;
  display: flex;
  align-items: center;
}
.photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-subtitle-text.column-text {
  flex-direction: column;
}
.photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-subtitle-text .click-submit-text {
  margin-top: 5px;
}
.photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-subtitle-text .subtitle-edit-button {
  margin: 0 7px;
  height: 20px;
  width: 60px;
  border-radius: 10px;
  border: 1px solid #00558f;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #4e85af;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
}
.photo-edit-property-container .photo-edit-property-form {
  margin: 30px auto 0 auto;
  border: 2px solid #4e85af;
  border-radius: 15px;
  width: 90%;
}
.photo-edit-property-container .photo-edit-property-form.old-props {
  height: 260px;
}
.photo-edit-property-container .photo-edit-property-form.new-props {
  height: 280px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container .photo-edit-property-form-title-text {
  margin-left: 5%;
  margin-top: 15px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  color: #475862;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container .photo-edit-property-form-title-divider {
  width: 696px;
  background-color: #4e85af;
  height: 2px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container .photo-edit-property-form-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  color: #00558f;
  margin-left: 5%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container .photo-edit-property-form-subtitle-text.mt-subtitle {
  margin-top: 15px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-radio-container {
  width: 70%;
  margin: 10px auto -10px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-radio-container .photo-edit-property-radio-row {
  height: 25px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-radio-container .photo-edit-property-radio-row span {
  margin-left: 5px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-radio-container .photo-edit-property-radio-row input:hover {
  cursor: pointer;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: 0px auto 0 auto;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body.photo-body-mt {
  margin-top: 20px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead > div,
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead > div p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.025em;
  line-height: 19px;
  text-transform: uppercase;
  color: #00305e;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead > div:nth-child(1) {
  width: 16%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead > div:nth-child(1) p {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead > div:nth-child(2) {
  width: 16%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead > div:nth-child(2) p {
  text-align: center;
  align-self: center;
  justify-content: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead > div:nth-child(3) {
  width: 16%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead > div:nth-child(3) p {
  text-align: center;
  align-self: center;
  justify-content: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead div:nth-child(4) {
  width: 16%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead div:nth-child(5) {
  width: 16%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead div:nth-child(6) {
  width: 16%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead div:nth-child(7) {
  width: 16%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead div:nth-child(8) {
  width: 20%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-thead div:nth-child(8) p {
  align-self: flex-ensd;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 10px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input > div {
  margin: 0 0.5%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input input {
  width: 100%;
  height: 25px;
  margin: auto;
  border: 0;
  border-bottom: 3px solid #4e85af;
  outline: 0;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input input:focus {
  outline: none !important;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input > div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: #414042;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input > div .form-edit-button {
  margin-top: 10px;
  width: 60px;
  height: 14px;
  border: 1.33px solid #4e85af;
  color: #4e85af;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 10px;
  text-align: center;
  border: 1px solid green;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input > div .form-edit-button.upload-photo {
  width: 133px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input > div .form-edit-button:hover {
  cursor: pointer;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-col,
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-col.centered-text,
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td.centered-text {
  justify-content: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-col .prop-highlight-arrow-down,
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #4e85af;
  margin-top: -59px;
  position: absolute;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-col .prop-highlight-arrow-down.higher-prop-highlight-arrow-down,
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-arrow-down.higher-prop-highlight-arrow-down {
  margin-top: -63px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-col .prop-highlight-arrow-down.lower-prop-highlight-arrow-down,
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-arrow-down.lower-prop-highlight-arrow-down {
  margin-top: -40px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-col .prop-highlight-arrow-down:hover,
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-arrow-down:hover {
  cursor: pointer;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-col .prop-highlight-options,
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-options {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-col .prop-highlight-options div,
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-options div {
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-col .prop-highlight-options div:nth-child(1),
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-options div:nth-child(1) {
  border-bottom: 3px solid #4e85af;
  height: 25px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 15%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .radio-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td.current-status {
  justify-content: flex-start;
  align-items: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .radio-and-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .radio-and-label input {
  width: 13px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .radio-and-label label {
  margin-left: 5px;
  margin-top: 3px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .address-td {
  width: 14%;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .city-name-td {
  width: 14%;
  display: flex;
  flex-direction: row;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .brokerage-name-td {
  width: 14%;
  margin-left: 15px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .sale-price-td {
  width: 14%;
  position: relative;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .sale-price-input {
  display: flex;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .sale-price-input .sale-price-input-dollar {
  position: absolute;
  top: 2px;
  left: -1px;
  font-size: 15px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .sale-price-input input {
  padding-left: 15px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td {
  width: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .photo-add-photo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .photo-add-photo-container.add-prop {
  max-height: 120px;
  max-width: 160px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 6px solid black;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .photo-add-photo-container:hover {
  cursor: pointer;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .photo-add-photo-container.no-hover:hover {
  cursor: auto;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .photo-add-photo-container img {
  max-height: 120px;
  max-width: 160px;
  height: auto;
  width: auto;
  border: 6px solid black;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .photo-add-photo-container p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  color: #00558f;
}
.photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .address-input-col {
  text-transform: uppercase;
}
.photo-edit-property-container .title-dialog-body-col {
  width: 90%;
  margin: auto;
}
.photo-edit-property-container .title-dialog-body-col .title-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section {
  width: 900px;
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section label {
  margin-left: 20px;
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section .character-count-error {
  color: rgb(184, 37, 37);
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section .old-text-container {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section .old-text-container span {
  font-weight: 700;
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  margin-left: 5px;
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-footer-text {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  display: flex;
  margin-left: 5px;
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section .extra-long-input {
  width: 700px;
  height: 25px;
  margin: auto;
  border: 0;
  border-bottom: 3px solid #4e85af;
  outline: 0;
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus {
  outline: none !important;
}
.photo-edit-property-container .title-dialog-body-col .title-column .title-section:nth-child(2) {
  margin-top: 50px;
  margin-bottom: 50px;
}
.photo-edit-property-container .photo-edit-form-note {
  font-size: 13px;
  text-align: center;
}
.photo-edit-property-container .photo-edit-form-button-container {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 20px 0 15px 10.5%;
}
.photo-edit-property-container .photo-edit-form-button-container .photo-edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-edit-property-container .photo-edit-form-button-container .photo-edit-form-cancel-button:hover {
  cursor: pointer;
}
.photo-edit-property-container .photo-edit-form-button-container .photo-edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.photo-edit-property-container .photo-edit-form-button-container .photo-edit-form-submit-button {
  width: 244px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-edit-property-container .photo-edit-form-button-container .photo-edit-form-submit-button:hover {
  cursor: pointer;
}
.photo-edit-property-container .photo-edit-form-button-container .photo-edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 13.5px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.photo-checkbox-extra-outer-container {
  height: 420px;
  max-height: 630px;
  min-height: 400px;
}
.photo-checkbox-extra-outer-container.adjusted-table-6 {
  height: 420px;
}
.photo-checkbox-extra-outer-container.adjusted-table-5 {
  height: 450px;
}
.photo-checkbox-extra-outer-container.adjusted-table-4 {
  height: 478px;
}
.photo-checkbox-extra-outer-container.adjusted-table-3 {
  height: 509px;
}
.photo-checkbox-extra-outer-container.adjusted-table-2 {
  height: 555px;
}
.photo-checkbox-extra-outer-container.adjusted-table-1 {
  height: 593px;
}
.photo-checkbox-extra-outer-container.adjusted-table-0 {
  height: 620px;
}

.photo-checkbox-outer-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.mailpro-ps-qv4-greetings-ps {
  position: absolute;
  top: 5px;
  right: 0px;
  width: 100%;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-title-container {
  width: auto;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 23px;
  margin-left: 48px;
  width: auto;
  letter-spacing: 0.075em;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider {
  margin-top: 1px;
  height: 1px;
  width: 580px;
  background-color: black;
  z-index: 1;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box {
  width: 97%;
  height: 208px;
  margin: 15px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.mailpro-ps-qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff {
  font-size: 14px;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .greetings-ps-text {
  margin: 0 20px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  text-justify: inter-word;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji {
  height: 16px;
  width: auto;
  margin: 0 10px;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1 {
  font-size: 14.2px;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15 {
  font-size: 13.5px;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14 {
  font-size: 13px;
  line-height: 19px;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .qv4-ps-line {
  margin: 9px 20px 20px 20px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  text-justify: inter-word;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .ye2-ps-line {
  margin: 0px 25px 0 25px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15.5px;
  line-height: 16px;
  text-align: justify;
  text-justify: inter-word;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .agent-signoff {
  display: flex;
  justify-content: flex-end;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15.5px;
  line-height: 21px;
  margin-right: 65px;
  margin-top: 4px;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff {
  margin-top: 16px;
  flex-direction: column;
  align-items: flex-end;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .agent-name {
  margin-left: 5px;
}
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.mailpro-ps-qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji {
  height: 16px;
  width: auto;
  margin: 0 10px;
}

.photo-table-photo-edit-property-container .photo-edit-property-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.photo-table-photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #3c4c55;
}
.photo-table-photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-title-divider {
  margin-top: 0px;
  width: 100%;
  background-color: #4e85af;
  height: 3px;
}
.photo-table-photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-subtitle-text {
  margin-top: 5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 19px;
  color: #00558f;
  display: flex;
  align-items: center;
}
.photo-table-photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-subtitle-text.column-text {
  flex-direction: column;
}
.photo-table-photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-subtitle-text .click-submit-text {
  margin-top: 5px;
}
.photo-table-photo-edit-property-container .photo-edit-property-title-container .photo-edit-property-subtitle-text .subtitle-edit-button {
  margin: 0 7px;
  height: 20px;
  width: 60px;
  border-radius: 10px;
  border: 1px solid #00558f;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #4e85af;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
}
.photo-table-photo-edit-property-container .photo-edit-property-form {
  margin: 30px auto 0 auto;
  border: 2px solid #4e85af;
  border-radius: 15px;
  width: 1200px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form.old-props {
  height: 260px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form.new-props {
  height: 280px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container .photo-edit-property-form-title-text {
  margin-left: 5%;
  margin-top: 15px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  color: #475862;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container .photo-edit-property-form-title-divider {
  width: 696px;
  background-color: #4e85af;
  height: 2px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container .photo-edit-property-form-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  color: #00558f;
  margin-left: 5%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-title-container .photo-edit-property-form-subtitle-text.mt-subtitle {
  margin-top: 15px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: 30px auto 0 auto;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead > div,
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead > div p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.025em;
  line-height: 19px;
  text-transform: uppercase;
  color: #00305e;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .status-th {
  width: 16%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .status-th p {
  text-align: left;
  align-self: flex-start;
  justify-content: flex-start;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .represented-th {
  width: 16%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .represented-th p {
  text-align: left;
  align-self: flex-start;
  justify-content: flex-start;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .address-th {
  width: 16%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .city-th {
  width: 16%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .sale-price-th {
  width: 16%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .brokerage-th {
  width: 16%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .photo-th {
  width: 20%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .edit-form-thead .photo-th p {
  align-self: flex-ensd;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 10px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input input {
  width: 90%;
  height: 25px;
  margin: auto;
  border: 0;
  border-bottom: 3px solid #4e85af;
  outline: 0;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input input:focus {
  outline: none !important;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input > div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: #414042;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input > div .form-edit-button {
  margin-top: 10px;
  width: 60px;
  height: 14px;
  border: 1.33px solid #4e85af;
  color: #4e85af;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 10px;
  text-align: center;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input > div .form-edit-button.upload-photo {
  width: 133px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input > div .form-edit-button:hover {
  cursor: pointer;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td,
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td {
  width: 16%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td.centered-text,
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td.centered-text {
  justify-content: center;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-arrow-down,
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .prop-highlight-arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #4e85af;
  margin-top: -38px;
  position: absolute;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-arrow-down.higher-prop-highlight-arrow-down,
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .prop-highlight-arrow-down.higher-prop-highlight-arrow-down {
  margin-top: -63px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-arrow-down:hover,
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .prop-highlight-arrow-down:hover {
  cursor: pointer;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-options,
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .prop-highlight-options {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-options div,
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .prop-highlight-options div {
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .represented-td .prop-highlight-options div:nth-child(1),
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .status-td .prop-highlight-options div:nth-child(1) {
  border-bottom: 3px solid #4e85af;
  height: 25px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .address-td {
  width: 16%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .city-name-td {
  width: 16%;
  display: flex;
  flex-direction: row;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .brokerage-name-td {
  width: 15%;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .sale-price-td {
  width: 16%;
  display: flex;
  flex-direction: row;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .add-photo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  height: 130px;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .add-photo-container.add-prop {
  max-height: 120px;
  max-width: 160px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 6px solid black;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .add-photo-container:hover {
  cursor: pointer;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .add-photo-container.no-hover:hover {
  cursor: auto;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .add-photo-container img {
  max-height: 120px;
  max-width: 160px;
  height: auto;
  width: auto;
  border: 6px solid black;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .photo-td .add-photo-container p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  color: #00558f;
}
.photo-table-photo-edit-property-container .photo-edit-property-form .photo-edit-property-form-body .photo-edit-form-body-col .photo-edit-form-input .address-input-col {
  text-transform: uppercase;
}
.photo-table-photo-edit-property-container .title-dialog-body-col {
  width: 90%;
  margin: auto;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section {
  width: 900px;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section label {
  margin-left: 20px;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section .character-count-error {
  color: rgb(184, 37, 37);
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section .old-text-container {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section .old-text-container span {
  font-weight: 700;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  margin-left: 5px;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-footer-text {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  display: flex;
  margin-left: 5px;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section .extra-long-input {
  width: 700px;
  height: 25px;
  margin: auto;
  border: 0;
  border-bottom: 3px solid #4e85af;
  outline: 0;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus {
  outline: none !important;
}
.photo-table-photo-edit-property-container .title-dialog-body-col .title-column .title-section:nth-child(2) {
  margin-top: 50px;
  margin-bottom: 50px;
}
.photo-table-photo-edit-property-container .edit-form-button-container {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 20px 0 15px 10.5%;
}
.photo-table-photo-edit-property-container .edit-form-button-container .edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-table-photo-edit-property-container .edit-form-button-container .edit-form-cancel-button:hover {
  cursor: pointer;
}
.photo-table-photo-edit-property-container .edit-form-button-container .edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.photo-table-photo-edit-property-container .edit-form-button-container .edit-form-submit-button {
  width: 178px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-table-photo-edit-property-container .edit-form-button-container .edit-form-submit-button:hover {
  cursor: pointer;
}
.photo-table-photo-edit-property-container .edit-form-button-container .edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.title-dialog-body-col {
  width: 90%;
  margin: auto;
}
.title-dialog-body-col .title-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-dialog-body-col .title-column .title-section {
  width: 900px;
}
.title-dialog-body-col .title-column .title-section label {
  margin-left: 20px;
}
.title-dialog-body-col .title-column .title-section .character-count-error {
  color: rgb(184, 37, 37);
}
.title-dialog-body-col .title-column .title-section .old-text-container {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
}
.title-dialog-body-col .title-column .title-section .old-text-container span {
  font-weight: 700;
}
.title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  margin-left: 5px;
}
.title-dialog-body-col .title-column .title-section .old-text-container .actual-footer-text {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  display: flex;
  margin-left: 5px;
}
.title-dialog-body-col .title-column .title-section .extra-long-input {
  width: 700px;
  height: 25px;
  margin: auto;
  border: 0;
  border-bottom: 3px solid #4e85af;
  outline: 0;
}
.title-dialog-body-col .title-column .title-section .extra-long-input:focus {
  outline: none !important;
}
.title-dialog-body-col .title-column .title-section:nth-child(2) {
  margin-top: 50px;
  margin-bottom: 50px;
}

.edit-form-button-container {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 20px 0 15px 10.5%;
}
.edit-form-button-container .edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-form-button-container .edit-form-cancel-button:hover {
  cursor: pointer;
}
.edit-form-button-container .edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.edit-form-button-container .edit-form-submit-button {
  width: 244px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-form-button-container .edit-form-submit-button:hover {
  cursor: pointer;
}
.edit-form-button-container .edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 13.5px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.update-changes-button {
  align-self: flex-end;
  width: 288px;
  height: 40px;
  border-radius: 5px;
  margin: auto;
  background-color: #4a7396;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
  margin-bottom: 15px;
}
.update-changes-button:hover {
  cursor: pointer;
}
.update-changes-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.photo-modal {
  position: absolute;
  z-index: 8;
  background-color: white;
  top: 0px;
  width: 100%;
  left: -100%;
  margin: auto;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.photo-modal.closed {
  display: none;
}
.photo-modal .photo2-modal-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.photo-modal .photo2-modal-col {
  width: 100%;
  height: 100%;
}
.photo-modal .photo-modal-body {
  height: 750px;
  width: 100%;
  margin: auto;
}
.photo-modal .photo-add-property-row {
  margin: 35px auto 0 auto;
  display: flex;
  align-items: center;
  width: 85%;
}
.photo-modal .photo-add-property-row .add-property-button {
  background-color: #62bb46;
  height: 25px;
  width: 25px;
  max-height: 25px;
  max-width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-modal .photo-add-property-row .add-property-button div {
  font-size: 25px;
  color: white;
  margin-top: -3px;
}
.photo-modal .photo-add-property-row .add-property-button div:hover {
  cursor: pointer;
}
.photo-modal .photo-add-property-row .add-property-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  color: #00558f;
  margin-left: 30px;
  border: 1px solid rgb(0, 79, 172);
  border-radius: 10px;
  padding: 6px 10px;
}
.photo-modal .photo-add-property-row .add-property-text .sold-span {
  color: red;
}
.photo-modal .photo-add-property-row .add-property-text:hover {
  cursor: pointer;
}
.photo-modal .photo-modal-title-section {
  width: 100%;
}
.photo-modal .photo-modal-top-title-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 95%;
  margin: auto;
}
.photo-modal .photo-modal-top-title-row .photo-modal-title {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.photo-modal .photo-modal-top-title-row .photo-modal-title .photo-modal-title-text {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  letter-spacing: 0.025em;
  color: black;
  text-transform: uppercase;
}
.photo-modal .photo-modal-top-title-row .photo-modal-title .photo-modal-title-divider {
  height: 26px;
  width: 2px;
  background-color: black;
  margin: 0 10px;
}
.photo-modal .photo-modal-top-title-row .close-photo-modal-button {
  width: 128px;
  height: 32px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4a7396;
  margin-bottom: 8px;
}
.photo-modal .photo-modal-top-title-row .close-photo-modal-button:hover {
  cursor: pointer;
}
.photo-modal .photo-modal-top-title-row .close-photo-modal-button p {
  color: white;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
}
.photo-modal .photo-modal-top-divider {
  width: 110%;
  height: 2px;
  background-color: #f3d3ae;
  margin: 10px auto;
}
.photo-modal .photo-modal-bottom-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: auto;
}
.photo-modal .photo-modal-bottom-title-row .photo-modal-bottom-title-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.photo-modal .photo-modal-bottom-title-row .photo-modal-bottom-title-area {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: black;
}
.photo-modal .photo-modal-bottom-title-row .photo-modal-bottom-title-date {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  color: black;
}
.photo-modal .photo-modal-information-banner {
  height: 65px;
  width: 82%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaf6f7;
  margin-top: 50px;
  box-shadow: 5px 5px 5px rgb(203, 195, 214);
}
.photo-modal .photo-modal-information-banner .photo-modal-information-banner-text p {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  padding: 0 20px;
  text-align: center;
}
.photo-modal .update-changes-button-container {
  display: flex;
  justify-content: flex-end;
}
.photo-modal .update-changes-button-container .support-photo-modal-button {
  align-self: flex-end;
  height: 32px;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: gray;
}
.photo-modal .update-changes-button-container .support-photo-modal-button:hover {
  cursor: pointer;
}
.photo-modal .update-changes-button-container .support-photo-modal-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 11px;
  letter-spacing: 0.05em;
}
.photo-modal .photo-modal-legend {
  width: 40%;
  margin-left: 20px;
  margin-bottom: 40px;
}
.photo-modal .photo-modal-legend .photo-modal-legend-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.photo-modal .photo-modal-legend .photo-modal-legend-row .photo-modal-my-listing-dot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  margin-right: 10px;
}
.photo-modal .photo-sales-table {
  width: 97%;
  border-collapse: collapse;
}
.photo-modal .photo-sales-table .listing-row-gap {
  height: 30px;
}
.photo-modal .photo-sales-table .table-header-tr {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.photo-modal .photo-sales-table .table-header-tr {
  margin: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 30px;
}
.photo-modal .photo-sales-table .table-header-tr div {
  font-size: 12px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #00305e;
  width: 150px;
}
.photo-modal .photo-sales-table .table-header-tr div p {
  line-height: 11px;
}
.photo-modal .photo-sales-table .table-header-tr div:nth-child(1) {
  width: 55px;
}
.photo-modal .photo-sales-table .table-header-tr div:nth-child(2) {
  width: 55px;
}
.photo-modal .photo-sales-table .table-header-tr div:nth-child(3) {
  width: 95px;
}
.photo-modal .photo-sales-table .table-header-tr div:nth-child(4) {
  width: 120px;
}
.photo-modal .photo-sales-table .table-header-tr div:nth-child(5) {
  width: 150px;
}
.photo-modal .photo-sales-table .table-header-tr div:nth-child(6) {
  width: 300px;
}
.photo-modal .photo-sales-table .table-header-tr div:nth-child(7) {
  width: 150px;
}
.photo-modal .photo-sales-table .row-for-property {
  background-color: #f2eee6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #dddbdb;
}
.photo-modal .photo-sales-table .row-for-property.prop-row-checked {
  background-color: rgb(234, 246, 247);
}
.photo-modal .photo-sales-table .row-for-property.table-header-tr {
  background: none;
  height: 50px;
  margin-top: 30px;
  border: 1px solid pink;
}
.photo-modal .photo-sales-table .row-for-property.table-header-tr div {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #00305e;
  font-size: 15px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  vertical-align: bottom;
  padding-bottom: 5px;
}
.photo-modal .photo-sales-table .row-for-property div {
  vertical-align: middle;
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: black;
}
.photo-modal .photo-sales-table .row-for-property div div {
  height: 19px;
}
.photo-modal .photo-sales-table .row-for-property .list-checked-td {
  width: 45px;
  margin-left: 20px;
}
.photo-modal .photo-sales-table .row-for-property .status-text.sold {
  color: rgb(153, 23, 31);
}
.photo-modal .photo-sales-table .row-for-property .status-text.contingent, .photo-modal .photo-sales-table .row-for-property .status-text.pending {
  color: rgb(0, 79, 172);
}
.photo-modal .photo-sales-table .row-for-property .status-text.active {
  color: rgb(56, 122, 9);
}
.photo-modal .photo-sales-table .row-for-property .photo-row-checkbox {
  height: 25px;
  width: 25px;
}
.photo-modal .photo-sales-table .row-for-property .photo-row-checkbox:hover {
  cursor: pointer;
}
.photo-modal .photo-sales-table .row-for-property .list-num-td {
  width: 50px;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-align: center;
}
.photo-modal .photo-sales-table .row-for-property .edit-btn {
  height: 100%;
  width: 73px;
  display: flex;
  justify-content: center;
}
.photo-modal .photo-sales-table .row-for-property .edit-btn div {
  height: 20px;
  border-radius: 25px;
  border: 1px solid blue;
  background-color: rgb(0, 79, 172);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  text-align: center;
}
.photo-modal .photo-sales-table .row-for-property .edit-btn:hover {
  cursor: pointer;
}
.photo-modal .photo-sales-table .row-for-property .list-status-td {
  width: 117px;
}
.photo-modal .photo-sales-table .row-for-property .list-status-td p {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
}
.photo-modal .photo-sales-table .row-for-property .list-date-td {
  width: 151px;
}
.photo-modal .photo-sales-table .row-for-property .list-date-td .date-td div {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
}
.photo-modal .photo-sales-table .row-for-property .list-date-td.list-date-td-Sold {
  color: rgb(184, 37, 37);
}
.photo-modal .photo-sales-table .row-for-property .list-address-td {
  width: 300px;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
}
.photo-modal .photo-sales-table .row-for-property .list-address-td .list-address-name-td-text {
  margin: 0 7px;
}
.photo-modal .photo-sales-table .row-for-property .list-address-td .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-modal .photo-sales-table .row-for-property .list-address-td .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.photo-modal .photo-sales-table .row-for-property .list-list-price-td {
  font-weight: 400;
  width: 150px;
}
.photo-modal .photo-sales-table .row-for-property .list-list-price-td div {
  margin: auto;
  width: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13.5px;
}
.photo-modal .photo-sales-table .row-for-property .list-list-price-td .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-modal .photo-sales-table .row-for-property .list-list-price-td .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
}
.photo-modal .photo-sales-table .row-for-property .list-list-price-td .list-list-price-p.sold {
  color: rgb(184, 37, 37);
}
.photo-modal .photo-sales-table .row-for-property .list-list-price-td .list-list-price-p.pending, .photo-modal .photo-sales-table .row-for-property .list-list-price-td .list-list-price-p.contingent {
  color: rgb(0, 79, 172);
}
.photo-modal .photo-sales-table .row-for-property .list-list-price-td .list-list-price-p.list-active {
  color: #019091;
}
.photo-modal .photo-sales-table .row-for-property .list-sale-price-td {
  font-weight: 400;
  width: 150px;
}
.photo-modal .photo-sales-table .row-for-property .list-sale-price-td div {
  margin: auto;
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13.5px;
}
.photo-modal .photo-sales-table .row-for-property .list-sale-price-td .list-sale-price-p.sold {
  color: rgb(184, 37, 37);
}
.photo-modal .photo-sales-table .row-for-property .list-sale-price-td .list-sale-price-p.pending, .photo-modal .photo-sales-table .row-for-property .list-sale-price-td .list-sale-price-p.contingent {
  color: rgb(0, 79, 172);
}
.photo-modal .photo-sales-table .row-for-property .list-sale-price-td .list-sale-price-p.list-active {
  color: #019091;
}
.photo-modal .photo-sales-table .row-for-property .list-sale-price-td .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-modal .photo-sales-table .row-for-property .list-sale-price-td .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
}
.photo-modal .photo-sales-table .photo-presolds-row:last-child {
  border: none;
  margin-bottom: 8px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.photo-modal .photo-sales-table .photo-recent-row:first-child,
.photo-modal .photo-sales-table .photo-presolds-row:first-child {
  vertical-align: bottom;
}
.photo-modal .photo-sales-table .photo-recent-row:first-child td,
.photo-modal .photo-sales-table .photo-presolds-row:first-child td {
  padding-top: 10px;
}
.photo-modal .photo-sales-table .single-table-row:last-child {
  border: none;
}

.available-soon {
  width: 100%;
  position: absolute;
  top: 600px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.available-soon p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 45.5px;
  letter-spacing: -0.025em;
  margin-top: 3px;
  color: #53626c;
}

.midquarter-pma-page,
.pma-page-container,
.qv4-pma-editor-page-container,
.mq2-pma-editor-page-container,
.bubble-container-pma-editor-page-container,
.mq2-editor-page-container,
.mq3-editor-page-container,
.ye2-pma-editor-page-container,
.qv2-pma-editor-page-container,
.bubble-compare-editor-page-container,
.table-quadrants-pma-editor-page-container {
  width: 1365px;
  height: 1070px;
  margin: 0 auto;
  background: white;
  background-size: contain;
  position: relative;
}
.midquarter-pma-page .center,
.pma-page-container .center,
.qv4-pma-editor-page-container .center,
.mq2-pma-editor-page-container .center,
.bubble-container-pma-editor-page-container .center,
.mq2-editor-page-container .center,
.mq3-editor-page-container .center,
.ye2-pma-editor-page-container .center,
.qv2-pma-editor-page-container .center,
.bubble-compare-editor-page-container .center,
.table-quadrants-pma-editor-page-container .center {
  text-align: center;
}
.midquarter-pma-page .pl-1,
.pma-page-container .pl-1,
.qv4-pma-editor-page-container .pl-1,
.mq2-pma-editor-page-container .pl-1,
.bubble-container-pma-editor-page-container .pl-1,
.mq2-editor-page-container .pl-1,
.mq3-editor-page-container .pl-1,
.ye2-pma-editor-page-container .pl-1,
.qv2-pma-editor-page-container .pl-1,
.bubble-compare-editor-page-container .pl-1,
.table-quadrants-pma-editor-page-container .pl-1 {
  padding-left: 1em;
}
.midquarter-pma-page .bold,
.pma-page-container .bold,
.qv4-pma-editor-page-container .bold,
.mq2-pma-editor-page-container .bold,
.bubble-container-pma-editor-page-container .bold,
.mq2-editor-page-container .bold,
.mq3-editor-page-container .bold,
.ye2-pma-editor-page-container .bold,
.qv2-pma-editor-page-container .bold,
.bubble-compare-editor-page-container .bold,
.table-quadrants-pma-editor-page-container .bold {
  font-weight: bold;
}
.midquarter-pma-page .mt-three-quarters,
.pma-page-container .mt-three-quarters,
.qv4-pma-editor-page-container .mt-three-quarters,
.mq2-pma-editor-page-container .mt-three-quarters,
.bubble-container-pma-editor-page-container .mt-three-quarters,
.mq2-editor-page-container .mt-three-quarters,
.mq3-editor-page-container .mt-three-quarters,
.ye2-pma-editor-page-container .mt-three-quarters,
.qv2-pma-editor-page-container .mt-three-quarters,
.bubble-compare-editor-page-container .mt-three-quarters,
.table-quadrants-pma-editor-page-container .mt-three-quarters {
  margin-top: 0.75em;
}
.midquarter-pma-page .mx-6,
.pma-page-container .mx-6,
.qv4-pma-editor-page-container .mx-6,
.mq2-pma-editor-page-container .mx-6,
.bubble-container-pma-editor-page-container .mx-6,
.mq2-editor-page-container .mx-6,
.mq3-editor-page-container .mx-6,
.ye2-pma-editor-page-container .mx-6,
.qv2-pma-editor-page-container .mx-6,
.bubble-compare-editor-page-container .mx-6,
.table-quadrants-pma-editor-page-container .mx-6 {
  margin: 0 6px;
}
.midquarter-pma-page h1,
.midquarter-pma-page h2,
.midquarter-pma-page h3,
.midquarter-pma-page h4,
.midquarter-pma-page h5,
.midquarter-pma-page h6,
.pma-page-container h1,
.pma-page-container h2,
.pma-page-container h3,
.pma-page-container h4,
.pma-page-container h5,
.pma-page-container h6,
.qv4-pma-editor-page-container h1,
.qv4-pma-editor-page-container h2,
.qv4-pma-editor-page-container h3,
.qv4-pma-editor-page-container h4,
.qv4-pma-editor-page-container h5,
.qv4-pma-editor-page-container h6,
.mq2-pma-editor-page-container h1,
.mq2-pma-editor-page-container h2,
.mq2-pma-editor-page-container h3,
.mq2-pma-editor-page-container h4,
.mq2-pma-editor-page-container h5,
.mq2-pma-editor-page-container h6,
.bubble-container-pma-editor-page-container h1,
.bubble-container-pma-editor-page-container h2,
.bubble-container-pma-editor-page-container h3,
.bubble-container-pma-editor-page-container h4,
.bubble-container-pma-editor-page-container h5,
.bubble-container-pma-editor-page-container h6,
.mq2-editor-page-container h1,
.mq2-editor-page-container h2,
.mq2-editor-page-container h3,
.mq2-editor-page-container h4,
.mq2-editor-page-container h5,
.mq2-editor-page-container h6,
.mq3-editor-page-container h1,
.mq3-editor-page-container h2,
.mq3-editor-page-container h3,
.mq3-editor-page-container h4,
.mq3-editor-page-container h5,
.mq3-editor-page-container h6,
.ye2-pma-editor-page-container h1,
.ye2-pma-editor-page-container h2,
.ye2-pma-editor-page-container h3,
.ye2-pma-editor-page-container h4,
.ye2-pma-editor-page-container h5,
.ye2-pma-editor-page-container h6,
.qv2-pma-editor-page-container h1,
.qv2-pma-editor-page-container h2,
.qv2-pma-editor-page-container h3,
.qv2-pma-editor-page-container h4,
.qv2-pma-editor-page-container h5,
.qv2-pma-editor-page-container h6,
.bubble-compare-editor-page-container h1,
.bubble-compare-editor-page-container h2,
.bubble-compare-editor-page-container h3,
.bubble-compare-editor-page-container h4,
.bubble-compare-editor-page-container h5,
.bubble-compare-editor-page-container h6,
.table-quadrants-pma-editor-page-container h1,
.table-quadrants-pma-editor-page-container h2,
.table-quadrants-pma-editor-page-container h3,
.table-quadrants-pma-editor-page-container h4,
.table-quadrants-pma-editor-page-container h5,
.table-quadrants-pma-editor-page-container h6 {
  white-space: nowrap !important;
}
.midquarter-pma-page .qv4-greetings-ps,
.pma-page-container .qv4-greetings-ps,
.qv4-pma-editor-page-container .qv4-greetings-ps,
.mq2-pma-editor-page-container .qv4-greetings-ps,
.bubble-container-pma-editor-page-container .qv4-greetings-ps,
.mq2-editor-page-container .qv4-greetings-ps,
.mq3-editor-page-container .qv4-greetings-ps,
.ye2-pma-editor-page-container .qv4-greetings-ps,
.qv2-pma-editor-page-container .qv4-greetings-ps,
.bubble-compare-editor-page-container .qv4-greetings-ps,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps {
  position: absolute;
  top: 90px;
  right: 0px;
  width: 100%;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-title-container,
.pma-page-container .qv4-greetings-ps .greetings-ps-title-container,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-title-container,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-title-container,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-title-container,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container {
  width: auto;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.pma-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 23px;
  margin-left: 48px;
  width: auto;
  letter-spacing: 0.075em;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.pma-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text {
  font-size: 18px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.pma-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text {
  font-size: 15px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.pma-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider {
  margin-top: 1px;
  height: 1px;
  width: 580px;
  background-color: black;
  z-index: 1;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box,
.pma-page-container .qv4-greetings-ps .greetings-ps-box,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box {
  width: 97%;
  height: 170px;
  margin: 15px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.pma-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.pma-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff {
  font-size: 14px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.pma-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box {
  width: 100%;
  margin: 10px auto 0 auto;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.pma-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.pma-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.pma-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line {
  font-size: 14px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.pma-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.pma-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.pma-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text.medium-note-text,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff.medium-note-text,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line.medium-note-text {
  font-size: 14px;
  line-height: 21px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text {
  margin: 0 27px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13.7px;
  line-height: 20px;
  text-align: justify;
  text-justify: inter-word;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1 {
  font-size: 14px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14 {
  font-size: 13px;
  line-height: 19px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15 {
  font-size: 13px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20 {
  font-size: 17.25px;
  line-height: 26px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji {
  height: 16px;
  width: auto;
  margin: 0 10px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line {
  margin: 9px 27px 0 27px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13.7px;
  line-height: 20px;
  text-align: justify;
  text-justify: inter-word;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line {
  margin: 0px 25px 0 25px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  text-justify: inter-word;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff {
  display: flex;
  justify-content: flex-end;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  margin-right: 65px;
  margin-top: 4px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff {
  margin-top: -1px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps {
  margin-top: -17px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20 {
  font-size: 17.25px;
  line-height: 26px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14 {
  font-size: 13px;
  line-height: 16px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff {
  margin-top: 15px;
  flex-direction: column;
  align-items: flex-end;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name {
  margin-top: 6px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .small-note-text,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text {
  font-size: 14px;
  line-height: 21px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .agent-name,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name {
  margin-left: 5px;
}
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.midquarter-pma-page .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.pma-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.qv4-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.mq2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.bubble-container-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.mq2-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.mq3-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.ye2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.qv2-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.bubble-compare-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.table-quadrants-pma-editor-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height {
  font-size: 14px;
  line-height: 21px;
}
.midquarter-pma-page .missing-logo,
.pma-page-container .missing-logo,
.qv4-pma-editor-page-container .missing-logo,
.mq2-pma-editor-page-container .missing-logo,
.bubble-container-pma-editor-page-container .missing-logo,
.mq2-editor-page-container .missing-logo,
.mq3-editor-page-container .missing-logo,
.ye2-pma-editor-page-container .missing-logo,
.qv2-pma-editor-page-container .missing-logo,
.bubble-compare-editor-page-container .missing-logo,
.table-quadrants-pma-editor-page-container .missing-logo {
  border: 3px dotted red;
  height: 40px;
  min-width: 120px;
  max-width: 120px;
  background-color: white;
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 0.5em;
}
.midquarter-pma-page .disclaimer-container,
.pma-page-container .disclaimer-container,
.qv4-pma-editor-page-container .disclaimer-container,
.mq2-pma-editor-page-container .disclaimer-container,
.bubble-container-pma-editor-page-container .disclaimer-container,
.mq2-editor-page-container .disclaimer-container,
.mq3-editor-page-container .disclaimer-container,
.ye2-pma-editor-page-container .disclaimer-container,
.qv2-pma-editor-page-container .disclaimer-container,
.bubble-compare-editor-page-container .disclaimer-container,
.table-quadrants-pma-editor-page-container .disclaimer-container {
  height: 34px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 156px;
  left: -85px;
  width: 373px;
}
.midquarter-pma-page .disclaimer-container .qv4-solicitation-disclaimer,
.midquarter-pma-page .disclaimer-container .qv4-cover-photo-credit,
.midquarter-pma-page .disclaimer-container .qv4-reliable-text,
.pma-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.pma-page-container .disclaimer-container .qv4-cover-photo-credit,
.pma-page-container .disclaimer-container .qv4-reliable-text,
.qv4-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.qv4-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.qv4-pma-editor-page-container .disclaimer-container .qv4-reliable-text,
.mq2-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.mq2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.mq2-pma-editor-page-container .disclaimer-container .qv4-reliable-text,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-reliable-text,
.mq2-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.mq2-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.mq2-editor-page-container .disclaimer-container .qv4-reliable-text,
.mq3-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.mq3-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.mq3-editor-page-container .disclaimer-container .qv4-reliable-text,
.ye2-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.ye2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.ye2-pma-editor-page-container .disclaimer-container .qv4-reliable-text,
.qv2-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.qv2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.qv2-pma-editor-page-container .disclaimer-container .qv4-reliable-text,
.bubble-compare-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.bubble-compare-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.bubble-compare-editor-page-container .disclaimer-container .qv4-reliable-text,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-reliable-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 5.4px;
  letter-spacing: -0.025em;
  margin-top: 3px;
  color: black;
  text-align: center;
}
.midquarter-pma-page .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.midquarter-pma-page .disclaimer-container .qv4-cover-photo-credit.one-trees,
.midquarter-pma-page .disclaimer-container .qv4-reliable-text.one-trees,
.pma-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.pma-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.pma-page-container .disclaimer-container .qv4-reliable-text.one-trees,
.qv4-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.qv4-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.qv4-pma-editor-page-container .disclaimer-container .qv4-reliable-text.one-trees,
.mq2-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.mq2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.mq2-pma-editor-page-container .disclaimer-container .qv4-reliable-text.one-trees,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-reliable-text.one-trees,
.mq2-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.mq2-editor-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.mq2-editor-page-container .disclaimer-container .qv4-reliable-text.one-trees,
.mq3-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.mq3-editor-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.mq3-editor-page-container .disclaimer-container .qv4-reliable-text.one-trees,
.ye2-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.ye2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.ye2-pma-editor-page-container .disclaimer-container .qv4-reliable-text.one-trees,
.qv2-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.qv2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.qv2-pma-editor-page-container .disclaimer-container .qv4-reliable-text.one-trees,
.bubble-compare-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.bubble-compare-editor-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.bubble-compare-editor-page-container .disclaimer-container .qv4-reliable-text.one-trees,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.one-trees,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-reliable-text.one-trees {
  font-size: 6.5px;
  color: #1e453e;
  margin-top: 5px;
}
.midquarter-pma-page .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.midquarter-pma-page .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.midquarter-pma-page .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.pma-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.pma-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.pma-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.qv4-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.qv4-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.qv4-pma-editor-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.mq2-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.mq2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.mq2-pma-editor-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.mq2-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.mq2-editor-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.mq2-editor-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.mq3-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.mq3-editor-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.mq3-editor-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.ye2-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.ye2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.ye2-pma-editor-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.qv2-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.qv2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.qv2-pma-editor-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.bubble-compare-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.bubble-compare-editor-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.bubble-compare-editor-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-solicitation-disclaimer.updated-2024-disclaimer,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit.updated-2024-disclaimer,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-reliable-text.updated-2024-disclaimer {
  margin-bottom: 10px;
}
.midquarter-pma-page .disclaimer-container .qv4-cover-photo-credit,
.pma-page-container .disclaimer-container .qv4-cover-photo-credit,
.qv4-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.mq2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.bubble-container-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.mq2-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.mq3-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.ye2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.qv2-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.bubble-compare-editor-page-container .disclaimer-container .qv4-cover-photo-credit,
.table-quadrants-pma-editor-page-container .disclaimer-container .qv4-cover-photo-credit {
  font-size: 7px;
}
.midquarter-pma-page .barcode,
.pma-page-container .barcode,
.qv4-pma-editor-page-container .barcode,
.mq2-pma-editor-page-container .barcode,
.bubble-container-pma-editor-page-container .barcode,
.mq2-editor-page-container .barcode,
.mq3-editor-page-container .barcode,
.ye2-pma-editor-page-container .barcode,
.qv2-pma-editor-page-container .barcode,
.bubble-compare-editor-page-container .barcode,
.table-quadrants-pma-editor-page-container .barcode {
  font-family: "BarcodeFont";
  font-size: 20px;
  height: 30px;
  position: absolute;
  top: 30px;
  left: 0px;
}
.midquarter-pma-page .mail-info-container,
.pma-page-container .mail-info-container,
.qv4-pma-editor-page-container .mail-info-container,
.mq2-pma-editor-page-container .mail-info-container,
.bubble-container-pma-editor-page-container .mail-info-container,
.mq2-editor-page-container .mail-info-container,
.mq3-editor-page-container .mail-info-container,
.ye2-pma-editor-page-container .mail-info-container,
.qv2-pma-editor-page-container .mail-info-container,
.bubble-compare-editor-page-container .mail-info-container,
.table-quadrants-pma-editor-page-container .mail-info-container {
  position: absolute;
  top: 90px;
  left: 397px;
  height: auto;
  width: 370px;
}
.midquarter-pma-page .disclaimer-container,
.pma-page-container .disclaimer-container,
.qv4-pma-editor-page-container .disclaimer-container,
.mq2-pma-editor-page-container .disclaimer-container,
.bubble-container-pma-editor-page-container .disclaimer-container,
.mq2-editor-page-container .disclaimer-container,
.mq3-editor-page-container .disclaimer-container,
.ye2-pma-editor-page-container .disclaimer-container,
.qv2-pma-editor-page-container .disclaimer-container,
.bubble-compare-editor-page-container .disclaimer-container,
.table-quadrants-pma-editor-page-container .disclaimer-container {
  height: 44px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 166px;
  left: -38px;
  width: 285px;
}
.midquarter-pma-page .disclaimer-container.annette-disclaimer-container,
.pma-page-container .disclaimer-container.annette-disclaimer-container,
.qv4-pma-editor-page-container .disclaimer-container.annette-disclaimer-container,
.mq2-pma-editor-page-container .disclaimer-container.annette-disclaimer-container,
.bubble-container-pma-editor-page-container .disclaimer-container.annette-disclaimer-container,
.mq2-editor-page-container .disclaimer-container.annette-disclaimer-container,
.mq3-editor-page-container .disclaimer-container.annette-disclaimer-container,
.ye2-pma-editor-page-container .disclaimer-container.annette-disclaimer-container,
.qv2-pma-editor-page-container .disclaimer-container.annette-disclaimer-container,
.bubble-compare-editor-page-container .disclaimer-container.annette-disclaimer-container,
.table-quadrants-pma-editor-page-container .disclaimer-container.annette-disclaimer-container {
  top: 174px;
}
.midquarter-pma-page .disclaimer-container.team-solicitation,
.pma-page-container .disclaimer-container.team-solicitation,
.qv4-pma-editor-page-container .disclaimer-container.team-solicitation,
.mq2-pma-editor-page-container .disclaimer-container.team-solicitation,
.bubble-container-pma-editor-page-container .disclaimer-container.team-solicitation,
.mq2-editor-page-container .disclaimer-container.team-solicitation,
.mq3-editor-page-container .disclaimer-container.team-solicitation,
.ye2-pma-editor-page-container .disclaimer-container.team-solicitation,
.qv2-pma-editor-page-container .disclaimer-container.team-solicitation,
.bubble-compare-editor-page-container .disclaimer-container.team-solicitation,
.table-quadrants-pma-editor-page-container .disclaimer-container.team-solicitation {
  top: 170px;
}
.midquarter-pma-page .disclaimer-container .solicitation-disclaimer,
.pma-page-container .disclaimer-container .solicitation-disclaimer,
.qv4-pma-editor-page-container .disclaimer-container .solicitation-disclaimer,
.mq2-pma-editor-page-container .disclaimer-container .solicitation-disclaimer,
.bubble-container-pma-editor-page-container .disclaimer-container .solicitation-disclaimer,
.mq2-editor-page-container .disclaimer-container .solicitation-disclaimer,
.mq3-editor-page-container .disclaimer-container .solicitation-disclaimer,
.ye2-pma-editor-page-container .disclaimer-container .solicitation-disclaimer,
.qv2-pma-editor-page-container .disclaimer-container .solicitation-disclaimer,
.bubble-compare-editor-page-container .disclaimer-container .solicitation-disclaimer,
.table-quadrants-pma-editor-page-container .disclaimer-container .solicitation-disclaimer {
  height: 12px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 5.3px;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  margin: 3px auto 0 auto;
}
.midquarter-pma-page .disclaimer-container .mls-disclaimer,
.pma-page-container .disclaimer-container .mls-disclaimer,
.qv4-pma-editor-page-container .disclaimer-container .mls-disclaimer,
.mq2-pma-editor-page-container .disclaimer-container .mls-disclaimer,
.bubble-container-pma-editor-page-container .disclaimer-container .mls-disclaimer,
.mq2-editor-page-container .disclaimer-container .mls-disclaimer,
.mq3-editor-page-container .disclaimer-container .mls-disclaimer,
.ye2-pma-editor-page-container .disclaimer-container .mls-disclaimer,
.qv2-pma-editor-page-container .disclaimer-container .mls-disclaimer,
.bubble-compare-editor-page-container .disclaimer-container .mls-disclaimer,
.table-quadrants-pma-editor-page-container .disclaimer-container .mls-disclaimer {
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
}
.midquarter-pma-page .solicitation-disclaimer,
.midquarter-pma-page .cover-photo-credit,
.midquarter-pma-page .reliable-text,
.pma-page-container .solicitation-disclaimer,
.pma-page-container .cover-photo-credit,
.pma-page-container .reliable-text,
.qv4-pma-editor-page-container .solicitation-disclaimer,
.qv4-pma-editor-page-container .cover-photo-credit,
.qv4-pma-editor-page-container .reliable-text,
.mq2-pma-editor-page-container .solicitation-disclaimer,
.mq2-pma-editor-page-container .cover-photo-credit,
.mq2-pma-editor-page-container .reliable-text,
.bubble-container-pma-editor-page-container .solicitation-disclaimer,
.bubble-container-pma-editor-page-container .cover-photo-credit,
.bubble-container-pma-editor-page-container .reliable-text,
.mq2-editor-page-container .solicitation-disclaimer,
.mq2-editor-page-container .cover-photo-credit,
.mq2-editor-page-container .reliable-text,
.mq3-editor-page-container .solicitation-disclaimer,
.mq3-editor-page-container .cover-photo-credit,
.mq3-editor-page-container .reliable-text,
.ye2-pma-editor-page-container .solicitation-disclaimer,
.ye2-pma-editor-page-container .cover-photo-credit,
.ye2-pma-editor-page-container .reliable-text,
.qv2-pma-editor-page-container .solicitation-disclaimer,
.qv2-pma-editor-page-container .cover-photo-credit,
.qv2-pma-editor-page-container .reliable-text,
.bubble-compare-editor-page-container .solicitation-disclaimer,
.bubble-compare-editor-page-container .cover-photo-credit,
.bubble-compare-editor-page-container .reliable-text,
.table-quadrants-pma-editor-page-container .solicitation-disclaimer,
.table-quadrants-pma-editor-page-container .cover-photo-credit,
.table-quadrants-pma-editor-page-container .reliable-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 5.4px;
  letter-spacing: -0.025em;
  margin-top: 2px;
  color: black;
}
.midquarter-pma-page .solicitation-disclaimer.one-trees,
.midquarter-pma-page .cover-photo-credit.one-trees,
.midquarter-pma-page .reliable-text.one-trees,
.pma-page-container .solicitation-disclaimer.one-trees,
.pma-page-container .cover-photo-credit.one-trees,
.pma-page-container .reliable-text.one-trees,
.qv4-pma-editor-page-container .solicitation-disclaimer.one-trees,
.qv4-pma-editor-page-container .cover-photo-credit.one-trees,
.qv4-pma-editor-page-container .reliable-text.one-trees,
.mq2-pma-editor-page-container .solicitation-disclaimer.one-trees,
.mq2-pma-editor-page-container .cover-photo-credit.one-trees,
.mq2-pma-editor-page-container .reliable-text.one-trees,
.bubble-container-pma-editor-page-container .solicitation-disclaimer.one-trees,
.bubble-container-pma-editor-page-container .cover-photo-credit.one-trees,
.bubble-container-pma-editor-page-container .reliable-text.one-trees,
.mq2-editor-page-container .solicitation-disclaimer.one-trees,
.mq2-editor-page-container .cover-photo-credit.one-trees,
.mq2-editor-page-container .reliable-text.one-trees,
.mq3-editor-page-container .solicitation-disclaimer.one-trees,
.mq3-editor-page-container .cover-photo-credit.one-trees,
.mq3-editor-page-container .reliable-text.one-trees,
.ye2-pma-editor-page-container .solicitation-disclaimer.one-trees,
.ye2-pma-editor-page-container .cover-photo-credit.one-trees,
.ye2-pma-editor-page-container .reliable-text.one-trees,
.qv2-pma-editor-page-container .solicitation-disclaimer.one-trees,
.qv2-pma-editor-page-container .cover-photo-credit.one-trees,
.qv2-pma-editor-page-container .reliable-text.one-trees,
.bubble-compare-editor-page-container .solicitation-disclaimer.one-trees,
.bubble-compare-editor-page-container .cover-photo-credit.one-trees,
.bubble-compare-editor-page-container .reliable-text.one-trees,
.table-quadrants-pma-editor-page-container .solicitation-disclaimer.one-trees,
.table-quadrants-pma-editor-page-container .cover-photo-credit.one-trees,
.table-quadrants-pma-editor-page-container .reliable-text.one-trees {
  font-size: 6px;
  color: black;
  margin-top: 11px;
}
.midquarter-pma-page .solicitation-disclaimer.one-trees p,
.midquarter-pma-page .cover-photo-credit.one-trees p,
.midquarter-pma-page .reliable-text.one-trees p,
.pma-page-container .solicitation-disclaimer.one-trees p,
.pma-page-container .cover-photo-credit.one-trees p,
.pma-page-container .reliable-text.one-trees p,
.qv4-pma-editor-page-container .solicitation-disclaimer.one-trees p,
.qv4-pma-editor-page-container .cover-photo-credit.one-trees p,
.qv4-pma-editor-page-container .reliable-text.one-trees p,
.mq2-pma-editor-page-container .solicitation-disclaimer.one-trees p,
.mq2-pma-editor-page-container .cover-photo-credit.one-trees p,
.mq2-pma-editor-page-container .reliable-text.one-trees p,
.bubble-container-pma-editor-page-container .solicitation-disclaimer.one-trees p,
.bubble-container-pma-editor-page-container .cover-photo-credit.one-trees p,
.bubble-container-pma-editor-page-container .reliable-text.one-trees p,
.mq2-editor-page-container .solicitation-disclaimer.one-trees p,
.mq2-editor-page-container .cover-photo-credit.one-trees p,
.mq2-editor-page-container .reliable-text.one-trees p,
.mq3-editor-page-container .solicitation-disclaimer.one-trees p,
.mq3-editor-page-container .cover-photo-credit.one-trees p,
.mq3-editor-page-container .reliable-text.one-trees p,
.ye2-pma-editor-page-container .solicitation-disclaimer.one-trees p,
.ye2-pma-editor-page-container .cover-photo-credit.one-trees p,
.ye2-pma-editor-page-container .reliable-text.one-trees p,
.qv2-pma-editor-page-container .solicitation-disclaimer.one-trees p,
.qv2-pma-editor-page-container .cover-photo-credit.one-trees p,
.qv2-pma-editor-page-container .reliable-text.one-trees p,
.bubble-compare-editor-page-container .solicitation-disclaimer.one-trees p,
.bubble-compare-editor-page-container .cover-photo-credit.one-trees p,
.bubble-compare-editor-page-container .reliable-text.one-trees p,
.table-quadrants-pma-editor-page-container .solicitation-disclaimer.one-trees p,
.table-quadrants-pma-editor-page-container .cover-photo-credit.one-trees p,
.table-quadrants-pma-editor-page-container .reliable-text.one-trees p {
  text-align: center;
}
.midquarter-pma-page .solicitation-disclaimer.updated-2024-disclaimer,
.midquarter-pma-page .cover-photo-credit.updated-2024-disclaimer,
.midquarter-pma-page .reliable-text.updated-2024-disclaimer,
.pma-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.pma-page-container .cover-photo-credit.updated-2024-disclaimer,
.pma-page-container .reliable-text.updated-2024-disclaimer,
.qv4-pma-editor-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.qv4-pma-editor-page-container .cover-photo-credit.updated-2024-disclaimer,
.qv4-pma-editor-page-container .reliable-text.updated-2024-disclaimer,
.mq2-pma-editor-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.mq2-pma-editor-page-container .cover-photo-credit.updated-2024-disclaimer,
.mq2-pma-editor-page-container .reliable-text.updated-2024-disclaimer,
.bubble-container-pma-editor-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.bubble-container-pma-editor-page-container .cover-photo-credit.updated-2024-disclaimer,
.bubble-container-pma-editor-page-container .reliable-text.updated-2024-disclaimer,
.mq2-editor-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.mq2-editor-page-container .cover-photo-credit.updated-2024-disclaimer,
.mq2-editor-page-container .reliable-text.updated-2024-disclaimer,
.mq3-editor-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.mq3-editor-page-container .cover-photo-credit.updated-2024-disclaimer,
.mq3-editor-page-container .reliable-text.updated-2024-disclaimer,
.ye2-pma-editor-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.ye2-pma-editor-page-container .cover-photo-credit.updated-2024-disclaimer,
.ye2-pma-editor-page-container .reliable-text.updated-2024-disclaimer,
.qv2-pma-editor-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.qv2-pma-editor-page-container .cover-photo-credit.updated-2024-disclaimer,
.qv2-pma-editor-page-container .reliable-text.updated-2024-disclaimer,
.bubble-compare-editor-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.bubble-compare-editor-page-container .cover-photo-credit.updated-2024-disclaimer,
.bubble-compare-editor-page-container .reliable-text.updated-2024-disclaimer,
.table-quadrants-pma-editor-page-container .solicitation-disclaimer.updated-2024-disclaimer,
.table-quadrants-pma-editor-page-container .cover-photo-credit.updated-2024-disclaimer,
.table-quadrants-pma-editor-page-container .reliable-text.updated-2024-disclaimer {
  margin-bottom: 10px;
}
.midquarter-pma-page .endorsement,
.pma-page-container .endorsement,
.qv4-pma-editor-page-container .endorsement,
.mq2-pma-editor-page-container .endorsement,
.bubble-container-pma-editor-page-container .endorsement,
.mq2-editor-page-container .endorsement,
.mq3-editor-page-container .endorsement,
.ye2-pma-editor-page-container .endorsement,
.qv2-pma-editor-page-container .endorsement,
.bubble-compare-editor-page-container .endorsement,
.table-quadrants-pma-editor-page-container .endorsement {
  display: flex;
  font-size: 8px;
  width: auto;
  position: absolute;
  top: 58px;
  left: 0px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  color: black;
}
.midquarter-pma-page .endorsement p:first-child,
.pma-page-container .endorsement p:first-child,
.qv4-pma-editor-page-container .endorsement p:first-child,
.mq2-pma-editor-page-container .endorsement p:first-child,
.bubble-container-pma-editor-page-container .endorsement p:first-child,
.mq2-editor-page-container .endorsement p:first-child,
.mq3-editor-page-container .endorsement p:first-child,
.ye2-pma-editor-page-container .endorsement p:first-child,
.qv2-pma-editor-page-container .endorsement p:first-child,
.bubble-compare-editor-page-container .endorsement p:first-child,
.table-quadrants-pma-editor-page-container .endorsement p:first-child {
  margin-right: 1em;
}
.midquarter-pma-page .endorsement p:nth-child(2),
.pma-page-container .endorsement p:nth-child(2),
.qv4-pma-editor-page-container .endorsement p:nth-child(2),
.mq2-pma-editor-page-container .endorsement p:nth-child(2),
.bubble-container-pma-editor-page-container .endorsement p:nth-child(2),
.mq2-editor-page-container .endorsement p:nth-child(2),
.mq3-editor-page-container .endorsement p:nth-child(2),
.ye2-pma-editor-page-container .endorsement p:nth-child(2),
.qv2-pma-editor-page-container .endorsement p:nth-child(2),
.bubble-compare-editor-page-container .endorsement p:nth-child(2),
.table-quadrants-pma-editor-page-container .endorsement p:nth-child(2) {
  margin-right: 0.4em;
}
.midquarter-pma-page .endorsement p:nth-child(3),
.pma-page-container .endorsement p:nth-child(3),
.qv4-pma-editor-page-container .endorsement p:nth-child(3),
.mq2-pma-editor-page-container .endorsement p:nth-child(3),
.bubble-container-pma-editor-page-container .endorsement p:nth-child(3),
.mq2-editor-page-container .endorsement p:nth-child(3),
.mq3-editor-page-container .endorsement p:nth-child(3),
.ye2-pma-editor-page-container .endorsement p:nth-child(3),
.qv2-pma-editor-page-container .endorsement p:nth-child(3),
.bubble-compare-editor-page-container .endorsement p:nth-child(3),
.table-quadrants-pma-editor-page-container .endorsement p:nth-child(3) {
  margin-right: 0.4em;
}
.midquarter-pma-page .endorsement p:nth-child(4),
.pma-page-container .endorsement p:nth-child(4),
.qv4-pma-editor-page-container .endorsement p:nth-child(4),
.mq2-pma-editor-page-container .endorsement p:nth-child(4),
.bubble-container-pma-editor-page-container .endorsement p:nth-child(4),
.mq2-editor-page-container .endorsement p:nth-child(4),
.mq3-editor-page-container .endorsement p:nth-child(4),
.ye2-pma-editor-page-container .endorsement p:nth-child(4),
.qv2-pma-editor-page-container .endorsement p:nth-child(4),
.bubble-compare-editor-page-container .endorsement p:nth-child(4),
.table-quadrants-pma-editor-page-container .endorsement p:nth-child(4) {
  margin-right: 0.4em;
}
.midquarter-pma-page .endorsement p:nth-child(5),
.pma-page-container .endorsement p:nth-child(5),
.qv4-pma-editor-page-container .endorsement p:nth-child(5),
.mq2-pma-editor-page-container .endorsement p:nth-child(5),
.bubble-container-pma-editor-page-container .endorsement p:nth-child(5),
.mq2-editor-page-container .endorsement p:nth-child(5),
.mq3-editor-page-container .endorsement p:nth-child(5),
.ye2-pma-editor-page-container .endorsement p:nth-child(5),
.qv2-pma-editor-page-container .endorsement p:nth-child(5),
.bubble-compare-editor-page-container .endorsement p:nth-child(5),
.table-quadrants-pma-editor-page-container .endorsement p:nth-child(5) {
  margin-right: 0.4em;
}
.midquarter-pma-page .electronic-service,
.pma-page-container .electronic-service,
.qv4-pma-editor-page-container .electronic-service,
.mq2-pma-editor-page-container .electronic-service,
.bubble-container-pma-editor-page-container .electronic-service,
.mq2-editor-page-container .electronic-service,
.mq3-editor-page-container .electronic-service,
.ye2-pma-editor-page-container .electronic-service,
.qv2-pma-editor-page-container .electronic-service,
.bubble-compare-editor-page-container .electronic-service,
.table-quadrants-pma-editor-page-container .electronic-service {
  position: absolute;
  top: 0;
  left: 25px;
  font-size: 9px;
  margin-bottom: 0.5em;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  color: black;
}
.midquarter-pma-page .barcode,
.pma-page-container .barcode,
.qv4-pma-editor-page-container .barcode,
.mq2-pma-editor-page-container .barcode,
.bubble-container-pma-editor-page-container .barcode,
.mq2-editor-page-container .barcode,
.mq3-editor-page-container .barcode,
.ye2-pma-editor-page-container .barcode,
.qv2-pma-editor-page-container .barcode,
.bubble-compare-editor-page-container .barcode,
.table-quadrants-pma-editor-page-container .barcode {
  font-family: "usps4cb";
  font-size: 14px;
  height: 30px;
  position: absolute;
  top: 30px;
  left: 0px;
}
.midquarter-pma-page .mq-ho-address,
.pma-page-container .mq-ho-address,
.qv4-pma-editor-page-container .mq-ho-address,
.mq2-pma-editor-page-container .mq-ho-address,
.bubble-container-pma-editor-page-container .mq-ho-address,
.mq2-editor-page-container .mq-ho-address,
.mq3-editor-page-container .mq-ho-address,
.ye2-pma-editor-page-container .mq-ho-address,
.qv2-pma-editor-page-container .mq-ho-address,
.bubble-compare-editor-page-container .mq-ho-address,
.table-quadrants-pma-editor-page-container .mq-ho-address {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  top: 71px;
  left: 0px;
  font-size: 9px;
  color: black;
}
.midquarter-pma-page .mq-ho-address .ho-mailing-info .ho-name,
.pma-page-container .mq-ho-address .ho-mailing-info .ho-name,
.qv4-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-name,
.mq2-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-name,
.bubble-container-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-name,
.mq2-editor-page-container .mq-ho-address .ho-mailing-info .ho-name,
.mq3-editor-page-container .mq-ho-address .ho-mailing-info .ho-name,
.ye2-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-name,
.qv2-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-name,
.bubble-compare-editor-page-container .mq-ho-address .ho-mailing-info .ho-name,
.table-quadrants-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-name {
  font-weight: 600;
  font-size: 11.5px;
}
.midquarter-pma-page .mq-ho-address .ho-mailing-info .ho-second-name,
.pma-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.qv4-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.mq2-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.bubble-container-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.mq2-editor-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.mq3-editor-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.ye2-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.qv2-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.bubble-compare-editor-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.table-quadrants-pma-editor-page-container .mq-ho-address .ho-mailing-info .ho-second-name {
  font-weight: 600;
  font-size: 11.5px;
}
.midquarter-pma-page .mq-ho-address p,
.pma-page-container .mq-ho-address p,
.qv4-pma-editor-page-container .mq-ho-address p,
.mq2-pma-editor-page-container .mq-ho-address p,
.bubble-container-pma-editor-page-container .mq-ho-address p,
.mq2-editor-page-container .mq-ho-address p,
.mq3-editor-page-container .mq-ho-address p,
.ye2-pma-editor-page-container .mq-ho-address p,
.qv2-pma-editor-page-container .mq-ho-address p,
.bubble-compare-editor-page-container .mq-ho-address p,
.table-quadrants-pma-editor-page-container .mq-ho-address p {
  line-height: 20px;
}
.midquarter-pma-page .mq-ho-address .row,
.pma-page-container .mq-ho-address .row,
.qv4-pma-editor-page-container .mq-ho-address .row,
.mq2-pma-editor-page-container .mq-ho-address .row,
.bubble-container-pma-editor-page-container .mq-ho-address .row,
.mq2-editor-page-container .mq-ho-address .row,
.mq3-editor-page-container .mq-ho-address .row,
.ye2-pma-editor-page-container .mq-ho-address .row,
.qv2-pma-editor-page-container .mq-ho-address .row,
.bubble-compare-editor-page-container .mq-ho-address .row,
.table-quadrants-pma-editor-page-container .mq-ho-address .row {
  display: flex;
  flex-wrap: wrap;
}
.midquarter-pma-page .mq-ho-address .row p:first-child,
.pma-page-container .mq-ho-address .row p:first-child,
.qv4-pma-editor-page-container .mq-ho-address .row p:first-child,
.mq2-pma-editor-page-container .mq-ho-address .row p:first-child,
.bubble-container-pma-editor-page-container .mq-ho-address .row p:first-child,
.mq2-editor-page-container .mq-ho-address .row p:first-child,
.mq3-editor-page-container .mq-ho-address .row p:first-child,
.ye2-pma-editor-page-container .mq-ho-address .row p:first-child,
.qv2-pma-editor-page-container .mq-ho-address .row p:first-child,
.bubble-compare-editor-page-container .mq-ho-address .row p:first-child,
.table-quadrants-pma-editor-page-container .mq-ho-address .row p:first-child {
  margin-right: 5px;
}
.midquarter-pma-page .mq-ho-address .row p:nth-child(2),
.pma-page-container .mq-ho-address .row p:nth-child(2),
.qv4-pma-editor-page-container .mq-ho-address .row p:nth-child(2),
.mq2-pma-editor-page-container .mq-ho-address .row p:nth-child(2),
.bubble-container-pma-editor-page-container .mq-ho-address .row p:nth-child(2),
.mq2-editor-page-container .mq-ho-address .row p:nth-child(2),
.mq3-editor-page-container .mq-ho-address .row p:nth-child(2),
.ye2-pma-editor-page-container .mq-ho-address .row p:nth-child(2),
.qv2-pma-editor-page-container .mq-ho-address .row p:nth-child(2),
.bubble-compare-editor-page-container .mq-ho-address .row p:nth-child(2),
.table-quadrants-pma-editor-page-container .mq-ho-address .row p:nth-child(2) {
  margin-right: 5px;
}
.midquarter-pma-page .breakMarkContainer,
.pma-page-container .breakMarkContainer,
.qv4-pma-editor-page-container .breakMarkContainer,
.mq2-pma-editor-page-container .breakMarkContainer,
.bubble-container-pma-editor-page-container .breakMarkContainer,
.mq2-editor-page-container .breakMarkContainer,
.mq3-editor-page-container .breakMarkContainer,
.ye2-pma-editor-page-container .breakMarkContainer,
.qv2-pma-editor-page-container .breakMarkContainer,
.bubble-compare-editor-page-container .breakMarkContainer,
.table-quadrants-pma-editor-page-container .breakMarkContainer {
  display: flex;
  height: 209px;
  width: 10px;
  margin-top: 7.3em;
  margin-left: -2em;
  left: 0 !important;
}
.midquarter-pma-page .breakMarkBlue,
.pma-page-container .breakMarkBlue,
.qv4-pma-editor-page-container .breakMarkBlue,
.mq2-pma-editor-page-container .breakMarkBlue,
.bubble-container-pma-editor-page-container .breakMarkBlue,
.mq2-editor-page-container .breakMarkBlue,
.mq3-editor-page-container .breakMarkBlue,
.ye2-pma-editor-page-container .breakMarkBlue,
.qv2-pma-editor-page-container .breakMarkBlue,
.bubble-compare-editor-page-container .breakMarkBlue,
.table-quadrants-pma-editor-page-container .breakMarkBlue {
  background-color: #0f53a4;
}
.midquarter-pma-page .breakMarkLightGreen,
.pma-page-container .breakMarkLightGreen,
.qv4-pma-editor-page-container .breakMarkLightGreen,
.mq2-pma-editor-page-container .breakMarkLightGreen,
.bubble-container-pma-editor-page-container .breakMarkLightGreen,
.mq2-editor-page-container .breakMarkLightGreen,
.mq3-editor-page-container .breakMarkLightGreen,
.ye2-pma-editor-page-container .breakMarkLightGreen,
.qv2-pma-editor-page-container .breakMarkLightGreen,
.bubble-compare-editor-page-container .breakMarkLightGreen,
.table-quadrants-pma-editor-page-container .breakMarkLightGreen {
  background-color: #3fa5b9;
}
.midquarter-pma-page .breakMarkHeightWithEqual,
.pma-page-container .breakMarkHeightWithEqual,
.qv4-pma-editor-page-container .breakMarkHeightWithEqual,
.mq2-pma-editor-page-container .breakMarkHeightWithEqual,
.bubble-container-pma-editor-page-container .breakMarkHeightWithEqual,
.mq2-editor-page-container .breakMarkHeightWithEqual,
.mq3-editor-page-container .breakMarkHeightWithEqual,
.ye2-pma-editor-page-container .breakMarkHeightWithEqual,
.qv2-pma-editor-page-container .breakMarkHeightWithEqual,
.bubble-compare-editor-page-container .breakMarkHeightWithEqual,
.table-quadrants-pma-editor-page-container .breakMarkHeightWithEqual {
  width: 100%;
  height: 100%;
}
.midquarter-pma-page .breakMarkHeightWithHalfEqual,
.pma-page-container .breakMarkHeightWithHalfEqual,
.qv4-pma-editor-page-container .breakMarkHeightWithHalfEqual,
.mq2-pma-editor-page-container .breakMarkHeightWithHalfEqual,
.bubble-container-pma-editor-page-container .breakMarkHeightWithHalfEqual,
.mq2-editor-page-container .breakMarkHeightWithHalfEqual,
.mq3-editor-page-container .breakMarkHeightWithHalfEqual,
.ye2-pma-editor-page-container .breakMarkHeightWithHalfEqual,
.qv2-pma-editor-page-container .breakMarkHeightWithHalfEqual,
.bubble-compare-editor-page-container .breakMarkHeightWithHalfEqual,
.table-quadrants-pma-editor-page-container .breakMarkHeightWithHalfEqual {
  width: 100%;
  height: 50%;
}
.midquarter-pma-page .flexContainer,
.pma-page-container .flexContainer,
.qv4-pma-editor-page-container .flexContainer,
.mq2-pma-editor-page-container .flexContainer,
.bubble-container-pma-editor-page-container .flexContainer,
.mq2-editor-page-container .flexContainer,
.mq3-editor-page-container .flexContainer,
.ye2-pma-editor-page-container .flexContainer,
.qv2-pma-editor-page-container .flexContainer,
.bubble-compare-editor-page-container .flexContainer,
.table-quadrants-pma-editor-page-container .flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.midquarter-pma-page .soldMMYY,
.pma-page-container .soldMMYY,
.qv4-pma-editor-page-container .soldMMYY,
.mq2-pma-editor-page-container .soldMMYY,
.bubble-container-pma-editor-page-container .soldMMYY,
.mq2-editor-page-container .soldMMYY,
.mq3-editor-page-container .soldMMYY,
.ye2-pma-editor-page-container .soldMMYY,
.qv2-pma-editor-page-container .soldMMYY,
.bubble-compare-editor-page-container .soldMMYY,
.table-quadrants-pma-editor-page-container .soldMMYY {
  margin-left: 0px;
  color: #b82425;
}
.midquarter-pma-page .noteWeightBold,
.pma-page-container .noteWeightBold,
.qv4-pma-editor-page-container .noteWeightBold,
.mq2-pma-editor-page-container .noteWeightBold,
.bubble-container-pma-editor-page-container .noteWeightBold,
.mq2-editor-page-container .noteWeightBold,
.mq3-editor-page-container .noteWeightBold,
.ye2-pma-editor-page-container .noteWeightBold,
.qv2-pma-editor-page-container .noteWeightBold,
.bubble-compare-editor-page-container .noteWeightBold,
.table-quadrants-pma-editor-page-container .noteWeightBold {
  font-weight: 500 !important;
}
.midquarter-pma-page .nowrapLine,
.pma-page-container .nowrapLine,
.qv4-pma-editor-page-container .nowrapLine,
.mq2-pma-editor-page-container .nowrapLine,
.bubble-container-pma-editor-page-container .nowrapLine,
.mq2-editor-page-container .nowrapLine,
.mq3-editor-page-container .nowrapLine,
.ye2-pma-editor-page-container .nowrapLine,
.qv2-pma-editor-page-container .nowrapLine,
.bubble-compare-editor-page-container .nowrapLine,
.table-quadrants-pma-editor-page-container .nowrapLine {
  white-space: nowrap !important;
}
.midquarter-pma-page .leftAlign,
.pma-page-container .leftAlign,
.qv4-pma-editor-page-container .leftAlign,
.mq2-pma-editor-page-container .leftAlign,
.bubble-container-pma-editor-page-container .leftAlign,
.mq2-editor-page-container .leftAlign,
.mq3-editor-page-container .leftAlign,
.ye2-pma-editor-page-container .leftAlign,
.qv2-pma-editor-page-container .leftAlign,
.bubble-compare-editor-page-container .leftAlign,
.table-quadrants-pma-editor-page-container .leftAlign {
  text-align: left !important;
}
.midquarter-pma-page .rightAlign,
.pma-page-container .rightAlign,
.qv4-pma-editor-page-container .rightAlign,
.mq2-pma-editor-page-container .rightAlign,
.bubble-container-pma-editor-page-container .rightAlign,
.mq2-editor-page-container .rightAlign,
.mq3-editor-page-container .rightAlign,
.ye2-pma-editor-page-container .rightAlign,
.qv2-pma-editor-page-container .rightAlign,
.bubble-compare-editor-page-container .rightAlign,
.table-quadrants-pma-editor-page-container .rightAlign {
  text-align: right !important;
}
.midquarter-pma-page .qv4-photo-section-container,
.pma-page-container .qv4-photo-section-container,
.qv4-pma-editor-page-container .qv4-photo-section-container,
.mq2-pma-editor-page-container .qv4-photo-section-container,
.bubble-container-pma-editor-page-container .qv4-photo-section-container,
.mq2-editor-page-container .qv4-photo-section-container,
.mq3-editor-page-container .qv4-photo-section-container,
.ye2-pma-editor-page-container .qv4-photo-section-container,
.qv2-pma-editor-page-container .qv4-photo-section-container,
.bubble-compare-editor-page-container .qv4-photo-section-container,
.table-quadrants-pma-editor-page-container .qv4-photo-section-container {
  position: absolute;
  top: 333px;
  left: 0px;
  width: 100%;
}
.midquarter-pma-page .qv4-photo-section-container .qv4-photo-section-title,
.pma-page-container .qv4-photo-section-container .qv4-photo-section-title,
.qv4-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title,
.mq2-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title,
.bubble-container-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title,
.mq2-editor-page-container .qv4-photo-section-container .qv4-photo-section-title,
.mq3-editor-page-container .qv4-photo-section-container .qv4-photo-section-title,
.ye2-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title,
.qv2-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title,
.bubble-compare-editor-page-container .qv4-photo-section-container .qv4-photo-section-title,
.table-quadrants-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: black;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.midquarter-pma-page .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.pma-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.qv4-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.mq2-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.bubble-container-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.mq2-editor-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.mq3-editor-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.ye2-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.qv2-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.bubble-compare-editor-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.table-quadrants-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title {
  font-weight: 500;
  margin-bottom: 3px;
}
.midquarter-pma-page .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.pma-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.qv4-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.mq2-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.bubble-container-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.mq2-editor-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.mq3-editor-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.ye2-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.qv2-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.bubble-compare-editor-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.table-quadrants-pma-editor-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood {
  text-transform: uppercase;
  margin-left: 6px;
  margin-bottom: -1px;
}
.midquarter-pma-page .qv4-photo-section-container .mls-listings,
.pma-page-container .qv4-photo-section-container .mls-listings,
.qv4-pma-editor-page-container .qv4-photo-section-container .mls-listings,
.mq2-pma-editor-page-container .qv4-photo-section-container .mls-listings,
.bubble-container-pma-editor-page-container .qv4-photo-section-container .mls-listings,
.mq2-editor-page-container .qv4-photo-section-container .mls-listings,
.mq3-editor-page-container .qv4-photo-section-container .mls-listings,
.ye2-pma-editor-page-container .qv4-photo-section-container .mls-listings,
.qv2-pma-editor-page-container .qv4-photo-section-container .mls-listings,
.bubble-compare-editor-page-container .qv4-photo-section-container .mls-listings,
.table-quadrants-pma-editor-page-container .qv4-photo-section-container .mls-listings {
  width: 100%;
  margin: 15px auto 0 auto;
  text-align: center;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
}
.midquarter-pma-page .qr-photo-section-title,
.pma-page-container .qr-photo-section-title,
.qv4-pma-editor-page-container .qr-photo-section-title,
.mq2-pma-editor-page-container .qr-photo-section-title,
.bubble-container-pma-editor-page-container .qr-photo-section-title,
.mq2-editor-page-container .qr-photo-section-title,
.mq3-editor-page-container .qr-photo-section-title,
.ye2-pma-editor-page-container .qr-photo-section-title,
.qv2-pma-editor-page-container .qr-photo-section-title,
.bubble-compare-editor-page-container .qr-photo-section-title,
.table-quadrants-pma-editor-page-container .qr-photo-section-title {
  margin-top: -13px;
  margin-bottom: 13px;
  text-transform: none;
}
.midquarter-pma-page .qr-photo-section-title .qr-text-new,
.pma-page-container .qr-photo-section-title .qr-text-new,
.qv4-pma-editor-page-container .qr-photo-section-title .qr-text-new,
.mq2-pma-editor-page-container .qr-photo-section-title .qr-text-new,
.bubble-container-pma-editor-page-container .qr-photo-section-title .qr-text-new,
.mq2-editor-page-container .qr-photo-section-title .qr-text-new,
.mq3-editor-page-container .qr-photo-section-title .qr-text-new,
.ye2-pma-editor-page-container .qr-photo-section-title .qr-text-new,
.qv2-pma-editor-page-container .qr-photo-section-title .qr-text-new,
.bubble-compare-editor-page-container .qr-photo-section-title .qr-text-new,
.table-quadrants-pma-editor-page-container .qr-photo-section-title .qr-text-new {
  font-weight: 600;
  margin-right: 10px;
  color: rgb(0, 79, 172);
}
@keyframes bubbleBtnBlinker {
  80% {
    opacity: 0;
  }
}
.midquarter-pma-page .qr-photo-section-title .qr-code-link-for-photos,
.pma-page-container .qr-photo-section-title .qr-code-link-for-photos,
.qv4-pma-editor-page-container .qr-photo-section-title .qr-code-link-for-photos,
.mq2-pma-editor-page-container .qr-photo-section-title .qr-code-link-for-photos,
.bubble-container-pma-editor-page-container .qr-photo-section-title .qr-code-link-for-photos,
.mq2-editor-page-container .qr-photo-section-title .qr-code-link-for-photos,
.mq3-editor-page-container .qr-photo-section-title .qr-code-link-for-photos,
.ye2-pma-editor-page-container .qr-photo-section-title .qr-code-link-for-photos,
.qv2-pma-editor-page-container .qr-photo-section-title .qr-code-link-for-photos,
.bubble-compare-editor-page-container .qr-photo-section-title .qr-code-link-for-photos,
.table-quadrants-pma-editor-page-container .qr-photo-section-title .qr-code-link-for-photos {
  color: rgb(0, 79, 172);
  font-style: italic;
  font-weight: 500;
  animation: bubbleBtnBlinker 2s linear infinite;
  animation-iteration-count: 10;
}
.midquarter-pma-page .qv4-mapped-listings,
.pma-page-container .qv4-mapped-listings,
.qv4-pma-editor-page-container .qv4-mapped-listings,
.mq2-pma-editor-page-container .qv4-mapped-listings,
.bubble-container-pma-editor-page-container .qv4-mapped-listings,
.mq2-editor-page-container .qv4-mapped-listings,
.mq3-editor-page-container .qv4-mapped-listings,
.ye2-pma-editor-page-container .qv4-mapped-listings,
.qv2-pma-editor-page-container .qv4-mapped-listings,
.bubble-compare-editor-page-container .qv4-mapped-listings,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings {
  margin: -3px auto 0 auto;
  width: 630px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 9px;
  grid-row-gap: 9px;
}
.midquarter-pma-page .qv4-mapped-listings .select-another-property-container,
.pma-page-container .qv4-mapped-listings .select-another-property-container,
.qv4-pma-editor-page-container .qv4-mapped-listings .select-another-property-container,
.mq2-pma-editor-page-container .qv4-mapped-listings .select-another-property-container,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .select-another-property-container,
.mq2-editor-page-container .qv4-mapped-listings .select-another-property-container,
.mq3-editor-page-container .qv4-mapped-listings .select-another-property-container,
.ye2-pma-editor-page-container .qv4-mapped-listings .select-another-property-container,
.qv2-pma-editor-page-container .qv4-mapped-listings .select-another-property-container,
.bubble-compare-editor-page-container .qv4-mapped-listings .select-another-property-container,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .select-another-property-container {
  border: 3px solid gray;
  height: 214.8px;
  width: 305px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.midquarter-pma-page .qv4-mapped-listings .select-another-property-container:hover,
.pma-page-container .qv4-mapped-listings .select-another-property-container:hover,
.qv4-pma-editor-page-container .qv4-mapped-listings .select-another-property-container:hover,
.mq2-pma-editor-page-container .qv4-mapped-listings .select-another-property-container:hover,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .select-another-property-container:hover,
.mq2-editor-page-container .qv4-mapped-listings .select-another-property-container:hover,
.mq3-editor-page-container .qv4-mapped-listings .select-another-property-container:hover,
.ye2-pma-editor-page-container .qv4-mapped-listings .select-another-property-container:hover,
.qv2-pma-editor-page-container .qv4-mapped-listings .select-another-property-container:hover,
.bubble-compare-editor-page-container .qv4-mapped-listings .select-another-property-container:hover,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .select-another-property-container:hover {
  cursor: pointer;
}
.midquarter-pma-page .qv4-mapped-listings .select-another-property-container div p,
.pma-page-container .qv4-mapped-listings .select-another-property-container div p,
.qv4-pma-editor-page-container .qv4-mapped-listings .select-another-property-container div p,
.mq2-pma-editor-page-container .qv4-mapped-listings .select-another-property-container div p,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .select-another-property-container div p,
.mq2-editor-page-container .qv4-mapped-listings .select-another-property-container div p,
.mq3-editor-page-container .qv4-mapped-listings .select-another-property-container div p,
.ye2-pma-editor-page-container .qv4-mapped-listings .select-another-property-container div p,
.qv2-pma-editor-page-container .qv4-mapped-listings .select-another-property-container div p,
.bubble-compare-editor-page-container .qv4-mapped-listings .select-another-property-container div p,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .select-another-property-container div p {
  color: rgb(0, 79, 172);
  font-size: 36px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .bold,
.pma-page-container .qv4-mapped-listings .list-map-item .bold,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .bold,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .bold,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .bold,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .bold,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .bold,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .bold,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .bold,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .bold,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .bold {
  font-weight: bold;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-img-container,
.pma-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container {
  margin: auto;
  width: 305px;
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.pma-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg {
  width: 30px;
  height: 169px;
  background-color: #a6ab93;
  color: white;
  align-self: flex-start;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 11.2px;
  letter-spacing: 0.015em;
  font-variant: small-caps;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.pma-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by {
  font-weight: bold;
  text-align: center;
  color: rgb(0, 79, 172);
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-img-container p,
.pma-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-img-container p {
  text-align: center;
  margin-bottom: 0.1em;
  color: white;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container {
  position: relative;
  top: 0;
  left: 0;
  height: 169px;
  width: 100%;
  overflow: hidden;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img {
  height: auto;
  width: 116%;
  position: relative;
  top: 47%;
  transform: translateY(-50%);
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 26px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 3px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price {
  display: flex;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status {
  font-weight: bold;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold {
  color: rgb(184, 37, 37);
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active {
  color: #019091;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary, .midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending, .midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent {
  color: rgb(0, 79, 172);
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary {
  color: rgb(0, 34, 90);
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.pma-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
  margin-left: 10px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container,
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container {
  width: 97%;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row {
  width: 100%;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented {
  margin-top: 0.75em;
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 6.8px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml {
  margin-left: 17px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  color: black;
  margin-top: 3px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom {
  background-color: rgba(204, 233, 233, 0.6);
  color: black;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols {
  width: 97%;
  margin: 5px auto 0 auto;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child {
  width: 31%;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2) {
  width: 25%;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.pma-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.mq2-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.mq3-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3) {
  width: 25%;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.pma-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container {
  margin-left: 4px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.pma-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row {
  flex-direction: row;
  justify-content: flex-start;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.pma-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner {
  justify-content: flex-start;
  margin-left: 8px;
}
.midquarter-pma-page .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.pma-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.qv4-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.mq2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.bubble-container-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.mq2-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.mq3-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.ye2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.qv2-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.bubble-compare-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.table-quadrants-pma-editor-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container {
  flex-direction: row-reverse;
}
.midquarter-pma-page .photo2-modal-mapped-listings,
.pma-page-container .photo2-modal-mapped-listings,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings,
.mq2-editor-page-container .photo2-modal-mapped-listings,
.mq3-editor-page-container .photo2-modal-mapped-listings,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings {
  margin: 1px auto 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  width: 771px;
  height: 753px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .select-another-property-container,
.pma-page-container .photo2-modal-mapped-listings .select-another-property-container,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container,
.mq2-editor-page-container .photo2-modal-mapped-listings .select-another-property-container,
.mq3-editor-page-container .photo2-modal-mapped-listings .select-another-property-container,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .select-another-property-container,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container {
  border: 3px solid gray;
  height: 223px;
  width: 373px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.midquarter-pma-page .photo2-modal-mapped-listings .select-another-property-container:hover,
.pma-page-container .photo2-modal-mapped-listings .select-another-property-container:hover,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container:hover,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container:hover,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container:hover,
.mq2-editor-page-container .photo2-modal-mapped-listings .select-another-property-container:hover,
.mq3-editor-page-container .photo2-modal-mapped-listings .select-another-property-container:hover,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container:hover,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container:hover,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .select-another-property-container:hover,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container:hover {
  cursor: pointer;
}
.midquarter-pma-page .photo2-modal-mapped-listings .select-another-property-container div p,
.pma-page-container .photo2-modal-mapped-listings .select-another-property-container div p,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container div p,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container div p,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container div p,
.mq2-editor-page-container .photo2-modal-mapped-listings .select-another-property-container div p,
.mq3-editor-page-container .photo2-modal-mapped-listings .select-another-property-container div p,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container div p,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container div p,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .select-another-property-container div p,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .select-another-property-container div p {
  color: rgb(0, 79, 172);
  font-size: 36px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .bold,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .bold,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .bold,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .bold,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .bold,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .bold,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .bold,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .bold,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .bold,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .bold,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .bold {
  font-weight: bold;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-img-container,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .banner-text-bg {
  width: 30px;
  height: 192px;
  background-color: black;
  color: white;
  align-self: flex-start;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 11.2px;
  letter-spacing: 0.015em;
  font-variant: small-caps;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container .sold-by {
  font-weight: bold;
  text-align: center;
  color: rgb(0, 79, 172);
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-img-container p {
  text-align: center;
  margin-bottom: 0.1em;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container {
  position: relative;
  top: 0;
  left: 0;
  height: 192px;
  width: 100%;
  overflow: hidden;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img {
  height: auto;
  width: 116%;
  position: relative;
  top: 47%;
  transform: translateY(-50%);
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 26px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 3px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price {
  display: flex;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status {
  font-weight: bold;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold {
  color: rgb(184, 37, 37);
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active {
  color: #019091;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary, .midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending, .midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent {
  color: rgb(0, 79, 172);
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary {
  color: rgb(0, 34, 90);
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  margin-left: 10px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container {
  width: 97%;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row {
  width: 100%;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented {
  margin-top: 0.75em;
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 7.8px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml {
  margin-left: 17px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: black;
  margin-top: 3px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom {
  background-color: rgba(204, 233, 233, 0.6);
  color: black;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols {
  width: 97%;
  margin: 5px auto 0 auto;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child {
  width: 31%;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2) {
  width: 25%;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.pma-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3) {
  width: 25%;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.pma-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container {
  margin-left: 4px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.pma-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row {
  flex-direction: row;
  justify-content: flex-start;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.pma-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner {
  justify-content: flex-start;
  margin-left: 8px;
}
.midquarter-pma-page .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.pma-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.qv4-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.mq2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.bubble-container-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.mq2-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.mq3-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.ye2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.qv2-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.bubble-compare-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.table-quadrants-pma-editor-page-container .photo2-modal-mapped-listings .list-map-item:nth-child(even) .list-img-container {
  flex-direction: row-reverse;
}