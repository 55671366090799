.editor-alt-qv4-produced-by-footer-with-names {
  width: 100%;
  // height: 62px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  right: 0;
  // background-color: #424142;
  background-color: black;
  .row-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6.5px;
    color: white;
    text-transform: uppercase;
    height: 30%;
  }
  .names-row {
    display: flex;
    height: 60%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    .names-col {
      width: 80%;
      .place {
        width: 80%;

        display: flex;
        justify-content: center;
        margin-left: 10px;
        font-size: 6.5px;
        color: white;
      }
    }
  }
}
.editor-qv4-produced-by-footer {
  width: 100%;
  height: 46px;
  // background-color: #424142;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  &.black-produced-by {
    background-color: black;
  }
  &.berk-footer {
    text-align: justify;
    text-justify: inter-word;
    div {
      width: 95%;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 7px;
      color: white;
      line-height: 8.8px;
    }
  }
  .produced-by-divider {
    margin: 0 10px;
    width: 1px;
    font-weight: 200;
    font-style: normal;
    font-size: 20px;
    color: white;
  }
  p {
    color: white;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
  }
  .presented-by-compass-logo {
    height: 12px;
    width: auto;
    margin-bottom: 0px;
  }
}
