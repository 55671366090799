/* Variables

 */
.bubble-compare-editor .dashboard-data-pending {
  border: 1px solid black;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  position: absolute;
  top: 200px;
  left: 100px;
  padding: 200px;
  text-align: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container {
  width: 1365px;
  height: 1070px;
  margin: 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-right,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-second-left,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-second-right {
  width: 50%;
  position: relative;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .qv4-first-left {
  width: 50%;
  position: relative;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container {
  background-color: white;
  position: absolute;
  z-index: 2;
  left: 101%;
  width: 98%;
  bottom: 11%;
  height: 515px;
  border-radius: 20px;
  overflow-y: auto;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #3c4c55;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title-divider {
  margin-top: 0px;
  width: 70%;
  background-color: #4e85af;
  height: 3px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text {
  margin-top: 5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 17px;
  color: #00558f;
  display: flex;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text {
  flex-direction: column;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text {
  margin-top: 5px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button {
  margin: 0 7px;
  height: 20px;
  width: 60px;
  border-radius: 10px;
  border: 1px solid #00558f;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #4e85af;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form {
  border-radius: 15px;
  height: auto;
  padding: 20px 0;
  width: 90%;
  margin: 30px auto 0 auto;
  border: 2px solid #4e85af;
  border-radius: 15px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text {
  margin-left: 5%;
  margin-top: 15px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #475862;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider {
  width: 90%;
  background-color: #4e85af;
  height: 2px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  color: #00558f;
  margin-left: 5%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle {
  margin-top: 15px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body {
  display: flex;
  width: 94%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .input-uppercase {
  text-transform: uppercase;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter {
  text-transform: capitalize;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body {
  flex-direction: row;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col {
  width: 50%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col.full {
  width: 100%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea {
  max-width: 265px;
  min-width: 265px;
  height: 160px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  color: #424142;
  resize: none;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead {
  display: flex;
  flex-direction: row;
  width: 70%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead {
  margin-top: 15px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.025em;
  line-height: 19px;
  text-transform: uppercase;
  color: #00305e;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error {
  color: rgb(184, 37, 37);
  font-size: 13px;
  font-weight: 500;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label {
  width: 155px;
  font-size: 13px;
  width: 100%;
  font-weight: 500;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter {
  text-transform: capitalize;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input {
  width: 100%;
  height: 30px;
  border: 0;
  border-bottom: 3px solid #4e85af;
  outline: 0;
  font-size: 15px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter {
  text-transform: capitalize;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus {
  outline: none !important;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #414042;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .bolded-input-col {
  width: 100%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .bolded-input-col .character-count-error {
  color: rgb(184, 37, 37);
  font-size: 13px;
  font-weight: 500;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .bolded-input-col label {
  width: 255px;
  font-size: 13px;
  font-weight: 500;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col {
  width: 100%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input {
  font-family: playfair-display, serif;
  font-weight: 600;
  font-style: italic;
  font-size: 13px;
  color: #414042;
  letter-spacing: 0.05em;
  align-self: flex-start;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter {
  text-transform: capitalize;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col {
  width: 80%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input {
  letter-spacing: 0.025em;
  font-size: 13px;
  font-family: playfair-display, serif;
  font-weight: 600;
  font-style: italic;
  color: #414042;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter {
  text-transform: capitalize;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter {
  text-transform: capitalize;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col {
  width: 90%;
  margin: auto;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col .title-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section {
  width: 900px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section label {
  margin-left: 20px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container span {
  font-weight: 700;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  margin-left: 5px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input {
  width: 700px;
  height: 25px;
  margin: auto;
  border: 0;
  outline: 0;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus {
  outline: none !important;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section:nth-child(2) {
  margin-top: 50px;
  margin-bottom: 50px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 20px auto 15px auto;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .button-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .restore-default {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .restore-default:hover {
  cursor: pointer;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .restore-default p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button:hover {
  cursor: pointer;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button {
  width: 178px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button:hover {
  cursor: pointer;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .edit-quadrant-container .saving-message {
  color: green;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  letter-spacing: 0.05em;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
@keyframes bubbleBtnBlinker {
  80% {
    opacity: 0;
  }
}
@keyframes mymove {
  from {
    left: 0px;
  }
  to {
    left: 200px;
  }
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn {
  height: 36px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4e85af;
  position: absolute;
  z-index: 5;
  border-radius: 10px;
  font-size: 11.5px;
  font-weight: 700;
  animation: bubbleBtnBlinker 2s linear infinite;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn:hover {
  cursor: pointer;
  animation: 0;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn div {
  color: white;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn.apply-all-areas-btn {
  left: 260px;
  top: 210px;
  width: 150px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn.bubble-title-btn {
  top: -32px;
  left: 435px;
  width: 150px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn.bubble-footer-btn {
  top: 25px;
  left: 0px;
  width: 150px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn.bubble-btn-0 {
  top: 170px;
  left: 5px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn.bubble-btn-1 {
  top: 117px;
  left: 0px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn.bubble-btn-2 {
  top: 117px;
  left: 0px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn.bubble-btn-3 {
  top: 117px;
  left: 0px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .bubble-btn.bubble-btn-4 {
  top: 662px;
  left: 25px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .backpage-boxes-img {
  position: absolute;
  bottom: 75px;
  left: 0px;
  width: 428px;
  height: 575px;
  z-index: 0;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .backpage-boxes-img img {
  width: 100%;
  height: 100%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .extended-img {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 428px;
  height: 626px;
  z-index: 0;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .extended-img img {
  width: 100%;
  height: 100%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .backpage-box-box-img {
  display: flex;
  width: 83%;
  height: 164px;
  justify-content: center;
  margin-top: 5px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .backpage-boxes-with-image {
  display: flex;
  width: 64%;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .content-coming-soon {
  font-size: 40px;
  position: absolute;
  top: 510px;
  left: 230px;
  z-index: 3;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 210px;
  width: 100%;
  z-index: 0;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-body {
  position: absolute;
  bottom: 50px;
  margin: auto;
  width: 100%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-header {
  width: 96%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-header .home-value-title {
  margin-left: 70px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  letter-spacing: 0.16em;
  color: #475862;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-header .home-value-divider {
  height: 2.66px;
  width: 545px;
  background-color: rgba(246, 204, 108, 0.8);
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-header .home-value-subtitle {
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #53626c;
  margin-top: 6px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto 0 auto;
  table-layout: auto;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table thead th {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #8a927c;
  font-size: 13px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  line-height: 18px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table thead th:first-child {
  padding-left: 90px;
  text-align: left;
  width: 320px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table thead th:nth-child(2),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table thead th:nth-child(3) {
  width: 130px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table thead th:last-child {
  padding-right: 70px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table tbody tr {
  border-bottom: 1px solid rgb(205.4655172414, 214.7896551724, 220.0344827586);
  height: 27px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table tbody td {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #53626c;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:first-child {
  padding-left: 90px;
  text-align: left;
  font-size: 13px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 52%;
  font-size: 13px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p {
  width: auto;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:last-child {
  padding-right: 70px;
  font-size: 13px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:last-child p {
  margin: 0 0 0 30%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .bubble-compare-first-left .home-value .home-value-source {
  width: 90%;
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  position: relative;
  z-index: 4;
  top: 15px;
  color: white;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table {
  width: 100%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 10px 0 30px 24px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: black;
  margin: -3px 8px 0 8px;
  height: 4px;
  width: 4px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 4px;
  height: 4px;
  width: 4px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: black;
  text-transform: uppercase;
  font-size: 33px;
  line-height: 26px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 25px;
  line-height: 18px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 24px;
  line-height: 18px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 18px;
  line-height: 26px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 23px;
  line-height: 26px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider {
  height: 1px;
  margin-top: 2px;
  width: 406px;
  margin-left: 19px;
  background-color: black;
  margin-bottom: 1px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider.extra-long {
  margin-left: 19px;
  width: 283px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
  z-index: 0;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 245px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 355px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  text-transform: uppercase;
  text-align: right;
  line-height: 17px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 12px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 21px;
  display: flex;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 12px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8px;
  text-transform: uppercase;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers {
  margin-top: -3px;
  min-height: 500px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 512px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th {
  font-size: 9px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: black;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p {
  line-height: 11px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 20px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 30px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 17px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8.4px;
  color: black;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 68px;
  text-align: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 46px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 74px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 189px;
  text-align: left;
  font-weight: 400;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address {
  font-size: 7.5px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  font-weight: 400;
  width: 82px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 63px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 31px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 90px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 53px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 8px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8px;
  line-height: 8px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p {
  font-size: 6px;
  color: black;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend {
  border: 1px solid gray;
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 7px;
  color: black;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2),
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 19px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider,
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-second-left-middle-section {
  height: 855px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators {
  width: 100%;
  height: 175px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer {
  width: 80%;
  margin-left: 34px;
  display: flex;
  flex-direction: column;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row {
  display: flex;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2) {
  width: 88px;
  text-align: right;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare {
  width: 105px;
  display: flex;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator {
  width: 118px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator {
  width: 122px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 13px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
  padding-top: 1px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  text-transform: uppercase;
  text-align: right;
  line-height: 17px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: 19px;
  margin-top: 1px;
  padding-top: 1px;
  width: 404px;
  background-color: black;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: -4px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #b8b8b8;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(236, 237, 241, 0.35);
  height: 50px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -4px;
  width: 100px;
  height: 1.5px;
  background-color: #b8b8b8;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  color: black;
  text-align: center;
  margin-right: 20px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up {
  width: 30px;
  height: 30px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down {
  width: 30px;
  height: 30px;
}
.bubble-compare-editor .bubble-compare-editor-page .bubble-compare-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: black;
  margin-bottom: -4px;
  text-align: center;
}