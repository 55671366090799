.editor-mission-statement-block {
  position: absolute;
  bottom: 47px;
  right: 0px;
  height: 620px;
  width: 100%;
  .mission-statement-custom-photo {
    height: 100%;
    width: 100%;
  }
  .edit-button-mission-statement {
    position: absolute;
    top: 100px;
    left: 40px;
    height: 42px;
    z-index: 4;
    background-color: #4e85af;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 36px;

    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    p {
      color: white;
    }
  }
  .mission-statement-block-bg-container {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    .mission-statement-block-bg {
      height: 100%;
      width: 100%;
    }
  }
  .mission-statement-content-container {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;

    justify-content: center;
    align-items: center;
    .mission-statement-column-container {
      width: 80%;
      height: 35%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      position: absolute;

      top: 0px;
      left: 0px;
      z-index: 2;
      &.large-mission-statement-photo {
        width: 84%;
        .mission-statement-image-container {
          .mission-statement-image {
            max-width: 303px;
            max-height: 189px;
          }
        }
      }
      .mission-statement-image-container {
        //width: 100%;

        .mission-statement-image {
          width: auto;
          height: auto;
          max-width: 260px;
          max-height: 160px;
          &.mission-photo-border {
            border: 1px solid black;
          }
        }
      }
    }

    .mission-statement-inner-container {
      position: absolute;
      left: 0%;
      bottom: 7%;
      border: 1px solid #ded9d5;
      width: 90%;
      height: 63%;
      background-color: black;
      box-shadow: 10px 10px 5px #cdcdcd;
      display: flex;
      align-items: center;
      flex-direction: column;

      justify-content: space-between;

      .mission-statement-text-container {
        width: 80%;
        margin: 76px auto 0 auto;

        .mission-statement-title {
          text-align: left;
          font-family: "playfair-display", serif;
          font-weight: 400;
          font-style: italic;
          font-size: 26px;
          letter-spacing: 0.05em;
          margin-bottom: 10px;
          color: white;
          &.mission-margin-top-30 {
            margin-top: 30px;
          }
          &.mission-statement-title-26 {
            font-size: 22px;
          }
        }
        .mission-statement-text {
          text-align: center;
          padding: 0 auto;
          text-align: center;
          text-align: justify;
          text-justify: inter-word;
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 14px;

          line-height: 23px;
          color: white;

          &.mission-statement-content-20 {
            font-size: 16px;
            line-height: 32px;
            margin-top: 42px;
          }

          .mission-statement-margin-top-spacing {
            margin-top: 15px;
          }
          &.mission-statement-14-5 {
            font-size: 11.5px;
          }
        }
      }
    }
  }
}

.editor-mission-statement-block-v2 {
  position: absolute;
  bottom: 55px;
  right: 0px;
  height: 620px;
  width: 100%;
  .edit-button-mission-statement {
    position: absolute;
    top: 100px;
    right: 40px;
    height: 42px;
    z-index: 4;
    background-color: #4e85af;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 36px;

    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    p {
      color: white;
    }
  }
  .mission-statement-block-bg-container {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    .mission-statement-block-bg {
      height: 100%;
      width: 100%;
    }
  }
  .mission-statement-content-container {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .mission-statement-inner-container {
      position: absolute;
      left: 16%;
      border: 1px solid #ded9d5;
      width: 78%;
      height: 80%;
      background-color: white;
      box-shadow: 10px 10px 5px #cdcdcd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mission-statement-image-container {
        width: 37%;
        margin-right: 20px;
        position: relative;
        right: 70px;
        top: 0px;
        .mission-statement-image {
          width: auto;
          height: auto;
          max-width: 330px;
          max-height: 270px;
          &.mission-photo-border {
            border: 1px solid black;
          }
        }
      }

      .mission-statement-text-container {
        width: 47%;
        margin-right: 23px;

        .mission-statement-title {
          text-align: left;
          font-family: "playfair-display", serif;
          font-weight: 400;
          font-style: italic;
          font-size: 21px;
          letter-spacing: 0.13em;
          margin-bottom: 10px;
          &.mission-margin-top-30 {
            margin-top: 30px;
          }
          &.mission-statement-title-26 {
            font-size: 23px;
          }
        }
        .mission-statement-text {
          text-align: center;
          padding: 0 15px 0 1px;
          text-align: justify;
          text-justify: inter-word;
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 400;
          font-style: italic;
          font-size: 12px;
          letter-spacing: 0.1em;
          line-height: 20px;
        }
      }
    }
  }
}
