.editor-home-value-changed .dashboard-palmtree-img,
.editor-home-value-changed .dashboard-iris-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 97%;
  width: 54%;
}
.editor-home-value-changed .dashboard-header {
  position: absolute;
  top: 81px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editor-home-value-changed .dashboard-header .dashboard-header-content {
  width: 89%;
}
.editor-home-value-changed .dashboard-header .dashboard-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 34px;
  letter-spacing: 0.16em;
}
.editor-home-value-changed .dashboard-header .dashboard-title .dashboard-title-light {
  color: white;
  width: 53.5%;
  text-align: right;
}
.editor-home-value-changed .dashboard-header .dashboard-title .dashboard-title-dark {
  color: black;
  width: 100%;
  text-align: right;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 0.11em;
}
.editor-home-value-changed .dashboard-header .dashboard-subtitle-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.editor-home-value-changed .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container {
  width: 63%;
}
.editor-home-value-changed .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header {
  display: flex;
  justify-content: flex-end;
}
.editor-home-value-changed .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.editor-home-value-changed .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.2em;
}
.editor-home-value-changed .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  margin-right: 22px;
  color: white;
}
.editor-home-value-changed .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark {
  color: black;
}
.editor-home-value-changed .dashboard-header .dashboard-subtitle-section .dashboard-info {
  margin-top: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
  text-justify: inter-word;
  width: 255px;
}
.editor-home-value-changed .dashboard-header .dashboard-subtitle-section .dashboard-info .bolded-dashboard-header-area-name {
  font-weight: 500;
}
.editor-home-value-changed .dashboard-body {
  position: absolute;
  top: 270px;
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range {
  height: 176px;
  display: flex;
  position: relative;
  width: 100%;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .dashboard-body-divider {
  height: 1px;
  min-height: 1px;
  width: 470px;
  background-color: black;
  position: absolute;
  top: 80px;
  left: 0px;
  z-index: 1;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .price-range-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  z-index: 2;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container {
  width: 80%;
  margin: -7px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 37%;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text {
  margin: -20px 0 10px 0;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 23px;
  color: black;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: black;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-img {
  display: flex;
  width: 64%;
  height: 105%;
  justify-content: center;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col-with-image {
  display: flex;
  width: 64%;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col,
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col-with-image {
  display: flex;
  width: 60%;
  justify-content: center;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 31%;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 18px;
  color: black;
  margin-top: -17px;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes {
  display: flex;
  flex-direction: column;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 5px;
  font-size: 12px;
  color: #3c4c55;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  line-height: 19px;
  text-align: center;
  color: black;
}
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.editor-home-value-changed .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 9px;
  text-align: center;
  color: black;
  margin-top: 8px;
}
.editor-home-value-changed .qr-code-section {
  position: absolute;
  bottom: 30px;
  right: 0;
  height: 140px;
  width: 88%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
}
.editor-home-value-changed .qr-code-section .qr-code-col1 {
  width: 18%;
  margin-top: 10px;
}
.editor-home-value-changed .qr-code-section .qr-code-col1 .bubble-arrow-container {
  width: 60%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.editor-home-value-changed .qr-code-section .qr-code-col1 .bubble-arrow-container img {
  height: 40px;
  width: auto;
}
.editor-home-value-changed .qr-code-section .qr-code-col2 {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 10px;
}
.editor-home-value-changed .qr-code-section .qr-code-col2 .qr-code-title {
  height: 16%;
  display: flex;
  align-items: center;
}
.editor-home-value-changed .qr-code-section .qr-code-col2 .qr-code-title p {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
}
.editor-home-value-changed .qr-code-section .qr-code-col2 .qr-code-title .qr-code-space {
  margin-right: 8px;
  font-style: normal;
  font-weight: 600;
  color: rgb(0, 79, 172);
}
.editor-home-value-changed .qr-code-section .qr-code-col2 .qr-code-content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70%;
}
.editor-home-value-changed .qr-code-section .qr-code-col2 .qr-code-content-row .bubble-qr-container {
  width: 20%;
}
.editor-home-value-changed .qr-code-section .qr-code-col2 .qr-code-content-row .bubble-qr-container img {
  height: 90px;
  width: 90px;
}
.editor-home-value-changed .qr-code-section .qr-code-col2 .qr-code-content-row .qr-code-text {
  width: 80%;
  display: flex;
  align-items: center;
}
.editor-home-value-changed .qr-code-section .qr-code-col2 .qr-code-content-row .qr-code-text p {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.editor-home-value-changed .qr-code-section .qr-code-col2 .qr-code-content-row .qr-code-link {
  text-decoration: underline;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0.015em;
  line-height: 26px;
  color: #8a9985;
  margin-right: 5px;
  animation: bubbleBtnBlinker 2s linear infinite;
}
.editor-home-value-changed .dashboard-valuation {
  position: absolute;
  bottom: 30px;
  right: 0;
  height: 140px;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.8);
}
.editor-home-value-changed .dashboard-valuation .valuation-title {
  display: flex;
  justify-content: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12.5px;
  letter-spacing: 0.025em;
  color: black;
  margin: 15px 0 0 0;
}
.editor-home-value-changed .dashboard-valuation .valuation-section-row {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 8px auto 0 auto;
}
.editor-home-value-changed .dashboard-valuation .valuation-section {
  width: 31%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.editor-home-value-changed .dashboard-valuation .valuation-section .valuation-number {
  line-height: 55px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
  width: 45px;
}
.editor-home-value-changed .dashboard-valuation .valuation-section .valuation-number.light, .editor-home-value-changed .dashboard-valuation .valuation-section .valuation-number.medium, .editor-home-value-changed .dashboard-valuation .valuation-section .valuation-number.dark {
  color: #a3b6bd;
}
.editor-home-value-changed .dashboard-valuation .valuation-section .valuation-data {
  margin-top: 5px;
  width: 83%;
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 11px;
  line-height: 17px;
  color: black;
}