/* PMA Creator Styles

 */
body {
  -webkit-print-color-adjust: exact !important;
}
// * {
//   font-family: roboto;
// }
@import "../../_styles/variables";
.unavailable {
  font-size: 36px;
  color: $primary;
  text-align: center;
  margin: 25vh auto 0 auto;
}
.handle-homeowner-button-container {
  display: flex;
  width: 25%;
  justify-content: space-between;
  margin: auto;
}
.next-homeowner-btn {
  font-size: 16px;

  margin: 40px 20px 0 20px;
  height: 32px;

  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  color: white;
  width: 150px;

  &:hover {
    cursor: pointer;
  }
}
.under-construction {
  margin: 100px auto 0 auto;
  font-family: "Arial";
  text-align: center;

  width: 600px;
  p,
  div {
    font-size: 18px;
  }
  .blue {
    color: rgb(65, 86, 196);
  }
  .green {
    color: rgb(52, 154, 50);
  }
  .compass-margin-top-construction {
    margin-top: 50px;
    p,
    div {
      line-height: 24px;
    }
  }
}
.pending-pma {
  // border: 3px solid $primary;

  width: 50%;
  margin-top: 5em;
  margin: auto;
  p {
    margin-top: 2em;
    margin-bottom: 10em;
    font-size: 40px;
    text-align: center;
  }
}

.version-info-container {
  display: flex;
  flex-direction: column;
  align-items: space-around;
  font-size: 12px;
  div {
    text-align: center;
    padding: 4px;
  }
}
.active-version {
  color: white;
  background-color: green;
}
.non-active-version {
  color: white;
  background-color: darkred;
}

.text-count-size {
  font-size: 14px !important;
}

.greeting-text {
  color: #004fac !important;
  font-weight: 500 !important;
}

.version-btn-container {
  width: 40%;
  margin: auto;
  padding: 1em 0;
  &.hover {
    cursor: pointer;
  }
  .inner-btn-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
.version-switch-btn {
  width: 150px;
  padding: 10px 10px;
  border-radius: 10px;

  cursor: pointer;
  &:focus {
    outline: none;
  }
  .switch-btn {
    font-weight: bold;
  }
  .switch-subtitle {
    margin-top: 8px;
    font-size: 10px;
  }
}

.verify-details-button {
  border: 1px solid black;
  background-color: green;
  height: 48px;
  width: 200px;

  // position: absolute;
  // bottom: 10vh;
  // right: 30vh;
  position: fixed;
  z-index: 5;
  bottom: 74px;
  right: 74px;
  z-index: 2;
  // cursor: move;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  button,
  div {
    color: white;
  }
}
.at-the-rate {
  font-size: 16px !important;
}

.pma-margin-top-mailpro {
  //position: fixed;old one
  width: 100%;
  //height: calc(100vh - 128px);old one
}

.showUnApprovedMessage {
  //width: calc(100vw);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  z-index: 5 !important;
}

.showUnApprovedMessageInner {
  border: 1px solid #fdecea;
  background: #fdecea;
  border-radius: 4px;
  padding: 8px;
}
.showUnApprovedMessageInner p:nth-child(1) {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

.showUnApprovedMessageInner p:nth-child(2) {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
}

.unApprovedButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 16px 0 8px 0;
}

.unApprovedButtonContainer button {
  margin-left: 24px;
  text-transform: none;
}

.unApprovedButtonContainer button:nth-child(1) {
  background: #3d85c6;
  color: white;
}

.unApprovedButtonContainer button:nth-child(1):hover {
  background: #0b5394;
  color: white;
}

.unApprovedButtonContainer button:nth-child(2) {
  background: rgb(230, 119, 119);
  color: white;
}

.unApprovedButtonContainer button:nth-child(2):hover {
  background: rgb(223, 77, 77);
  color: white;
}

// .noUpperCaseButton {
//   text-transform: none !important;
// }

.capitalizeUpperCaseButton {
  text-transform: capitalize !important;
}

// html {
//   --scrollbarBG: #CFD8DC;
//   --thumbBG: #90A4AE;
// }
// body::-webkit-scrollbar {
//   width: 11px;
// }
// body {
//   scrollbar-width: auto;
//   scrollbar-color: var(--thumbBG) var(--scrollbarBG);
// }
// body::-webkit-scrollbar-track {
//   background: var(--scrollbarBG);
// }
// body::-webkit-scrollbar-thumb {
//   background-color: var(--thumbBG) ;
//   border-radius: 6px;
//   border: 3px solid var(--scrollbarBG);
// }
