/* Variables

 */
.qv4-photo-section-container .edit-properties-button {
  align-self: flex-end;
  width: 188px;
  height: 32px;
  margin-left: 20px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 79, 172);
  position: absolute;
  top: -38px;
}
.qv4-photo-section-container .edit-properties-button:hover {
  cursor: pointer;
}
.qv4-photo-section-container .edit-properties-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.alt-button-container {
  width: 400px;
  display: flex;
  justify-content: space-around;
}
.alt-button-container .alt-button {
  width: 170px;
  height: 32px;
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.alt-button-container .alt-button.left {
  background-color: rgb(0, 79, 172);
}
.alt-button-container .alt-button.right {
  background-color: gray;
}
.alt-button-container .alt-button:hover {
  cursor: pointer;
}

.qv4-pma-editor-page .qv4-pma-editor-page-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container {
  width: 1365px;
  height: 1070px;
  margin: 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-right,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-right,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-right,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-right,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-right {
  width: 50%;
  position: relative;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 660px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.gail-highlight,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.gail-highlight,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.gail-highlight,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.gail-highlight {
  height: 695px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alt-ye2-property-highlight,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alt-ye2-property-highlight,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alt-ye2-property-highlight,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alt-ye2-property-highlight {
  height: 685px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-new-edit-button-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-new-edit-button-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-new-edit-button-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-new-edit-button-container {
  position: absolute;
  z-index: 3;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-new-edit-button-container.regular-prop-edit-button,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-new-edit-button-container.regular-prop-edit-button,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-new-edit-button-container.regular-prop-edit-button,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-new-edit-button-container.regular-prop-edit-button {
  top: -61px;
  right: 15px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-new-edit-button-container.gummow-alt-prop-edit-button-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-new-edit-button-container.gummow-alt-prop-edit-button-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-new-edit-button-container.gummow-alt-prop-edit-button-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-new-edit-button-container.gummow-alt-prop-edit-button-container {
  top: -32px;
  right: 50px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-new-edit-button-container .ye2-new-edit-button,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-new-edit-button-container .ye2-new-edit-button,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-new-edit-button-container .ye2-new-edit-button,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-new-edit-button-container .ye2-new-edit-button {
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  color: white;
  width: 250px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg {
  height: 100%;
  width: 100%;
  background-color: #dde5e7;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg {
  top: 12px;
  height: 300px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg {
  height: 445px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 106.7%;
  width: 100%;
  z-index: 1;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg {
  height: 100%;
  width: 100%;
  background-color: #231f20;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-body,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-body,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-body,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-body {
  position: absolute;
  top: 15px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 97.4%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container {
  margin: 0px auto 0 auto;
  position: relative;
  height: 160px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr {
  position: absolute;
  right: 45px;
  top: -5px;
  width: 90px;
  height: 90px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img {
  width: 90px;
  height: 90px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow {
  position: absolute;
  right: 118px;
  top: 23px;
  width: 80px;
  height: 80px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img {
  width: 50px;
  height: 50px;
  transform: rotate(-25deg);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text {
  color: white;
  font-size: 26px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
  margin-top: 3px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 35px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small {
  padding-left: 5px;
  font-size: 32px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .photo-footer-text {
  color: white;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
  color: white;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  width: 68%;
  display: flex;
  justify-content: flex-end;
  margin-top: 7px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle {
  height: 100px;
  width: 77%;
  margin: 15px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p {
  color: white;
  font-size: 13px;
  line-height: 19px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 18px;
  left: 0px;
  height: 157px;
  width: 100%;
  z-index: 1;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg {
  height: 295px;
  width: 93%;
  background-color: #e3e9dd;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container {
  margin: -5px auto 0px auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 23px;
  text-align: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12.25px;
  width: 90%;
  margin: 4px auto 0 auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1 {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  width: 90%;
  margin: 35px auto 0 auto;
  font-family: "utopia-std", serif;
  font-weight: 400;
  font-style: italic;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2 {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  width: 90%;
  margin: 5px auto 0 auto;
  font-family: "utopia-std", serif;
  font-weight: 400;
  font-style: italic;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text {
  color: black;
  font-size: 27.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 37px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12.25px;
  line-height: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  width: 90%;
  margin: 5px auto 0 auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container {
  margin: 0px auto 8px auto;
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text {
  color: white;
  font-size: 22px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title {
  height: 14px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  height: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text {
  font-size: 8.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 12px;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text {
  width: 65%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 8px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text {
  color: white;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div {
  height: 13px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 10.5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 425px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container {
  margin: 10px auto 15px auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text {
  color: black;
  font-size: 26px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
  margin-top: 3px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 35px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small {
  padding-left: 5px;
  font-size: 32px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 559px;
  margin: 5px auto 0 auto;
  height: 74px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p {
  color: black;
  font-size: 13.5px;
  line-height: 22px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.05em;
  text-align: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text {
  width: 593px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p {
  font-size: 11.7px;
  line-height: 18px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p {
  font-size: 13.4px;
  line-height: 28px;
  font-weight: 500;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child {
  font-weight: 500;
  font-size: 26px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2) {
  margin-top: 10px;
  margin-bottom: -5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.column-subtitle-styles,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.column-subtitle-styles,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.column-subtitle-styles,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.column-subtitle-styles {
  flex-direction: column;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 {
  width: 593px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p {
  font-size: 11.7px;
  line-height: 18px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text {
  color: rgb(239.7, 0, 0);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container {
  margin: 2px auto 10px auto;
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text {
  color: black;
  font-size: 19px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text .no-title,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text .no-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text .no-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text .no-title {
  height: 14px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text {
  position: absolute;
  width: 100%;
  bottom: 25px;
  left: 0;
  height: 33px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lower-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lower-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lower-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lower-banner-text {
  bottom: 26px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2) {
  bottom: 26.25px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price {
  font-weight: 600;
  text-transform: uppercase;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .no-bold-regular-case,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .no-bold-regular-case,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .no-bold-regular-case,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .no-bold-regular-case {
  font-weight: 400;
  text-transform: none;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case {
  font-weight: 600;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row {
  display: flex;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row p {
  font-weight: 600;
  font-size: 11px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row .coming-soon-representation,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row .coming-soon-representation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row .coming-soon-representation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row .coming-soon-representation {
  text-align: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lowered-banner,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lowered-banner,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lowered-banner,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lowered-banner {
  bottom: 3px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text div,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text div {
  font-size: 10px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 15px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .capitalize-city,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .capitalize-city,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .capitalize-city,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .capitalize-city {
  text-transform: capitalize;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 7px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row {
  display: flex;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column {
  align-items: center;
  flex-direction: column;
  margin-top: 2px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2) {
  margin-top: 4px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row p {
  font-weight: 600;
  font-size: 11px;
  margin-right: 5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row .pending-representation,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row .pending-representation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row .pending-representation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row .pending-representation {
  color: green;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row .active-representation,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row .active-representation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row .active-representation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row .active-representation {
  color: #3d85c6;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.white-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.white-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.white-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.white-text {
  color: white;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text div:nth-child(1),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text div:nth-child(1),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text div:nth-child(1),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text div:nth-child(1) {
  line-height: 9px;
  height: 8.5px;
  margin-top: 2px;
  font-size: 10.5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text div:nth-child(2),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text div:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text div:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text div:nth-child(2) {
  line-height: 9px;
  height: 9.3px;
  margin-top: 4px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2) {
  margin-top: 6px;
  margin-bottom: -4px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin {
  margin-top: 0px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p {
  line-height: 10px;
  height: 10px;
  margin-top: 3px;
  font-size: 9.2px;
  text-align: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child {
  margin-top: 4px;
  margin-bottom: 2px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line {
  padding-top: 5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 {
  width: 95%;
  margin: auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col {
  position: relative;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img {
  height: 150px;
  width: 200px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 {
  padding-top: 15px;
  width: 95%;
  margin: auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col {
  position: relative;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img {
  height: 228px;
  width: 300px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .property-highlight-placeholder,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .property-highlight-placeholder,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .property-highlight-placeholder,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .property-highlight-placeholder {
  position: relative;
  background-color: white;
  color: red;
  height: 180px;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout {
  margin-top: 15px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  height: 34px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text {
  font-size: 11.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 14px;
  width: 95%;
  margin: auto 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text {
  width: 65%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
  margin: 1px 10px 0 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text.white-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text.white-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text.white-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text.white-text {
  color: white;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div {
  height: 13px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 10.5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 444px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 93%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col {
  width: 292px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 198px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height {
  height: 188px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin {
  margin-top: 32px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text {
  height: 60px;
  bottom: 3px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text div,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text div,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text div,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text div {
  font-size: 9px;
  line-height: 15px;
  height: 11px;
  margin-top: 0px;
  text-align: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text p {
  font-size: 9px;
  line-height: 15px;
  height: 11px;
  margin-top: 0px;
  text-align: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text {
  margin-top: 0px;
  height: auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container {
  height: 29px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0 {
  height: 232px;
  width: 100%;
  max-width: 300px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0.reduced-image-height,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0.reduced-image-height {
  height: 210px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin {
  margin-top: 32px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout {
  margin-top: 30px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 49%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col {
  height: 252px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  width: 300px;
  height: 225px;
  max-height: 240px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position {
  object-fit: cover;
  object-position: 6% 34%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col {
  height: 252px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  width: 300px;
  height: auto;
  max-height: 240px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position {
  object-fit: cover;
  object-position: 70% 50%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position {
  object-fit: cover;
  object-position: 0% 34%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: auto;
  width: auto;
  max-height: 429px;
  max-width: 276px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain {
  object-fit: contain;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 189px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-1 .row1-photo-col .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-1 .row1-photo-col .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-1 .row1-photo-col .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-1 .row1-photo-col .row-banner-text {
  bottom: 24px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 126px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .row-banner-text {
  bottom: 24px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer {
  display: flex;
  flex-direction: column;
  margin: 8px auto 0 auto;
  justify-content: center;
  align-items: center;
  width: 95%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1 {
  font-size: 16px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  line-height: 29px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 93%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text {
  font-size: 12px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 {
  color: white;
  font-size: 16px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  line-height: 29px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 93%;
  margin-top: 14px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title {
  height: 12px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 {
  color: white;
  font-size: 22px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  width: 100%;
  letter-spacing: 0.025em;
  margin-top: 5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title {
  height: 12px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  padding-top: 16px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text {
  color: white;
  font-size: 19px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.125em;
  margin-top: 8px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-inner-photo-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-inner-photo-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin: auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 {
  height: 580px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg {
  height: 315px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2 {
  margin-top: -19px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text {
  bottom: 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col {
  height: 325px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0 {
  height: 300px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text {
  height: 40px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text {
  margin-top: 12px;
  margin-bottom: -12px;
  height: 26px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm {
  object-fit: contain;
  height: 256px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container {
  position: absolute;
  bottom: 0px;
  height: 690px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title {
  position: absolute;
  top: -5px;
  left: 327px;
  width: 460px;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text {
  color: #424142;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.15em;
  font-size: 50px;
  text-transform: uppercase;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container {
  position: absolute;
  top: 67px;
  left: 408px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 11px;
  margin-bottom: 15px;
  letter-spacing: 0.1em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 102px;
  left: 401px;
  height: 76%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: #424142;
  font-size: 22px;
  position: absolute;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1 {
  top: 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2 {
  top: 207px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3 {
  top: 364px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container {
  position: absolute;
  top: 63px;
  left: 0px;
  height: 150px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 56%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.025em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-container {
  z-index: 1;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image {
  height: 797px;
  max-width: 100%;
  max-height: 100%;
  width: 56%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section {
  display: flex;
  width: 100%;
  position: absolute;
  left: 408px;
  top: 111px;
  z-index: 2;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container {
  width: 80%;
  height: 86%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section {
  margin-bottom: 16px;
  width: 74%;
  margin-left: 40px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 6px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  line-height: 16px;
  margin-bottom: 9px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer {
  display: flex;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column {
  display: flex;
  flex-direction: column;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 8.5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml {
  margin-left: 3px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource {
  font-weight: 500;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .backpage-bg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .backpage-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .backpage-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .backpage-bg {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 760px;
  z-index: 0;
  background-color: black;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container {
  position: absolute;
  top: 645px;
  left: 0;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container .neighborhood-trends-title,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container .neighborhood-trends-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container .neighborhood-trends-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container .neighborhood-trends-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 23px;
  color: black;
  margin-left: 40px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider,
.qv4-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider,
.qv4-pma-editor-page .ye2-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider {
  height: 1px;
  background-color: black;
  width: 360px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left {
  width: 50%;
  position: relative;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-background-img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-background-img {
  height: 370px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container {
  position: absolute;
  top: 94px;
  left: 40px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-title {
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-left: 4px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle {
  color: #333333;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  display: flex;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle.speedometer-subtitle-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle.speedometer-subtitle-col {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xl-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xl-speedometer-area-text {
  font-size: 22px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .large-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .large-speedometer-area-text {
  font-size: 22px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .medium-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .medium-speedometer-area-text {
  font-size: 22px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .small-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .small-speedometer-area-text {
  font-size: 22px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text {
  font-size: 16px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xxs-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xxs-speedometer-area-text {
  font-size: 16px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: #475862;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-img {
  position: absolute;
  top: 56px;
  right: 80px;
  height: 150px;
  width: 250px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .buyers-advantage-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .buyers-advantage-text {
  position: absolute;
  top: 194px;
  right: 255px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 10.5px;
  letter-spacing: 0.05em;
  color: black;
  line-height: 12px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .sellers-advantage-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .sellers-advantage-text {
  position: absolute;
  top: 194px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 10.5px;
  letter-spacing: 0.05em;
  color: black;
  line-height: 12px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container {
  position: absolute;
  top: 300px;
  right: 20px;
  height: 50px;
  width: 361px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title {
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 21px;
  text-transform: uppercase;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics {
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.025em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-bg-gradient,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-bg-gradient {
  background: linear-gradient(163deg, rgb(222, 185, 201) 0%, rgb(255, 255, 255) 60%);
  height: 340px;
  width: 99.5%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-background-img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-background-img {
  height: 370px;
  width: 99.5%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 {
  position: absolute;
  top: 260px;
  right: 80px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2.raise-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2.raise-title {
  margin-top: -55px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2.raise-title .speedometer-column-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2.raise-title .speedometer-column-subtitle {
  margin-top: 15px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-title {
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: black;
  font-size: 13px;
  letter-spacing: 0.05em;
  margin-left: 4px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: -17px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle div,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle div {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: #333333;
  text-transform: uppercase;
  font-size: 19px;
  letter-spacing: 0.075em;
  line-height: 35px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.xxs-single-line-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.xxs-single-line-speedometer-area-text {
  font-size: 18px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.xs-single-line-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.xs-single-line-speedometer-area-text {
  font-size: 20px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.small-single-line-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.small-single-line-speedometer-area-text {
  font-size: 20px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.speedometer-subtitle-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.speedometer-subtitle-col {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .small-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .small-speedometer-area-text {
  font-size: 29px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xs-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xs-speedometer-area-text {
  font-size: 15px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xxs-speedometer-area-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xxs-speedometer-area-text {
  font-size: 20px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-circle-divider,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-img {
  position: absolute;
  top: 45px;
  right: 80px;
  height: auto;
  width: 320px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .buyers-advantage-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .buyers-advantage-text {
  position: absolute;
  top: 209px;
  right: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .sellers-advantage-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .sellers-advantage-text {
  position: absolute;
  top: 209px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 {
  position: absolute;
  top: 94px;
  left: 40px;
  height: 90px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-market-title-v2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-market-title-v2 {
  color: black;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  line-height: 38px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-statistics-v2,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-statistics-v2 {
  color: black;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  line-height: 38px;
  letter-spacing: 0.1em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-chartTip,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-chartTip {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 50;
  max-width: 250px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph {
  height: 200px;
  width: 350px;
  margin-left: 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph .graph-line,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph .graph-line {
  stroke: rgba(235, 242, 242, 0.4);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph .x-labels,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph .x-labels {
  text-anchor: middle;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph .y-labels,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph .y-labels {
  text-anchor: end;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph .quiz-graph-grid,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph .quiz-graph-grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .label-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .label-title {
  text-anchor: middle;
  text-transform: uppercase;
  font-size: 12px;
  fill: gray;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left.firstBg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left.firstBg {
  fill: rgba(217, 173, 118, 0.8);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left.secondBg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left.secondBg {
  fill: rgba(229, 213, 178, 0.8);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left.thirdBg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left.thirdBg {
  fill: rgba(171, 170, 168, 0.8);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-dot.firstBg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.firstBg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph-dot.firstBg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.firstBg {
  fill: rgba(210, 157, 92, 0.8);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-dot.secondBg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.secondBg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph-dot.secondBg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.secondBg {
  fill: rgba(219, 196, 148, 0.8);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-dot.thirdBg,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.thirdBg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph-dot.thirdBg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.thirdBg {
  fill: rgba(154, 153, 151, 0.8);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .svg-cover,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .svg-cover {
  width: 350px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .bottom-x-labels text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .bottom-x-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 9px;
  letter-spacing: -0.075em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .bottom-x-labels text.graph-quarter-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .bottom-x-labels text.graph-quarter-text {
  font-weight: 400;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .data-labels,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .data-labels text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .data-labels,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .data-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10.5px;
  width: 30px;
  text-align: center;
  text-anchor: middle;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container {
  position: absolute;
  top: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section {
  display: flex;
  height: 180px;
  width: 100%;
  margin-bottom: 31px;
  box-shadow: 0 12px 18px -9px rgb(204.5, 204.5, 204.5);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row {
  display: flex;
  justify-content: space-between;
  height: 200px;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph {
  width: 33.3%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section {
  width: 57%;
  height: 90%;
  display: flex;
  flex-direction: column;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row {
  font-weight: bold;
  width: 100%;
  height: 20px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: black;
  line-height: 12px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider {
  height: 80px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0 5px;
  align-self: center;
  display: flex;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section {
  display: flex;
  flex-direction: column;
  background-color: #f2eee4;
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 83%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 46%;
  height: 120px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  margin-top: -6px;
  height: 18px;
  letter-spacing: 0.05em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data.zero-units-sold,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data.zero-units-sold {
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9.6px;
  text-transform: uppercase;
  color: black;
  margin-top: 19px;
  margin-bottom: -19px;
  height: 15px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 7px;
  text-transform: uppercase;
  color: black;
  margin-top: 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text {
  margin-top: 22px;
  margin-bottom: -15px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: black;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11.5px;
  text-transform: uppercase;
  color: black;
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow {
  height: 22px;
  width: 36px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .qv4-sellers-adv-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .qv4-sellers-adv-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 12.5px;
  margin-top: 17px;
  height: 19px;
  color: rgb(0, 34, 90);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .dashboard-bg,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .dashboard-bg {
  position: absolute;
  margin: auto;
  left: 5%;
  right: 5%;
  top: 9.6%;
  width: 572px;
  height: 849px;
  z-index: 0;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-header,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 70px;
  margin-top: 69px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-header .db-header-main,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-header .db-header-main {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: 0.35em;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body {
  position: absolute;
  top: 133px;
  width: 100%;
  height: 910px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-body-header,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-body-header {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px auto 10px auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-body-header .db-body-header-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-body-header .db-body-header-title {
  color: #838b75;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-align: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-body-subheader,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-body-subheader {
  width: 280px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box {
  right: 0px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider {
  right: 0px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header {
  width: 490px;
  flex-direction: row-reverse;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category {
  text-align: left;
  margin-left: 15%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation {
  width: 65%;
  margin-left: 13%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 13%;
  margin-top: 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage {
  display: flex;
  justify-content: flex-start;
  color: #8f555c;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box {
  left: 0px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider {
  left: 0px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header {
  width: 471px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category {
  text-align: right;
  margin-right: -6.5%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation {
  width: 65%;
  margin-left: 22%;
  text-align: right;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 22%;
  margin-top: 10px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage {
  display: flex;
  justify-content: flex-end;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container {
  clear: both;
  display: inline-block;
  width: 100%;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator {
  height: 189px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 1.3333px;
  width: 502px;
  position: absolute;
  top: 48.5px;
  z-index: 3;
  background-color: black;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
  height: 210px;
  z-index: 1;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 575px;
  height: 91%;
  position: absolute;
  z-index: 2;
  background-color: rgba(251, 251, 246, 0.9);
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  height: 40px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num {
  width: 125px;
  text-align: right;
  color: #a36b71;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 34px;
  line-height: 28px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  width: 280px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 13px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  line-height: 17px;
  text-align: justify;
  text-justify: inter-word;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation {
  margin-top: 10px;
  color: #424142;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  line-height: 17px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage {
  color: #8f555c;
  font-weight: 400;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-weight: 400;
  color: black;
  margin-left: 4px;
  display: inline-block;
  float: right;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: 19px;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-title,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-weight: 700;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-text,
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-text,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.075em;
  color: #8f555c;
  width: 90%;
  margin: auto;
}
.qv4-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff,
.qv4-pma-editor-page .ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  display: inline-block;
  margin-left: 10px;
  width: auto;
}
.qv4-pma-editor-page-container {
  width: 1465px;
  height: 1070px;
  margin: 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.qv4-pma-editor-page-container .qv4-first-left,
.qv4-pma-editor-page-container .qv4-first-right,
.qv4-pma-editor-page-container .qv4-second-left,
.qv4-pma-editor-page-container .qv4-second-right {
  width: 50%;
  position: relative;
}
.qv4-pma-editor-page-container .MuiDialog-paper {
  background-color: green;
}
.qv4-pma-editor-page-container .MuiPaper-root {
  border: 15px solid green;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container {
  height: 690px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-bg-image-container {
  position: absolute;
  left: 0px;
  top: 79px;
  z-index: 0;
  height: 100%;
  width: 100%;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-bg-image-container .tax-rate-bg-image {
  width: 58%;
  height: 69%;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-inner-container {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-title-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-title-container .tax-rate-title-text {
  align-self: flex-start;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-title-container .tax-rate-subtitle-text {
  align-self: flex-end;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 18px;
  letter-spacing: 0.025em;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container {
  height: 493px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section {
  margin-top: 34px;
  height: 200px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(246, 247, 247);
  box-shadow: 0px 15px 9px 0px rgb(191.75, 191.75, 191.75);
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner {
  width: 90%;
  margin: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-title {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-text {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  line-height: 19px;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-disclaimer-container .tax-rate-disclaimer-text {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 9px;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-footer-container {
  margin-top: 30px;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-1 {
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-2 {
  margin-top: 11px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 20.5px;
  letter-spacing: 0.025em;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .tax-rate-qr-code .mccaw-qr-logo-and-avatar {
  width: 70px;
  height: 70px;
  margin-left: 10px;
  margin-right: 10px;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-bold {
  margin-top: 0px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
  letter-spacing: 0.025em;
  text-align: center;
  line-height: 21px;
}
.qv4-pma-editor-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-thin {
  margin-top: 5px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 10px;
  letter-spacing: 0.025em;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal {
  height: 320px;
  width: 370px;
  background-color: gray;
  position: absolute;
  z-index: 3;
  left: 125%;
  bottom: 5%;
  border-radius: 20px;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-title {
  width: 80%;
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
  overflow-y: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row input {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row input:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row p {
  font-size: 24px;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 90%;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button {
  width: 178px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.qv4-pma-editor-page-container .qv4-first-left .first-left-dim {
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.5;
  height: 400px;
  with: 100%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor {
  width: 1080px;
  position: absolute;
  right: 10px;
  top: 50%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .return-to-pma-btn {
  position: absolute;
  top: 100px;
  right: 100px;
  z-index: 4;
  background-color: gray;
  height: 25px;
  padding: 5px 10px;
  border-radius: 10px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .return-to-pma-btn div {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .return-to-pma-btn div:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-select-instructions {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  color: red;
  animation: blinker 1s step-start 10;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-title-instructions {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list {
  display: flex;
  justify-content: center;
  width: 60%;
  margin: 40px auto 0 auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button {
  border: 2px solid gray;
  width: auto;
  height: 40px;
  padding: 0 10px;
  margin: 0 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button div {
  color: black;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button.highlighted-btn {
  border: none;
  background-color: #3d85c6;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button.highlighted-btn div {
  color: white;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-body {
  margin: 20px auto 0 auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button {
  background-color: #3d85c6;
  height: 30px;
  width: 150px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button div {
  color: white;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .empty-div {
  width: 30%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-btn {
  background-color: #3d85c6;
  height: 25px;
  width: 185px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-btn:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-btn div {
  color: white;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-title {
  right: 170px;
  top: 50px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-text {
  top: 50px;
  right: 270px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .restore-btn {
  height: 35px;
  width: 80px;
  left: 270px;
  top: 60px;
  position: absolute;
  color: #3d85c6;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .restore-btn:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container {
  display: flex;
  justify-content: center;
  height: 25px;
  margin: 25px auto 0 auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container .submit-btn {
  background-color: #3d85c6;
  height: 25px;
  width: 280px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container .submit-btn:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container .submit-btn div {
  color: white;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section {
  background-color: #bfbdb8;
  width: 320px;
  height: 130px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-title {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: regular;
  font-size: 30.5px;
  text-transform: uppercase;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-text p,
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-text div {
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-divider {
  width: 100%;
  height: 2px;
  background-color: gray;
  margin: 30px auto 0 auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selector-text {
  text-align: center;
  margin: 30px auto 0 auto;
}