@font-face {
  font-family: "FormationSansRegular";
  src: url("../YearEndStatement/FormationSansRegular2.ttf");
}

.agent-performance {
  border: 1px solid gray;
  background-color: #f9f7ec;
  width: 1080px;
  margin: auto;
  .ap-header {
    //  border: 1px solid green;
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
    background-color: #4a7396;
    .ap-header-inner {
      height: 70%;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .ap-header-left {
        width: 25%;
        div {
          font-family: FormationSansRegular;
          font-size: 48px;
          letter-spacing: 0.05em;
          color: white;
        }
      }
      .ap-header-center {
        width: 50%;
        display: flex;
        justify-content: center;
        div {
          font-family: FormationSansRegular;
          font-size: 48px;
          letter-spacing: 0.05em;
          color: white;
        }
      }
      .ap-header-right {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 300;
          font-style: normal;
          font-size: 15px;
          text-transform: uppercase;
          color: white;
          &:hover {
            cursor: pointer;
            font-size: 19px;
          }
        }
      }
    }
  }
  .ap-body {
    width: 90%;
    margin: auto;
    .ap-body-header {
      flex-direction: column;
      justify-content: flex end;
      .available-areas {
        display: flex;
        justify-content: flex-end;
        border: 1px solid gray;
        color: lightblue;
      }
      .download-link {
        display: flex;
        justify-content: flex-end;
      }
    }
    .invoice-table {
      margin: 20px auto 0 auto;
      //  border: 1px solid green;
      width: 100%;
      .invoice-table-header {
        //border: 1px solid red;
        display: flex;
        border-bottom: 1px solid #4a7396;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 11px;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          line-height: 13px;
          color: #00305e;
        }
        div:nth-child(1) {
          width: 100px;
          // border: 1px solid purple;
        }
        div:nth-child(2) {
          width: 100px;
          // border: 1px solid purple;
        }
        div:nth-child(3) {
          width: 150px;
          // border: 1px solid purple;
        }
        div:nth-child(4) {
          width: 150px;
          // border: 1px solid purple;
        }
        div:nth-child(5) {
          width: 150px;
          //border: 1px solid purple;
        }
        div:nth-child(6) {
          width: 200px;
          // border: 1px solid purple;
        }
        div:nth-child(7) {
          > div {
            width: 210px;
          }
          width: 210px;
          // border: 1px solid purple;
        }
      }

      .invoice-table-body {
        //border: 1px solid red;
        display: flex;
        flex-direction: column;
        margin: 20px auto 0 auto;
        .invoice-table-row {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid darkgray;
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 11px;
          }
          div:nth-child(1) {
            width: 100px;
            //  border: 1px solid purple;
            > div {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: italic;
              font-size: 12px;
              line-height: 16px;
              text-decoration: underline;
              color: #4e85af;
              &:hover {
                cursor: pointer;
              }
            }
          }
          div:nth-child(2) {
            width: 100px;
            //border: 1px solid purple;
          }
          div:nth-child(3) {
            width: 150px;
            //  border: 1px solid purple;
          }
          div:nth-child(4) {
            width: 150px;
            //  border: 1px solid purple;
          }
          div:nth-child(5) {
            width: 150px;
            //border: 1px solid purple;
          }
          div:nth-child(6) {
            width: 200px;
            //border: 1px solid purple;
            font-family: objektiv-mk2, sans-serif;
            font-weight: 300;
            font-style: normal;
            > div {
              line-height: 14.4px;
              color: #414042;
            }
          }
          div:nth-child(7) {
            width: 210px;
            //  border: 1px solid purple;
          }
        }
      }
    }
  }
}
