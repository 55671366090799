* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f9fafb;
}

a {
  text-decoration: none;
}

.ps__rail-y {
  right: 0 !important;
  left: auto !important;
}

.ps__rail-x {
  bottom: 0 !important;
  top: auto !important;
}