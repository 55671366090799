.editor-y2-year-in-review-v4,
.editor-one-area-review-v4 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 95.7%;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-bg-v4,
.editor-one-area-review-v4 .y2-year-in-review-bg-v4 {
  position: relative;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ded9ce;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-inner-container-v4,
.editor-one-area-review-v4 .y2-year-in-review-inner-container-v4 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 {
  margin: 20px auto 0 auto;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 248px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 70px;
  margin-top: 8px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 .review-title-year-v2,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 .review-title-year-v2 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 63px;
  letter-spacing: 0.05em;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 .review-title-vs-v2,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 .review-title-vs-v2 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 43px;
  line-height: 74px;
  letter-spacing: 0.05em;
  padding: 0 20px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-subtitle-v2,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-subtitle-v2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-subtitle-v2 .y2-year-in-review-subtitle-text-v2,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-subtitle-v2 .y2-year-in-review-subtitle-text-v2 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  line-height: 27px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 {
  margin-top: -15px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-title-row .y2-comparing-description-title,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-title-row .y2-comparing-description-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 77px;
  text-align: center;
  color: #7b97a2;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-title-row .y2-comparing-description-title .large-c,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-title-row .y2-comparing-description-title .large-c {
  font-size: 93px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-text-col,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-text-col {
  width: 100%;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-text-col .y2-comparing-description-text,
.editor-one-area-review-v4 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-text-col .y2-comparing-description-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.1em;
  line-height: 25.5px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 {
  margin: 20px auto 0 auto;
  height: 268px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 248px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-title-row-v4,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-title-row-v4 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 70px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-title-row-v4 .review-title-year-v4,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-title-row-v4 .review-title-year-v4 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 63px;
  letter-spacing: 0.05em;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-title-row-v4 .review-title-vs-v4,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-title-row-v4 .review-title-vs-v4 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: 0.075em;
  padding: 0 10px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-subtitle-v4,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-subtitle-v4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-subtitle-v4 .y2-year-in-review-subtitle-text-v4,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-year-in-review-subtitle-v4 .y2-year-in-review-subtitle-text-v4 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  line-height: 27px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4 {
  margin-top: -15px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4 .y2-comparing-description-title-row .y2-comparing-description-title,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4 .y2-comparing-description-title-row .y2-comparing-description-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 77px;
  text-align: center;
  color: #87a7a9;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4 .y2-comparing-description-title-row .y2-comparing-description-title .large-c,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4 .y2-comparing-description-title-row .y2-comparing-description-title .large-c {
  font-size: 93px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4 .y2-comparing-description-text-col,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4 .y2-comparing-description-text-col {
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4 .y2-comparing-description-text-col .y2-comparing-description-text,
.editor-one-area-review-v4 .y2-year-in-review-header-v4 .y2-year-in-review-title-container-v4 .y2-comparing-description-container-v4 .y2-comparing-description-text-col .y2-comparing-description-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 22.5px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-area-names-v4,
.editor-one-area-review-v4 .y2-year-in-review-area-names-v4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4,
.editor-y2-year-in-review-v4 .single-area-v4,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4,
.editor-one-area-review-v4 .single-area-v4 {
  display: flex;
  flex-direction: row;
  width: 94%;
  margin: auto;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4.special-review-mt-negative,
.editor-y2-year-in-review-v4 .single-area-v4.special-review-mt-negative,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4.special-review-mt-negative,
.editor-one-area-review-v4 .single-area-v4.special-review-mt-negative {
  margin-top: -10px;
  height: 67px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4.special-review-mt-negative .special-section-1,
.editor-y2-year-in-review-v4 .single-area-v4.special-review-mt-negative .special-section-1,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4.special-review-mt-negative .special-section-1,
.editor-one-area-review-v4 .single-area-v4.special-review-mt-negative .special-section-1 {
  display: flex;
  flex-direction: column;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4.special-review-mt-negative .special-section-1 .y2-year-in-review-special-city-name,
.editor-y2-year-in-review-v4 .single-area-v4.special-review-mt-negative .special-section-1 .y2-year-in-review-special-city-name,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4.special-review-mt-negative .special-section-1 .y2-year-in-review-special-city-name,
.editor-one-area-review-v4 .single-area-v4.special-review-mt-negative .special-section-1 .y2-year-in-review-special-city-name {
  height: 60px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4 .y2-review-names-section,
.editor-y2-year-in-review-v4 .single-area-v4 .y2-review-names-section,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4 .y2-review-names-section,
.editor-one-area-review-v4 .single-area-v4 .y2-review-names-section {
  width: 100%;
  margin-top: -5px;
  margin-bottom: 5px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-city-name,
.editor-y2-year-in-review-v4 .single-area-v4 .y2-year-in-review-city-name,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-city-name,
.editor-one-area-review-v4 .single-area-v4 .y2-year-in-review-city-name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  letter-spacing: 0.01em;
  line-height: 23px;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-city-name p,
.editor-y2-year-in-review-v4 .single-area-v4 .y2-year-in-review-city-name p,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-city-name p,
.editor-one-area-review-v4 .single-area-v4 .y2-year-in-review-city-name p {
  text-align: center;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-special-city-name,
.editor-y2-year-in-review-v4 .single-area-v4 .y2-year-in-review-special-city-name,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-special-city-name,
.editor-one-area-review-v4 .single-area-v4 .y2-year-in-review-special-city-name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 22px;
  letter-spacing: 0.01em;
  line-height: 31px;
  text-transform: capitalize;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-special-city-name p,
.editor-y2-year-in-review-v4 .single-area-v4 .y2-year-in-review-special-city-name p,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-special-city-name p,
.editor-one-area-review-v4 .single-area-v4 .y2-year-in-review-special-city-name p {
  text-align: center;
}
.editor-y2-year-in-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-area-name-subtitle-v4,
.editor-y2-year-in-review-v4 .single-area-v4 .y2-year-in-review-area-name-subtitle-v4,
.editor-one-area-review-v4 .y2-year-in-review-names-row-v4 .y2-year-in-review-area-name-subtitle-v4,
.editor-one-area-review-v4 .single-area-v4 .y2-year-in-review-area-name-subtitle-v4 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.editor-y2-year-in-review-v4 .single-area-v4,
.editor-one-area-review-v4 .single-area-v4 {
  width: 40%;
  margin: auto;
}
.editor-y2-year-in-review-v4 .single-area-v4 div,
.editor-one-area-review-v4 .single-area-v4 div {
  width: 100%;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 {
  display: flex;
  height: 67.2%;
  justify-content: center;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4:nth-child(1),
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4:nth-child(1) {
  margin-right: 15px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4:nth-child(2),
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4:nth-child(2) {
  margin-left: 15px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 {
  width: 305px;
  height: 100%;
  background-color: white;
  position: relative;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .sideways-past-6-months-dashboard,
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .sideways-previous-6-months-dashboard,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .sideways-past-6-months-dashboard,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .sideways-previous-6-months-dashboard {
  position: absolute;
  height: 100%;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(0deg);
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  color: #87a7a9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .sideways-previous-6-months-dashboard,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .sideways-previous-6-months-dashboard {
  transform: rotate(180deg);
  left: 0px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .sideways-past-6-months-dashboard,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .sideways-past-6-months-dashboard {
  right: 0px;
  transform: rotate(360deg);
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .y2-year-in-review-area-names-column-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .y2-year-in-review-area-names-column-v4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 82px;
  margin-top: 10px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .y2-year-in-review-area-names-column-v4 .y2-year-in-review-area-title-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .y2-year-in-review-area-names-column-v4 .y2-year-in-review-area-title-v4 {
  color: #3d4e57;
  font-family: playfair-display, serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.2em;
  font-size: 20px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .y2-year-in-review-area-names-column-v4 .y2-year-in-review-area-name-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .y2-year-in-review-area-names-column-v4 .y2-year-in-review-area-name-v4 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .y2-year-in-review-area-names-column-v4 .y2-year-in-review-area-name-v4 p,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .y2-year-in-review-area-names-column-v4 .y2-year-in-review-area-name-v4 p {
  text-align: center;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-center-divider-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-center-divider-v4 {
  height: 720px;
  width: 4px;
  background-color: #f3d3ae;
  position: absolute;
  top: 170px;
  left: 50%;
  z-index: 0;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-combined-cities-avg-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-combined-cities-avg-v4 {
  position: absolute;
  top: 95px;
  left: 0px;
  width: 100%;
  text-align: center;
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-top-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-top-v4 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 65%;
  position: relative;
  z-index: 1;
  height: 30px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-perc-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-perc-v4 {
  font-size: 18px;
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 10px;
  line-height: 36px;
  margin-top: -26.2px;
  letter-spacing: 0.05em;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-perc-v4 .small-perc-in-text,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-perc-v4 .small-perc-in-text {
  font-size: 14px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-perc-v4.no-change-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-perc-v4.no-change-v4 {
  line-height: 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-perc-v4.no-change-v4 p,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-perc-v4.no-change-v4 p {
  line-height: 35px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-category-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-category-v4 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: black;
  letter-spacing: 0.01em;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 104px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-review-box-year-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-review-box-year-v4 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: rgb(129.9435483871, 89.7983870968, 66.5564516129);
  font-variant: small-caps;
  font-size: 19px;
  letter-spacing: 0.05em;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-review-box-year-v4.year-2020-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-review-box-year-v4.year-2020-v4 {
  transform: rotate(-90deg) translateY(0%) translateX(-12%);
  margin: 0;
  width: 35px;
  height: 17px;
  padding: 0;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-review-box-year-v4.year-2021-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-review-box-year-v4.year-2021-v4 {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-inner-data-box-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-inner-data-box-v4 {
  background-color: #f2ede7;
  width: 229px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 115px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-inner-data-box-v4 .ye2-review-box-data-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-inner-data-box-v4 .ye2-review-box-data-v4 {
  display: flex;
  justify-content: center;
  width: 48%;
  font-size: 20px;
  letter-spacing: 0.025em;
  color: black;
  font-family: playfair-display, serif;
  font-weight: 300;
  font-style: normal;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-inner-data-box-v4 .ye2-box-divider-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-comparison-v4 .ye2-inner-data-box-v4 .ye2-box-divider-v4 {
  height: 84px;
  width: 1px;
  background-color: #a59d95;
  margin-top: -25px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-sellers-adv-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .ye2-review-box-v4 .ye2-review-box-sellers-adv-v4 {
  height: 25px;
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: black;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .year-in-review-disclaimer,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .year-in-review-disclaimer {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .year-in-review-disclaimer p,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .year-in-review-disclaimer p {
  font-size: 10px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 {
  width: 90%;
  display: flex;
  margin: 0px auto 0 auto;
  height: 70px;
  padding-top: 1px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-inner-container,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-inner-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-inner-container .units-sold-column,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-inner-container .units-sold-column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-second-row,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-second-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-title-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-title-v4 {
  display: flex;
  justify-content: center;
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  margin-right: 10px;
  letter-spacing: 0.05em;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-vs,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-vs {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  margin: 0 10px;
  letter-spacing: 0.05em;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-data-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-data-v4 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.06em;
  margin-top: -2px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-data-v4 strong,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-data-v4 strong {
  font-size: 12px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-data-v4.data-percentage-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-data-v4.data-percentage-v4 {
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .no-change-data-perc-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .no-change-data-perc-v4 {
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  flex-direction: column;
  text-align: center;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .no-change-data-perc-v4 div,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .no-change-data-perc-v4 div {
  line-height: 16px;
  font-size: 11px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-divider-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-divider-v4 {
  height: 11px;
  width: 1px;
  background-color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  margin: 5px 10px 0px 10px;
}
.editor-y2-year-in-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-not-divider-v4,
.editor-one-area-review-v4 .ye2-year-in-review-body-v4 .ye2-year-in-review-col-v4 .units-sold-container-v4 .units-sold-not-divider-v4 {
  height: 11px;
  width: 1px;
  background-color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  margin: 5px 10px 0px 10px;
}