/* Variables

 */
* {
  -webkit-font-smoothing: auto;
}

.ye2-pma-editor-page-container {
  width: 1365px;
  height: 1070px;
  margin: 20px auto 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.ye2-pma-editor-page-container .ye2-first-left,
.ye2-pma-editor-page-container .table-quadrants-first-left,
.ye2-pma-editor-page-container .ye2-first-right,
.ye2-pma-editor-page-container .ye2-second-left,
.ye2-pma-editor-page-container .ye2-second-right,
.ye2-pma-editor-page-container .bubble-compare-second-left {
  width: 50%;
  position: relative;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 95.7%;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-bg-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-bg-v2 {
  position: relative;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #f2ede7;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-inner-container-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-inner-container-v2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 {
  margin: 20px auto 0 auto;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 248px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 70px;
  margin-top: 8px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 .review-title-year-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 .review-title-year-v2 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 63px;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 .review-title-vs-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-title-row-v2 .review-title-vs-v2 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 43px;
  line-height: 74px;
  letter-spacing: 0.05em;
  padding: 0 20px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-subtitle-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-subtitle-v2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-subtitle-v2 .y2-year-in-review-subtitle-text-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-year-in-review-subtitle-v2 .y2-year-in-review-subtitle-text-v2 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  line-height: 27px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 {
  margin-top: -15px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-title-row .y2-comparing-description-title,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-title-row .y2-comparing-description-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 77px;
  text-align: center;
  color: #d8c2b4;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-title-row .y2-comparing-description-title .large-c,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-title-row .y2-comparing-description-title .large-c {
  font-size: 93px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-text-col,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-text-col {
  width: 100%;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-text-col .y2-comparing-description-text,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-header-v2 .y2-year-in-review-title-container-v2 .y2-comparing-description-container-v2 .y2-comparing-description-text-col .y2-comparing-description-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.1em;
  line-height: 23.5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-area-names-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-area-names-v2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2 {
  display: flex;
  flex-direction: row;
  width: 94%;
  margin: auto;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2.special-review-mt-negative,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2.special-review-mt-negative,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2.special-review-mt-negative,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2.special-review-mt-negative {
  margin-top: -10px;
  height: 67px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2.special-review-mt-negative .special-section-1,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2.special-review-mt-negative .special-section-1,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2.special-review-mt-negative .special-section-1,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2.special-review-mt-negative .special-section-1 {
  display: flex;
  flex-direction: column;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2.special-review-mt-negative .special-section-1 .y2-year-in-review-special-city-name,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2.special-review-mt-negative .special-section-1 .y2-year-in-review-special-city-name,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2.special-review-mt-negative .special-section-1 .y2-year-in-review-special-city-name,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2.special-review-mt-negative .special-section-1 .y2-year-in-review-special-city-name {
  height: 60px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2 .y2-review-names-section,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2 .y2-review-names-section,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2 .y2-review-names-section,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2 .y2-review-names-section {
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-city-name,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2 .y2-year-in-review-city-name,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-city-name,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2 .y2-year-in-review-city-name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 22px;
  letter-spacing: 0.01em;
  line-height: 31px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-city-name p,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2 .y2-year-in-review-city-name p,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-city-name p,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2 .y2-year-in-review-city-name p {
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-special-city-name,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2 .y2-year-in-review-special-city-name,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-special-city-name,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2 .y2-year-in-review-special-city-name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 22px;
  letter-spacing: 0.01em;
  line-height: 31px;
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-special-city-name p,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2 .y2-year-in-review-special-city-name p,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-special-city-name p,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2 .y2-year-in-review-special-city-name p {
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-area-name-subtitle-v2,
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2 .y2-year-in-review-area-name-subtitle-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .y2-year-in-review-names-row-v2 .y2-year-in-review-area-name-subtitle-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2 .y2-year-in-review-area-name-subtitle-v2 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2 {
  width: 40%;
  margin: auto;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .single-area-v2 div,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .single-area-v2 div {
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 {
  display: flex;
  height: 67.2%;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2:nth-child(1),
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2:nth-child(1) {
  margin-right: 15px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2:nth-child(2),
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2:nth-child(2) {
  margin-left: 15px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 {
  width: 305px;
  height: 100%;
  background-color: white;
  position: relative;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .y2-year-in-review-area-names-column-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .y2-year-in-review-area-names-column-v2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 82px;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .y2-year-in-review-area-names-column-v2 .y2-year-in-review-area-title-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .y2-year-in-review-area-names-column-v2 .y2-year-in-review-area-title-v2 {
  color: #3d4e57;
  font-family: playfair-display, serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.2em;
  font-size: 20px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .y2-year-in-review-area-names-column-v2 .y2-year-in-review-area-name-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .y2-year-in-review-area-names-column-v2 .y2-year-in-review-area-name-v2 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .y2-year-in-review-area-names-column-v2 .y2-year-in-review-area-name-v2 p,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .y2-year-in-review-area-names-column-v2 .y2-year-in-review-area-name-v2 p {
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-center-divider-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-center-divider-v2 {
  height: 720px;
  width: 4px;
  background-color: #f3d3ae;
  position: absolute;
  top: 170px;
  left: 50%;
  z-index: 0;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-combined-cities-avg-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-combined-cities-avg-v2 {
  position: absolute;
  top: 95px;
  left: 0px;
  width: 100%;
  text-align: center;
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-top-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-top-v2 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 65%;
  position: relative;
  z-index: 1;
  height: 30px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-perc-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-perc-v2 {
  font-size: 18px;
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 10px;
  line-height: 36px;
  margin-top: -26.2px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-perc-v2.no-change-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-perc-v2.no-change-v2 {
  line-height: 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-perc-v2.no-change-v2 p,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-perc-v2.no-change-v2 p {
  line-height: 35px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-category-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-category-v2 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: black;
  letter-spacing: 0.01em;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 109px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-review-box-year-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-review-box-year-v2 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: rgb(129.9435483871, 89.7983870968, 66.5564516129);
  font-variant: small-caps;
  font-size: 19px;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-review-box-year-v2.year-2020-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-review-box-year-v2.year-2020-v2 {
  transform: rotate(-90deg) translateY(0%) translateX(-12%);
  margin: 0;
  width: 35px;
  height: 17px;
  padding: 0;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-review-box-year-v2.year-2021-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-review-box-year-v2.year-2021-v2 {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-inner-data-box-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-inner-data-box-v2 {
  background-color: #f2ede7;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-inner-data-box-v2 .ye2-review-box-data-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-inner-data-box-v2 .ye2-review-box-data-v2 {
  display: flex;
  justify-content: center;
  width: 48%;
  font-size: 23px;
  letter-spacing: 0.075em;
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-inner-data-box-v2 .ye2-box-divider-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-comparison-v2 .ye2-inner-data-box-v2 .ye2-box-divider-v2 {
  height: 84px;
  width: 1px;
  background-color: #d8c2b4;
  margin-top: -25px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-sellers-adv-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .ye2-review-box-v2 .ye2-review-box-sellers-adv-v2 {
  height: 25px;
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: black;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0 auto;
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-second-row,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-second-row {
  display: flex;
  margin-top: 4px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-title-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-title-v2 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  margin-right: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-data-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-data-v2 {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.06em;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-data-v2 strong,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-data-v2 strong {
  font-size: 14.5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-data-v2.data-percentage-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-data-v2.data-percentage-v2 {
  font-weight: 600;
  font-size: 14.5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .no-change-data-perc-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .no-change-data-perc-v2 {
  text-align: center;
  font-weight: 400;
  font-size: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-divider-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-divider-v2 {
  margin: 0 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-not-divider-v2,
.ye2-pma-editor-page-container .ye2-second-left .one-area-review-v2 .ye2-year-in-review-body-v2 .ye2-year-in-review-col-v2 .units-sold-container-v2 .units-sold-not-divider-v2 {
  margin: 0 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .dashboard-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 42%;
  height: 97%;
  z-index: 0;
}
.ye2-pma-editor-page-container .ye2-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 170px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-header .db-header-main,
.ye2-pma-editor-page-container .ye2-second-left .db-header .db-header-sub {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.3em;
}
.ye2-pma-editor-page-container .ye2-second-left .db-header .db-header-sub {
  width: 315px;
  display: flex;
  justify-content: space-between;
  margin-left: -3px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body {
  position: absolute;
  top: 130px;
  width: 100%;
  height: 850px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 370px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title.large-title {
  font-size: 20px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title.medium-title {
  font-size: 16px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title.small-title {
  font-size: 14px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title.xs-title {
  font-size: 12px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-subtitle {
  color: #657460;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  line-height: 24px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator {
  height: 200px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 2.666px;
  width: 410px;
  position: absolute;
  left: 0;
  top: 55px;
  z-index: 3;
  background-color: #f3d3ae;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 5px;
  width: 530px;
  height: 200px;
  z-index: 1;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 530px;
  height: 90%;
  position: absolute;
  right: 0px;
  z-index: 2;
  background-color: rgba(251, 251, 246, 0.7);
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 2;
  height: 30px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num {
  width: 85px;
  text-align: right;
  color: #c3c1ba;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 37px;
  line-height: 27px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  margin: 30px 0 0 100px;
  width: 300px;
  text-align: left;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px 0 0 76px;
  width: 82%;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  line-height: 14.8px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage-and-benefit-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70px;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px 0 0 55px;
  width: 89%;
  color: #2e6a8e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  line-height: 14.8px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-weight: 400;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: -43px;
  left: 10px;
  width: 125px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: 13px;
  color: #3c4c55;
  margin-bottom: 10px;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line .db-bottom-line-text,
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 9px;
  line-height: 16px;
  color: #3c4c55;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line .db-signoff {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-self: right;
  float: right;
}