/* Variables

 */
* {
  -webkit-font-smoothing: auto;
}

.qv3-print-page-container {
  position: relative;
  width: 1698px;
  height: 1140px;
  margin: 0;
  background: white;
  background-size: contain;
  display: flex;
}
.qv3-print-page-container.flipbook {
  width: 849px;
}
.qv3-print-page-container.regular-print-page {
  width: 1698px;
}
.qv3-print-page-container .qv3-first-left,
.qv3-print-page-container .qv3-first-right,
.qv3-print-page-container .qv3-second-left,
.qv3-print-page-container .qv3-second-right {
  width: 100%;
  position: relative;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 730px;
  z-index: 3;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -2;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-bg img {
  width: 650px;
  height: 691px;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-title-container {
  width: 889px;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-title-container .quick-to-market-trends-title {
  margin-left: 65px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 48px;
  line-height: 30.5px;
  letter-spacing: 0.2em;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-title-container .quick-to-market-subtitle {
  margin-top: 10px;
  margin-left: 213px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  letter-spacing: 0.025em;
  text-align: left;
  z-index: 3;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-body {
  width: 90%;
  height: 514px;
  margin: 31px auto 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section-container {
  width: 46.8%;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section.short {
  height: 230px;
  margin-top: -65px;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section.shorter {
  height: 270px;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section .quick-to-market-trends-section-title {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section .quick-to-market-trends-section-text {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-trends-body .quick-to-market-trends-section .quick-to-market-trends-author {
  color: #9a5f67;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-cta {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 4px;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-cta p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  color: #8f555c;
}
.qv3-print-page-container .qv3-first-left .quick-to-market-trends .quick-to-market-cta span {
  padding-left: 6px;
}
.qv3-print-page-container .qv3-first-left .qv3-corner-highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 348px;
  background-color: #761037;
}
.qv3-print-page-container .qv3-first-left .custom-disclaimer-container {
  height: 34px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  top: 224px;
  left: -115px;
  width: 413px;
}
.qv3-print-page-container .qv3-first-left .custom-disclaimer-container.team-solicitation {
  top: 206px;
}
.qv3-print-page-container .qv3-first-left .custom-disclaimer-container .qv3-solicitation-disclaimer,
.qv3-print-page-container .qv3-first-left .custom-disclaimer-container .qv3-reliable-text,
.qv3-print-page-container .qv3-first-left .custom-disclaimer-container .qv3-cover-photo-credit {
  width: 410px;
  height: 12px;
  text-align: center;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 7.4px;
  text-transform: uppercase;
  letter-spacing: -0.025em;
}
.qv3-print-page-container .qv3-first-left .custom-disclaimer-container .cover-photo-credit {
  text-align: right;
  width: 412px;
  align-self: center;
  height: 12px;
  color: #475862;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
}
.qv3-print-page-container .qv3-first-left .custom-disclaimer-container .mls-disclaimer {
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11.9px;
}
.qv3-print-page-container .qv3-first-left .qv3-testimonial2-container {
  position: absolute;
  bottom: 20px;
  right: 50px;
  height: 220px;
  width: 520px;
  z-index: 5;
}
.qv3-print-page-container .qv3-first-left .qv3-testimonial2-container.extra-width-testimonial {
  width: 525px;
}
.qv3-print-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial-title {
  width: 100%;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 20px;
  color: #3c4c55;
  margin-left: 5px;
  letter-spacing: 0.025em;
}
.qv3-print-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial-divider {
  background-color: #f3d3ae;
  height: 2.66px;
  width: 570px;
}
.qv3-print-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial {
  margin-top: 10px;
}
.qv3-print-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial .testimonial-container {
  font-family: mencken-std-head, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.075em;
  color: #6f7270;
  text-align: center;
}
.qv3-print-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial .testimonial-container .testimonial-signoff {
  text-align: right;
  margin-right: 30px;
}
.qv3-print-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial .testimonial-container .testimonial-content-row {
  display: flex;
}