//the top right corner header of the photo page
.print-agent-logo-header {
  position: absolute;
  top: 24px;
  right: 40px;
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  .agent-logo-tagline {
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    margin-right: 40px;
  }
  &.reduced-agent-logo-header {
    top: 24px;
  }
  &.less-margin-agent-logo-header {
    top: 24px;
  }

  &.down-left-agent-logo-header {
    top: 26px;
    right: 70px;
  }
  &.extra-reduced-agent-logo-header {
    top: 24px;
  }
  &.xxl-agent-logo-header {
    max-height: 85px;
    max-width: 250px;
    height: 76px;
    width: auto;
  }
  .agent-logo-header-img {
    max-height: 60px;
    max-width: 250px;
    height: auto;
    width: auto;

    &.larger-logo {
      max-height: 65px;
      max-width: 290px;
      height: 65px;
      width: auto;
    }
    &.reduce-height-logo {
      max-height: 45px;
      height: 36px;
    }
    &.agent-logo-height-85 {
      max-height: 85px;
      max-width: 290px;
      height: 85px;
      width: auto;
    }
    &.agent-logo-height-25 {
      max-height: 25px;
      max-width: 290px;
      height: 25px;
      width: auto;
    }
    &.medium-height-logo {
      max-height: 55px;
      max-width: 290px;
      height: 50px;
      width: auto;
    }
    &.width-275-height-60 {
      max-height: 60px;
      max-width: 275px;
      height: auto;
      width: auto;
    }
    &.width-200-height-60 {
      max-height: 60px;
      max-width: 200px;
      height: auto;
      width: auto;
    }
    &.agent-logo-header-height-45 {
      max-height: 45px;
      max-width: 290px;
      height: 45px;
      width: auto;
    }
    &.m1-height-logo {
      max-height: 70px;
      max-width: 320px;
      height: 65px;
      width: auto;
    }
    &.xl-agent-logo {
      max-height: 75px;
      max-width: 250px;
      height: auto;
      width: auto;
    }
    &.xxl-agent-logo {
      max-height: 85px;
      max-width: 250px;
      height: 79px;
      width: auto;
    }
    &.triple-x-agent-logo {
      max-height: 100px;
      max-width: 250px;
      height: 100px;
      width: auto;
    }
    &.reduced-width-agent-logo-header {
      max-height: 75px;
      max-width: 220px;
      height: auto;
      width: auto;
    }
  }
  //custom agent logo styles on photo page
  .uhrig-agent-logo-header {
    max-height: 60px;
    max-width: 250px;
    height: auto;
    width: auto;
    margin-left: 10px;
  }
  .amber-russell-agent-logo-header {
    max-height: 58px;
    max-width: 250px;
    height: auto;
    width: auto;
    margin-top: -10px;
    margin-right: 20px;
  }

  .sheldon-rilliet-logo-header {
    max-height: 50px;
    max-width: 250px;
    height: auto;
    width: auto;
    margin-left: 20px;
    margin-top: -2px;
  }
  .mr-2-agent-logo-header {
    margin-right: 20px;
    margin-left: 0px;
  }
  .dianne-johnson-agent-logo-header {
    max-height: 60px;
    max-width: 330px;
    height: auto;
    width: auto;
    margin-left: 10px;
  }
  .long-agent-logo-header-v1 {
    max-height: 90px;
    max-width: 320px;
    height: auto;
    width: auto;
    margin-left: 10px;
  }
  .kristin-arntz-agent-logo-header {
    max-height: 80px;
    max-width: 300px;
    height: auto;
    width: auto;
    margin-left: 10px;
  }
}
