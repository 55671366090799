.print-first-left-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 10px;
  left: 10px;
  .logo-address {
    .agentLogo1-left {
      height: 90px;
      width: 250px;
      position: absolute;
      top: 30px;
      left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: auto;
        max-height: 90px;
        width: auto;
        max-width: 250px;
      }
    }
  }
}
