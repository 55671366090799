.editor-qr-code-section-v2 {
  height: 80px;
  width: 95%;

  display: flex;
  flex-direction: flex-start;
  margin: 18px auto 0 auto;
  position: absolute;
  top: 274px;
  left: 30px;
  &.smaller-qr-section-v2 {
    top: 223px;
  }
  &.lower-qr-code {
    top: 223px;
  }
  &.lower-qr-code-2 {
    top: 313px;
  }
  &.qr-code-top-345 {
    top: 310px;
  }
  &.qr-code-top-300 {
    top: 280px;
  }
  .qr-code-col1 {
    width: 14%;
    // margin-top: 10px;
    .bubble-arrow-container {
      width: 100%;
      height: 100%;
      margin: auto;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      img {
        height: 41px;
        // height: 38px;
        width: auto;
        margin-top: 19px;
      }
      &.larger-qr-arrow-img {
        img {
          margin-top: 23px;
        }
      }
      &.smaller-qr-arrow-img {
        img {
          height: 38px;
          margin-left: 15px;
        }
      }
    }
  }
  .qr-code-col2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 87%;
    // margin-top: 10px;
    .qr-code-title {
      height: 16%;
      display: flex;
      align-items: center;
      p {
        font-family: "objektiv-mk2", sans-serif;
        font-weight: 500;
        font-style: italic;
        font-size: 19px;
      }
      .qr-code-space {
        margin-right: 9px;
        font-style: normal;
        font-weight: 600;
        color: rgb(0, 79, 172);
      }
    }
    .qr-code-content-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 80%;
      .bubble-qr-container {
        width: 14%;
        //  border: 1px solid orange;
        img {
          // margin-top: -8px;
          height: 75px;
          width: 75px;
          // height: 70px;
          // width: 70px;
        }
        &.larger-qr-img {
          img {
            height: 75px;
            width: 75px;
            margin-bottom: -5px;
          }
        }

        &.smaller-qr-img {
          img {
            height: 70px;
            width: 70px;
          }
        }
      }

      .qr-code-text {
        width: 79%;
        display: flex;
        align-items: center;
        margin-left: 10px;
        p {
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 400;
          font-style: italic;
          font-size: 13px;
          line-height: 21.5px;
          letter-spacing: 0.025em;
        }
        .smaller-qr-code-text {
          font-size: 13px;

          letter-spacing: 0.025em;
          line-height: 16px;
          margin-top: -3px;
        }
      }
      .qr-code-link {
        font-family: "objektiv-mk2", sans-serif;
        font-weight: 600;
        font-style: italic;
        font-size: 14.75px;
        line-height: 21px;
        letter-spacing: 0.05em;

        // color: #8a9985;
        color: rgb(0, 79, 172);
        margin-left: 5px;
      }
    }
  }
}
