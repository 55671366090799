/* Variables

 */
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container,
.ye-print .ye2-print-page .ye2-print-page-container {
  position: relative;
  width: 1698px;
  height: 1140px;
  margin: 0;
  background: white;
  background-size: contain;
  display: flex;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-right,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-right,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-right,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-right,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-right,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-right,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-right,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-right,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-right,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-right,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-right,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-right,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-right,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-right,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-right,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-right,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-right,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-right,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-right,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-right,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-right,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-right,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-right,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-right {
  width: 50%;
  position: relative;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container {
  position: absolute;
  bottom: 0px;
  height: 680px;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-inner-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-inner-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-inner-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-inner-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-inner-container {
  width: 85%;
  margin: auto;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container {
  height: 240px;
  width: 100%;
  position: absolute;
  width: 85%;
  z-index: 0;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image {
  height: 100%;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text {
  color: red;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 28px;
  line-height: 27px;
  text-align: center;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin: auto;
  position: absolute;
  z-index: 1;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff {
  padding: 0 10px;
  color: #424142;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.2em;
  text-align: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.smaller-testimonial-text,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.smaller-testimonial-text {
  font-size: 13px;
  letter-spacing: 0.15em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  width: 90%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p {
  text-align: right;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn {
  height: 59px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4e85af;
  position: absolute;
  z-index: 5;
  border-radius: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn:hover {
  cursor: pointer;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn div,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn div,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn div,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn div,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn div {
  color: white;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn {
  left: 260px;
  top: 210px;
  width: 150px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-title-btn {
  top: 45px;
  left: 5px;
  width: 150px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-0 {
  top: 130px;
  left: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-1 {
  top: 105px;
  left: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-2 {
  top: 140px;
  left: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-btn.quadrant-btn-3 {
  top: 345px;
  left: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 50px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text {
  padding: 13px 0px 7px 0px;
  align-self: flex-start;
  margin-right: 5px;
  text-transform: uppercase;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 59px;
  color: #424142;
  letter-spacing: 0.14em;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  padding: 8px 0px 7px 0px;
  color: #424142;
  align-self: flex-end;
  margin-left: 5px;
  line-height: 20px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 440px;
  flex-wrap: wrap;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .section-container .section-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5px;
  height: 185px;
  width: 45%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 40.5px;
  color: #424142;
  margin-right: 15px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section {
  background-color: rgb(222.4311111111, 221.4133333333, 218.8688888889);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section:hover {
  background-color: rgb(222.4311111111, 221.4133333333, 218.8688888889);
  cursor: pointer;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title {
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #424142;
  text-align: left;
  font-size: 17px;
  text-transform: uppercase;
  padding-top: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter {
  text-transform: capitalize;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  color: #424142;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p {
  text-align: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter {
  text-transform: capitalize;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -230px;
  z-index: 4;
  position: relative;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .quadrant-container .quadrant-logo-container img {
  height: 145px;
  width: auto;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .placeholder,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .placeholder,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .placeholder,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .placeholder,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .placeholder,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .placeholder,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .placeholder,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .placeholder,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .placeholder,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .placeholder,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .placeholder,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .placeholder,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .placeholder,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .placeholder,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .placeholder,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .placeholder,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .placeholder,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .placeholder,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .placeholder,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .placeholder,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .placeholder,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .placeholder,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .placeholder,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .placeholder {
  position: absolute;
  bottom: 0px;
  height: 750px;
  width: 60%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container {
  position: absolute;
  bottom: 0px;
  height: 750px;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title {
  position: absolute;
  top: 35px;
  left: 386px;
  width: 460px;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text {
  color: #424142;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.15em;
  font-size: 64px;
  text-transform: uppercase;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container {
  position: absolute;
  top: 99px;
  left: 487px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 15px;
  margin-bottom: 15px;
  letter-spacing: 0.1em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  left: 486px;
  height: 82%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: #424142;
  font-size: 25.5px;
  position: absolute;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1 {
  top: 6px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2 {
  top: 216px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3 {
  top: 390px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container {
  position: absolute;
  top: 95px;
  left: 0px;
  height: 150px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 457px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.025em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 730px;
  width: 457px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section {
  display: flex;
  width: 100%;
  position: absolute;
  left: 503px;
  top: 139px;
  z-index: 2;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container {
  width: 80%;
  height: 86%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section {
  margin-bottom: 16px;
  width: 74%;
  margin-left: 40px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11.5px;
  line-height: 17px;
  margin-bottom: 11px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer {
  display: flex;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column {
  display: flex;
  flex-direction: column;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml {
  margin-left: 3px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource {
  font-weight: 500;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-bg-img,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-bg-img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-bg-img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-bg-img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-bg-img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-bg-img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-bg-img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-bg-img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-bg-img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-bg-img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-bg-img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-bg-img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-bg-img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-bg-img,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-bg-img,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 270px;
  width: 100%;
  z-index: 1;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-body,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-body,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-body,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-body,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-body,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-body,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-body,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-body,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-body,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-body,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-body,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-body,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-body,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-body,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-body,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-body,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-body,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-body,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-body,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-body,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-body,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-body,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-body,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-body {
  position: absolute;
  bottom: 50px;
  margin: auto;
  width: 100%;
  z-index: 2;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header {
  width: 96%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-title,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title {
  margin-left: 70px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 28.25px;
  letter-spacing: 0.16em;
  color: #475862;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-divider,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider {
  height: 2.66px;
  width: 645px;
  background-color: rgba(246, 204, 108, 0.8);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-subtitle,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-header .home-value-subtitle,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle {
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.05em;
  color: #53626c;
  margin-top: 6px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto 0 auto;
  table-layout: auto;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #8a927c;
  font-size: 15px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  line-height: 18px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child {
  padding-left: 90px;
  text-align: left;
  width: 320px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(3),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:nth-child(3),
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3) {
  width: 130px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table thead th:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child {
  padding-right: 70px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody tr,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody tr,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody tr,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody tr,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody tr,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody tr,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody tr,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody tr,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody tr,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody tr {
  border-bottom: 1px solid rgb(205.4655172414, 214.7896551724, 220.0344827586);
  height: 27px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #53626c;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child {
  padding-left: 90px;
  text-align: left;
  font-size: 16px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 52%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p {
  width: auto;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child {
  padding-right: 70px;
  font-size: 15px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-table tbody td:last-child p,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p {
  margin: 0 0 0 30%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-source,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-source,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-source,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-source,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-source,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-source,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-source,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-source,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-source,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-source,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-source,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-source,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-source,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-source,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-source,
.ye-print .table-quadrants-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-source,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-source,
.ye-print .table-quadrants-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-source,
.ye-print .ye2-print-page .table-quadrants-print-page-container .qv4-first-left .home-value .home-value-source,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-first-left .home-value .home-value-source,
.ye-print .ye2-print-page .table-quadrants-print-page-container .ye2-first-left .home-value .home-value-source,
.ye-print .ye2-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-source,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-first-left .home-value .home-value-source,
.ye-print .ye2-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-source {
  width: 90%;
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  position: relative;
  z-index: 4;
  top: 15px;
  color: white;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left {
  width: 50%;
  position: relative;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table {
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 30px 0 30px 41px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: black;
  margin: -8px 8px 0 8px;
  height: 5px;
  width: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 5px;
  height: 5px;
  width: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: black;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 31px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 28px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 25px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 30px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider {
  height: 1px;
  margin-top: 2px;
  margin-left: 23px;
  width: 477px;
  background-color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long {
  margin-left: 23px;
  width: 826px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 319px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 430px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 21px;
  display: flex;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers {
  margin-top: -1px;
  min-height: 500px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 552px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th {
  font-size: 11px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p {
  line-height: 13px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 23px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 33px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 18.5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 64px;
  text-align: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 59px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 120px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 159px;
  text-align: left;
  font-weight: 400;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address {
  font-size: 8.5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  width: 92px;
  font-weight: 400;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 73px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 41px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 100px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 63px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 10px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p {
  font-size: 8px;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend {
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 9px;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 21px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-second-left-middle-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-second-left-middle-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-second-left-middle-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-second-left-middle-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-second-left-middle-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-second-left-middle-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-second-left-middle-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-second-left-middle-section {
  height: 887px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators {
  width: 100%;
  height: 165px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer {
  width: 80%;
  margin-left: 43px;
  display: flex;
  flex-direction: column;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row {
  display: flex;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2) {
  width: 90px;
  text-align: right;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare {
  width: 105px;
  display: flex;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator {
  width: 112px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator {
  width: 121px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 13px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: 23px;
  height: 1px;
  width: 477px;
  background-color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -4px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section {
  font-size: 13px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #b8b8b8;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.35);
  height: 50px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -4px;
  width: 100px;
  height: 1.5px;
  background-color: #b8b8b8;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  color: black;
  text-align: center;
  margin-right: 20px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up {
  width: 35px;
  height: 35px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down {
  width: 35px;
  height: 35px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: black;
  margin-bottom: -4px;
  text-align: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer {
  position: absolute;
  top: 0;
  left: 0;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-background-img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-background-img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-background-img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-background-img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-background-img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-background-img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-background-img,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-background-img {
  height: 370px;
  width: 99.5%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container {
  position: absolute;
  top: 25px;
  left: 70px;
  text-transform: uppercase;
  width: 85%;
  display: flex;
  flex-direction: column;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle {
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: black;
  font-size: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-top: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text {
  font-size: 17px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-img,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-img {
  position: absolute;
  top: 95px;
  right: 80px;
  height: auto;
  width: 320px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .buyers-advantage-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .buyers-advantage-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .buyers-advantage-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .buyers-advantage-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .buyers-advantage-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .buyers-advantage-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .buyers-advantage-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .buyers-advantage-text {
  position: absolute;
  top: 255px;
  right: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.05em;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .sellers-advantage-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .sellers-advantage-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .sellers-advantage-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .sellers-advantage-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .sellers-advantage-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .sellers-advantage-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .sellers-advantage-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .sellers-advantage-text {
  position: absolute;
  top: 255px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.05em;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container {
  position: absolute;
  top: 300px;
  right: 0px;
  height: 50px;
  width: 658px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title {
  text-align: left;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics {
  position: absolute;
  top: 38px;
  right: 35px;
  text-align: right;
  z-index: 4;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-chartTip,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-chartTip,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-chartTip,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-chartTip,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-chartTip,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-chartTip,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-chartTip,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-chartTip {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 50;
  max-width: 250px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph {
  height: 200px;
  width: 350px;
  margin-left: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .graph-line,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .graph-line,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .graph-line,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .graph-line,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .graph-line,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .graph-line,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .graph-line,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .graph-line {
  stroke: rgba(235, 242, 242, 0.4);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .x-labels,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .x-labels,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .x-labels,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .x-labels,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .x-labels,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .x-labels,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .x-labels,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .x-labels {
  text-anchor: middle;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .y-labels,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .y-labels,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .y-labels,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .y-labels,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .y-labels,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .y-labels,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .y-labels,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .y-labels {
  text-anchor: end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .quiz-graph-grid,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .quiz-graph-grid,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .quiz-graph-grid,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .quiz-graph-grid,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .quiz-graph-grid,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .quiz-graph-grid,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph .quiz-graph-grid,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph .quiz-graph-grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .label-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .label-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .label-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .label-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .label-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .label-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .label-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .label-title {
  text-anchor: middle;
  text-transform: uppercase;
  font-size: 12px;
  fill: gray;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left.firstBg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left.firstBg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left.firstBg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left.firstBg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left.firstBg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left.firstBg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left.firstBg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left.firstBg {
  fill: rgba(185, 158, 66, 0.8);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left.secondBg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left.secondBg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left.secondBg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left.secondBg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left.secondBg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left.secondBg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left.secondBg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left.secondBg {
  fill: rgba(163, 107, 113, 0.8);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left.thirdBg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left.thirdBg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left.thirdBg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left.thirdBg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left.thirdBg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left.thirdBg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left.thirdBg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left.thirdBg {
  fill: rgba(123, 121, 139, 0.8);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.firstBg,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.firstBg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.firstBg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.firstBg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.firstBg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.firstBg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.firstBg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.firstBg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.firstBg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.firstBg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.firstBg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.firstBg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.firstBg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.firstBg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.firstBg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.firstBg {
  fill: rgba(185, 158, 66, 0.8);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.secondBg,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.secondBg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.secondBg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.secondBg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.secondBg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.secondBg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.secondBg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.secondBg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.secondBg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.secondBg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.secondBg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.secondBg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.secondBg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.secondBg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.secondBg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.secondBg {
  fill: rgba(163, 107, 113, 0.8);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.thirdBg,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.thirdBg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.thirdBg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.thirdBg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.thirdBg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.thirdBg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.thirdBg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.thirdBg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.thirdBg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.thirdBg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.thirdBg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.thirdBg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-dot.thirdBg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.thirdBg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-dot.thirdBg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.thirdBg {
  fill: rgba(123, 121, 139, 0.8);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .svg-cover,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .svg-cover,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .svg-cover,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .svg-cover,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .svg-cover,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .svg-cover,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .svg-cover,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .svg-cover {
  width: 350px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .bottom-x-labels text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .bottom-x-labels text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .bottom-x-labels text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .bottom-x-labels text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .bottom-x-labels text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .bottom-x-labels text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .bottom-x-labels text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .bottom-x-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  letter-spacing: -0.075em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .data-labels,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .data-labels text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .data-labels,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .data-labels text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .data-labels,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .data-labels text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .data-labels,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .data-labels text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .data-labels,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .data-labels text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .data-labels,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .data-labels text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .data-labels,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .data-labels text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .data-labels,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .data-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11.5px;
  width: 30px;
  text-align: center;
  text-anchor: middle;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container {
  position: absolute;
  top: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section {
  display: flex;
  height: 200px;
  width: 99.5%;
  margin-bottom: 40px;
  box-shadow: 0 12px 18px -9px rgb(204.5, 204.5, 204.5);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row {
  display: flex;
  justify-content: space-between;
  height: 200px;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph {
  width: 33.3%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section {
  width: 57%;
  display: flex;
  flex-direction: column;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row {
  font-weight: bold;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  line-height: 15px;
  text-transform: uppercase;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider {
  height: 80px;
  width: 1px;
  align-self: center;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section {
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 238, 230);
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 90%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 46%;
  height: 120px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  margin-top: -15px;
  letter-spacing: 0.05em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 7px;
  text-transform: uppercase;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text {
  margin-top: 10px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  text-transform: uppercase;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 47px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: black;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow {
  height: 30px;
  width: 68px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  margin-top: 20px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-bg,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-bg,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-bg,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-bg,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-bg,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-bg,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-bg,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-bg {
  position: absolute;
  margin: auto;
  left: 5%;
  right: 5%;
  top: 8.2%;
  width: 672px;
  height: 922px;
  z-index: 0;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-header,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 70px;
  margin-top: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-header .db-header-main,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-header .db-header-main,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-header .db-header-main,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-header .db-header-main,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-header .db-header-main,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-header .db-header-main,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-header .db-header-main,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-header .db-header-main {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 27.5px;
  line-height: 26px;
  letter-spacing: 0.35em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body {
  position: absolute;
  top: 140px;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-header,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-header {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px auto 10px auto;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-header .db-body-header-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-header .db-body-header-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-header .db-body-header-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-header .db-body-header-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-header .db-body-header-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-header .db-body-header-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-header .db-body-header-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-header .db-body-header-title {
  color: #838b75;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23px;
  text-align: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-subheader,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-subheader,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-subheader,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-subheader,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-subheader,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-subheader,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-body-subheader,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-body-subheader {
  width: 280px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box {
  right: 0px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider {
  right: 0px;
  width: 616px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header {
  width: 604px;
  flex-direction: row-reverse;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category {
  text-align: left;
  margin-left: 15%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation {
  width: 65%;
  margin-left: 13%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 13%;
  margin-top: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage {
  display: flex;
  justify-content: flex-start;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box {
  left: 0px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider {
  left: 0px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header {
  width: 575px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category {
  text-align: right;
  margin-right: -6.5%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation {
  width: 65%;
  margin-left: 22%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 22%;
  margin-top: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage {
  display: flex;
  justify-content: flex-end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container {
  clear: both;
  display: inline-block;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator {
  height: 203px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 1.3px;
  width: 612px;
  position: absolute;
  top: 48px;
  z-index: 3;
  background-color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
  height: 210px;
  z-index: 1;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 707px;
  height: 91%;
  position: absolute;
  z-index: 2;
  background-color: rgba(251, 251, 246, 0.9);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  height: 40px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num {
  width: 125px;
  text-align: right;
  color: #a36b71;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 37px;
  line-height: 32px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  width: 280px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: justify;
  text-justify: inter-word;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation {
  margin-top: 10px;
  color: #424142;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  line-height: 19px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage {
  color: #8f555c;
  font-weight: 400;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-weight: 400;
  color: black;
  margin-left: 4px;
  display: inline-block;
  float: right;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: -86px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-weight: 700;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.075em;
  color: #8f555c;
  width: 90%;
  margin: auto;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff {
  display: inline-block;
  margin-left: 10px;
  width: auto;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table {
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 30px 0 30px 41px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: #3c4c55;
  margin: -8px 8px 0 8px;
  height: 5px;
  width: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 5px;
  height: 5px;
  width: 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: #3c4c55;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 31px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 28px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 25px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 30px;
  line-height: 26px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: #657460;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider {
  height: 1.333px;
  margin-top: 2px;
  width: 500px;
  background-color: #abbc87;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 319px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 430px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 21px;
  display: flex;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers {
  margin-top: -1px;
  min-height: 500px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 552px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th {
  font-size: 11px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #3c4c55;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p {
  line-height: 13px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 23px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 33px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 18.5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #3c4c55;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 64px;
  text-align: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 59px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 120px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 159px;
  text-align: left;
  font-weight: 400;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  width: 92px;
  font-weight: 400;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 73px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 41px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 100px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 63px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 10px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: #475862;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p {
  font-size: 8px;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend {
  border: 1px solid gray;
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 9px;
  color: black;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2),
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2),
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2),
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2),
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2),
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2),
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2),
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 21px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .table-quadrants-second-left-middle-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .table-quadrants-second-left-middle-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .table-quadrants-second-left-middle-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .table-quadrants-second-left-middle-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .table-quadrants-second-left-middle-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .table-quadrants-second-left-middle-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .table-quadrants-second-left-middle-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .table-quadrants-second-left-middle-section {
  height: 935px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators {
  width: 100%;
  height: 165px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: -7px;
  height: 1.33px;
  width: 500px;
  background-color: #abbc87;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -4px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section {
  font-size: 13px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .inner-indicator-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .inner-indicator-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .inner-indicator-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .inner-indicator-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .inner-indicator-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .inner-indicator-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .inner-indicator-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.2);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.35);
  height: 50px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -4px;
  width: 100px;
  height: 1.5px;
  background-color: #b8b8b8;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 30.5px;
  color: #53626c;
  text-align: center;
  margin-right: 20px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up {
  width: 35px;
  height: 35px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down {
  width: 35px;
  height: 35px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: #53626c;
  margin-bottom: -4px;
  text-align: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-palmtree-img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-palmtree-img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-palmtree-img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-palmtree-img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-palmtree-img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-palmtree-img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-palmtree-img,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-palmtree-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 97%;
  width: 54%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header {
  position: absolute;
  top: 100px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-header-content,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-header-content,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-header-content,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-header-content,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-header-content,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-header-content,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-header-content,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-header-content {
  width: 89%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 37px;
  letter-spacing: 0.16em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-light,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-light,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-light,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-light,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-light,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-light,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-light,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-light {
  color: white;
  width: 53.5%;
  text-align: right;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-dark,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-dark,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-dark,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-dark,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-dark,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-dark,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-dark,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-dark {
  color: #3c4c55;
  width: 44.5%;
  text-align: left;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container {
  width: 61%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header {
  display: flex;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.2em;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  margin-right: 20px;
  color: white;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark {
  color: #3c4c55;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info {
  margin-top: 15px;
  color: rgb(70.2098765432, 76.5925925926, 64.4074074074);
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  width: 315px;
  text-align: justify;
  text-justify: inter-word;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body {
  position: absolute;
  top: 300px;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range {
  height: 186px;
  display: flex;
  position: relative;
  width: 100%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider {
  height: 2.66px;
  min-height: 2.66px;
  width: 570px;
  background-color: #f3d3ae;
  position: absolute;
  top: 80px;
  left: 0px;
  z-index: 1;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 37%;
  z-index: 2;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container {
  width: 82%;
  margin: -7px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 37%;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text {
  margin: -20px 0 10px 0;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 29.5px;
  color: white;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 15px;
  color: white;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-img,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-img,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-img,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-img,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-img,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-img,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-img,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-img {
  display: flex;
  width: 64%;
  height: 105%;
  justify-content: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image {
  display: flex;
  width: 64%;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 35%;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 21px;
  color: #3c4c55;
  margin-top: -25px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes {
  display: flex;
  flex-direction: column;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 5px;
  font-size: 15px;
  color: #3c4c55;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #3c4c55;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  text-align: center;
  color: rgb(28.1479289941, 34.8875739645, 38.8520710059);
  margin-top: 8px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation {
  position: absolute;
  bottom: 30px;
  right: 0;
  height: 170px;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.8);
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-title,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-title,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-title,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-title,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-title,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-title,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-title,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-title {
  display: flex;
  justify-content: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #3c4c55;
  margin: 20px 0 0 0;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section-row,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section-row,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section-row,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section-row,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section-row,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section-row,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section-row,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section-row {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 8px auto 0 auto;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number {
  line-height: 90px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.light, .table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.medium, .table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.dark,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.light,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.medium,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.dark,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.light,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.medium,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.dark,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.light,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.medium,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.dark,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.light,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.medium,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.dark,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.light,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.medium,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.dark,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.light,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.medium,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.dark,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.light,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.medium,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.dark {
  color: #939d87;
}
.table-quadrants-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-data,
.table-quadrants-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-data,
.table-quadrants-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-data,
.table-quadrants-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-data,
.ye-print .table-quadrants-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-data,
.ye-print .table-quadrants-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-data,
.ye-print .ye2-print-page .table-quadrants-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-data,
.ye-print .ye2-print-page .ye2-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-data {
  margin-top: 10px;
  width: 83%;
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
  line-height: 24px;
  color: #3c4c55;
}