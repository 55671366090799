.print-y2-year-in-review-v4 {
  position: absolute;
  bottom: 55px;
  left: 0px;
  width: 100%;
  height: 95.25%;
  // height: 95.25%;
  .y2-year-in-review-bg-v4 {
    position: relative;
    z-index: 1;

    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    background-color: #ded9ce;
  }
  .y2-year-in-review-inner-container-v4 {
    position: absolute;
    top: 20px;
    left: 0px;
    z-index: 2;
    width: 100%;
    height: 94%;
  }

  .y2-year-in-review-header-v2 {
    //margin-top: 60px;
    height: 255px;
    margin-top: 20px;
    .y2-year-in-review-title-container-v2 {
      display: flex;
      flex-direction: column;

      width: 100%;
      // margin: auto;
      margin: 25px auto 0 auto;
      position: relative;

      .y2-year-in-review-title-row-v2 {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 63px;
        // margin-top: 8px;
        .review-title-year-v2 {
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;

          font-size: 66px;
          letter-spacing: 0.05em;
        }

        .review-title-vs-v2 {
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: italic;
          font-size: 46px;
          line-height: 61px;
          letter-spacing: 0.05em;
          padding: 0 20px;
        }
      }

      .y2-year-in-review-subtitle-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        // margin-top: 5px;

        .y2-year-in-review-subtitle-text-v2 {
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 22px;
          color: black;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          line-height: 30px;

          text-align: center;
        }
      }
      .y2-comparing-description-container-v2 {
        //margin-top: 5px;
        height: 154px;
        .y2-comparing-description-title-row {
          .y2-comparing-description-title {
            font-family: playfair-display, serif;
            font-weight: 400;
            font-style: italic;
            font-size: 80px;
            line-height: 108px;
            text-align: center;
            color: #7b97a2;
            .large-c {
              font-size: 96px;
              line-height: 108px;
            }
          }
        }
        .y2-comparing-description-text-col {
          width: 100%;
          margin-top: -15px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .y2-comparing-description-text {
            font-family: playfair-display, serif;
            font-weight: 400;
            font-style: normal;
            font-size: 22px;
            letter-spacing: 0.1em;
            line-height: 30.5px;
          }
        }
      }
    }
  }
  .y2-year-in-review-header-v4 {
    height: 279px;

    .y2-year-in-review-title-container-v4 {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0px auto 0 auto;
      position: relative;

      .y2-year-in-review-title-row-v4 {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 93px;
        margin-top: 23px;
        .review-title-year-v4 {
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;

          font-size: 66px;
          letter-spacing: 0.05em;
        }

        .review-title-vs-v4 {
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: italic;
          font-size: 35px;
          line-height: 50px;
          letter-spacing: 0.075em;
          padding: 0 10px;
        }
      }

      .y2-year-in-review-subtitle-v4 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        // margin-top: 5px;

        .y2-year-in-review-subtitle-text-v4 {
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 19px;
          color: black;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          line-height: 30px;

          text-align: center;
        }
      }
      .y2-comparing-description-container-v4 {
        //margin-top: 5px;
        height: 154px;
        .y2-comparing-description-title-row {
          .y2-comparing-description-title {
            font-family: playfair-display, serif;
            font-weight: 400;
            font-style: italic;
            font-size: 80px;
            line-height: 108px;
            text-align: center;
            color: #87a7a9;
            .large-c {
              font-size: 96px;
              line-height: 108px;
            }
          }
        }
        .y2-comparing-description-text-col {
          width: 100%;
          margin-top: 0px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .y2-comparing-description-text {
            font-family: playfair-display, serif;
            font-weight: 400;
            font-style: normal;
            font-size: 18.5px;
            letter-spacing: 0.1em;
            line-height: 26.5px;
          }
        }
      }
    }
  }

  .y2-year-in-review-area-names-v4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .y2-year-in-review-names-row-v4 {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    &.special-review-mt-negative {
      margin-top: -10px;

      height: 69px;
      .special-section-1 {
        display: flex;
        flex-direction: column;
        .y2-year-in-review-special-city-name {
          height: 60px;
        }
      }
    }

    .y2-review-names-section {
      width: 100%;
      margin-top: -5px;
      margin-bottom: 5px;
    }
    .y2-year-in-review-city-name {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 60px;
      font-family: playfair-display, serif;
      font-weight: 400;
      font-style: normal;
      font-size: 25px;
      letter-spacing: 0.01em;
      line-height: 26px;
      //text-transform: capitalize;
      p {
        text-align: center;
      }
    }
    .y2-year-in-review-special-city-name {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: flex-end;

      height: 60px;
      font-family: playfair-display, serif;
      font-weight: 400;
      font-style: italic;
      font-size: 25px;
      letter-spacing: 0.01em;
      line-height: 37px;
      text-transform: capitalize;
      p {
        text-align: center;
      }
    }
    .y2-year-in-review-area-name-subtitle-v4 {
      color: black;
      font-family: playfair-display, serif;
      font-weight: 400;
      font-style: normal;
      font-size: 9px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
    }
  }
  .ye2-year-in-review-body-v4 {
    display: flex;
    height: 71.25%;
    justify-content: center;

    .ye2-year-in-review-col-v4:nth-child(1) {
      margin-right: 15px;
    }

    .ye2-year-in-review-col-v4:nth-child(2) {
      margin-left: 15px;
    }

    .ye2-year-in-review-col-v4 {
      width: 368px;
      height: 100%;
      background-color: white;
      //background-color: rgba(255, 255, 255, 0.6);
      position: relative;
      .sideways-past-6-months-dashboard,
      .sideways-previous-6-months-dashboard {
        position: absolute;
        height: 100%;

        writing-mode: vertical-lr;
        text-orientation: mixed;
        transform: rotate(0deg);
        font-family: playfair-display, serif;
        font-weight: 400;
        font-style: normal;
        font-size: 38px;
        color: #87a7a9;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sideways-previous-6-months-dashboard {
        transform: rotate(180deg);
        left: 0px;
      }
      .sideways-past-6-months-dashboard {
        right: 0px;
        transform: rotate(360deg);
        width: 45px;
      }

      .y2-year-in-review-area-names-column-v4 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 82px;
        margin-top: 10px;

        .y2-year-in-review-area-title-v4 {
          color: #3d4e57;
          font-family: playfair-display, serif;
          font-weight: 700;
          font-style: normal;
          letter-spacing: 0.2em;
          font-size: 23px;
        }

        .y2-year-in-review-area-name-v4 {
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 14px;
          text-transform: uppercase;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 5px;
          p {
            text-align: center;
          }
        }
        .y2-year-in-review-area-name-subtitle-v4 {
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 12px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 5px;
        }
      }

      .ye2-review-box-center-divider-v4 {
        height: 720px;
        width: 4px;
        background-color: #f3d3ae;
        position: absolute;
        top: 170px;
        left: 50%;
        z-index: 0;
      }

      .ye2-combined-cities-avg-v4 {
        position: absolute;
        top: 95px;
        left: 0px;
        width: 100%;
        text-align: center;
        color: black;
        font-family: playfair-display, serif;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
      }

      .ye2-review-box-v4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5px;

        .ye2-review-box-top-v4 {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: center;
          width: 65%;
          position: relative;
          z-index: 1;
          height: 30px;
        }

        .ye2-review-box-perc-v4 {
          font-size: 21px;
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          margin-left: 10px;
          line-height: 39px;
          margin-top: -26.2px;
          letter-spacing: 0.05em;
          .small-perc-in-text {
            font-size: 16px;
          }
          &.no-change-v4 {
            line-height: 23px;
            font-size: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 13px;
            p {
              line-height: 38px;
            }
            // margin-top: -31px;
          }
        }

        .ye2-review-box-category-v4 {
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 19px;
          color: black;
          letter-spacing: 0.01em;
        }

        .ye2-review-box-comparison-v4 {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 112px;

          .ye2-review-box-year-v4 {
            font-family: playfair-display, serif;
            font-weight: 400;
            font-style: normal;
            color: darken(#c09a84, 25%);
            font-variant: small-caps;
            font-size: 22px;
            letter-spacing: 0.05em;

            &.year-2020-v4 {
              transform: rotate(-90deg) translateY(0%) translateX(-12%);
              margin: 0;
              width: 35px;
              height: 11px;
              padding: 0;
            }

            &.year-2021-v4 {
              writing-mode: vertical-rl;
              text-orientation: mixed;
            }
          }

          // .year-color-1 {
          //   color: #c09a84;
          // }
          // .year-color-2 {
          //   color: #c9a794;
          // }
          //
          // .year-color-3 {
          //   color: #d8c2b4;
          // }
          .ye2-inner-data-box-v4 {
            background-color: #f2ede7;
            width: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 115px;

            .ye2-review-box-data-v4 {
              display: flex;
              justify-content: center;
              width: 48%;
              font-size: 23px;
              letter-spacing: 0.025em;
              color: black;
              font-family: objektiv-mk2, sans-serif;
              font-weight: 300;
              font-style: normal;
            }
            .ye2-box-divider-v4 {
              height: 95px;
              width: 1px;
              background-color: #a59d95;
              margin-top: -25px;
            }
          }
        }

        .ye2-review-box-sellers-adv-v4 {
          height: 25px;
          text-transform: uppercase;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 200;
          font-style: normal;
          font-size: 11px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: black;
        }
      }
      .year-in-review-disclaimer {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        p {
          font-size: 11.5px;
        }
      }
      .units-sold-container-v4 {
        width: 90%;
        display: flex;
        margin: 0px auto 0 auto;
        height: 100px;
        padding-top: 1px;

        .units-sold-inner-container {
          width: 100%;
          display: flex;
          flex-direction: row;

          justify-content: center;
          align-items: center;
          .units-sold-column {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
        }
        .units-sold-second-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        .units-sold-title-v4 {
          display: flex;
          justify-content: center;
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 15px;
          margin-right: 10px;
          letter-spacing: 0.05em;
        }
        .units-sold-vs {
          margin: 0 10px;
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 15px;

          letter-spacing: 0.05em;
        }
        .units-sold-data-v4 {
          color: black;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 15px;
          letter-spacing: 0.06em;
          margin-top: -2px;
          strong {
            font-size: 15px;
          }
          &.data-percentage-v4 {
            font-weight: 600;
            font-size: 15px;
            //width: 38%;
            display: flex;
            justify-content: flex-end;
          }
        }
        .no-change-data-perc-v4 {
          display: flex;
          flex-direction: column;
          text-align: center;
          font-weight: 400;
          font-size: 11px;
          margin: 8px -10px 0 10px;

          div {
            line-height: 16px;
            font-size: 12px;
          }
        }

        .units-sold-divider-v4 {
          height: 11px;
          width: 1px;
          background-color: #1f3039;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 15px;
          margin: 7px 12px 0px 12px;
        }

        .units-sold-not-divider-v4 {
          height: 11px;
          width: 1px;
          background-color: #1f3039;
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 15px;
          margin: 7px 12px 0px 12px;
          // margin: 0 12px 0px 12px;
        }
      }
    }
  }
}
