.print-qr-code-section-v2 {
  position: absolute;
  top: 309px;
  left: 50px;
  height: 80px;
  width: 94%;

  display: flex;
  flex-direction: flex-start;
  margin: 5px auto 0 auto;
  &.smaller-qr-section-v2 {
    top: 254px;
    .qr-code-col1 {
      width: 14%;
    }
  }
  &.lower-qr-code {
    top: 253px;
  }
  &.lower-qr-code-2 {
    top: 347px;
  }
  &.qr-code-top-345 {
    top: 345px;
  }
  &.qr-code-top-300 {
    top: 300px;
  }
  .qr-code-col1 {
    width: 16%;
    // margin-top: 10px;
    .bubble-arrow-container {
      width: 100%;
      height: 100%;
      margin: auto;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      img {
        height: 55px;
        // height: 45px;
        width: auto;
        margin-top: 14px;
      }
      &.larger-qr-arrow-image {
        img {
          margin-top: 21px;
        }
      }
      &.smaller-qr-arrow-img {
        img {
          height: 45px;
        }
      }
    }
  }
  .qr-code-col2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    // margin-top: 10px;
    .qr-code-title {
      height: 16%;
      display: flex;
      align-items: center;
      p {
        font-family: "objektiv-mk2", sans-serif;
        font-weight: 500;
        font-style: italic;
        font-size: 19px;
      }
      .qr-code-space {
        margin-right: 9px;
        font-style: normal;
        font-weight: 600;
        color: rgb(0, 79, 172);
      }
    }
    .qr-code-content-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 80%;
      .bubble-qr-container {
        width: 16%;
        img {
          margin-top: -8px;
          height: 95px;
          width: 95px;
          // height: 75px;
          // width: 75px;
        }
        &.larger-qr-img {
          img {
            height: 90px;
            width: 90px;
            margin-bottom: -11px;
          }
        }
        &.smaller-qr-img {
          img {
            height: 75px;
            width: 75px;
          }
        }
      }

      .qr-code-text {
        width: 79%;
        display: flex;
        align-items: center;
        margin-left: 10px;
        p {
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 400;
          font-style: italic;
          font-size: 16px;
          line-height: 25.5px;
          letter-spacing: 0.025em;
        }
        .smaller-qr-code-text {
          font-size: 15px;

          letter-spacing: 0.025em;
          line-height: 20px;
          margin-top: -8px;
        }
      }
      .qr-code-link {
        font-family: "objektiv-mk2", sans-serif;
        font-weight: 600;
        font-style: italic;
        font-size: 17px;
        letter-spacing: 0.05em;

        line-height: 25px;
        // color: #8a9985;
        color: rgb(0, 79, 172);
        margin-left: 5px;
      }
    }
  }
}
