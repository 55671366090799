.print-ye2-market-indicators {
  width: 100%;
  height: 165px;
  .market-indicators-footer {
    width: 80%;
    margin-left: 43px;
    display: flex;
    flex-direction: column;
    .market-indicators-footer-row {
      display: flex;

      p:nth-child(2) {
        width: 90px;
        text-align: right;
      }
      .market-indicators-footer-compare {
        width: 105px;
        display: flex;
        &.six-month-indicator {
          width: 112px;
        }
        &.twelve-month-indicator {
          width: 121px;
        }
      }
      p {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 12px;
        line-height: 13px;
      }
    }
  }
  .mini-market-title {
    display: flex;
    justify-content: space-between;
    .mini-market-title-divider {
      margin: 0 10px;
    }
    .left-market-title-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      .left-market-title-section {
        margin-left: 35px;
        display: flex;
        padding-left: 5px;

        color: black;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 21px;
        text-transform: uppercase;
        text-align: right;
        line-height: 21px;
      }
    }
    .right-market-title-section {
      height: 33px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 20px;
      color: black;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 15px;
      text-transform: uppercase;
      margin-top: -4px;

      &.smaller-market-title-section {
        font-size: 13px;
      }
      .lc-market-title {
        text-transform: lowercase;
        font-variant: small-caps;
        margin: 0 5px;
      }
    }
    .same-6-month-last-year {
      height: 33px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 33px;

      margin-top: -6px;
      p {
        color: black;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        line-height: 15px;
        text-transform: uppercase;
      }
      &.smaller-market-title-section {
        font-size: 13px;
      }
      .lc-market-title {
        text-transform: lowercase;
        font-variant: small-caps;
        margin: 2px 5px;
      }
    }
  }
  .mini-market-title-divider {
    margin-left: 0px;
    margin-top: -10px;
    margin-bottom: 13px;
    height: 1px;
    width: 818px;
    // background-color: rgba(246, 204, 108, 0.8);
    background-color: #7c7a8c;
  }
  .indicator-container {
    width: 100%;
    height: 120px;
    background-color: white;
    display: flex;
    justify-content: center;
    .inner-indicator-container {
      width: 93%;
      display: flex;
      justify-content: space-between;
    }
    .indicator-section {
      width: 23.5%;
      height: 126px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      //background-color: rgba(192, 191, 191, 0.2);
      border: 1px solid #bbb2a7;
      .indicator-title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #e0d6cf;
        height: 50px;
        .indicator-category {
          text-transform: uppercase;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 13px;
          letter-spacing: 0.025em;
          line-height: 17px;
          color: black;
        }
        .indicator-value {
          text-transform: uppercase;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 13.5px;
          letter-spacing: 0.025em;
          line-height: 17px;
          color: black;
        }
        .indicator-title-bottom-border {
          margin-bottom: -6px;
          margin-top: 9px;
          width: 100px;
          height: 1px;
          background-color: #ad9787;
        }
      }
      .indicator-col-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        height: 56px;
      }
      .indicator-perc {
        margin-bottom: -4px;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 26px;
        color: black;
        text-align: center;
        margin-right: 20px;
      }
      .mq3-indicator-arrow-up {
        width: 35px;
        height: 35px;
      }
      .mq3-indicator-arrow-down {
        width: 35px;
        height: 35px;
      }
      .indicator-balanced {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 17px;
        color: black;
        margin-bottom: -4px;
        text-align: center;
      }
    }
  }
}
