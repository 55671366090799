/* Variables

 */
* {
  -webkit-font-smoothing: auto;
}

.qv3-greetings-ps {
  width: 70%;
  margin: auto;
}
.qv3-greetings-ps .greetings-ps-title-container {
  width: auto;
}
.qv3-greetings-ps .greetings-ps-title-container .greetings-ps-title {
  color: #657460;
  margin-left: 48px;
  width: auto;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 0.15em;
}
.qv3-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text {
  font-size: 22px;
}
.qv3-greetings-ps .greetings-ps-title-container .greetings-ps-divider {
  margin-top: -3px;
  height: 2.666px;
  width: 580px;
  background-color: rgba(246, 204, 108, 0.8);
  z-index: 1;
}
.qv3-greetings-ps .greetings-ps-box {
  width: 90%;
  height: 170px;
  margin: 35px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.qv3-greetings-ps .greetings-ps-box .greetings-ps-text {
  margin: 0 20px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12.5px;
  line-height: 18px;
  text-align: justify;
  text-justify: inter-word;
}
.qv3-greetings-ps .greetings-ps-box .qv3-ps-line {
  margin: 20px 25px 0 25px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 6px;
  text-align: justify;
  text-justify: inter-word;
}
.qv3-greetings-ps .greetings-ps-box .agent-signoff {
  display: flex;
  justify-content: flex-end;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 21px;
  margin-right: 86px;
}
.qv3-greetings-ps .greetings-ps-box .agent-name {
  margin-left: 5px;
}

.left-justified-ps .inner-ps-text-container .homeowners-salutation .ps-text {
  margin: 0 25px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: justify;
  text-justify: inter-word;
}
.left-justified-ps .inner-ps-text-container .homeowners-salutation .agent-signoff .signoff {
  display: flex;
  justify-content: flex-end;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
  margin-right: 86px;
}
.left-justified-ps .inner-ps-text-container .homeowners-salutation .agent-signoff .agent-name {
  margin-left: 5px;
}

.qv3-pma-editor-page-container {
  width: 1365px;
  height: 1070px;
  margin: 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.qv3-pma-editor-page-container .qv3-first-left,
.qv3-pma-editor-page-container .qv3-first-right,
.qv3-pma-editor-page-container .qv3-second-left,
.qv3-pma-editor-page-container .qv3-second-right {
  width: 50%;
  position: relative;
}
.qv3-pma-editor-page-container .ye-two-property-highlight {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.qv3-pma-editor-page-container .ye-two-property-highlight .ye-two-property-highlight-background {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 700px;
  width: 90%;
  background-color: black;
  z-index: 0;
}
.qv3-pma-editor-page-container .ye-two-property-highlight .ye-two-property-highlight-row-1 {
  position: absolute;
  top: 160px;
  width: 100%;
  margin: auto;
}
.qv3-pma-editor-page-container .ye-two-property-highlight .ye-two-property-highlight-row-1 img {
  height: 180px;
  width: 200px;
}
.qv3-pma-editor-page-container .ye-two-property-highlight .ye-two-property-highlight-row-2 {
  position: absolute;
  top: 360px;
  width: 100%;
  margin: auto;
}
.qv3-pma-editor-page-container .ye-two-property-highlight .ye-two-property-highlight-row-2 img {
  height: 190px;
  width: 310px;
}
.qv3-pma-editor-page-container .ye-two-property-highlight .ye-two-property-highlight-inner-photo-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin: auto;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-corner-highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 303px;
  background-color: #761037;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-mail-info-container-print {
  position: absolute;
  top: 118px;
  left: 506px;
  height: auto;
  width: 370px;
}
.qv3-pma-editor-page-container .qv3-first-left .disclaimer-container,
.qv3-pma-editor-page-container .qv3-first-left .custom-disclaimer-container {
  height: 34px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  top: 196px;
  right: 70px;
  width: 413px;
}
.qv3-pma-editor-page-container .qv3-first-left .disclaimer-container.team-solicitation,
.qv3-pma-editor-page-container .qv3-first-left .custom-disclaimer-container.team-solicitation {
  top: 206px;
}
.qv3-pma-editor-page-container .qv3-first-left .disclaimer-container .qv3-solicitation-disclaimer,
.qv3-pma-editor-page-container .qv3-first-left .disclaimer-container .qv3-reliable-text,
.qv3-pma-editor-page-container .qv3-first-left .disclaimer-container .qv3-cover-photo-credit,
.qv3-pma-editor-page-container .qv3-first-left .custom-disclaimer-container .qv3-solicitation-disclaimer,
.qv3-pma-editor-page-container .qv3-first-left .custom-disclaimer-container .qv3-reliable-text,
.qv3-pma-editor-page-container .qv3-first-left .custom-disclaimer-container .qv3-cover-photo-credit {
  width: 410px;
  height: 12px;
  text-align: center;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 6.6px;
  text-transform: uppercase;
  letter-spacing: -0.025em;
}
.qv3-pma-editor-page-container .qv3-first-left .custom-disclaimer-container {
  top: 176px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-outer-container {
  position: absolute;
  top: 390px;
  left: 50px;
  width: 600px;
  height: 360px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-outer-container .qv3-top-categories-header {
  height: 80px;
  width: 510px;
  display: flex;
  flex-direction: column;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-outer-container .qv3-top-categories-header .qv3-top-categories-header-divider {
  height: 2.666px;
  width: 576px;
  margin-left: -50px;
  background-color: #fac47e;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-outer-container .qv3-top-categories-header .qv3-top-categories-header-title-container {
  display: flex;
  align-items: center;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-outer-container .qv3-top-categories-header .qv3-top-categories-header-title-container .qv3-top-categories-header-title-text {
  color: #475862;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 0.15em;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-outer-container .qv3-top-categories-header .qv3-top-categories-header-title-container .qv3-top-categories-description {
  color: #475862;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: -2px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-outer-container .qv3-top-categories-header .qv3-top-categories-header-subtitle {
  margin-top: 7px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 21px;
  text-transform: uppercase;
  align-self: flex-end;
  text-align: right;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-outer-container .qv3-top-categories-header .qv3-top-categories-header-subtitle.medium-subtitle {
  font-size: 17px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-outer-container .qv3-top-categories-header .qv3-top-categories-header-subtitle.small-subtitle {
  font-size: 15px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category {
  width: 180px;
  height: 205px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .regular-spaced-styles {
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .regular-spaced-styles.first-line {
  line-height: 27px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .regular-spaced-styles.lowercase {
  text-transform: lowercase;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .regular-spaced-styles.second-reg-line {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .regular-spaced-styles.second-reg-line.v-centered {
  height: 37px;
  align-items: center;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .bottom-description {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .higher-spaced-styles {
  height: 40px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .higher-spaced-styles.second-reg-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 47px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .higher-spaced-styles.second-reg-line.v-centered {
  justify-content: center;
  align-items: center;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .higher-spaced-styles.second-reg-line.neg-mt-3 {
  margin-top: 2px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .higher-spaced-styles.second-reg-line p {
  line-height: 15px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-title {
  color: #475862;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-img-body {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 170px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-img-body .top-category-divider {
  background-color: #e9c359;
  height: 2px;
  width: 187px;
  margin-bottom: 50px;
  z-index: 2;
  position: absolute;
  top: 37px;
  left: 0px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .category-shadow-box-img {
  position: absolute;
  z-index: 1;
  width: 221px;
  height: 180px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body {
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data,
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data-sale-range-one {
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data.regular-spaced-styles,
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data .regular-spaced-styles,
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data-sale-range-one.regular-spaced-styles,
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data-sale-range-one .regular-spaced-styles {
  font-size: 23px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data.higher-spaced-styles,
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data .higher-spaced-styles,
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data-sale-range-one.higher-spaced-styles,
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data-sale-range-one .higher-spaced-styles {
  font-size: 23px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data-sale-range-two {
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-data-sale-range-two .higher-spaced-styles {
  height: 27px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-description {
  color: #475862;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-top-categories-inner-container .top-category .top-category-body .top-category-description .highest-price-address {
  text-transform: capitalize;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-backpage-bg {
  background-color: #d1cec8;
  height: 673px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-backpage-bg img {
  width: 100%;
  height: 100%;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-testimonial2-container {
  position: absolute;
  bottom: 20px;
  right: 30px;
  height: 220px;
  width: 400px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial-title {
  width: 100%;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 17px;
  color: #3c4c55;
  margin-left: -5px;
  letter-spacing: 0.025em;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial-divider {
  background-color: #f3d3ae;
  height: 2.66px;
  width: 576px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial {
  margin-top: 10px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial .testimonial-container {
  font-family: mencken-std-head, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.075em;
  color: #6f7270;
  text-align: center;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial .testimonial-container .testimonial-signoff {
  text-align: right;
  margin-right: 30px;
}
.qv3-pma-editor-page-container .qv3-first-left .qv3-testimonial2-container .qv3-testimonial .testimonial-container .testimonial-content-row {
  display: flex;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container {
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #fff;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-bg-photo {
  height: 100%;
  width: 100%;
  background-size: cover;
  position: absolute;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-color-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-edition {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  color: white;
  text-transform: uppercase;
  align-self: flex-end;
  position: absolute;
  top: 37px;
  right: 76px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-edition .cover-page-small-caps {
  text-transform: lowercase;
  font-variant: small-caps;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-title-container {
  width: 95%;
  max-width: 92%;
  position: absolute;
  top: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 280px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-city-area {
  width: auto;
  max-width: 90%;
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  flex-wrap: wrap;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .qv3-subdivision-container .subdivision-subtitle-container {
  display: flex;
  font-size: 22px;
  justify-content: flex-end;
  align-self: flex-end;
  text-align: right;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .qv3-subdivision-container .subdivision-subtitle-container .including-subtitle {
  font-style: italic;
  margin-right: 7px;
  text-transform: none;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .one-cover-page-area {
  font-size: 54px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .one-line-cover-page {
  font-size: 48px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .two-cover-page-area {
  font-size: 53px;
  line-height: 60px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .three-cover-page-area {
  font-size: 39px;
  line-height: 60px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .medium-cover-page-area-text {
  font-size: 47px;
  line-height: 60px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container {
  color: white;
  width: 500px;
  position: absolute;
  top: 430px;
  left: 42px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body {
  height: 452px;
  width: 413px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body .cover-page-body-sideways-text {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: -0.005em;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body .cover-page-body-sideways-text.small-cp-comparison {
  font-size: 14px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body .cover-page-body-sideways-text .sideways-lowercase {
  text-transform: lowercase;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 325px;
  margin-top: 5px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section {
  margin-left: 20px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-around;
  align-items: space-around;
  flex-direction: column;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section .data-section-top-row {
  display: flex;
  align-items: flex-end;
  height: 29px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section .data-section-top-row .data-section-data-point {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  line-height: 17px;
  margin-right: 5px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section .data-section-top-row .data-section-data-point .small-dollar-sign {
  font-size: 26px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section .data-section-top-row .data-section-top-row-triangle {
  width: 0;
  height: 0;
  margin: 0 5px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section .data-section-top-row .data-section-top-row-triangle.up {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid white;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section .data-section-top-row .data-section-top-row-triangle.down {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section .data-section-top-row .data-section-top-row-balanced {
  color: white;
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  margin: 20px 5px -2px 10px;
  line-height: 16px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section .data-section-top-row .data-section-top-row-perc-diff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  margin-bottom: -5px;
  margin-left: 5px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-container .cover-page-text-container .cover-page-body-data-container .cover-page-body-data-section .data-section-bottom-row-category {
  margin-bottom: 11px;
  display: flex;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info {
  position: absolute;
  bottom: 12px;
  right: 0;
  height: 190px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .transparent-inner-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 600px;
  height: 80%;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .cover-photo-courtesy {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
  color: white;
  padding-top: 10px;
  padding-left: 6px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .agent-info-presented-by {
  width: 595px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: white;
  margin-bottom: 10px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 auto;
  width: 600px;
  height: 110px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col {
  width: 74.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-separator {
  height: 1px;
  background-color: white;
  width: 200px;
  margin: auto;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-container {
  display: flex;
  justify-content: flex-start;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-container .team-agent-col {
  width: auto;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-container .team-agent-col .team-sub-info {
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  height: 31px;
  justify-content: center;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-and-phone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 31px;
  color: white;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-and-phone .phone-circle-divider {
  background-color: white;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin: 0px 3px 0px 3px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-and-phone .team-agent-name {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-and-phone .team-agent-phone {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-and-phone .smaller-name-and-phone {
  font-size: 18px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-agent-and-phone .team-vertical-divider {
  background-color: white;
  margin: 0 10px;
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-designation-and-dre {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-designation-and-dre .designation-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  text-transform: uppercase;
  margin-right: 15px;
  letter-spacing: 0.075em;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-designation-and-dre .dre-row {
  display: flex;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-designation-and-dre .dre-row .dre-word {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 0.075em;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-designation-and-dre .dre-row .dre-num {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 0.075em;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-col .team-email {
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  color: white;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-box-logo {
  height: 100%;
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-box-logo.longer-agent-info-box-logo {
  width: 45%;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-box-logo img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 80%;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .team-info-box-logo .info-box-coldwell-banker-logo-large {
  max-height: 100%;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .phone-num-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-team-cover-page-info .phone-circle-divider {
  background-color: white;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin: 0px 3px 0px 3px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info {
  position: absolute;
  bottom: 0px;
  right: 0;
  height: 165px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-transparent-inner-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 600px;
  height: 80%;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .cover-photo-courtesy {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
  color: white;
  padding-left: 8px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .phone-num-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .phone-circle-divider {
  background-color: white;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin: 0px 3px 0px 3px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-presented-by {
  width: 590px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: white;
  margin-bottom: 10px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 600px;
  height: 80px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col {
  width: 74%;
  height: 100%;
  margin-bottom: -5px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col div {
  display: flex;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .agent-and-phone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .agent-and-phone .agent-name,
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .agent-and-phone .agent-phone,
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .agent-and-phone .phone-num-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  color: white;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .agent-and-phone .agent-name.smaller-name-and-phone,
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .agent-and-phone .agent-phone.smaller-name-and-phone,
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .agent-and-phone .phone-num-row.smaller-name-and-phone {
  font-size: 20px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .agent-and-phone .vertical-divider {
  padding: 0 10px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .designation-and-dre {
  color: white;
  display: flex;
  align-items: flex-end;
  margin-top: 9px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .designation-and-dre .agent-designation {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  margin-right: 15px;
  letter-spacing: 0.075em;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .designation-and-dre .dre-word,
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-section .agent-info-col .designation-and-dre .dre-num {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  margin-bottom: 1.5px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-box-logo {
  height: 100%;
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-box-logo.longer-agent-info-box-logo {
  width: 45%;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-box-logo img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 80%;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-box-logo .info-box-coldwell-banker-logo-large {
  max-height: 100%;
}
.qv3-pma-editor-page-container .qv3-first-right .qv3-cover-page-info .agent-info-box-logo .info-box-coldwell-banker-logo {
  max-height: 117%;
}