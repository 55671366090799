@font-face {
  font-family: "FormationSansRegular";
  src: url("FormationSansRegular2.ttf");
}

.year-end-statement {
  border: 1px solid gray;
  background-color: #f9f7ec;
  width: 1080px;
  margin: auto;
  .yes-header {
    //  border: 1px solid green;
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
    background-color: #4a7396;
    .yes-header-inner {
      height: 70%;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .yes-header-left {
        width: 70%;
        div {
          font-family: FormationSansRegular;
          font-size: 48px;
          letter-spacing: 0.05em;
          color: white;
        }
      }
      .yes-header-right {
        width: 19%;
        display: flex;
        justify-content: space-between;
        div {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 300;
          font-style: normal;
          font-size: 15px;
          line-height: 15px;
          text-transform: uppercase;
          color: white;
          &:hover {
            cursor: pointer;
            font-size: 17px;
          }
        }
      }
    }
  }
  .yes-body {
    .agent-information {
      width: 90%;
      margin: 30px auto 0 auto;
      .agent-information-top {
        display: flex;
        justify-content: space-between;
        .agent-name {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 17px;
          color: #00305e;
        }
        .account-number {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
          color: #00305e;
        }
      }
      .agent-information-divider {
        height: 1px;
        width: 100%;
        background-color: darkgray;
        margin: 5px 0;
      }
      .agent-phone-number,
      .agent-email {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        color: #323232;
      }
    }
    .ytd-title {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 25px;
      color: #c0bfbf;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    .ytd-dates {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      .ytd-date-range {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 15px;
        color: #00305e;
      }
      .ytd-divider-container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        .ytd-divider {
          height: 1px;
          width: 61%;
          background-color: #4a7396;
          margin: 5px 0;
        }
      }
    }
    .ytd-total {
      display: flex;
      justify-content: space-between;
      width: 280px;
      margin: 30px auto 0 auto;
      div {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 19px;
        color: #4a7396;
        text-transform: uppercase;
      }
    }
    .invoice-table {
      margin: 20px auto 0 auto;
      //  border: 1px solid green;
      width: 100%;
      .invoice-table-header {
        display: flex;
        border-bottom: 1px solid #4a7396;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 11px;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          line-height: 13px;
          color: #00305e;
        }
        div:nth-child(1) {
          width: 100px;
          // border: 1px solid purple;
        }
        div:nth-child(2) {
          width: 100px;
          // border: 1px solid purple;
        }
        div:nth-child(3) {
          width: 150px;
          // border: 1px solid purple;
        }
        div:nth-child(4) {
          width: 150px;
          // border: 1px solid purple;
        }
        div:nth-child(5) {
          width: 150px;
          //border: 1px solid purple;
        }
        div:nth-child(6) {
          width: 200px;
          // border: 1px solid purple;
        }
        div:nth-child(7) {
          width: 210px;
          > div {
            width: 210px;
          }
          width: 210px;
          // border: 1px solid purple;
        }
      }

      .invoice-table-body {
        display: flex;
        flex-direction: column;
        margin: 20px auto 0 auto;
        .invoice-table-row {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #4a7396;
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 11px;
          }
          .view-invoice {
            width: 100px;
            //  border: 1px solid purple;
            > div {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: italic;
              font-size: 12px;
              line-height: 16px;
              text-decoration: underline;
              color: #4e85af;
              &:hover {
                cursor: pointer;
              }
            }
          }

          div:nth-child(2) {
            width: 100px;
            //border: 1px solid purple;
          }
          div:nth-child(3) {
            width: 150px;
            //  border: 1px solid purple;
          }
          div:nth-child(4) {
            width: 150px;
            //  border: 1px solid purple;
          }
          div:nth-child(5) {
            width: 150px;
            //border: 1px solid purple;
          }
          .areas-col {
            width: 200px;
            //  border: 1px solid purple;
            font-family: objektiv-mk2, sans-serif;
            font-weight: 300;
            font-style: normal;
            display: flex;
            flex-direction: column;
            .mapped-area-box {
              display: flex;
              flex-direction: column;
              border: 1px solid darkgray;
              width: 100%;
              align-items: center;
              div {
                line-height: 14.4px;
                color: #414042;
                //border: 1px solid green;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
              }
            }
            // > div {
            // line-height: 14.4px;
            // color: #414042;
            // //border: 1px solid green;
            // display: flex;
            // flex-direction: row;
            // width: 100%;
            // justify-content: center;
            // }
          }

          div:nth-child(7) {
            width: 210px;
            //  border: 1px solid purple;
          }
        }
      }
      .total-pmas {
        margin-top: 7px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .total-pmas-text {
          width: 90px;
          display: flex;
          justify-content: center;

          margin-right: 75px;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 13px;
          color: #414042;
        }
      }
      .yes-summary {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 60px;
        > div {
          width: 60%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 40px;
          div:nth-child(1) {
            margin-left: 300px;
            width: 200px;
          }
        }
        .credits {
          background-color: #dcefe7;
        }
        .credits,
        .charges {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 15px;
          color: #00305e;
        }
        .year-to-date-total {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 19px;
          color: #4a7396;
        }
      }
    }
  }
}
