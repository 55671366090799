/* Variables

 */
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container {
  position: relative;
  width: 1698px;
  height: 1140px;
  margin: 0;
  background: white;
  background-size: contain;
  display: flex;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-right,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-right {
  width: 50%;
  position: relative;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .qv4-first-left {
  width: 50%;
  position: relative;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles {
  position: absolute;
  bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubble-footnote {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 7px;
  color: #657460;
  position: absolute;
  bottom: -10px;
  right: 24px;
  width: 400px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubble-img {
  height: 230px;
  width: 230px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubble-img-container {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-title-container {
  margin-left: 100px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-title-container .fact-bubbles-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: white;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-subtitle-container {
  margin-left: 50px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-subtitle-container .fact-bubbles-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  color: white;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubble-dark-highlight {
  background-color: #e5e9d2;
  width: 50px;
  height: 360px;
  position: absolute;
  left: 0px;
  top: 150px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubble-light-highlight {
  background-color: #bfc9b3;
  width: 50px;
  height: 330px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubble-top-line {
  background-color: #abbc87;
  height: 1.75px;
  width: 610px;
  position: absolute;
  top: 126px;
  left: 0px;
  z-index: 3;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubble-bottom-line {
  background-color: #abbc87;
  height: 1.75px;
  width: 636px;
  position: absolute;
  top: 366px;
  right: 0px;
  z-index: 3;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body {
  height: 480px;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .bubble-content {
  position: relative;
  width: 260px;
  height: 192px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  margin-top: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .dark-bubble {
  position: relative;
  width: 45%;
  height: 200px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15.5px;
  text-transform: uppercase;
  color: #475862;
  height: 60px;
  text-transform: uppercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 27px;
  letter-spacing: -0.025em;
  color: #3c4c55;
  height: 65px;
  display: flex;
  align-items: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-subdata-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
  text-transform: capitalize;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-subdata-container .dark-bubble-subdata {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-subdata-container .dark-bubble-subdata.medium-address {
  font-size: 9px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-subdata-container .dark-bubble-subdata .lowercase {
  text-transform: lowercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .light-bubble {
  position: relative;
  width: 45%;
  height: 200px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15.5px;
  text-transform: uppercase;
  color: white;
  height: 60px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 30px;
  letter-spacing: -0.025em;
  color: white;
  height: 65px;
  display: flex;
  align-items: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-data.smaller-data {
  font-size: 30px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-subdata-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-transform: capitalize;
  height: 70px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-subdata-container .light-bubble-subdata {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: white;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-subdata-container .light-bubble-subdata.lowercase {
  text-transform: lowercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-subdata-container .light-bubble-subdata.medium-address {
  font-size: 11px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .bubble-compare-testimonial {
  position: absolute;
  top: 30px;
  left: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .bubble-compare-testimonial .bubble-compare-testimonial-title-container {
  margin-left: 100px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .bubble-compare-testimonial .bubble-compare-testimonial-title-container .bubble-compare-testimonial-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: white;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .bubble-compare-testimonial .bubble-compare-testimonial-subtitle-container {
  margin-left: 50px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .bubble-compare-testimonial .bubble-compare-testimonial-subtitle-container .bubble-compare-testimonial-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  color: white;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .bubble-compare-testimonial .bubble-compare-testimonial-text-container {
  width: 90%;
  margin-top: 7px;
  display: flex;
  justify-content: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .bubble-compare-testimonial .bubble-compare-testimonial-text-container .bubble-compare-testimonial-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  line-height: 22px;
  color: white;
  text-align: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .bubble-compare-testimonial .bubble-compare-testimonial-text-container .bubble-compare-testimonial-text.testimonial-alt-spacing {
  width: 87%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .bubble-compare-testimonial .bubble-compare-testimonial-signoff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 17px;
  color: white;
  width: 75%;
  display: flex;
  margin-top: 5px;
  justify-content: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 270px;
  width: 100%;
  z-index: 1;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-body {
  position: absolute;
  bottom: 50px;
  margin: auto;
  width: 100%;
  z-index: 2;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-header {
  width: 96%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-header .home-value-title {
  margin-left: 70px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 28.25px;
  letter-spacing: 0.16em;
  color: #475862;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-header .home-value-divider {
  height: 2.66px;
  width: 645px;
  background-color: rgba(246, 204, 108, 0.8);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-header .home-value-subtitle {
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.05em;
  color: #53626c;
  margin-top: 6px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto 0 auto;
  table-layout: auto;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table thead th {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #8a927c;
  font-size: 15px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  line-height: 18px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table thead th:first-child {
  padding-left: 90px;
  text-align: left;
  width: 320px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table thead th:nth-child(2),
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table thead th:nth-child(3) {
  width: 130px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table thead th:last-child {
  padding-right: 70px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table tbody tr {
  border-bottom: 1px solid rgb(205.4655172414, 214.7896551724, 220.0344827586);
  height: 27px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table tbody td {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #53626c;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:first-child {
  padding-left: 90px;
  text-align: left;
  font-size: 16px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 52%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p {
  width: auto;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:last-child {
  padding-right: 70px;
  font-size: 15px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-table tbody td:last-child p {
  margin: 0 0 0 30%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-first-left .home-value .home-value-source {
  width: 90%;
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  position: relative;
  z-index: 4;
  top: 15px;
  color: white;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left {
  width: 50%;
  position: relative;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table {
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 30px 0 30px 41px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: black;
  margin: -8px 8px 0 8px;
  height: 5px;
  width: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 5px;
  height: 5px;
  width: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: black;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 31px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 28px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 25px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 30px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider {
  height: 1px;
  margin-top: 2px;
  margin-left: 23px;
  width: 477px;
  background-color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long {
  margin-left: 23px;
  width: 826px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 319px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 430px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 21px;
  display: flex;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers {
  margin-top: -1px;
  min-height: 500px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 552px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th {
  font-size: 11px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p {
  line-height: 13px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 23px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 33px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 18.5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 64px;
  text-align: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 59px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 120px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 159px;
  text-align: left;
  font-weight: 400;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address {
  font-size: 8.5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  width: 92px;
  font-weight: 400;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 73px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 41px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 100px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 63px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 10px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p {
  font-size: 8px;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend {
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 9px;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 21px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-second-left-middle-section {
  height: 927px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators {
  width: 100%;
  height: 189px;
  margin-top: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer {
  width: 80%;
  margin-left: 43px;
  display: flex;
  flex-direction: column;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row {
  display: flex;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p:nth-child(2) {
  width: 90px;
  text-align: right;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare {
  width: 105px;
  display: flex;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator {
  width: 112px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator {
  width: 121px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer .market-indicators-footer-row p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 13px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: 23px;
  height: 1px;
  width: 477px;
  background-color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -4px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section {
  font-size: 13px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #b8b8b8;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(236, 237, 241, 0.35);
  height: 50px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -4px;
  width: 100px;
  height: 1.5px;
  background-color: #b8b8b8;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  color: black;
  text-align: center;
  margin-right: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up {
  width: 35px;
  height: 35px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down {
  width: 35px;
  height: 35px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: black;
  margin-bottom: -4px;
  text-align: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer {
  position: absolute;
  top: 0;
  left: 0;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-background-img {
  height: 370px;
  width: 99.5%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container {
  position: absolute;
  top: 25px;
  left: 70px;
  text-transform: uppercase;
  width: 85%;
  display: flex;
  flex-direction: column;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle {
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: black;
  font-size: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-top: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text {
  font-size: 17px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-img {
  position: absolute;
  top: 95px;
  right: 80px;
  height: auto;
  width: 320px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .buyers-advantage-text {
  position: absolute;
  top: 255px;
  right: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.05em;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .sellers-advantage-text {
  position: absolute;
  top: 255px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.05em;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container {
  position: absolute;
  top: 300px;
  right: 0px;
  height: 50px;
  width: 658px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title {
  text-align: left;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics {
  position: absolute;
  top: 38px;
  right: 35px;
  text-align: right;
  z-index: 4;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-chartTip {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 50;
  max-width: 250px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph {
  height: 200px;
  width: 350px;
  margin-left: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph .graph-line {
  stroke: rgba(235, 242, 242, 0.4);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph .x-labels {
  text-anchor: middle;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph .y-labels {
  text-anchor: end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph .quiz-graph-grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .label-title {
  text-anchor: middle;
  text-transform: uppercase;
  font-size: 12px;
  fill: gray;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left.firstBg {
  fill: rgba(185, 158, 66, 0.8);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left.secondBg {
  fill: rgba(163, 107, 113, 0.8);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left.thirdBg {
  fill: rgba(123, 121, 139, 0.8);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph-dot.firstBg,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.firstBg {
  fill: rgba(185, 158, 66, 0.8);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph-dot.secondBg,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.secondBg {
  fill: rgba(163, 107, 113, 0.8);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph-dot.thirdBg,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .quiz-graph-start-dot.thirdBg {
  fill: rgba(123, 121, 139, 0.8);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .svg-cover {
  width: 350px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .bottom-x-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  letter-spacing: -0.075em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .data-labels,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .data-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11.5px;
  width: 30px;
  text-align: center;
  text-anchor: middle;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container {
  position: absolute;
  top: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section {
  display: flex;
  height: 200px;
  width: 99.5%;
  margin-bottom: 40px;
  box-shadow: 0 12px 18px -9px rgb(204.5, 204.5, 204.5);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row {
  display: flex;
  justify-content: space-between;
  height: 200px;
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph {
  width: 33.3%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section {
  width: 57%;
  display: flex;
  flex-direction: column;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row {
  font-weight: bold;
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  line-height: 15px;
  text-transform: uppercase;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider {
  height: 80px;
  width: 1px;
  align-self: center;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section {
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 238, 230);
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 90%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 46%;
  height: 120px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  margin-top: -15px;
  letter-spacing: 0.05em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 7px;
  text-transform: uppercase;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text {
  margin-top: 10px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  text-transform: uppercase;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 47px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: black;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow {
  height: 30px;
  width: 68px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  margin-top: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-bg {
  position: absolute;
  margin: auto;
  left: 5%;
  right: 5%;
  top: 8.2%;
  width: 672px;
  height: 922px;
  z-index: 0;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 70px;
  margin-top: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-header .db-header-main {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 27.5px;
  line-height: 26px;
  letter-spacing: 0.35em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body {
  position: absolute;
  top: 140px;
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-body-header {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px auto 10px auto;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-body-header .db-body-header-title {
  color: #838b75;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23px;
  text-align: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-body-subheader {
  width: 280px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box {
  right: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider {
  right: 0px;
  width: 616px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header {
  width: 604px;
  flex-direction: row-reverse;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category {
  text-align: left;
  margin-left: 15%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation {
  width: 65%;
  margin-left: 13%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 13%;
  margin-top: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage {
  display: flex;
  justify-content: flex-start;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box {
  left: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider {
  left: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header {
  width: 575px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category {
  text-align: right;
  margin-right: -6.5%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation {
  width: 65%;
  margin-left: 22%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 22%;
  margin-top: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage {
  display: flex;
  justify-content: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container {
  clear: both;
  display: inline-block;
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator {
  height: 203px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 1.3px;
  width: 612px;
  position: absolute;
  top: 48px;
  z-index: 3;
  background-color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
  height: 210px;
  z-index: 1;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 707px;
  height: 91%;
  position: absolute;
  z-index: 2;
  background-color: rgba(251, 251, 246, 0.9);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  height: 40px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num {
  width: 125px;
  text-align: right;
  color: #a36b71;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 37px;
  line-height: 32px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  width: 280px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: justify;
  text-justify: inter-word;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation {
  margin-top: 10px;
  color: #424142;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  line-height: 19px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage {
  color: #8f555c;
  font-weight: 400;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-weight: 400;
  color: black;
  margin-left: 4px;
  display: inline-block;
  float: right;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: -86px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-weight: 700;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-bottom-line-text,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.075em;
  color: #8f555c;
  width: 90%;
  margin: auto;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .db-body .db-bottom-line .db-signoff {
  display: inline-block;
  margin-left: 10px;
  width: auto;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table {
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 30px 0 30px 41px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: #3c4c55;
  margin: -8px 8px 0 8px;
  height: 5px;
  width: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 5px;
  height: 5px;
  width: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: #3c4c55;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 31px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 28px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 25px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 30px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: #657460;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider {
  height: 1px;
  margin-top: 2px;
  width: 500px;
  background-color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider.extra-long {
  width: 390px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .golden-divider.extra-small {
  width: 300px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 319px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 430px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 21px;
  display: flex;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section p {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers {
  margin-top: -1px;
  min-height: 500px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 552px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th {
  font-size: 11px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th p {
  line-height: 13px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 23px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 33px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 18.5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 64px;
  text-align: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 59px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 120px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 159px;
  text-align: left;
  font-weight: 400;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  width: 92px;
  font-weight: 400;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 73px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 41px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 100px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 63px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 10px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sold-overflow-tb .sales-table-row,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: #475862;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p {
  font-size: 8px;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend {
  border: 1px solid gray;
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 9px;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 21px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .table-quadrants-second-left-middle-section {
  height: 927px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators {
  width: 100%;
  height: 165px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: -7px;
  height: 1.33px;
  width: 500px;
  background-color: #abbc87;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -4px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section {
  font-size: 13px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.2);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(236, 237, 241, 0.35);
  height: 50px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -4px;
  width: 100px;
  height: 1.5px;
  background-color: #b8b8b8;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 30.5px;
  color: #53626c;
  text-align: center;
  margin-right: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up {
  width: 35px;
  height: 35px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down {
  width: 35px;
  height: 35px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: #53626c;
  margin-bottom: -4px;
  text-align: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-palmtree-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 97%;
  width: 54%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header {
  position: absolute;
  top: 100px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-header-content {
  width: 89%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 37px;
  letter-spacing: 0.16em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-light {
  color: white;
  width: 53.5%;
  text-align: right;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-title .dashboard-title-dark {
  color: #3c4c55;
  width: 44.5%;
  text-align: left;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container {
  width: 61%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header {
  display: flex;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.2em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  margin-right: 20px;
  color: white;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark {
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info {
  margin-top: 15px;
  color: rgb(70.2098765432, 76.5925925926, 64.4074074074);
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  width: 315px;
  text-align: justify;
  text-justify: inter-word;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body {
  position: absolute;
  top: 300px;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range {
  height: 186px;
  display: flex;
  position: relative;
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider {
  height: 2.66px;
  min-height: 2.66px;
  width: 570px;
  background-color: #f3d3ae;
  position: absolute;
  top: 80px;
  left: 0px;
  z-index: 1;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 37%;
  z-index: 2;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container {
  width: 82%;
  margin: -7px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 37%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text {
  margin: -20px 0 10px 0;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 29.5px;
  color: white;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 15px;
  color: white;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-img {
  display: flex;
  width: 64%;
  height: 105%;
  justify-content: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image {
  display: flex;
  width: 64%;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 35%;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 21px;
  color: #3c4c55;
  margin-top: -25px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes {
  display: flex;
  flex-direction: column;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 5px;
  font-size: 15px;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  text-align: center;
  color: rgb(28.1479289941, 34.8875739645, 38.8520710059);
  margin-top: 8px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-valuation {
  position: absolute;
  bottom: 30px;
  right: 0;
  height: 170px;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.8);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-title {
  display: flex;
  justify-content: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #3c4c55;
  margin: 20px 0 0 0;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section-row {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 8px auto 0 auto;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number {
  line-height: 90px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.light, .bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.medium, .bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-number.dark {
  color: #939d87;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .table-quadrants-second-left .dashboard-valuation .valuation-section .valuation-data {
  margin-top: 10px;
  width: 83%;
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
  line-height: 24px;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table {
  width: 100%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 30px 0 30px 41px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: #3c4c55;
  margin: -8px 8px 0 8px;
  height: 5px;
  width: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 5px;
  height: 5px;
  width: 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: #3c4c55;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 31px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 28px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 25px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 30px;
  line-height: 26px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: #657460;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row .golden-divider {
  height: 1.333px;
  margin-top: 2px;
  width: 500px;
  background-color: #abbc87;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 319px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 430px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 7px;
  display: flex;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-table-containers {
  margin-top: -1px;
  min-height: 500px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 552px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table thead tr th {
  font-size: 11px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table thead tr th p {
  line-height: 13px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 23px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 33px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 18.5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 64px;
  text-align: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 59px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 120px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 159px;
  text-align: left;
  font-weight: 400;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  width: 92px;
  font-weight: 400;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 73px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 41px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 100px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 63px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 10px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .sold-overflow-tb,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: #475862;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .sales-dates p {
  font-size: 8px;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .sales-legend {
  border: 1px solid gray;
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 9px;
  color: black;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 21px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-second-left-middle-section {
  height: 935px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators {
  width: 100%;
  height: 165px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: -7px;
  height: 1.33px;
  width: 500px;
  background-color: #abbc87;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -4px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section {
  font-size: 13px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.2);
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.35);
  height: 50px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -8px;
  width: 100px;
  height: 1.5px;
  background-color: #abbc87;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 30.5px;
  color: #53626c;
  text-align: center;
  margin-right: 20px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up {
  width: 35px;
  height: 35px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down {
  width: 35px;
  height: 35px;
}
.bubble-compare-print .bubble-compare-print-page .bubble-compare-print-page-container .bubble-compare-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: #53626c;
  margin-bottom: -4px;
  text-align: center;
}