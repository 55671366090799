/* Variables

 */
* {
  -webkit-font-smoothing: auto;
}

.ye2-pma-editor-page-container {
  width: 1365px;
  height: 1070px;
  margin: 20px auto 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.ye2-pma-editor-page-container .ye2-first-left,
.ye2-pma-editor-page-container .table-quadrants-first-left,
.ye2-pma-editor-page-container .ye2-first-right,
.ye2-pma-editor-page-container .ye2-second-left,
.ye2-pma-editor-page-container .ye2-second-right {
  width: 50%;
  position: relative;
}
.ye2-pma-editor-page-container .add-areas-modal {
  height: 320px;
  width: 370px;
  background-color: gray;
  position: absolute;
  z-index: 3;
  left: 125%;
  bottom: 5%;
  border-radius: 20px;
}
.ye2-pma-editor-page-container .add-areas-modal .add-areas-title {
  width: 80%;
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
}
.ye2-pma-editor-page-container .add-areas-modal .area-list-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
  overflow-y: auto;
}
.ye2-pma-editor-page-container .add-areas-modal .area-list-section .area-selection-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ye2-pma-editor-page-container .add-areas-modal .area-list-section .area-selection-row input {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
.ye2-pma-editor-page-container .add-areas-modal .area-list-section .area-selection-row input:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .add-areas-modal .area-list-section .area-selection-row p {
  font-size: 24px;
}
.ye2-pma-editor-page-container .add-areas-modal .add-areas-button-row {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 90%;
}
.ye2-pma-editor-page-container .add-areas-modal .add-areas-button-row .edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .add-areas-modal .add-areas-button-row .edit-form-cancel-button:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .add-areas-modal .add-areas-button-row .edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .add-areas-modal .add-areas-button-row .edit-form-submit-button {
  width: 178px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .add-areas-modal .add-areas-button-row .edit-form-submit-button:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .add-areas-modal .add-areas-button-row .edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .first-left-dim {
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.5;
  height: 400px;
  with: 100%;
}
.ye2-pma-editor-page-container .quadrant-editor {
  width: 1080px;
  position: absolute;
  right: 10px;
  top: 50%;
}
.ye2-pma-editor-page-container .quadrant-editor .return-to-pma-btn {
  position: absolute;
  top: 100px;
  right: 100px;
  z-index: 4;
  background-color: gray;
  height: 25px;
  padding: 5px 10px;
  border-radius: 10px;
}
.ye2-pma-editor-page-container .quadrant-editor .return-to-pma-btn div {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .quadrant-editor .return-to-pma-btn div:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .quadrant-editor .area-select-instructions {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  color: red;
  animation: blinker 1s step-start 10;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-title-instructions {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
}
.ye2-pma-editor-page-container .quadrant-editor .area-list {
  display: flex;
  justify-content: center;
  width: 60%;
  margin: 40px auto 0 auto;
}
.ye2-pma-editor-page-container .quadrant-editor .area-list .area-button {
  border: 2px solid gray;
  width: auto;
  height: 40px;
  padding: 0 10px;
  margin: 0 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .quadrant-editor .area-list .area-button:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .quadrant-editor .area-list .area-button div {
  color: black;
  text-align: center;
}
.ye2-pma-editor-page-container .quadrant-editor .area-list .area-button.highlighted-btn {
  border: none;
  background-color: #3d85c6;
}
.ye2-pma-editor-page-container .quadrant-editor .area-list .area-button.highlighted-btn div {
  color: white;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-body {
  margin: 20px auto 0 auto;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-selection-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-selection-row div:hover {
  background-color: rgb(115.25, 115.25, 115.25);
  cursor: pointer;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-selection-row .select-quadrant-button-container {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button {
  background-color: #3d85c6;
  height: 30px;
  width: 150px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button div {
  color: white;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-selection-row .empty-div {
  width: 30%;
}
.ye2-pma-editor-page-container .quadrant-editor .selected-quadrant-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .quadrant-editor .selected-quadrant-row .edit-btn {
  background-color: #3d85c6;
  height: 25px;
  width: 185px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.ye2-pma-editor-page-container .quadrant-editor .selected-quadrant-row .edit-btn:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .quadrant-editor .selected-quadrant-row .edit-btn div {
  color: white;
}
.ye2-pma-editor-page-container .quadrant-editor .selected-quadrant-row .edit-title {
  right: 170px;
  top: 50px;
}
.ye2-pma-editor-page-container .quadrant-editor .selected-quadrant-row .edit-text {
  top: 50px;
  right: 270px;
}
.ye2-pma-editor-page-container .quadrant-editor .selected-quadrant-row .restore-btn {
  height: 35px;
  width: 80px;
  left: 270px;
  top: 60px;
  position: absolute;
  color: #3d85c6;
  text-align: center;
}
.ye2-pma-editor-page-container .quadrant-editor .selected-quadrant-row .restore-btn:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .quadrant-editor .submit-btn-container {
  display: flex;
  justify-content: center;
  height: 25px;
  margin: 25px auto 0 auto;
}
.ye2-pma-editor-page-container .quadrant-editor .submit-btn-container .submit-btn {
  background-color: #3d85c6;
  height: 25px;
  width: 280px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .quadrant-editor .submit-btn-container .submit-btn:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .quadrant-editor .submit-btn-container .submit-btn div {
  color: white;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-section {
  background-color: #bfbdb8;
  width: 320px;
  height: 130px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-section .quadrant-section-title {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: regular;
  font-size: 30.5px;
  text-transform: uppercase;
  text-align: center;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-section .quadrant-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-section .quadrant-section-text p,
.ye2-pma-editor-page-container .quadrant-editor .quadrant-section .quadrant-section-text div {
  text-align: center;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-divider {
  width: 100%;
  height: 2px;
  background-color: gray;
  margin: 30px auto 0 auto;
}
.ye2-pma-editor-page-container .quadrant-editor .quadrant-selector-text {
  text-align: center;
  margin: 30px auto 0 auto;
}
.ye2-pma-editor-page-container .edit-quadrant-container {
  background-color: white;
  position: absolute;
  z-index: 2;
  left: 101%;
  width: 98%;
  bottom: 5%;
  height: 515px;
  border-radius: 20px;
  overflow-y: auto;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #3c4c55;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title-divider {
  margin-top: 0px;
  width: 70%;
  background-color: #4e85af;
  height: 3px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text {
  margin-top: 5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 17px;
  color: #00558f;
  display: flex;
  align-items: center;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text {
  flex-direction: column;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text {
  margin-top: 5px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button {
  margin: 0 7px;
  height: 20px;
  width: 60px;
  border-radius: 10px;
  border: 1px solid #00558f;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #4e85af;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form {
  border-radius: 15px;
  height: auto;
  padding: 20px 0;
  width: 90%;
  margin: 30px auto 0 auto;
  border: 2px solid #4e85af;
  border-radius: 15px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text {
  margin-left: 5%;
  margin-top: 15px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #475862;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider {
  width: 90%;
  background-color: #4e85af;
  height: 2px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  color: #00558f;
  margin-left: 5%;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle {
  margin-top: 15px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body {
  display: flex;
  width: 89%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter {
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body {
  flex-direction: row;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col {
  width: 45%;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea {
  max-width: 265px;
  min-width: 265px;
  height: 160px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  line-height: 19px;
  color: #424142;
  resize: none;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead {
  display: flex;
  flex-direction: row;
  width: 70%;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead {
  margin-top: 15px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.025em;
  line-height: 19px;
  text-transform: uppercase;
  color: #00305e;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error {
  color: rgb(184, 37, 37);
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label {
  width: 155px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter {
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input {
  width: 100%;
  height: 30px;
  border: 0;
  border-bottom: 3px solid #4e85af;
  outline: 0;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter {
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus {
  outline: none !important;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #414042;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col {
  width: 100%;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input {
  font-family: playfair-display, serif;
  font-weight: 600;
  font-style: italic;
  font-size: 13px;
  color: #414042;
  letter-spacing: 0.05em;
  align-self: flex-start;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter {
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col {
  width: 80%;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input {
  letter-spacing: 0.025em;
  font-size: 13px;
  font-family: playfair-display, serif;
  font-weight: 600;
  font-style: italic;
  color: #414042;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter {
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter {
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col {
  width: 90%;
  margin: auto;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col .title-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col .title-column .title-section {
  width: 900px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col .title-column .title-section label {
  margin-left: 20px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container span {
  font-weight: 700;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  margin-left: 5px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input {
  width: 700px;
  height: 25px;
  margin: auto;
  border: 0;
  outline: 0;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus {
  outline: none !important;
}
.ye2-pma-editor-page-container .edit-quadrant-container .title-dialog-body-col .title-column .title-section:nth-child(2) {
  margin-top: 50px;
  margin-bottom: 50px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 20px auto 15px auto;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .button-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .restore-default {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .restore-default:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .restore-default p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .edit-form-submit-button {
  width: 178px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .edit-form-submit-button:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .edit-quadrant-container .edit-form-button-container .edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .edit-quadrant-container .saving-message {
  color: green;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  letter-spacing: 0.05em;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-property-highlight {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 670px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ye2-pma-editor-page-container .ye2-property-highlight.gail-highlight {
  height: 695px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.xl-highlights {
  height: 746px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.xl-highlights .alt-ye2-property-highlight-title-container {
  height: 185px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.xl-highlights .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle {
  width: 91%;
  height: 157px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.xl-highlights .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p {
  font-size: 11.5px;
  line-height: 14px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.one-prop-with-testimonial-container .alt-ye2-property-highlight-title-container {
  height: 48px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.taller-higlights-bg {
  height: 705px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-new-edit-button-container {
  position: absolute;
  z-index: 3;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-new-edit-button-container.regular-prop-edit-button {
  top: -61px;
  right: 15px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-new-edit-button-container.gummow-alt-prop-edit-button-container {
  top: -42px;
  right: 50px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-new-edit-button-container .ye2-new-edit-button {
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  color: white;
  width: 250px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg {
  height: 100%;
  width: 100%;
  background-color: #dde5e7;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg {
  top: 12px;
  height: 300px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg {
  height: 445px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg {
  height: 100%;
  width: 100%;
  background-color: #231f20;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-body {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container {
  margin: 10px auto 0 auto;
  position: relative;
  height: 135px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr {
  position: absolute;
  right: 45px;
  top: -5px;
  width: 90px;
  height: 90px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img {
  width: 90px;
  height: 90px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow {
  position: absolute;
  right: 118px;
  top: 23px;
  width: 80px;
  height: 80px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img {
  width: 50px;
  height: 50px;
  transform: rotate(-25deg);
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text {
  color: white;
  font-size: 26px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
  margin-top: 3px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 35px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small {
  padding-left: 5px;
  font-size: 32px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .photo-footer-text {
  color: white;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
  color: white;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  width: 68%;
  display: flex;
  justify-content: flex-end;
  margin-top: 7px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle {
  height: 95px;
  width: 79%;
  margin: 5px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p {
  color: white;
  font-size: 13.5px;
  line-height: 22px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.05em;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 18px;
  left: 0px;
  height: 157px;
  width: 100%;
  z-index: 1;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg {
  height: 295px;
  width: 93%;
  background-color: #e3e9dd;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container {
  margin: -5px auto 0px auto;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 23px;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12.25px;
  width: 90%;
  margin: 4px auto 0 auto;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1 {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  width: 90%;
  margin: 35px auto 0 auto;
  font-family: "utopia-std", serif;
  font-weight: 400;
  font-style: italic;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2 {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  width: 90%;
  margin: 5px auto 0 auto;
  font-family: "utopia-std", serif;
  font-weight: 400;
  font-style: italic;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text {
  color: black;
  font-size: 27.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 37px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12.25px;
  line-height: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  width: 90%;
  margin: 5px auto 0 auto;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container {
  margin: 0px auto 8px auto;
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text {
  color: white;
  font-size: 22px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title {
  height: 14px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  height: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text {
  font-size: 8.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 12px;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text {
  width: 65%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 8px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text {
  color: white;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div {
  height: 13px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 10.5px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 425px;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container {
  margin: 10px auto 15px auto;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text {
  color: black;
  font-size: 26px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
  margin-top: 3px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 35px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small {
  padding-left: 5px;
  font-size: 32px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 559px;
  margin: 5px auto 0 auto;
  height: 74px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p {
  color: black;
  font-size: 13.5px;
  line-height: 22px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.05em;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text {
  width: 593px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p {
  font-size: 11.7px;
  line-height: 18px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p {
  font-size: 13.4px;
  line-height: 28px;
  font-weight: 500;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child {
  font-weight: 500;
  font-size: 26px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2) {
  margin-top: 10px;
  margin-bottom: -5px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 {
  width: 593px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p {
  font-size: 11.7px;
  line-height: 18px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text {
  color: rgb(239.7, 0, 0);
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-photo-title-container {
  margin: 2px auto 10px auto;
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text {
  color: black;
  font-size: 19px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-photo-title-container .ye2-property-highlight-photo-title-text .no-title {
  height: 14px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text {
  position: absolute;
  width: 100%;
  bottom: 25px;
  left: 0;
  height: 33px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text.lower-banner-text {
  bottom: 26px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2) {
  bottom: 26.25px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text .bolded-listing-price {
  font-weight: 600;
  text-transform: uppercase;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text .no-bold-regular-case {
  font-weight: 400;
  text-transform: none;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case {
  font-weight: 600;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text .extra-status-row {
  display: flex;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text .extra-status-row p {
  font-weight: 600;
  font-size: 11px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text .extra-status-row .coming-soon-representation {
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text.lowered-banner {
  bottom: 3px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text div {
  font-size: 10px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 15px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .row-banner-text .capitalize-city {
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 7px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text .representation-row {
  display: flex;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text .representation-row.representation-column {
  align-items: center;
  flex-direction: column;
  margin-top: 2px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2) {
  margin-top: 4px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text .representation-row p {
  font-weight: 600;
  font-size: 11px;
  margin-right: 5px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text .representation-row .pending-representation {
  color: green;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text .representation-row .active-representation {
  color: #3d85c6;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text.white-text {
  color: white;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text div:nth-child(1) {
  line-height: 9px;
  height: 8.5px;
  margin-top: 2px;
  font-size: 10.5px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text div:nth-child(2) {
  line-height: 9px;
  height: 9.3px;
  margin-top: 4px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2) {
  margin-top: 6px;
  margin-bottom: -4px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin {
  margin-top: 0px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p {
  line-height: 10px;
  height: 10px;
  margin-top: 3px;
  font-size: 9.2px;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child {
  margin-top: 4px;
  margin-bottom: 2px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line {
  padding-top: 5px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-row-1 {
  width: 95%;
  margin: auto;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col {
  position: relative;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img {
  height: 150px;
  width: 200px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-row-2 {
  padding-top: 15px;
  width: 95%;
  margin: auto;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col {
  position: relative;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img {
  height: 228px;
  width: 300px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .property-highlight-placeholder {
  position: relative;
  background-color: white;
  color: red;
  height: 180px;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout {
  margin-top: 15px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  height: 34px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text {
  font-size: 11.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 14px;
  width: 95%;
  margin: auto 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text {
  width: 65%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
  margin: 1px 10px 0 10px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div {
  height: 13px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 10.5px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 444px;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial {
  margin-top: 0px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col > div:nth-child(2) {
  display: flex;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col > div:nth-child(2) .one-prop-banner-row {
  left: 2.75%;
  width: 95%;
  bottom: 0px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col > div:nth-child(2) .one-prop-banner-row .one-prop-photo-footer-text {
  width: 36%;
  margin: -3px 10px 0 10px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col > div:nth-child(2) .one-prop-banner-row .one-prop-photo-footer-text div {
  line-height: 13px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 390px;
  width: auto;
}
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 93%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col {
  width: 292px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 194px;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height {
  height: 188px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.extra-reduced-image-height,
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.extra-reduced-image-height {
  height: 182px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin {
  margin-top: 32px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text {
  height: 60px;
  bottom: 3px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .no-status-height,
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .no-status-height {
  height: 30px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text div,
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text div {
  font-size: 9px;
  line-height: 15px;
  height: 11px;
  margin-top: 0px;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text p,
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text p {
  font-size: 9px;
  line-height: 15px;
  height: 11px;
  margin-top: 0px;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text {
  margin-top: 0px;
  height: auto;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container {
  height: 20px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0 {
  height: 232px;
  width: 100%;
  max-width: 300px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0.reduced-image-height,
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0.reduced-image-height {
  height: 210px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin {
  margin-top: 32px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout {
  margin-top: 30px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 49%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col {
  height: 252px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  width: 300px;
  height: 225px;
  max-height: 240px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position {
  object-fit: cover;
  object-position: 6% 34%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  width: 49%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col {
  height: 252px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  width: 300px;
  height: 225px;
  max-height: 240px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position {
  object-fit: cover;
  object-position: 70% 50%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position {
  object-fit: cover;
  object-position: 0% 34%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: auto;
  width: auto;
  max-height: 429px;
  max-width: 276px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain {
  object-fit: contain;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 189px;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-1 .row1-photo-col .row-banner-text {
  bottom: 24px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 126px;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .row-banner-text {
  bottom: 24px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer {
  display: flex;
  flex-direction: column;
  margin: 2px auto 0 auto;
  justify-content: center;
  align-items: center;
  width: 95%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1 {
  font-size: 16px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  line-height: 29px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 93%;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text {
  font-size: 12px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 {
  color: white;
  font-size: 16px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  line-height: 29px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 93%;
  margin-top: 14px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title {
  height: 12px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 {
  color: white;
  font-size: 22px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  width: 100%;
  letter-spacing: 0.025em;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title {
  height: 12px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  padding-top: 16px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text {
  color: white;
  font-size: 19px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.125em;
  margin-top: 8px;
}
.ye2-pma-editor-page-container .ye2-property-highlight .ye2-property-highlight-inner-photo-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin: auto;
}
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 {
  height: 580px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg {
  height: 315px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2 {
  margin-top: -19px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text {
  bottom: 10px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col {
  height: 325px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0 {
  height: 300px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text {
  height: 40px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text {
  margin-top: 12px;
  margin-bottom: -12px;
  height: 26px;
}
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.ye2-pma-editor-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm {
  object-fit: contain;
  height: 256px;
}
.ye2-pma-editor-page-container .quadrant-container {
  position: absolute;
  bottom: 0px;
  height: 736px;
  width: 100%;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-inner-container {
  width: 85%;
  margin: auto;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .testimonial-bg-image-container {
  height: 180px;
  width: 100%;
  position: absolute;
  width: 85%;
  z-index: 0;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image {
  height: 100%;
  width: 100%;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .add-testimonial-text {
  color: red;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
  line-height: 23px;
  text-align: center;
  width: 100%;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .testimonial-content-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin: auto;
  position: absolute;
  z-index: 1;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff {
  padding: 0 10px;
  color: #424142;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.2em;
  text-align: center;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.small-size-testimonial,
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.small-size-testimonial {
  font-size: 12px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  width: 90%;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p {
  text-align: right;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn {
  height: 36px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4e85af;
  position: absolute;
  z-index: 5;
  border-radius: 10px;
  font-size: 11.5px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn:hover {
  cursor: pointer;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn div {
  color: white;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn.apply-all-areas-btn {
  left: 260px;
  top: 210px;
  width: 150px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn.quadrant-title-btn {
  top: 5px;
  left: 405px;
  width: 150px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn.quadrant-btn-0 {
  top: 170px;
  left: 5px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn.quadrant-btn-1 {
  top: 282px;
  left: 155px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn.quadrant-btn-2 {
  top: 282px;
  left: 455px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn.quadrant-btn-3 {
  top: 662px;
  left: 155px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-btn.quadrant-btn-4 {
  top: 662px;
  left: 455px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 38px;
  margin-top: 50px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-title-container .quadrant-title-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  padding: 8px 0px 7px 0px;
  color: #424142;
  align-self: flex-start;
  text-transform: uppercase;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-title-container .quadrant-title-text p {
  font-size: 41px;
  line-height: 10px;
  letter-spacing: 0.1em;
  margin-right: 5px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-title-container .quadrant-subtitle-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  padding: 8px 0px 7px 0px;
  color: #424142;
  align-self: flex-end;
  margin-left: 5px;
  line-height: 20px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 400px;
  flex-wrap: wrap;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 46px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .section-container .section-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  height: 165px;
  width: 43%;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-number {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  color: #424142;
  margin-right: 15px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section {
  background-color: rgb(222.4311111111, 221.4133333333, 218.8688888889);
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section:hover {
  background-color: rgb(222.4311111111, 221.4133333333, 218.8688888889);
  cursor: pointer;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title {
  width: 100%;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #424142;
  text-align: left;
  font-size: 14px;
  text-transform: uppercase;
  padding-top: 10px;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter {
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  line-height: 17px;
  color: #424142;
  width: 100%;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p {
  text-align: center;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter {
  text-transform: capitalize;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -210px;
  z-index: 4;
  position: relative;
}
.ye2-pma-editor-page-container .quadrant-container .quadrant-logo-container img {
  height: 125px;
  width: auto;
}
.ye2-pma-editor-page-container .ye2-first-left .no-property-highlights {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-first-left .no-property-highlights .no-property-highlights-text {
  margin: 30px;
  border: 3px dashed black;
  color: black;
  font-size: 40px;
  text-align: center;
  padding: 0 100px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-backpage-bg {
  background-color: #d1cec8;
  height: 673px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-backpage-bg img {
  width: 100%;
  height: 100%;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists {
  position: absolute;
  top: 360px;
  width: 100%;
  height: 400px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-title-container {
  display: flex;
  flex-direction: column;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-title-container .ye2-economists-title-text {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-size: 32px;
  letter-spacing: 0px;
  margin-left: 50px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-title-container .ye2-economists-title-divider {
  width: 536px;
  height: 2.66px;
  background-color: #f3d3ae;
  margin-top: px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-title-container .ye2-economists-title-subtitle {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 17px;
  color: #3c4c55;
  margin-left: 380px;
  margin-top: 10px;
  letter-spacing: 0.025em;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 25px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-body .ye2-economists-section:nth-child(3),
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-body .ye2-economists-section:nth-child(4) {
  margin-top: 15px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-body .ye2-economists-section {
  width: 44%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-body .ye2-economists-section .ye2-economists-section-title {
  color: #ffffff;
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 23px;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-bottom: 5px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-body .ye2-economists-section .ye2-economists-section-content {
  margin-bottom: 4px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-body .ye2-economists-section .ye2-economists-section-content p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  color: #3c4c55;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-body .ye2-economists-section .ye2-economists-section-author {
  margin-top: 4px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-economists .ye2-economists-body .ye2-economists-section .ye2-economists-section-author p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  line-height: 13px;
  color: #3c4c55;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-testimonial2-container {
  position: absolute;
  bottom: 20px;
  right: 30px;
  height: 220px;
  width: 400px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-testimonial2-container .ye2-testimonial-title {
  width: 100%;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 17px;
  color: #3c4c55;
  margin-left: -5px;
  letter-spacing: 0.025em;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-testimonial2-container .ye2-testimonial-divider {
  background-color: #f3d3ae;
  height: 2.66px;
  width: 576px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-testimonial2-container .ye2-testimonial {
  margin-top: 10px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-testimonial2-container .ye2-testimonial .testimonial-container {
  font-family: mencken-std-head, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.075em;
  color: #6f7270;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-testimonial2-container .ye2-testimonial .testimonial-container .testimonial-signoff {
  text-align: right;
  margin-right: 30px;
}
.ye2-pma-editor-page-container .ye2-first-left .ye2-testimonial2-container .ye2-testimonial .testimonial-container .testimonial-content-row {
  display: flex;
}
.ye2-pma-editor-page-container .qv4-second-left {
  width: 50%;
  position: relative;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-background-img {
  height: 370px;
  width: 100%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container {
  position: absolute;
  top: 94px;
  left: 40px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-title {
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-left: 4px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle {
  color: #333333;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  display: flex;
  align-items: center;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle.speedometer-subtitle-col {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xl-speedometer-area-text {
  font-size: 22px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .large-speedometer-area-text {
  font-size: 22px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .medium-speedometer-area-text {
  font-size: 22px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .small-speedometer-area-text {
  font-size: 22px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text {
  font-size: 16px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xxs-speedometer-area-text {
  font-size: 16px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: #475862;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-img {
  position: absolute;
  top: 56px;
  right: 80px;
  height: 150px;
  width: 250px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .buyers-advantage-text {
  position: absolute;
  top: 194px;
  right: 255px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 10.5px;
  letter-spacing: 0.05em;
  color: black;
  line-height: 12px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .sellers-advantage-text {
  position: absolute;
  top: 194px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 10.5px;
  letter-spacing: 0.05em;
  color: black;
  line-height: 12px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container {
  position: absolute;
  top: 300px;
  right: 20px;
  height: 50px;
  width: 361px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title {
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 21px;
  text-transform: uppercase;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics {
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.025em;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-background-img {
  height: 370px;
  width: 99.5%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 {
  position: absolute;
  top: 240px;
  right: 80px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-title p {
  text-align: left;
  font-family: "Playfair Display SC";
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  margin-left: 4px;
  font-feature-settings: "lnum", "pnum";
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 11px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 16px;
  letter-spacing: 0.075em;
  line-height: 21px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 16px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.speedometer-subtitle-col {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .small-speedometer-area-text {
  font-size: 29px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xs-speedometer-area-text {
  font-size: 15px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xxs-speedometer-area-text {
  font-size: 20px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-img {
  position: absolute;
  top: 45px;
  right: 80px;
  height: auto;
  width: 320px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .buyers-advantage-text {
  position: absolute;
  top: 209px;
  right: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .sellers-advantage-text {
  position: absolute;
  top: 209px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 {
  position: absolute;
  top: 94px;
  left: 40px;
  height: 90px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-market-title-v2 {
  color: black;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 29px;
  line-height: 38px;
  letter-spacing: 0.1em;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-statistics-v2 {
  color: black;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 29px;
  line-height: 38px;
  letter-spacing: 0.1em;
}
.ye2-pma-editor-page-container .qv4-second-left .quiz-chartTip {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 50;
  max-width: 250px;
}
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph {
  height: 200px;
  width: 350px;
  margin-left: 10px;
}
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph .graph-line {
  stroke: rgba(235, 242, 242, 0.4);
}
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph .x-labels {
  text-anchor: middle;
}
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph .y-labels {
  text-anchor: end;
}
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph .quiz-graph-grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}
.ye2-pma-editor-page-container .qv4-second-left .label-title {
  text-anchor: middle;
  text-transform: uppercase;
  font-size: 12px;
  fill: gray;
}
.ye2-pma-editor-page-container .qv4-second-left.firstBg {
  fill: rgba(121, 127, 155, 0.8);
}
.ye2-pma-editor-page-container .qv4-second-left.secondBg {
  fill: rgba(165, 182, 144, 0.8);
}
.ye2-pma-editor-page-container .qv4-second-left.thirdBg {
  fill: rgba(163, 107, 113, 0.8);
}
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph-dot.firstBg,
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.firstBg {
  fill: rgba(121, 127, 155, 0.8);
}
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph-dot.secondBg,
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.secondBg {
  fill: rgba(165, 182, 144, 0.8);
}
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph-dot.thirdBg,
.ye2-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.thirdBg {
  fill: rgba(163, 107, 113, 0.8);
}
.ye2-pma-editor-page-container .qv4-second-left .svg-cover {
  width: 350px;
}
.ye2-pma-editor-page-container .qv4-second-left .bottom-x-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 9px;
  letter-spacing: -0.075em;
}
.ye2-pma-editor-page-container .qv4-second-left .data-labels,
.ye2-pma-editor-page-container .qv4-second-left .data-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10.5px;
  width: 30px;
  text-align: center;
  text-anchor: middle;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container {
  position: absolute;
  top: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section {
  display: flex;
  height: 180px;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 12px 18px -9px rgb(204.5, 204.5, 204.5);
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row {
  display: flex;
  justify-content: space-between;
  height: 200px;
  width: 100%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph {
  width: 33.3%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section {
  width: 57%;
  height: 90%;
  display: flex;
  flex-direction: column;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row {
  font-weight: bold;
  width: 100%;
  height: 20px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: black;
  line-height: 12px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider {
  height: 80px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0 5px;
  align-self: center;
  display: flex;
  align-items: center;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 83%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section.data-inner-bg-1 {
  background-color: #eef0f5;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section.data-inner-bg-2 {
  background-color: #edf0ea;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section.data-inner-bg-3 {
  background-color: #efe8e8;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 46%;
  height: 120px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  margin-top: -6px;
  height: 17px;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9.6px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
  height: 15px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 7px;
  text-transform: uppercase;
  color: black;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text {
  margin-top: 22px;
  margin-bottom: -15px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: black;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  color: black;
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow {
  height: 22px;
  width: 36px;
}
.ye2-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .qv4-sellers-adv-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 13px;
  margin-top: 17px;
  height: 19px;
}
.ye2-pma-editor-page-container .qv4-second-left .dashboard-bg {
  position: absolute;
  margin: auto;
  left: 5%;
  right: 5%;
  top: 9.6%;
  width: 572px;
  height: 849px;
  z-index: 0;
}
.ye2-pma-editor-page-container .qv4-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 70px;
  margin-top: 69px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .qv4-second-left .db-header .db-header-main {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: 0.35em;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body {
  position: absolute;
  top: 133px;
  width: 100%;
  height: 910px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-body-header {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px auto 10px auto;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-body-header .db-body-header-title {
  color: #838b75;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-align: center;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-body-subheader {
  width: 280px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box {
  right: 0px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider {
  right: 0px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header {
  width: 490px;
  flex-direction: row-reverse;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category {
  text-align: left;
  margin-left: 15%;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation {
  width: 65%;
  margin-left: 13%;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 13%;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage {
  display: flex;
  justify-content: flex-start;
  color: #8f555c;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box {
  left: 0px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider {
  left: 0px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header {
  width: 471px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category {
  text-align: right;
  margin-right: -6.5%;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation {
  width: 65%;
  margin-left: 22%;
  text-align: right;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 22%;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage {
  display: flex;
  justify-content: flex-end;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container {
  clear: both;
  display: inline-block;
  width: 100%;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator {
  height: 189px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 1.3333px;
  width: 502px;
  position: absolute;
  top: 48.5px;
  z-index: 3;
  background-color: black;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
  height: 210px;
  z-index: 1;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 575px;
  height: 91%;
  position: absolute;
  z-index: 2;
  background-color: rgba(251, 251, 246, 0.9);
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  height: 40px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num {
  width: 125px;
  text-align: right;
  color: #a36b71;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 34px;
  line-height: 28px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  width: 280px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 13px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  line-height: 17px;
  text-align: justify;
  text-justify: inter-word;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation {
  margin-top: 10px;
  color: #424142;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  line-height: 17px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage {
  color: #8f555c;
  font-weight: 400;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-weight: 400;
  color: black;
  margin-left: 4px;
  display: inline-block;
  float: right;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: 19px;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-weight: 700;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-text,
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.075em;
  color: #8f555c;
  width: 90%;
  margin: auto;
}
.ye2-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  display: inline-block;
  margin-left: 10px;
  width: auto;
}
.ye2-pma-editor-page-container .ye2-second-left .mq3-second-left-middle-section {
  height: 835px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.ye2-pma-editor-page-container .ye2-second-left .mq3-second-left-middle-section.compact-middle-section {
  height: 740px;
  margin-top: 30px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 96%;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-bg {
  position: relative;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 99.7%;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-bg img {
  width: 100%;
  height: 100%;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-inner-container {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-header .y2-year-in-review-title-container {
  display: flex;
  flex-direction: column;
  height: 100px;
  position: relative;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-title-row {
  display: flex;
  align-items: flex-end;
  height: 60px;
  margin-top: 8px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-title-row .review-title-year {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  font-size: 56px;
  letter-spacing: 0.1em;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-title-row .review-title-year:first-child {
  margin-left: 21px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-title-row .review-title-vs {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 43px;
  letter-spacing: 0.1em;
  padding: 0 20px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-end-title-divider {
  height: 105px;
  width: 2.33px;
  background-color: #f3d3ae;
  position: absolute;
  top: 45px;
  left: 388px;
  z-index: 1;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 88.5%;
  margin-top: -18px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-subtitle .y2-year-in-review-subtitle-text {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.175em;
  line-height: 26px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .y2-year-in-review-area-names {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body {
  display: flex;
  height: 87.2%;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col:nth-child(1) {
  margin-right: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col:nth-child(2) {
  margin-left: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col {
  width: 305px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  position: relative;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 72px;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column .y2-year-in-review-area-title {
  color: #3d4e57;
  font-family: playfair-display, serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.2em;
  font-size: 18px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column .y2-year-in-review-area-name {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column .y2-year-in-review-area-name p {
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column .y2-year-in-review-area-name-subtitle {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box-center-divider {
  height: 665px;
  width: 4px;
  background-color: #f3d3ae;
  position: absolute;
  top: 155px;
  left: 50%;
  z-index: 0;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-combined-cities-avg {
  position: absolute;
  top: 85px;
  left: 0px;
  width: 100%;
  text-align: center;
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-top {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 73%;
  position: relative;
  z-index: 1;
  height: 30px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-perc {
  font-size: 26px;
  color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 10px;
  line-height: 34px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-perc.no-change {
  line-height: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-category {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #3c4c55;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 115px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-review-box-year {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: #7a7a77;
  font-variant: small-caps;
  font-size: 20px;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-review-box-year.year-2020 {
  transform: rotate(-90deg) translateY(0%) translateX(-12%);
  margin: 0;
  width: 35px;
  height: 31px;
  padding: 0;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-review-box-year.year-2021 {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-inner-data-box {
  background-color: white;
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-inner-data-box .ye2-review-box-data {
  display: flex;
  justify-content: center;
  width: 48%;
  font-size: 23px;
  letter-spacing: 0.075em;
  color: #3c4c55;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-sellers-adv {
  height: 21px;
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #1f3039;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 23px auto 0 auto;
  height: 33px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-title {
  color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  margin-right: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-data {
  color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.06em;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-data.data-percentage {
  font-weight: 600;
  font-size: 14px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-data.no-change-data-perc {
  text-align: center;
  font-weight: 400;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-divider {
  height: 13px;
  width: 1px;
  background-color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  margin: 0 5px -7px 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .y2-year-in-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-not-divider {
  margin: 0 5px -7px 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 96%;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-bg {
  position: relative;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 99.7%;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-bg img {
  width: 100%;
  height: 100%;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-inner-container {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-header .y2-year-in-review-title-container {
  display: flex;
  flex-direction: column;
  height: 100px;
  position: relative;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-title-row {
  display: flex;
  align-items: flex-end;
  height: 60px;
  margin-top: 8px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-title-row .review-title-year {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  font-size: 56px;
  letter-spacing: 0.1em;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-title-row .review-title-year:first-child {
  margin-left: 21px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-title-row .review-title-vs {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 43px;
  letter-spacing: 0.1em;
  padding: 0 20px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-end-title-divider {
  height: 105px;
  width: 2.33px;
  background-color: #f3d3ae;
  position: absolute;
  top: 45px;
  left: 388px;
  z-index: 1;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 88.5%;
  margin-top: -18px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-header .y2-year-in-review-title-container .y2-year-in-review-subtitle .y2-year-in-review-subtitle-text {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.175em;
  line-height: 26px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .y2-year-in-review-area-names {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body {
  display: flex;
  height: 87.2%;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col:nth-child(1) {
  margin-right: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col:nth-child(2) {
  margin-left: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col {
  width: 427px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  position: relative;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 72px;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column .y2-year-in-review-area-title {
  color: #3d4e57;
  font-family: playfair-display, serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.2em;
  font-size: 22px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column .y2-year-in-review-area-name {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column .y2-year-in-review-area-name p {
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .y2-year-in-review-area-names-column .y2-year-in-review-area-name-subtitle {
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box-center-divider {
  height: 665px;
  width: 4px;
  background-color: #f3d3ae;
  position: absolute;
  top: 155px;
  left: 50%;
  z-index: 0;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-combined-cities-avg {
  position: absolute;
  top: 85px;
  left: 0px;
  width: 100%;
  text-align: center;
  color: black;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-top {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 73%;
  position: relative;
  z-index: 1;
  height: 30px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-perc {
  font-size: 28px;
  color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 10px;
  line-height: 34px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-perc.no-change {
  line-height: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-category {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #3c4c55;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 115px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-review-box-year {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: #7a7a77;
  font-variant: small-caps;
  font-size: 22px;
  letter-spacing: 0.05em;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-review-box-year.year-2020 {
  transform: rotate(-90deg) translateY(0%) translateX(-12%);
  margin: 0;
  width: 35px;
  height: 31px;
  padding: 0;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-review-box-year.year-2021 {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-inner-data-box {
  background-color: white;
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-comparison .ye2-inner-data-box .ye2-review-box-data {
  display: flex;
  justify-content: center;
  width: 48%;
  font-size: 25px;
  letter-spacing: 0.075em;
  color: #3c4c55;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .ye2-review-box .ye2-review-box-sellers-adv {
  height: 21px;
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #1f3039;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container {
  width: 89%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 33px auto 0 auto;
  height: 33px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-title {
  color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 17px;
  margin-right: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-data {
  color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.06em;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-data.data-percentage {
  font-weight: 600;
  font-size: 17px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-data.no-change-data-perc {
  text-align: center;
  margin-top: -10px;
  line-height: 17px;
  font-weight: 400;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-divider {
  height: 13px;
  width: 1px;
  background-color: #1f3039;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  margin: 0 5px -7px 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .one-area-review .ye2-year-in-review-body .ye2-year-in-review-col .units-sold-container .units-sold-not-divider {
  margin: 0 5px -7px 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .dashboard-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 42%;
  height: 97%;
  z-index: 0;
}
.ye2-pma-editor-page-container .ye2-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 170px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-header .db-header-main,
.ye2-pma-editor-page-container .ye2-second-left .db-header .db-header-sub {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.3em;
}
.ye2-pma-editor-page-container .ye2-second-left .db-header .db-header-sub {
  width: 315px;
  display: flex;
  justify-content: space-between;
  margin-left: -3px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body {
  position: absolute;
  top: 130px;
  width: 100%;
  height: 850px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 370px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title.large-title {
  font-size: 20px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title.medium-title {
  font-size: 16px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title.small-title {
  font-size: 14px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-title.xs-title {
  font-size: 12px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-body-header .db-body-header-subtitle {
  color: #657460;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  line-height: 24px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator {
  height: 200px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 2.666px;
  width: 410px;
  position: absolute;
  left: 0;
  top: 55px;
  z-index: 3;
  background-color: #f3d3ae;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 5px;
  width: 530px;
  height: 200px;
  z-index: 1;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 530px;
  height: 90%;
  position: absolute;
  right: 0px;
  z-index: 2;
  background-color: rgba(251, 251, 246, 0.7);
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 2;
  height: 30px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num {
  width: 85px;
  text-align: right;
  color: #c3c1ba;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 37px;
  line-height: 27px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  margin: 30px 0 0 100px;
  width: 300px;
  text-align: left;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px 0 0 76px;
  width: 82%;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  line-height: 14.8px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage-and-benefit-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70px;
  margin-top: 10px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px 0 0 55px;
  width: 89%;
  color: #2e6a8e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  line-height: 14.8px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-weight: 400;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: -43px;
  left: 10px;
  width: 125px;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: 13px;
  color: #3c4c55;
  margin-bottom: 10px;
  text-align: center;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line .db-bottom-line-text,
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 9px;
  line-height: 16px;
  color: #3c4c55;
}
.ye2-pma-editor-page-container .ye2-second-left .db-body .db-bottom-line .db-signoff {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-self: right;
  float: right;
}