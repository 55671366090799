.editor-qv3-second-left {
  width: 50%;
  position: relative;
  .dashboard-bg {
    position: absolute;
    top: 6%;
    left: 10%;
    width: 80%;
    height: 82%;
    z-index: 0;
  }
  .db-header {
    position: absolute;
    width: 96%;
    height: 60px;
    top: 36px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
    .db-header-main,
    .db-header-sub {
      color: black;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 24.5px;
      line-height: 30px;
      letter-spacing: 0.3em;
    }
    .db-header-sub {
      color: #ffffff;
    }
  }
  .db-body {
    position: absolute;
    top: 115px;
    width: 100%;
    height: 882px;
    .db-body-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin-bottom: 10px;
      margin-top: 5px;
      .db-body-header-title {
        color: #d9ae76;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 28px;
        letter-spacing: 0.05em;
        line-height: 40px;
        &.medium-header-title {
          font-size: 21px;
        }
        // &.large-title {
        //   font-size: 20px;
        // }
        // &.medium-title {
        //   font-size: 16px;
        // }
        // &.small-title {
        //   font-size: 14px;
        // }
        // &.xs-title {
        //   font-size: 12px;
        // }
      }
      .db-body-header-subtitle {
        color: #657460;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 300;
        font-style: italic;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .db-indicator-container {
      .db-indicator:nth-child(even) {
        right: 3px;
        .indicator-header-num {
          right: 20px;
        }
        .db-floating-box {
          right: 0px;
        }
        .db-divider {
          left: 0px;
          width: 100%;
        }
        .indicator-header {
          // width: 490px;
          // flex-direction: row-reverse;
          .indicator-header-category {
            // text-align: left;
            // margin-left: 228px;
          }
        }

        .indicator-explanation {
          width: 85%;
          //margin-left: 13%;
          //text-align: left;
        }
        .indicator-advantage-and-benefit-container {
          // width: 85%;
          // margin-left: 13%;
          //text-align: left;
          margin-top: 10px;
        }
      }
      .db-indicator:nth-child(odd) {
        .indicator-header-num {
          left: -25px;
        }
        .db-floating-box {
          left: 0px;
          .db-box-inner-container {
            margin-left: 10%;
          }
        }
        .db-divider {
          left: 0px;
        }
        .indicator-header {
          // width: 471px;
          .indicator-header-category {
            // text-align: left;
            // margin-left: 231px;
          }
        }
        .indicator-explanation {
          width: 85%;
          //margin-left: 22%;
          text-align: right;
        }
        .indicator-advantage-and-benefit-container {
          // width: 85%;
          //  margin-left: 22%;
          //text-align: right;
          margin-top: 10px;
        }
        .indicator-advantage {
          display: flex;
          justify-content: flex-end;
        }
      }
      // .indicator-advantage-and-benefit-container {
      //   clear: both;
      //   display: inline-block;
      //   width: 100%;
      // }
      .db-indicator {
        height: 185px;
        width: 100%;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .keep-hyphen {
          white-space: nowrap;
        }
        .db-divider {
          height: 3px;
          width: 100%;
          position: absolute;
          left: 0;
          top: 39px;
          z-index: 3;
          background-color: #ffffff;
          // background-color: #fac47e;
        }
        .white-box-img {
          position: absolute;
          top: 0;
          right: 5px;
          // width: auto;
          // width: 620px;
          width: 530px;
          height: 200px;
          z-index: 1;
        }
        .indicator-header-num {
          width: 85px;
          text-align: right;
          color: #b7a99a;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 61px;
          line-height: 39px;
          position: absolute;
          top: 68px;

          z-index: 4;
        }
        .db-floating-box {
          width: 575px;
          height: 90%;
          position: absolute;
          right: 0px;
          z-index: 2;
          background-color: rgba(225, 219, 205, 0.9);
          z-index: 3;

          .indicator-header {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            z-index: 2;
            height: 30px;

            .indicator-header-category {
              margin: 25px 0 0 100px;

              text-align: left;
              color: black;
              font-family: objektiv-mk2, sans-serif;
              font-weight: 300;
              font-style: normal;
              font-size: 16px;
              letter-spacing: 0.025em;
              text-transform: uppercase;
              &.db-indicator-medium-header {
                font-size: 14px;
                width: 530px;
                margin: 25px 0 0 77px;
              }
            }
          }
          .db-box-inner-container {
            width: 85%;
          }
          .indicator-explanation,
          .indicator-advantage {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 300;
            font-style: normal;
            text-align: justify;
            text-justify: inter-word;
            margin: 10px 0 0 76px;
            width: 82%;
            color: black;
            font-size: 11px;
            line-height: 15px;
          }
          .indicator-advantage-and-benefit-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 70px;
            margin-top: 10px;
          }
          .indicator-advantage {
            margin-top: 0px;
            color: #284f2a;
          }

          .indicator-benefit {
            font-weight: 500;
            color: #5d463e;
            margin-left: 10px;
            letter-spacing: 0.075em;
            &.no-mt-indicator-benefit {
              margin-top: 1px;
            }
          }
        }
      }
    }
    .db-bottom-line {
      position: absolute;
      bottom: -24px;
      width: 100%;
      height: 78px;

      display: flex;
      justify-content: center;
      align-items: center;
      .db-bottom-line-title {
        font-weight: 700;
      }
      .db-bottom-line-text,
      .db-signoff {
        text-align: center;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 500;
        font-style: italic;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #805b52;
        width: 80%;
        margin: auto;
      }
      .db-signoff {
        display: inline-block;
        float: right;
        margin-right: 30px;
        width: auto;
      }
    }
  }
}
