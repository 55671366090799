.qv4-pma-editor-page .qv4-first-left,
.qv4-pma-editor-page .bubble-compare-first-left,
.mq3-pma-editor-page .qv4-first-left,
.mq3-pma-editor-page .bubble-compare-first-left,
.qv3-pma-editor-page .qv4-first-left,
.qv3-pma-editor-page .bubble-compare-first-left,
.qv2-pma-editor-page .qv4-first-left,
.qv2-pma-editor-page .bubble-compare-first-left,
.ye2-pma-editor-page .qv4-first-left,
.ye2-pma-editor-page .bubble-compare-first-left,
.bubble-compare-editor-page-container .qv4-first-left,
.bubble-compare-editor-page-container .bubble-compare-first-left,
.mq2-editor-page-container .qv4-first-left,
.mq2-editor-page-container .bubble-compare-first-left,
.mq3-editor-page-container .qv4-first-left,
.mq3-editor-page-container .bubble-compare-first-left,
.table-quadrants-pma-editor-page .qv4-first-left,
.table-quadrants-pma-editor-page .bubble-compare-first-left {
  width: 50%;
}
.qv4-pma-editor-page .gummow-listing-packages,
.mq3-pma-editor-page .gummow-listing-packages,
.qv3-pma-editor-page .gummow-listing-packages,
.qv2-pma-editor-page .gummow-listing-packages,
.ye2-pma-editor-page .gummow-listing-packages,
.bubble-compare-editor-page-container .gummow-listing-packages,
.mq2-editor-page-container .gummow-listing-packages,
.mq3-editor-page-container .gummow-listing-packages,
.table-quadrants-pma-editor-page .gummow-listing-packages {
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-packages-title-container,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-packages-title-container,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-packages-title-container,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container {
  background-color: black;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container .gummow-listing-packages-title-text,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container .gummow-listing-packages-title-text,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container .gummow-listing-packages-title-text,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container .gummow-listing-packages-title-text,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container .gummow-listing-packages-title-text,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-packages-title-container .gummow-listing-packages-title-text,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-packages-title-container .gummow-listing-packages-title-text,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-packages-title-container .gummow-listing-packages-title-text,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-packages-title-container .gummow-listing-packages-title-text {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  letter-spacing: 0.05em;
  color: white;
  text-transform: uppercase;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-body,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-body,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-body,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-body,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-body,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-body,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-body,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-body,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-body {
  margin: 18px 0 18px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title {
  width: 90%;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-text,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-text,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-text,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-text,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-text,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-text,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-text,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-text,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-text {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-divider,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-divider,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-divider,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-divider,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-divider,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-divider,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-divider,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-divider,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-body-col-title .gummow-listing-body-col-title-divider {
  width: 270px;
  height: 2px;
  background-color: black;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container {
  width: 90%;
  margin-top: 4px;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container p,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container p,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container p,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container p,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container p,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container p,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container p,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container p,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container p {
  align-self: flex-start;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 21.5px;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container .gummow-listing-package-bolder,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container .gummow-listing-package-bolder,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container .gummow-listing-package-bolder,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container .gummow-listing-package-bolder,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container .gummow-listing-package-bolder,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container .gummow-listing-package-bolder,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container .gummow-listing-package-bolder,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container .gummow-listing-package-bolder,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-body .gummow-listing-body-col .gummow-listing-packages-container .gummow-listing-package-bolder {
  font-weight: 500;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-footer,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-footer,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-footer,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-footer,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-footer,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-footer,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-footer,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-footer,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6ddd1;
  height: 136px;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-title,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-title,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-title,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-title,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-title,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-title,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-title,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-title,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-title {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.05em;
  line-height: 19px;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-divider,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-divider,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-divider,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-divider,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-divider,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-divider,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-divider,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-divider,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-divider {
  height: 2px;
  width: 590px;
  background-color: black;
  margin: 12px 0;
}
.qv4-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-disclaimer,
.mq3-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-disclaimer,
.qv3-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-disclaimer,
.qv2-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-disclaimer,
.ye2-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-disclaimer,
.bubble-compare-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-disclaimer,
.mq2-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-disclaimer,
.mq3-editor-page-container .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-disclaimer,
.table-quadrants-pma-editor-page .gummow-listing-packages .gummow-listing-footer .gummow-listing-footer-inner-container .gummow-listing-footer-disclaimer {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 8px;
  line-height: 13px;
}
.qv4-pma-editor-page .johnsons-case-study,
.mq3-pma-editor-page .johnsons-case-study,
.qv3-pma-editor-page .johnsons-case-study,
.qv2-pma-editor-page .johnsons-case-study,
.ye2-pma-editor-page .johnsons-case-study,
.bubble-compare-editor-page-container .johnsons-case-study,
.mq2-editor-page-container .johnsons-case-study,
.mq3-editor-page-container .johnsons-case-study,
.table-quadrants-pma-editor-page .johnsons-case-study {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px 35px 75px 35px;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col {
  width: 20%;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col img,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col img,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col img,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col img,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col img,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col img,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col img,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col img,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-img-col img {
  width: 100%;
  height: 100%;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col {
  margin-left: 3%;
  width: 77%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo {
  margin-bottom: 40px;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo img,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo img,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo img,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo img,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo img,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo img,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo img,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo img,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-logo img {
  width: 42px;
  height: 42px;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title {
  display: flex;
  margin-bottom: 15px;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-caps-title,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-caps-title,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-caps-title,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-caps-title,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-caps-title,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-caps-title,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-caps-title,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-caps-title,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-caps-title {
  color: #76848f;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 27.5px;
  letter-spacing: 0.075em;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-noncaps-title,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-noncaps-title,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-noncaps-title,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-noncaps-title,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-noncaps-title,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-noncaps-title,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-noncaps-title,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-noncaps-title,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-title .johnsons-case-study-noncaps-title {
  color: #76848f;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 27.5px;
  letter-spacing: 0.01em;
  margin-left: 10px;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text {
  margin-top: 20px;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text p,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text p,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text p,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text p,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text p,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text p,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text p,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text p,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-body-text p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12.5px;
  line-height: 20px;
  text-align: justify;
  text-justify: inter-word;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bolded-text,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bolded-text,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bolded-text,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bolded-text,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bolded-text,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bolded-text,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bolded-text,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bolded-text,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bolded-text {
  margin-top: 20px;
  font-weight: 500;
  letter-spacing: -0.02em;
  font-size: 11.75px;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points {
  margin-top: 15px;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row {
  display: flex;
  flex-direction: row;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point {
  align-self: center;
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  margin: 0 30px 0 0;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point-text,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point-text,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point-text,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point-text,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point-text,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point-text,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point-text,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point-text,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .johnsons-case-study-bullet-point-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 23px;
}
.qv4-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .bolded-bullet-point-text,
.mq3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .bolded-bullet-point-text,
.qv3-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .bolded-bullet-point-text,
.qv2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .bolded-bullet-point-text,
.ye2-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .bolded-bullet-point-text,
.bubble-compare-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .bolded-bullet-point-text,
.mq2-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .bolded-bullet-point-text,
.mq3-editor-page-container .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .bolded-bullet-point-text,
.table-quadrants-pma-editor-page .johnsons-case-study .johnsons-case-study-inner-container .johnsons-case-study-body-col .johnsons-case-study-bullet-points .johnsons-case-study-bullet-points-row .bolded-bullet-point-text {
  font-weight: 500;
}
.qv4-pma-editor-page .annette-quadrants-image-container,
.mq3-pma-editor-page .annette-quadrants-image-container,
.qv3-pma-editor-page .annette-quadrants-image-container,
.qv2-pma-editor-page .annette-quadrants-image-container,
.ye2-pma-editor-page .annette-quadrants-image-container,
.bubble-compare-editor-page-container .annette-quadrants-image-container,
.mq2-editor-page-container .annette-quadrants-image-container,
.mq3-editor-page-container .annette-quadrants-image-container,
.table-quadrants-pma-editor-page .annette-quadrants-image-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 500px;
  width: 100%;
}
.qv4-pma-editor-page .annette-quadrants-image-container .annette-quadrants-image,
.mq3-pma-editor-page .annette-quadrants-image-container .annette-quadrants-image,
.qv3-pma-editor-page .annette-quadrants-image-container .annette-quadrants-image,
.qv2-pma-editor-page .annette-quadrants-image-container .annette-quadrants-image,
.ye2-pma-editor-page .annette-quadrants-image-container .annette-quadrants-image,
.bubble-compare-editor-page-container .annette-quadrants-image-container .annette-quadrants-image,
.mq2-editor-page-container .annette-quadrants-image-container .annette-quadrants-image,
.mq3-editor-page-container .annette-quadrants-image-container .annette-quadrants-image,
.table-quadrants-pma-editor-page .annette-quadrants-image-container .annette-quadrants-image {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 670px;
  width: 100%;
}
.qv4-pma-editor-page .annette-quadrants,
.mq3-pma-editor-page .annette-quadrants,
.qv3-pma-editor-page .annette-quadrants,
.qv2-pma-editor-page .annette-quadrants,
.ye2-pma-editor-page .annette-quadrants,
.bubble-compare-editor-page-container .annette-quadrants,
.mq2-editor-page-container .annette-quadrants,
.mq3-editor-page-container .annette-quadrants,
.table-quadrants-pma-editor-page .annette-quadrants {
  position: absolute;
  top: 345px;
  left: 0px;
  height: 424px;
  width: 100%;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-body-title-section,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-body-title-section,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-body-title-section,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-body-title-section,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-body-title-section,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-body-title-section,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-body-title-section,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-body-title-section,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-body-title-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-body-title,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-body-title,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-body-title,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-body-title,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-body-title,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-body-title,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-body-title,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-body-title,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-body-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 31px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-section-container,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-section-container,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-section-container,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 21px;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container .annette-section-row,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container .annette-section-row,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container .annette-section-row,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container .annette-section-row,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container .annette-section-row,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-section-container .annette-section-row,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-section-container .annette-section-row,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-section-container .annette-section-row,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-section-container .annette-section-row {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 30px;
  width: 47%;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section .annette-quadrant-divider,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section .annette-quadrant-divider,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section .annette-quadrant-divider,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section .annette-quadrant-divider,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section .annette-quadrant-divider,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section .annette-quadrant-divider,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section .annette-quadrant-divider,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section .annette-quadrant-divider,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-divider-section .annette-quadrant-divider {
  height: 1px;
  background-color: black;
  width: 110px;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section {
  width: 47%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-subtitle,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-subtitle,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-subtitle,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-subtitle,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-subtitle,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-subtitle,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-subtitle,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-subtitle,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-subtitle {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 19px;
  color: black;
  text-align: center;
  margin-bottom: 12px;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text p,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text p,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text p,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text p,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text p,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text p,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text p,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text p,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-body .annette-quadrants-section .annette-quadrants-section-text p {
  text-align: center;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-logo-container,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-logo-container,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-logo-container,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-logo-container,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-logo-container,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-logo-container,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-logo-container,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-logo-container,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -189px;
  z-index: 4;
  position: relative;
}
.qv4-pma-editor-page .annette-quadrants .annette-quadrants-logo-container img,
.mq3-pma-editor-page .annette-quadrants .annette-quadrants-logo-container img,
.qv3-pma-editor-page .annette-quadrants .annette-quadrants-logo-container img,
.qv2-pma-editor-page .annette-quadrants .annette-quadrants-logo-container img,
.ye2-pma-editor-page .annette-quadrants .annette-quadrants-logo-container img,
.bubble-compare-editor-page-container .annette-quadrants .annette-quadrants-logo-container img,
.mq2-editor-page-container .annette-quadrants .annette-quadrants-logo-container img,
.mq3-editor-page-container .annette-quadrants .annette-quadrants-logo-container img,
.table-quadrants-pma-editor-page .annette-quadrants .annette-quadrants-logo-container img {
  height: 90px;
  width: auto;
}
.qv4-pma-editor-page .annette-testimonial,
.mq3-pma-editor-page .annette-testimonial,
.qv3-pma-editor-page .annette-testimonial,
.qv2-pma-editor-page .annette-testimonial,
.ye2-pma-editor-page .annette-testimonial,
.bubble-compare-editor-page-container .annette-testimonial,
.mq2-editor-page-container .annette-testimonial,
.mq3-editor-page-container .annette-testimonial,
.table-quadrants-pma-editor-page .annette-testimonial {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 260px;
  background-color: #231f20;
}
.qv4-pma-editor-page .annette-testimonial .annette-testimonial-img,
.mq3-pma-editor-page .annette-testimonial .annette-testimonial-img,
.qv3-pma-editor-page .annette-testimonial .annette-testimonial-img,
.qv2-pma-editor-page .annette-testimonial .annette-testimonial-img,
.ye2-pma-editor-page .annette-testimonial .annette-testimonial-img,
.bubble-compare-editor-page-container .annette-testimonial .annette-testimonial-img,
.mq2-editor-page-container .annette-testimonial .annette-testimonial-img,
.mq3-editor-page-container .annette-testimonial .annette-testimonial-img,
.table-quadrants-pma-editor-page .annette-testimonial .annette-testimonial-img {
  width: 100%;
  height: 100%;
}
.qv4-pma-editor-page .annette-testimonial .annette-testimonial-inner,
.mq3-pma-editor-page .annette-testimonial .annette-testimonial-inner,
.qv3-pma-editor-page .annette-testimonial .annette-testimonial-inner,
.qv2-pma-editor-page .annette-testimonial .annette-testimonial-inner,
.ye2-pma-editor-page .annette-testimonial .annette-testimonial-inner,
.bubble-compare-editor-page-container .annette-testimonial .annette-testimonial-inner,
.mq2-editor-page-container .annette-testimonial .annette-testimonial-inner,
.mq3-editor-page-container .annette-testimonial .annette-testimonial-inner,
.table-quadrants-pma-editor-page .annette-testimonial .annette-testimonial-inner {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description,
.mq3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description,
.qv3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description,
.qv2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description,
.ye2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description,
.bubble-compare-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description,
.mq2-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description,
.mq3-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description,
.table-quadrants-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 23px;
  letter-spacing: 0.05em;
  color: white;
  text-align: center;
  margin-bottom: 10px;
}
.qv4-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0,
.mq3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0,
.qv3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0,
.qv2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0,
.ye2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0,
.bubble-compare-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0,
.mq2-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0,
.mq3-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0,
.table-quadrants-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 {
  margin-bottom: 8px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 22px;
  color: white;
  letter-spacing: 0em;
}
.qv4-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 span,
.mq3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 span,
.qv3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 span,
.qv2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 span,
.ye2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 span,
.bubble-compare-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 span,
.mq2-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 span,
.mq3-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 span,
.table-quadrants-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-0 span {
  font-size: 25.5px;
  font-weight: 500;
  letter-spacing: 0.025em;
  font-style: normal;
}
.qv4-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-1,
.mq3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-1,
.qv3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-1,
.qv2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-1,
.ye2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-1,
.bubble-compare-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-1,
.mq2-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-1,
.mq3-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-1,
.table-quadrants-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-1 {
  margin-top: 4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: white;
  leter-spacing: 0.05em;
}
.qv4-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-2,
.mq3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-2,
.qv3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-2,
.qv2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-2,
.ye2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-2,
.bubble-compare-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-2,
.mq2-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-2,
.mq3-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-2,
.table-quadrants-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-2 {
  margin-top: 4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  letter-spacing: 0;
  color: white;
}
.qv4-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-3,
.mq3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-3,
.qv3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-3,
.qv2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-3,
.ye2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-3,
.bubble-compare-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-3,
.mq2-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-3,
.mq3-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-3,
.table-quadrants-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-3 {
  margin-top: 7px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0;
  color: white;
}
.qv4-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-4,
.mq3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-4,
.qv3-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-4,
.qv2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-4,
.ye2-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-4,
.bubble-compare-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-4,
.mq2-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-4,
.mq3-editor-page-container .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-4,
.table-quadrants-pma-editor-page .annette-testimonial .annette-testimonial-inner .annette-testimonial-description-4 {
  margin-top: 4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0;
  color: white;
}
.qv4-pma-editor-page .brett-note,
.mq3-pma-editor-page .brett-note,
.qv3-pma-editor-page .brett-note,
.qv2-pma-editor-page .brett-note,
.ye2-pma-editor-page .brett-note,
.bubble-compare-editor-page-container .brett-note,
.mq2-editor-page-container .brett-note,
.mq3-editor-page-container .brett-note,
.table-quadrants-pma-editor-page .brett-note {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qv4-pma-editor-page .brett-note .brett-note-inner-container,
.mq3-pma-editor-page .brett-note .brett-note-inner-container,
.qv3-pma-editor-page .brett-note .brett-note-inner-container,
.qv2-pma-editor-page .brett-note .brett-note-inner-container,
.ye2-pma-editor-page .brett-note .brett-note-inner-container,
.bubble-compare-editor-page-container .brett-note .brett-note-inner-container,
.mq2-editor-page-container .brett-note .brett-note-inner-container,
.mq3-editor-page-container .brett-note .brett-note-inner-container,
.table-quadrants-pma-editor-page .brett-note .brett-note-inner-container {
  width: 85%;
}
.qv4-pma-editor-page .brett-note .brett-note-inner-container .brett-note-title,
.mq3-pma-editor-page .brett-note .brett-note-inner-container .brett-note-title,
.qv3-pma-editor-page .brett-note .brett-note-inner-container .brett-note-title,
.qv2-pma-editor-page .brett-note .brett-note-inner-container .brett-note-title,
.ye2-pma-editor-page .brett-note .brett-note-inner-container .brett-note-title,
.bubble-compare-editor-page-container .brett-note .brett-note-inner-container .brett-note-title,
.mq2-editor-page-container .brett-note .brett-note-inner-container .brett-note-title,
.mq3-editor-page-container .brett-note .brett-note-inner-container .brett-note-title,
.table-quadrants-pma-editor-page .brett-note .brett-note-inner-container .brett-note-title {
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30.5px;
  letter-spacing: 0.02em;
}
.qv4-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text,
.mq3-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text,
.qv3-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text,
.qv2-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text,
.ye2-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text,
.bubble-compare-editor-page-container .brett-note .brett-note-inner-container .brett-note-text,
.mq2-editor-page-container .brett-note .brett-note-inner-container .brett-note-text,
.mq3-editor-page-container .brett-note .brett-note-inner-container .brett-note-text,
.table-quadrants-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-top: 5px;
  text-align: justify;
}
.qv4-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text-2,
.mq3-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text-2,
.qv3-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text-2,
.qv2-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text-2,
.ye2-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text-2,
.bubble-compare-editor-page-container .brett-note .brett-note-inner-container .brett-note-text-2,
.mq2-editor-page-container .brett-note .brett-note-inner-container .brett-note-text-2,
.mq3-editor-page-container .brett-note .brett-note-inner-container .brett-note-text-2,
.table-quadrants-pma-editor-page .brett-note .brett-note-inner-container .brett-note-text-2 {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  margin-top: 5px;
  line-height: 11.5px;
}
.qv4-pma-editor-page .brett-note .brett-note-inner-container .brett-note-signoff,
.mq3-pma-editor-page .brett-note .brett-note-inner-container .brett-note-signoff,
.qv3-pma-editor-page .brett-note .brett-note-inner-container .brett-note-signoff,
.qv2-pma-editor-page .brett-note .brett-note-inner-container .brett-note-signoff,
.ye2-pma-editor-page .brett-note .brett-note-inner-container .brett-note-signoff,
.bubble-compare-editor-page-container .brett-note .brett-note-inner-container .brett-note-signoff,
.mq2-editor-page-container .brett-note .brett-note-inner-container .brett-note-signoff,
.mq3-editor-page-container .brett-note .brett-note-inner-container .brett-note-signoff,
.table-quadrants-pma-editor-page .brett-note .brett-note-inner-container .brett-note-signoff {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
  margin-top: 11px;
  text-align: right;
}
.qv4-pma-editor-page .brett-note .brett-note-img-container,
.mq3-pma-editor-page .brett-note .brett-note-img-container,
.qv3-pma-editor-page .brett-note .brett-note-img-container,
.qv2-pma-editor-page .brett-note .brett-note-img-container,
.ye2-pma-editor-page .brett-note .brett-note-img-container,
.bubble-compare-editor-page-container .brett-note .brett-note-img-container,
.mq2-editor-page-container .brett-note .brett-note-img-container,
.mq3-editor-page-container .brett-note .brett-note-img-container,
.table-quadrants-pma-editor-page .brett-note .brett-note-img-container {
  margin-top: 10px;
  display: flex;
}
.qv4-pma-editor-page .brett-note .brett-note-img-container .brett-note-img,
.mq3-pma-editor-page .brett-note .brett-note-img-container .brett-note-img,
.qv3-pma-editor-page .brett-note .brett-note-img-container .brett-note-img,
.qv2-pma-editor-page .brett-note .brett-note-img-container .brett-note-img,
.ye2-pma-editor-page .brett-note .brett-note-img-container .brett-note-img,
.bubble-compare-editor-page-container .brett-note .brett-note-img-container .brett-note-img,
.mq2-editor-page-container .brett-note .brett-note-img-container .brett-note-img,
.mq3-editor-page-container .brett-note .brett-note-img-container .brett-note-img,
.table-quadrants-pma-editor-page .brett-note .brett-note-img-container .brett-note-img {
  height: auto;
  width: 72%;
  height: auto;
  margin: 25px auto 20px auto;
}
.qv4-pma-editor-page .johnsons-back-to-school,
.mq3-pma-editor-page .johnsons-back-to-school,
.qv3-pma-editor-page .johnsons-back-to-school,
.qv2-pma-editor-page .johnsons-back-to-school,
.ye2-pma-editor-page .johnsons-back-to-school,
.bubble-compare-editor-page-container .johnsons-back-to-school,
.mq2-editor-page-container .johnsons-back-to-school,
.mq3-editor-page-container .johnsons-back-to-school,
.table-quadrants-pma-editor-page .johnsons-back-to-school {
  position: absolute;
  top: 375px;
  left: 0px;
  height: 430px;
  width: 100%;
  background-color: #e3e1d8;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  border-top: 1px solid #e3e1d8;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title-section,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title-section,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title-section,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title-section,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title-section,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title-section,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title-section,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title-section,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-body-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  line-height: 32px;
  color: #0b1717;
  letter-spacing: 0.1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-subtitle-text,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-subtitle-text,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-subtitle-text,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-subtitle-text,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-subtitle-text,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-subtitle-text,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-subtitle-text,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-subtitle-text,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #f57e20;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 4px;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .section-container,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .section-container,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .section-container,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 13px;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container .section-row,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container .section-row,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container .section-row,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container .section-row,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container .section-row,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .section-container .section-row,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .section-container .section-row,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .section-container .section-row,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .section-container .section-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section {
  width: 47%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-title,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-title,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-title,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-title,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-title,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-title,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-title,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-title,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: #0b1717;
  line-height: 21px;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 14px;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 28px;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text .extra-mt,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text .extra-mt,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text .extra-mt,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text .extra-mt,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text .extra-mt,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text .extra-mt,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text .extra-mt,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text .extra-mt,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text .extra-mt {
  margin-top: 14px;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.first-section,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.first-section,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.first-section,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.first-section,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.first-section,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.first-section,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.first-section,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.first-section,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.first-section {
  line-height: 32px;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.second-section,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.second-section,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.second-section,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.second-section,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.second-section,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.second-section,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.second-section,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.second-section,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text.second-section {
  line-height: 35px;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text p,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text p,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text p,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text p,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text p,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text p,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text p,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text p,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-body .johnsons-back-to-school-section .johnsons-back-to-school-section-text p {
  text-align: center;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-logo-container,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-logo-container,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-logo-container,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -165px;
  z-index: 4;
  position: relative;
}
.qv4-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container img,
.mq3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container img,
.qv3-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container img,
.qv2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container img,
.ye2-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container img,
.bubble-compare-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-logo-container img,
.mq2-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-logo-container img,
.mq3-editor-page-container .johnsons-back-to-school .johnsons-back-to-school-logo-container img,
.table-quadrants-pma-editor-page .johnsons-back-to-school .johnsons-back-to-school-logo-container img {
  height: 91px;
  width: auto;
}
.qv4-pma-editor-page .johnsons-teacher-fund,
.mq3-pma-editor-page .johnsons-teacher-fund,
.qv3-pma-editor-page .johnsons-teacher-fund,
.qv2-pma-editor-page .johnsons-teacher-fund,
.ye2-pma-editor-page .johnsons-teacher-fund,
.bubble-compare-editor-page-container .johnsons-teacher-fund,
.mq2-editor-page-container .johnsons-teacher-fund,
.mq3-editor-page-container .johnsons-teacher-fund,
.table-quadrants-pma-editor-page .johnsons-teacher-fund {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 330px;
  background-color: white;
}
.qv4-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container,
.mq3-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container,
.qv3-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container,
.qv2-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container,
.ye2-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container,
.bubble-compare-editor-page-container .johnsons-teacher-fund .teacher-fund-img-container,
.mq2-editor-page-container .johnsons-teacher-fund .teacher-fund-img-container,
.mq3-editor-page-container .johnsons-teacher-fund .teacher-fund-img-container,
.table-quadrants-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container {
  margin-top: 25px;
}
.qv4-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container img,
.mq3-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container img,
.qv3-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container img,
.qv2-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container img,
.ye2-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container img,
.bubble-compare-editor-page-container .johnsons-teacher-fund .teacher-fund-img-container img,
.mq2-editor-page-container .johnsons-teacher-fund .teacher-fund-img-container img,
.mq3-editor-page-container .johnsons-teacher-fund .teacher-fund-img-container img,
.table-quadrants-pma-editor-page .johnsons-teacher-fund .teacher-fund-img-container img {
  width: 160px;
  height: auto;
}
.qv4-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container,
.mq3-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container,
.qv3-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container,
.qv2-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container,
.ye2-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container,
.bubble-compare-editor-page-container .johnsons-teacher-fund .teacher-fund-title-container,
.mq2-editor-page-container .johnsons-teacher-fund .teacher-fund-title-container,
.mq3-editor-page-container .johnsons-teacher-fund .teacher-fund-title-container,
.table-quadrants-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container {
  margin-bottom: 0px;
  margin-top: 15px;
}
.qv4-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container .teacher-fund-title,
.mq3-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container .teacher-fund-title,
.qv3-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container .teacher-fund-title,
.qv2-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container .teacher-fund-title,
.ye2-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container .teacher-fund-title,
.bubble-compare-editor-page-container .johnsons-teacher-fund .teacher-fund-title-container .teacher-fund-title,
.mq2-editor-page-container .johnsons-teacher-fund .teacher-fund-title-container .teacher-fund-title,
.mq3-editor-page-container .johnsons-teacher-fund .teacher-fund-title-container .teacher-fund-title,
.table-quadrants-pma-editor-page .johnsons-teacher-fund .teacher-fund-title-container .teacher-fund-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: #0b1717;
  line-height: 21px;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 8px;
}
.qv4-pma-editor-page .johnsons-teacher-fund .teacher-fund-body,
.qv4-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.mq3-pma-editor-page .johnsons-teacher-fund .teacher-fund-body,
.mq3-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.qv3-pma-editor-page .johnsons-teacher-fund .teacher-fund-body,
.qv3-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.qv2-pma-editor-page .johnsons-teacher-fund .teacher-fund-body,
.qv2-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.ye2-pma-editor-page .johnsons-teacher-fund .teacher-fund-body,
.ye2-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.bubble-compare-editor-page-container .johnsons-teacher-fund .teacher-fund-body,
.bubble-compare-editor-page-container .johnsons-teacher-fund .teacher-wishlist,
.mq2-editor-page-container .johnsons-teacher-fund .teacher-fund-body,
.mq2-editor-page-container .johnsons-teacher-fund .teacher-wishlist,
.mq3-editor-page-container .johnsons-teacher-fund .teacher-fund-body,
.mq3-editor-page-container .johnsons-teacher-fund .teacher-wishlist,
.table-quadrants-pma-editor-page .johnsons-teacher-fund .teacher-fund-body,
.table-quadrants-pma-editor-page .johnsons-teacher-fund .teacher-wishlist {
  color: #0b1717;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 515px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 18px;
  text-align: justify;
  text-justify: inter-word;
  font-size: 11.8px;
}
.qv4-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.mq3-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.qv3-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.qv2-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.ye2-pma-editor-page .johnsons-teacher-fund .teacher-wishlist,
.bubble-compare-editor-page-container .johnsons-teacher-fund .teacher-wishlist,
.mq2-editor-page-container .johnsons-teacher-fund .teacher-wishlist,
.mq3-editor-page-container .johnsons-teacher-fund .teacher-wishlist,
.table-quadrants-pma-editor-page .johnsons-teacher-fund .teacher-wishlist {
  margin-top: 16px;
}
.qv4-pma-editor-page .johnsons-teacher-fund .teacher-community,
.mq3-pma-editor-page .johnsons-teacher-fund .teacher-community,
.qv3-pma-editor-page .johnsons-teacher-fund .teacher-community,
.qv2-pma-editor-page .johnsons-teacher-fund .teacher-community,
.ye2-pma-editor-page .johnsons-teacher-fund .teacher-community,
.bubble-compare-editor-page-container .johnsons-teacher-fund .teacher-community,
.mq2-editor-page-container .johnsons-teacher-fund .teacher-community,
.mq3-editor-page-container .johnsons-teacher-fund .teacher-community,
.table-quadrants-pma-editor-page .johnsons-teacher-fund .teacher-community {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: 18px;
  color: #0b1717;
}
.qv4-pma-editor-page .johnsons-education-fund,
.mq3-pma-editor-page .johnsons-education-fund,
.qv3-pma-editor-page .johnsons-education-fund,
.qv2-pma-editor-page .johnsons-education-fund,
.ye2-pma-editor-page .johnsons-education-fund,
.bubble-compare-editor-page-container .johnsons-education-fund,
.mq2-editor-page-container .johnsons-education-fund,
.mq3-editor-page-container .johnsons-education-fund,
.table-quadrants-pma-editor-page .johnsons-education-fund {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 700px;
  width: 100%;
  border-top: 1px solid darkgray;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body {
  display: flex;
  flex-direction: column;
  flex-direction: space-between;
  height: 100%;
  color: black;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row {
  display: flex;
  height: 35%;
  width: 94%;
  margin: auto;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 25%;
  height: 97%;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-apple-icon,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-apple-icon,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-apple-icon,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-apple-icon,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-apple-icon,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-apple-icon,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-apple-icon,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-apple-icon,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-apple-icon {
  width: 100%;
  height: auto;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .teacher-education-qr-code,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .teacher-education-qr-code,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .teacher-education-qr-code,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .teacher-education-qr-code,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .teacher-education-qr-code,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .teacher-education-qr-code,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .teacher-education-qr-code,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .teacher-education-qr-code,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .teacher-education-qr-code {
  height: 115px;
  width: 115px;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 p,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 p,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 p,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 p,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 p,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 p,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 p,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 p,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-first-col .teacher-education-qr-container .johnsons-education-fund-subtitle-1 p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10.25px;
  line-height: 20px;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 5%;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title {
  margin-top: 5px;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title p,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title p,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title p,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title p,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title p,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title p,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title p,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title p,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-title p {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 27px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body p,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body p,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body p,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body p,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body p,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body p,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body p,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body p,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 21.5px;
  text-align: justify;
  margin-top: 10px;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body .supplies-link,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body .supplies-link,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body .supplies-link,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body .supplies-link,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body .supplies-link,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body .supplies-link,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body .supplies-link,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body .supplies-link,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-top-row .johnsons-education-top-row-second-col .johnsons-education-top-row-body .supplies-link {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 13px;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row {
  display: flex;
  height: 60%;
}
.qv4-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row img,
.mq3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row img,
.qv3-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row img,
.qv2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row img,
.ye2-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row img,
.bubble-compare-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row img,
.mq2-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row img,
.mq3-editor-page-container .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row img,
.table-quadrants-pma-editor-page .johnsons-education-fund .johnsons-education-body .johnsons-education-bottom-row img {
  width: 100%;
  height: 100%;
}
.qv4-pma-editor-page .johnsons-logo-quadrants,
.mq3-pma-editor-page .johnsons-logo-quadrants,
.qv3-pma-editor-page .johnsons-logo-quadrants,
.qv2-pma-editor-page .johnsons-logo-quadrants,
.ye2-pma-editor-page .johnsons-logo-quadrants,
.bubble-compare-editor-page-container .johnsons-logo-quadrants,
.mq2-editor-page-container .johnsons-logo-quadrants,
.mq3-editor-page-container .johnsons-logo-quadrants,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants {
  position: absolute;
  top: 359px;
  left: 0px;
  height: 500px;
  width: 100%;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  border-top: 1px solid #e3e1d8;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .body-title-section,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .body-title-section,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .body-title-section,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .body-title-section,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .body-title-section,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .body-title-section,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .body-title-section,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .body-title-section,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .body-title-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  line-height: 37px;
  color: black;
  letter-spacing: 0.1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #f57e20;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 15px;
  letter-spacing: 0.02em;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-details,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-details,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-details,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-details,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-details,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-details,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-details,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-details,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-details {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  text-align: center;
  margin-top: 10px;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 200px;
  margin: 20px auto 0 auto;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row {
  display: flex;
  justify-content: center;
  width: 90%;
  height: 200px;
  margin: auto;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .forbes-logo,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .forbes-logo,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .forbes-logo,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .forbes-logo,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .forbes-logo,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .forbes-logo,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .forbes-logo,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .forbes-logo,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .forbes-logo {
  height: auto;
  width: 130px;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .lpi-logo,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .lpi-logo,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .lpi-logo,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .lpi-logo,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .lpi-logo,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .lpi-logo,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .lpi-logo,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .lpi-logo,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .lpi-logo {
  height: auto;
  width: 170px;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .leading-logo,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .leading-logo,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .leading-logo,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .leading-logo,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .leading-logo,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .leading-logo,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .leading-logo,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .leading-logo,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .leading-logo {
  height: auto;
  width: 180px;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .mayfair-logo,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .mayfair-logo,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .mayfair-logo,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .mayfair-logo,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .mayfair-logo,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .mayfair-logo,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .mayfair-logo,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .mayfair-logo,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .section-container .section-row .mayfair-logo {
  height: auto;
  width: 170px;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section {
  width: 47%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  color: #143e45;
  text-align: center;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  line-height: 28px;
  font-size: 32px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p {
  text-align: center;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-logo-container,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-logo-container,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-logo-container,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -142px;
  z-index: 4;
  position: relative;
}
.qv4-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container img,
.mq3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container img,
.qv3-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container img,
.qv2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container img,
.ye2-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container img,
.bubble-compare-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-logo-container img,
.mq2-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-logo-container img,
.mq3-editor-page-container .johnsons-logo-quadrants .johnsons-quadrants-logo-container img,
.table-quadrants-pma-editor-page .johnsons-logo-quadrants .johnsons-quadrants-logo-container img {
  height: 55px;
  width: auto;
}
.qv4-pma-editor-page .johnsons-quadrants-two,
.mq3-pma-editor-page .johnsons-quadrants-two,
.qv3-pma-editor-page .johnsons-quadrants-two,
.qv2-pma-editor-page .johnsons-quadrants-two,
.ye2-pma-editor-page .johnsons-quadrants-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two,
.mq2-editor-page-container .johnsons-quadrants-two,
.mq3-editor-page-container .johnsons-quadrants-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two {
  position: absolute;
  top: 380px;
  left: 0px;
  height: 500px;
  width: 100%;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  border-top: 1px solid #e3e1d8;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .body-title-section-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .body-title-section-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .body-title-section-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .body-title-section-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .body-title-section-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .body-title-section-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .body-title-section-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .body-title-section-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .body-title-section-two {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 17px;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-title-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-title-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-title-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-title-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-title-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-title-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-title-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-title-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-title-two {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 27px;
  line-height: 30px;
  color: black;
  letter-spacing: 0.1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-subtitle-text-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-subtitle-text-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-subtitle-text-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-subtitle-text-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-subtitle-text-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-subtitle-text-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-subtitle-text-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-subtitle-text-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-body-subtitle-text-two {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #f57e20;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 13px;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two .section-row-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two .section-row-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two .section-row-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two .section-row-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two .section-row-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two .section-row-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two .section-row-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two .section-row-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .section-container-two .section-row-two {
  display: flex;
  justify-content: space-between;
  width: 93%;
  margin: auto;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two {
  width: 47%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two.last-paragraph-reduced-mt,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two.last-paragraph-reduced-mt,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two.last-paragraph-reduced-mt,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two.last-paragraph-reduced-mt,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two.last-paragraph-reduced-mt,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two.last-paragraph-reduced-mt,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two.last-paragraph-reduced-mt,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two.last-paragraph-reduced-mt,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two.last-paragraph-reduced-mt {
  margin-top: 20px;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-subtitle-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-subtitle-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-subtitle-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-subtitle-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-subtitle-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-subtitle-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-subtitle-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-subtitle-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-subtitle-two {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: #143e45;
  text-align: center;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-title-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-title-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-title-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-title-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-title-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-title-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-title-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-title-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-title-two {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  line-height: 24px;
  font-size: 25px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 17px;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11.25px;
  line-height: 17px;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two.last-paragraph-high-mt,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two.last-paragraph-high-mt,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two.last-paragraph-high-mt,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two.last-paragraph-high-mt,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two.last-paragraph-high-mt,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two.last-paragraph-high-mt,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two.last-paragraph-high-mt,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two.last-paragraph-high-mt,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two.last-paragraph-high-mt {
  margin-top: 15px;
  margin-bottom: 15px;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two p,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two p,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two p,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two p,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two p,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two p,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two p,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two p,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-body-two .johnsons-quadrants-section-two .johnsons-quadrants-section-text-two p {
  text-align: center;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-logo-container-two,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-logo-container-two,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-logo-container-two,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -180px;
  z-index: 4;
  position: relative;
}
.qv4-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two img,
.mq3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two img,
.qv3-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two img,
.qv2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two img,
.ye2-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two img,
.bubble-compare-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-logo-container-two img,
.mq2-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-logo-container-two img,
.mq3-editor-page-container .johnsons-quadrants-two .johnsons-quadrants-logo-container-two img,
.table-quadrants-pma-editor-page .johnsons-quadrants-two .johnsons-quadrants-logo-container-two img {
  height: 55px;
  width: auto;
}
.qv4-pma-editor-page .johnsons-testimonial-two,
.mq3-pma-editor-page .johnsons-testimonial-two,
.qv3-pma-editor-page .johnsons-testimonial-two,
.qv2-pma-editor-page .johnsons-testimonial-two,
.ye2-pma-editor-page .johnsons-testimonial-two,
.bubble-compare-editor-page-container .johnsons-testimonial-two,
.mq2-editor-page-container .johnsons-testimonial-two,
.mq3-editor-page-container .johnsons-testimonial-two,
.table-quadrants-pma-editor-page .johnsons-testimonial-two {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 235px;
  background-color: #e3e1d8;
}
.qv4-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two,
.mq3-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two,
.qv3-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two,
.qv2-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two,
.ye2-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two,
.bubble-compare-editor-page-container .johnsons-testimonial-two .johnsons-testimonial-inner-two,
.mq2-editor-page-container .johnsons-testimonial-two .johnsons-testimonial-inner-two,
.mq3-editor-page-container .johnsons-testimonial-two .johnsons-testimonial-inner-two,
.table-quadrants-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}
.qv4-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two p,
.mq3-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two p,
.qv3-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two p,
.qv2-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two p,
.ye2-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two p,
.bubble-compare-editor-page-container .johnsons-testimonial-two .johnsons-testimonial-inner-two p,
.mq2-editor-page-container .johnsons-testimonial-two .johnsons-testimonial-inner-two p,
.mq3-editor-page-container .johnsons-testimonial-two .johnsons-testimonial-inner-two p,
.table-quadrants-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 18px;
  text-align: center;
  color: #143e45;
}
.qv4-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two .johnsons-testimonial-two-signoff-container,
.mq3-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two .johnsons-testimonial-two-signoff-container,
.qv3-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two .johnsons-testimonial-two-signoff-container,
.qv2-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two .johnsons-testimonial-two-signoff-container,
.ye2-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two .johnsons-testimonial-two-signoff-container,
.bubble-compare-editor-page-container .johnsons-testimonial-two .johnsons-testimonial-inner-two .johnsons-testimonial-two-signoff-container,
.mq2-editor-page-container .johnsons-testimonial-two .johnsons-testimonial-inner-two .johnsons-testimonial-two-signoff-container,
.mq3-editor-page-container .johnsons-testimonial-two .johnsons-testimonial-inner-two .johnsons-testimonial-two-signoff-container,
.table-quadrants-pma-editor-page .johnsons-testimonial-two .johnsons-testimonial-inner-two .johnsons-testimonial-two-signoff-container {
  width: 92%;
  display: flex;
  justify-content: flex-end;
}
.qv4-pma-editor-page .johnsons-quadrants,
.mq3-pma-editor-page .johnsons-quadrants,
.qv3-pma-editor-page .johnsons-quadrants,
.qv2-pma-editor-page .johnsons-quadrants,
.ye2-pma-editor-page .johnsons-quadrants,
.bubble-compare-editor-page-container .johnsons-quadrants,
.mq2-editor-page-container .johnsons-quadrants,
.mq3-editor-page-container .johnsons-quadrants,
.table-quadrants-pma-editor-page .johnsons-quadrants {
  position: absolute;
  top: 380px;
  left: 0px;
  height: 500px;
  width: 100%;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  border-top: 1px solid #e3e1d8;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .body-title-section,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .body-title-section,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .body-title-section,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .body-title-section,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .body-title-section,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .body-title-section,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .body-title-section,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .body-title-section,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .body-title-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 17px;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  line-height: 32px;
  color: black;
  letter-spacing: 0.1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-body-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #f57e20;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 13px;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .section-container,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .section-container,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .section-container,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container .section-row,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container .section-row,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container .section-row,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container .section-row,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container .section-row,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .section-container .section-row,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .section-container .section-row,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .section-container .section-row,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .section-container .section-row {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section {
  width: 47%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-subtitle {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: #143e45;
  text-align: center;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  line-height: 24px;
  font-size: 28px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 17px;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 17px;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-body .johnsons-quadrants-section .johnsons-quadrants-section-text p {
  text-align: center;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-logo-container,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-logo-container,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-logo-container,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -150px;
  z-index: 4;
  position: relative;
}
.qv4-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container img,
.mq3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container img,
.qv3-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container img,
.qv2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container img,
.ye2-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container img,
.bubble-compare-editor-page-container .johnsons-quadrants .johnsons-quadrants-logo-container img,
.mq2-editor-page-container .johnsons-quadrants .johnsons-quadrants-logo-container img,
.mq3-editor-page-container .johnsons-quadrants .johnsons-quadrants-logo-container img,
.table-quadrants-pma-editor-page .johnsons-quadrants .johnsons-quadrants-logo-container img {
  height: 55px;
  width: auto;
}
.qv4-pma-editor-page .johnsons-testimonial,
.mq3-pma-editor-page .johnsons-testimonial,
.qv3-pma-editor-page .johnsons-testimonial,
.qv2-pma-editor-page .johnsons-testimonial,
.ye2-pma-editor-page .johnsons-testimonial,
.bubble-compare-editor-page-container .johnsons-testimonial,
.mq2-editor-page-container .johnsons-testimonial,
.mq3-editor-page-container .johnsons-testimonial,
.table-quadrants-pma-editor-page .johnsons-testimonial {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 200px;
  background-color: #e3e1d8;
}
.qv4-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner,
.mq3-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner,
.qv3-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner,
.qv2-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner,
.ye2-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner,
.bubble-compare-editor-page-container .johnsons-testimonial .johnsons-testimonial-inner,
.mq2-editor-page-container .johnsons-testimonial .johnsons-testimonial-inner,
.mq3-editor-page-container .johnsons-testimonial .johnsons-testimonial-inner,
.table-quadrants-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding: 0 30px;
}
.qv4-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner p,
.mq3-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner p,
.qv3-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner p,
.qv2-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner p,
.ye2-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner p,
.bubble-compare-editor-page-container .johnsons-testimonial .johnsons-testimonial-inner p,
.mq2-editor-page-container .johnsons-testimonial .johnsons-testimonial-inner p,
.mq3-editor-page-container .johnsons-testimonial .johnsons-testimonial-inner p,
.table-quadrants-pma-editor-page .johnsons-testimonial .johnsons-testimonial-inner p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12.8px;
  line-height: 18px;
  text-align: center;
  color: #143e45;
}
.qv4-pma-editor-page .deb-bg,
.mq3-pma-editor-page .deb-bg,
.qv3-pma-editor-page .deb-bg,
.qv2-pma-editor-page .deb-bg,
.ye2-pma-editor-page .deb-bg,
.bubble-compare-editor-page-container .deb-bg,
.mq2-editor-page-container .deb-bg,
.mq3-editor-page-container .deb-bg,
.table-quadrants-pma-editor-page .deb-bg {
  height: 692px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #bfbdb8;
}
.qv4-pma-editor-page .senior-relocation-img,
.mq3-pma-editor-page .senior-relocation-img,
.qv3-pma-editor-page .senior-relocation-img,
.qv2-pma-editor-page .senior-relocation-img,
.ye2-pma-editor-page .senior-relocation-img,
.bubble-compare-editor-page-container .senior-relocation-img,
.mq2-editor-page-container .senior-relocation-img,
.mq3-editor-page-container .senior-relocation-img,
.table-quadrants-pma-editor-page .senior-relocation-img {
  height: 691px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
}
.qv4-pma-editor-page .senior-relocation,
.mq3-pma-editor-page .senior-relocation,
.qv3-pma-editor-page .senior-relocation,
.qv2-pma-editor-page .senior-relocation,
.ye2-pma-editor-page .senior-relocation,
.bubble-compare-editor-page-container .senior-relocation,
.mq2-editor-page-container .senior-relocation,
.mq3-editor-page-container .senior-relocation,
.table-quadrants-pma-editor-page .senior-relocation {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 728px;
  width: 100%;
  z-index: 2;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-title-container,
.mq3-pma-editor-page .senior-relocation .senior-relocation-title-container,
.qv3-pma-editor-page .senior-relocation .senior-relocation-title-container,
.qv2-pma-editor-page .senior-relocation .senior-relocation-title-container,
.ye2-pma-editor-page .senior-relocation .senior-relocation-title-container,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-title-container,
.mq2-editor-page-container .senior-relocation .senior-relocation-title-container,
.mq3-editor-page-container .senior-relocation .senior-relocation-title-container,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #761137;
  border-top: 4px solid #f3d3ae;
  border-bottom: 4px solid #f3d3ae;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-title-container .senior-relocation-title-text,
.mq3-pma-editor-page .senior-relocation .senior-relocation-title-container .senior-relocation-title-text,
.qv3-pma-editor-page .senior-relocation .senior-relocation-title-container .senior-relocation-title-text,
.qv2-pma-editor-page .senior-relocation .senior-relocation-title-container .senior-relocation-title-text,
.ye2-pma-editor-page .senior-relocation .senior-relocation-title-container .senior-relocation-title-text,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-title-container .senior-relocation-title-text,
.mq2-editor-page-container .senior-relocation .senior-relocation-title-container .senior-relocation-title-text,
.mq3-editor-page-container .senior-relocation .senior-relocation-title-container .senior-relocation-title-text,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-title-container .senior-relocation-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  padding: 2px 0px;
  color: #ffffff;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body,
.mq2-editor-page-container .senior-relocation .senior-relocation-body,
.mq3-editor-page-container .senior-relocation .senior-relocation-body,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .body-title-section,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .body-title-section,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .body-title-section,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .body-title-section,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .body-title-section,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .body-title-section,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .body-title-section,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .body-title-section,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .body-title-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-title,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-title,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-title,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-title,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-title,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-body-title,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-body-title,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-body-title,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  margin-top: 30px;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-subtitle-text,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-subtitle-text,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-subtitle-text,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-subtitle-text,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-subtitle-text,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-body-subtitle-text,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-body-subtitle-text,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-body-subtitle-text,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-body-subtitle-text {
  color: #761137;
  font-family: mencken-std-head, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 23px;
  letter-spacing: 0.025em;
  margin-top: 10px;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-phone-number,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-phone-number,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-phone-number,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number {
  color: black;
  font-family: mencken-std-head, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 26px;
  letter-spacing: 0.025em;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number .phone-dot-separator,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number .phone-dot-separator,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number .phone-dot-separator,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number .phone-dot-separator,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number .phone-dot-separator,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-phone-number .phone-dot-separator,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-phone-number .phone-dot-separator,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-phone-number .phone-dot-separator,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-phone-number .phone-dot-separator {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  margin: 1px 4px 0 4px;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .section-container,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .section-container,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .section-container,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .section-container,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .section-container,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .section-container,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .section-container,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .section-container,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .section-container .section-row,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .section-container .section-row,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .section-container .section-row,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .section-container .section-row,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .section-container .section-row,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .section-container .section-row,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .section-container .section-row,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .section-container .section-row,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .section-container .section-row {
  display: flex;
  justify-content: space-between;
  width: 93%;
  margin: auto;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section {
  width: 44%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-title,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-title,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-title,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-title,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-title,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-title,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-title,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-title,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-title {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: regular;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text p,
.mq3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text p,
.qv3-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text p,
.qv2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text p,
.ye2-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text p,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text p,
.mq2-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text p,
.mq3-editor-page-container .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text p,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-body .senior-relocation-section .senior-relocation-section-text p {
  text-align: center;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-logo-container,
.mq3-pma-editor-page .senior-relocation .senior-relocation-logo-container,
.qv3-pma-editor-page .senior-relocation .senior-relocation-logo-container,
.qv2-pma-editor-page .senior-relocation .senior-relocation-logo-container,
.ye2-pma-editor-page .senior-relocation .senior-relocation-logo-container,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-logo-container,
.mq2-editor-page-container .senior-relocation .senior-relocation-logo-container,
.mq3-editor-page-container .senior-relocation .senior-relocation-logo-container,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -195px;
  z-index: 4;
  position: relative;
}
.qv4-pma-editor-page .senior-relocation .senior-relocation-logo-container img,
.mq3-pma-editor-page .senior-relocation .senior-relocation-logo-container img,
.qv3-pma-editor-page .senior-relocation .senior-relocation-logo-container img,
.qv2-pma-editor-page .senior-relocation .senior-relocation-logo-container img,
.ye2-pma-editor-page .senior-relocation .senior-relocation-logo-container img,
.bubble-compare-editor-page-container .senior-relocation .senior-relocation-logo-container img,
.mq2-editor-page-container .senior-relocation .senior-relocation-logo-container img,
.mq3-editor-page-container .senior-relocation .senior-relocation-logo-container img,
.table-quadrants-pma-editor-page .senior-relocation .senior-relocation-logo-container img {
  height: 115px;
  width: auto;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr,
.mq3-pma-editor-page .v2-deb-testimonial-qr,
.qv3-pma-editor-page .v2-deb-testimonial-qr,
.qv2-pma-editor-page .v2-deb-testimonial-qr,
.ye2-pma-editor-page .v2-deb-testimonial-qr,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr,
.mq2-editor-page-container .v2-deb-testimonial-qr,
.mq3-editor-page-container .v2-deb-testimonial-qr,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 250px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .qr-container,
.mq3-pma-editor-page .v2-deb-testimonial-qr .qr-container,
.qv3-pma-editor-page .v2-deb-testimonial-qr .qr-container,
.qv2-pma-editor-page .v2-deb-testimonial-qr .qr-container,
.ye2-pma-editor-page .v2-deb-testimonial-qr .qr-container,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .qr-container,
.mq2-editor-page-container .v2-deb-testimonial-qr .qr-container,
.mq3-editor-page-container .v2-deb-testimonial-qr .qr-container,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .qr-container {
  width: 23%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .qr-container img,
.mq3-pma-editor-page .v2-deb-testimonial-qr .qr-container img,
.qv3-pma-editor-page .v2-deb-testimonial-qr .qr-container img,
.qv2-pma-editor-page .v2-deb-testimonial-qr .qr-container img,
.ye2-pma-editor-page .v2-deb-testimonial-qr .qr-container img,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .qr-container img,
.mq2-editor-page-container .v2-deb-testimonial-qr .qr-container img,
.mq3-editor-page-container .v2-deb-testimonial-qr .qr-container img,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .qr-container img {
  height: 100px;
  width: auto;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .qr-container .sbos-link,
.mq3-pma-editor-page .v2-deb-testimonial-qr .qr-container .sbos-link,
.qv3-pma-editor-page .v2-deb-testimonial-qr .qr-container .sbos-link,
.qv2-pma-editor-page .v2-deb-testimonial-qr .qr-container .sbos-link,
.ye2-pma-editor-page .v2-deb-testimonial-qr .qr-container .sbos-link,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .qr-container .sbos-link,
.mq2-editor-page-container .v2-deb-testimonial-qr .qr-container .sbos-link,
.mq3-editor-page-container .v2-deb-testimonial-qr .qr-container .sbos-link,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .qr-container .sbos-link {
  font-family: mencken-std-head, sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #686d6c;
  font-size: 14px;
  letter-spacing: 0.075em;
  line-height: 24px;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container {
  margin: auto;
  width: 90%;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-title,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-title,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-title,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-title,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-title,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-title,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-title,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-title,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 19px;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-divider,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-divider,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-divider,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-divider,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-divider,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-divider,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-divider,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-divider,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-divider {
  height: 2.66px;
  width: 492px;
  background-color: #f3d3ae;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text {
  margin-top: 10px;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text p,
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner p,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text p,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner p,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text p,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner p,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text p,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner p,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text p,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner p,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text p,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner p,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text p,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner p,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text p,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner p,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-text p,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner p {
  font-family: mencken-std-head, sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #686d6c;
  font-size: 14px;
  letter-spacing: 0.075em;
  line-height: 24px;
}
.qv4-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.mq3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.qv3-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.qv2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.ye2-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.bubble-compare-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.mq2-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.mq3-editor-page-container .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner,
.table-quadrants-pma-editor-page .v2-deb-testimonial-qr .ds-testimonial .ds-testimonial-text-container .ds-testimonial-homeowner {
  margin-top: 15px;
  align-items: center;
}
.qv4-pma-editor-page .kateryna-september-2024-container,
.mq3-pma-editor-page .kateryna-september-2024-container,
.qv3-pma-editor-page .kateryna-september-2024-container,
.qv2-pma-editor-page .kateryna-september-2024-container,
.ye2-pma-editor-page .kateryna-september-2024-container,
.bubble-compare-editor-page-container .kateryna-september-2024-container,
.mq2-editor-page-container .kateryna-september-2024-container,
.mq3-editor-page-container .kateryna-september-2024-container,
.table-quadrants-pma-editor-page .kateryna-september-2024-container {
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 635px;
  width: 100%;
}
.qv4-pma-editor-page .kateryna-september-2024-container .kateryna-september-img,
.mq3-pma-editor-page .kateryna-september-2024-container .kateryna-september-img,
.qv3-pma-editor-page .kateryna-september-2024-container .kateryna-september-img,
.qv2-pma-editor-page .kateryna-september-2024-container .kateryna-september-img,
.ye2-pma-editor-page .kateryna-september-2024-container .kateryna-september-img,
.bubble-compare-editor-page-container .kateryna-september-2024-container .kateryna-september-img,
.mq2-editor-page-container .kateryna-september-2024-container .kateryna-september-img,
.mq3-editor-page-container .kateryna-september-2024-container .kateryna-september-img,
.table-quadrants-pma-editor-page .kateryna-september-2024-container .kateryna-september-img {
  width: 100%;
  height: 100%;
}
.qv4-pma-editor-page .kateryna-and-trish-september-2024-container,
.mq3-pma-editor-page .kateryna-and-trish-september-2024-container,
.qv3-pma-editor-page .kateryna-and-trish-september-2024-container,
.qv2-pma-editor-page .kateryna-and-trish-september-2024-container,
.ye2-pma-editor-page .kateryna-and-trish-september-2024-container,
.bubble-compare-editor-page-container .kateryna-and-trish-september-2024-container,
.mq2-editor-page-container .kateryna-and-trish-september-2024-container,
.mq3-editor-page-container .kateryna-and-trish-september-2024-container,
.table-quadrants-pma-editor-page .kateryna-and-trish-september-2024-container {
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 700px;
  width: 100%;
}
.qv4-pma-editor-page .kateryna-and-trish-september-2024-container .kateryna-and-trish-september-img,
.mq3-pma-editor-page .kateryna-and-trish-september-2024-container .kateryna-and-trish-september-img,
.qv3-pma-editor-page .kateryna-and-trish-september-2024-container .kateryna-and-trish-september-img,
.qv2-pma-editor-page .kateryna-and-trish-september-2024-container .kateryna-and-trish-september-img,
.ye2-pma-editor-page .kateryna-and-trish-september-2024-container .kateryna-and-trish-september-img,
.bubble-compare-editor-page-container .kateryna-and-trish-september-2024-container .kateryna-and-trish-september-img,
.mq2-editor-page-container .kateryna-and-trish-september-2024-container .kateryna-and-trish-september-img,
.mq3-editor-page-container .kateryna-and-trish-september-2024-container .kateryna-and-trish-september-img,
.table-quadrants-pma-editor-page .kateryna-and-trish-september-2024-container .kateryna-and-trish-september-img {
  width: 100%;
  height: 100%;
}