.print-mission-statement-block {
  position: absolute;
  bottom: 55px;
  right: 0px;
  height: 650px;
  width: 100%;
  .mission-statement-custom-photo {
    height: 100%;
    width: 100%;
  }
  .mission-statement-block-bg-container {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    .mission-statement-block-bg {
      height: 100%;
      width: 100%;
    }
  }
  .mission-statement-content-container {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;

    justify-content: center;
    align-items: center;
    .mission-statement-column-container {
      width: 80%;
      height: 35%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      position: absolute;

      top: 0px;
      left: 0px;
      z-index: 2;
      &.large-mission-statement-photo {
        width: 82%;
        .mission-statement-image-container {
          .mission-statement-image {
            max-width: 321px;
            max-height: 203px;
          }
        }
      }
      .mission-statement-image-container {
        //width: 100%;

        .mission-statement-image {
          width: auto;
          height: auto;
          max-width: 360px;
          max-height: 186px;
          &.mission-photo-border {
            border: 1px solid black;
          }
        }
      }
    }

    .mission-statement-inner-container {
      position: absolute;
      left: 0%;

      bottom: 7%;
      border: 1px solid #ded9d5;
      width: 90%;
      height: 63%;
      background-color: black;
      box-shadow: 10px 10px 5px #cdcdcd;
      display: flex;
      align-items: center;
      flex-direction: column;

      justify-content: space-between;

      .mission-statement-text-container {
        width: 80%;
        margin: 61px auto 0 auto;

        .mission-statement-title {
          text-align: left;
          font-family: "playfair-display", serif;
          font-weight: 400;
          font-style: italic;
          font-size: 29px;
          letter-spacing: 0.05em;
          margin-bottom: 10px;
          color: white;
          &.mission-margin-top-30 {
            margin-top: 30px;
          }
          &.mission-statement-title-26 {
            font-size: 26px;
          }
        }
        .mission-statement-text {
          // text-align: center;
          padding: 0 auto;
          text-align: center;
          text-align: justify;
          text-justify: inter-word;
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 17px;

          line-height: 26px;
          color: white;
          &.mission-statement-content-20 {
            font-size: 20px;
            line-height: 35px;
            margin-top: 42px;
          }
          .mission-statement-margin-top-spacing {
            margin-top: 15px;
          }
          &.mission-statement-14-5 {
            font-size: 14.5px;
          }
        }
      }
    }
  }
}

.print-mission-statement-block-v2 {
  position: absolute;
  bottom: 55px;
  right: 0px;
  height: 650px;
  width: 100%;

  .mission-statement-block-bg-container {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    .mission-statement-block-bg {
      height: 100%;
      width: 100%;
    }
  }
  .mission-statement-content-container {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .mission-statement-inner-container {
      position: absolute;
      left: 16%;
      border: 1px solid #ded9d5;
      width: 78%;
      height: 80%;
      background-color: white;
      box-shadow: 10px 10px 5px #cdcdcd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mission-statement-image-container {
        width: 37%;
        margin-right: 20px;
        position: relative;
        right: 70px;
        top: 0px;
        .mission-statement-image {
          width: auto;
          height: auto;
          max-width: 360px;
          max-height: 250px;
          &.mission-photo-border {
            border: 1px solid black;
          }
        }
      }

      .mission-statement-text-container {
        width: 47%;
        margin-right: 23px;

        .mission-statement-title {
          text-align: left;
          font-family: "playfair-display", serif;
          font-weight: 400;
          font-style: italic;
          font-size: 24px;
          letter-spacing: 0.13em;
          margin-bottom: 10px;
          &.mission-margin-top-30 {
            margin-top: 30px;
          }
          &.mission-statement-title-26 {
            font-size: 26px;
          }
        }
        .mission-statement-text {
          // text-align: center;
          padding: 0 15px 0 1px;
          text-align: justify;
          text-justify: inter-word;
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 400;
          font-style: italic;
          font-size: 15px;
          letter-spacing: 0.1em;
          line-height: 23px;
        }
      }
    }
  }
}
