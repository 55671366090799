@import "src/_styles/variables";
@import "src/_styles/mixins";
*,
body {
  //-webkit-font-smoothing: auto !important;
}

.agent-focus-container {
  padding: 50px;
  .screen-number {
    display: flex;
    justify-content: center;
    font-size: 26px;
  }
  .checkbox-divider {
    height: 2px;
    width: 500px;
    background-color: gray;
    margin: 30px 0;
  }
  .checkbox-container {
    display: flex;
    flex-direction: column;
    .checkbox-row {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      input {
        margin-right: 10px;
        height: 30px;
        width: 30px;
        &:hover {
          cursor: pointer;
        }
      }
      label {
        font-size: 18px;
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    width: 30%;
    margin-top: 50px;
    .cancel-btn {
      background-color: gray;
      height: 25px;
      padding: 5px 10px;
      border-radius: 10px;
      div {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .next-btn {
      background-color: gray;
      height: 25px;
      padding: 5px 10px;
      border-radius: 10px;
      div {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.highlighted-btn {
        background-color: $primary;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
