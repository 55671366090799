.print-qv4-cover-page-container {
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #fff;
  .dwyer-bottom-cover-disclaimer-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
    .dwyer-bottom-cover-disclaimer-text {
      font-size: 14px;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 300;
      font-style: normal;
    }
  }
  .cover-page-bg-photo {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-size: cover;
    &.full-photo {
      background-size: 100% 100%;
    }
  }
  .cover-page-color-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .upper-cover-photo-credit {
    height: 19px;
    position: absolute;
    top: 47px;
    left: 76px;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: white;
  }

  .cover-page-edition {
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 19px;
    color: white;
    text-transform: uppercase;
    align-self: flex-end;
    position: absolute;
    top: 47px;
    right: 76px;
    &.left-justified-cover-date {
      left: 56px;
    }
    .cover-page-small-caps {
      text-transform: lowercase;
      font-variant: small-caps;
    }
  }
  .special-year-end-edition {
    position: absolute;
    top: 65px;
    right: 76px;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 19px;
  }
  .amin-cover-monogram {
    position: absolute;
    top: 47px;
    right: 76px;
    img {
      height: 80px;
      width: auto;
    }
  }
  .special-vait-above-cover-title {
    position: absolute;
    top: 15px;
    // font-family: "Sitka";

    .vait-logo-container {
      display: flex;
      justify-content: center;
      img {
        height: 130px;
        width: auto;
      }
    }
    .vait-presents-container {
      display: flex;
      justify-content: center;
      .vait-presents-text {
        font-family: "playfair-display", serif;
        font-weight: 400;
        font-style: italic;

        font-size: 29px;
        text-align: center;
      }
    }
  }
  .matt-t-cover-logo-container {
    position: absolute;
    top: 25px;
    left: 22px;
    z-index: 2;

    .matt-t-cover-logo-img {
      height: auto;
      width: auto;
      max-height: 120px;
    }
  }
  .special-kj-team-logo-container {
    position: absolute;
    top: 35px;
    left: 82px;
    z-index: 2;
    .kj-team-logo-img {
      height: auto;
      width: auto;
      max-height: 80px;
    }
  }
  .special-kazemi-team-photo-container {
    position: absolute;
    top: 45px;
    left: 100px;
    z-index: 2;
    .kazemi-team-photo-img {
      height: auto;
      width: auto;
      max-height: 180px;
    }
  }
  .cover-page-title-container {
    width: 90%;
    max-width: 90%;
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 330px;
    .high-patio-text-1 {
      margin-top: -100px;
    }
    .black-patio-text-on-wall {
      margin-top: 150px;
      color: black;
    }
    .dwyer-cover-text-margin {
      margin-top: -20px;

      div,
      p {
        //  color: black;
      }
    }
    .galli-cover-text-margin {
      margin-top: -20px;
    }
    .cover-text-top-100 {
      margin-top: -100px;
    }
    .high-cover-text-127 {
      margin-top: -127px;
    }
    .double-line-high-patio-text {
      margin-top: -110px;
    }
    &.sideways-title-container {
      right: 70px;
      top: 270px;
      height: 80px;
      max-width: 395px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      .sideways-cover-text {
        font-size: 34px;
        line-height: 32px;
      }
    }
    &.top-left-cover-info {
      top: 40px;
      width: 500px;
      height: auto;
      left: 60px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .qv4-subdivision-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .subdivision-title {
          font-size: 42px;
          line-height: 52px;
        }
      }
    }
    &.higher-cover-text-20-top {
      top: 20px;
    }
    &.higher-cover-text-40-top {
      top: 40px;
    }
    &.lower-cover-text-240 {
      top: 230px;
      height: 160px;
    }
    &.lower-cover-text-375 {
      top: 375px;
      height: 160px;
    }
    &.vait-width {
      width: 100%;
      max-width: 100%;
      top: -25px;
    }
    &.small-title-height {
      // height: 170px;
      margin-top: 70px;
    }
  }
  .cover-page-city-area {
    width: auto;
    max-width: 92%;
    text-align: center;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 200;
    font-style: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    // > div {
    //   font-weight: 300;
    // }
    .one-line-cover-page {
      // font-size: 58px;
      font-size: 50px;
      line-height: 62px;
      // line-height: 74px;
    }
  }
  .cover-photo-line-separator {
    height: 2.14px;
    background-color: white;

    width: 300px;
    margin: 15px auto 15px auto;
  }
  .dwyer-above-cover-container {
    height: 50px;
    margin: 119px auto 20px auto;
    &.black-text-dwyer-cover {
      div,
      p {
        //  color: black;
        font-weight: 400;
      }
    }
    .dwyer-above-cover-text {
      font-family: objektiv-mk2, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 34px;
      text-transform: none;
      &.smaller-dwyer-text {
        font-size: 26px;
      }
    }
  }
  .dwyer-below-cover-container {
    height: 50px;
    margin: 20px auto 0px auto;
    &.black-text-dwyer-cover {
      div,
      p {
        //  color: black;
        font-weight: 400;
      }
    }
    .dwyer-below-cover-text {
      font-family: objektiv-mk2, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 19px;
      text-transform: none;
      line-height: 26px;
    }
  }

  .qv4-subdivision-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .capitalized-title {
      text-transform: uppercase;
      font-size: 64px;
      line-height: 94px;
      margin-bottom: 20px;
    }

    .subdivision-title {
      font-size: 41px;
      line-height: 56px;
      &.cover-page-area-text-37 {
        font-size: 37px;
      }
      &.bolded-cover-photo-text {
        font-weight: 400;
      }
    }
    .cover-page-text-font-39 {
      font-size: 39px;
      line-height: 54px;
    }
    .subdivision-title-single-line {
      font-size: 39px;
      line-height: 56px;
    }
    .smaller-city-title {
      font-size: 41px;
      margin-top: -105px;
      margin-bottom: 24px;
    }
    &.higher-cover-page-text {
      margin-top: -130px;
    }
    &.higher-cover-page-text-2 {
      margin-top: -170px;
    }

    .subdivision-subtitle-container {
      display: flex;
      font-size: 26px;
      justify-content: flex-end;
      align-self: flex-end;
      text-align: right;
      .including-subtitle {
        font-style: italic;
        margin-right: 7px;
        text-transform: none;
      }
    }
  }
  .vait-cover-title-container {
    width: 100%;
    max-width: 100%;
    margin-top: 217px;

    .vait-cover-title {
      font-family: "Sitka";
      font-size: 40.5px;
      text-transform: capitalize;
    }
    .vait-market-title {
      font-family: "Sitka";
      font-size: 40.5px;
      text-transform: capitalize;
      margin-top: 5px;
    }
  }
  .gummow-brothers-above-cover-logo {
    margin: -10px auto 50px auto;

    img {
      height: 60px;
      width: auto;
      margin-top: -141px;
      margin-bottom: 100px;
    }
  }
  .one-cover-page-area {
    font-size: 50px;
    line-height: 62px;
    &.lower-cover-page-top-190 {
      margin-top: 250px;
    }
    &.larger-one-cover-area {
      font-size: 75px;
      font-weight: 200;
    }
    &.xxs-cover-page-area-text {
      font-size: 42px;
    }
    &.higher-cover-page-text {
      margin-top: -85px;
    }
    &.cover-page-area-text-32 {
      font-size: 32px;
    }
    &.cover-page-text-40 {
      font-size: 40px;
    }
    &.bolded-cover-photo-text {
      font-weight: 400;
    }
    &.monte-sereno-higher-cover-text {
      margin-top: 90px;
    }
    &.cover-page-area-text-47 {
      font-size: 47px;
    }
    &.top-left-cover-page-text {
      margin-top: -170px;
      left: 0px;
    }
    &.lower-cover-page-text-255 {
      margin-top: 255px;
    }
  }
  .area-text-capitalized {
    text-transform: none;
  }
  .nomita-cover-subtitle-positioning {
    position: absolute;
    top: 640px;

    right: 5px;
    width: 470px;
    .larger-nomita-subtitle {
      font-size: 32px;
      font-weight: 500;
    }
    .smaller-nomita-subtitle {
      font-size: 24px;
      font-weight: 500;
      text-transform: none;
    }
  }
  .cover-photo-subtitle {
    font-size: 27px;
    font-weight: 500;
    text-transform: none;
    margin-top: 8px;
    &.subtitle-row {
      display: flex;
      justify-content: center;
      .capitalized-subtitle {
        text-transform: capitalize;
      }
    }
    &.small-cover-subtitle {
      font-size: 22px;
    }
    &.extra-mt {
      margin-top: 10px;
    }
    .divider-space {
      margin: 0px 5px;
    }
    &.annette-cover-subtext {
      margin-bottom: -170px;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-style: italic;
        font-family: "Sitka";
        font-weight: 500;
        font-style: normal;
      }
      .annette-cover-text-1 {
        font-size: 46px;
      }
      .annette-cover-text-2 {
        margin-top: 35px;
      }
      .annette-cover-text-2,
      .annette-cover-text-3 {
        font-size: 24px;
      }
    }
  }

  .cover-photo-extra-description-info {
    position: absolute;
    top: 370px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: rgba(128, 128, 128, 0.5);
    //background-color: rgba(255, 0, 0, 0.3);
  }
  .two-cover-page-area {
    font-size: 50px;
    line-height: 62px;
  }
  .three-cover-page-area {
    font-size: 50px;
    line-height: 62px;
  }
  .medium-cover-page-area-text {
    font-size: 50px;
    line-height: 62px;
  }
  .cover-description-text {
    text-transform: none;
    margin-top: 30px;
    margin-bottom: -50px;
    p {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 24px;
      line-height: 39px;
    }
  }
  .cover-page-text-container {
    color: white;
    width: 500px;
    position: absolute;
    top: 390px;
    left: 82px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &.higher-up-cover-info {
      top: 330px;
    }

    .cover-page-body {
      height: 452px;
      width: 413px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .cover-page-body-sideways-text {
        writing-mode: vertical-lr;
        text-orientation: mixed;
        transform: rotate(180deg);
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 15.5px;
        text-transform: uppercase;
        letter-spacing: -0.005em;
        &.darker-info-bg {
          //  color: black;
          //background-color: rgba(105, 105, 105, 0.6);
        }

        &.small-cp-comparison {
          font-size: 14px;
        }
        .sideways-lowercase {
          text-transform: lowercase;
        }
      }
    }
    .cover-page-body-data-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 318px;
      margin-top: 10px;
      &.darker-info-bg {
        //  color: black;
        //  background-color: rgba(105, 105, 105, 0.6);
      }
      .cover-page-body-data-section {
        margin-left: 20px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-around;
        align-items: space-around;
        flex-direction: column;
        .data-section-top-row {
          display: flex;
          align-items: flex-end;
          height: 29px;
          .data-section-data-point {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 36px;
            line-height: 17px;
            margin-right: 5px;
            .small-dollar-sign {
              font-size: 27px;
            }
          }
          .data-section-top-row-triangle {
            width: 0;
            height: 0;
            margin: 0 5px;
            &.up {
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 12px solid white;
            }
            &.down {
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 12px solid white;
            }
          }
          .data-section-top-row-balanced {
            color: white;
            font-family: mr-eaves-xl-modern, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 18px;
            margin: 20px 5px -2px 10px;
            line-height: 19px;
          }
          .data-section-top-row-perc-diff {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 20px;
            margin-bottom: -5px;
            margin-left: 5px;
          }
        }
        .data-section-bottom-row-category {
          margin-bottom: 11px;
          display: flex;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 17px;
        }
      }
    }
    &.bolded-cover-data-text {
      .cover-page-body-sideways-text,
      .cover-page-body-data-container
        .data-section-top-row
        .data-section-data-point,
      .cover-page-body
        .cover-page-body-data-section
        .data-section-top-row
        .data-section-top-row-perc-diff {
        font-weight: 600;
      }
    }
  }
  .special-bottom-cover-photo-text {
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13.5px;
    color: white;
    width: 696px;
    position: absolute;
    bottom: 65px;

    p {
      font-weight: 500;
    }
  }
  .corner-cover-photo-credit {
    font-family: objektiv-mk2, sans-serif;

    font-style: normal;
    font-size: 13.5px;
    color: white;
    width: 690px;
    position: absolute;
    bottom: 30px;

    &.scar,
    &.uhrig,
    &.mccollum,
    &.arntz,
    &.albanese {
      left: 136px;
    }

    p {
      text-align: left;
      font-weight: 500;
    }
    .bolded-cover-credit {
      font-size: 20px;
      font-weight: 500;
    }
    &.bolded-cover-credit {
      p {
        font-weight: 600;
      }
    }
    &.smaller-size-credit {
      font-size: 11px;
    }
    &.right-aligned-credit {
      p {
        text-align: right;
      }
    }
    &.medium-size-credit {
      font-size: 12.9px;
    }
    &.larger-credit-size-1 {
      font-size: 15.25px;
    }
    &.dianne-johnson-credit {
      font-size: 14.5px;
      left: 119px;
    }
    &.kaufman-credit {
      left: 137px;
    }
    &.gummow-credit-style {
      left: 42px;
      width: 750px;
    }
    &.italicized-credit {
      font-style: italic;
    }
    &.three-team-credit-style {
      left: 20px;
      border: 1px solid green;
    }
  }

  .qv4-cover-page-info-row {
    position: absolute;
    bottom: 65px;
    right: 0;
    height: 170px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    &.bottom-135-cover-page-info {
      bottom: 135px;
    }
    &.stacked-brokerage-and-photo {
      width: 65%;
      right: auto;
      margin: auto;
      display: flex;
      flex-direction: column;
      .cover-portrait-and-agent-info-row {
        display: flex;
        width: 100%;
        .stephanie-sills-cover-logo {
          width: 25%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 15px;

          margin-bottom: 0px;
          img {
            max-width: 100px;
            width: auto;
            height: auto;
          }
        }
        .qv4-cover-page-info {
          width: 75%;
          .agent-info-presented-by {
            width: 100%;
          }
          .transparent-inner-container {
            width: 100%;
            .agent-info-section {
              .agent-info-col {
                width: 100%;
              }
            }
          }
        }
      }
      .agent-info-box-logo {
        height: 100px;
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;

        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 70%;
        }
      }
    }
    &.higher-cover-info-1 {
      bottom: 165px;
    }
    &.higher-cover-info-2 {
      bottom: 130px;
    }
    &.lower-cover-info-bottom-55 {
      bottom: 55px;
    }

    .transparent-inner-container {
      display: flex;
      justify-content: flex-start;
    }
  }
  .lori-b-cover-photo-logo {
    height: 100px;
    width: 96%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: 8px;
    img {
      height: 90%;
      width: auto;
    }
  }
  .kristen-wallace-cover-qr-code {
    width: 474px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0px;
    margin-top: 20px;
    margin-left: 0px;
    .kristen-wallace-qr-container {
      max-height: 86px;
      max-width: 88px;
      overflow: hidden;
      img {
        height: 110px;
        width: 110px;
        margin: -12px 0px 0px -11px;
      }
    }
    .kristen-wallace-cover-text-container {
      margin-left: 25px;
      .kristen-wallace-cover-text {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 600;
        font-style: normal;
        color: white;
        font-size: 17px;
      }
    }
  }
  .scar-logo,
  .uhrig-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
    }
  }
  .katherine-hunt-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
    }
  }
  .uhrig-logo {
    img {
      height: 79px;
      max-width: 100px;
      width: auto;
      margin-bottom: 23px;
    }
  }
  .johnsons-qr-code {
    width: 104px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -27px;
    margin-top: 20px;
    margin-left: 5px;
    img {
      height: 79px;
      max-width: 100px;
      width: auto;
      margin-bottom: 23px;
    }
  }
  .stephanie-sills-cover-logo {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-right: 15px;
    margin-left: 20px;
    margin-bottom: 0px;
    img {
      max-width: 100px;
      width: auto;
      height: auto;
    }
  }
  .gary-palacios-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5px;
    margin-left: -10px;
    margin-top: 40px;
    img {
      max-width: 100px;
      width: auto;
      height: auto;
    }
  }
  .kristin-arntz-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5px;
    margin-left: -10px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
    }
  }
  .david-kim-cover-logo {
    width: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -50px;
    margin-left: 20px;
    img {
      max-width: 50px;
      width: auto;
      height: auto;
    }
  }

  .lisa-mccollum-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-bottom: -30px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
    }
  }

  .dawn-thomas-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 0px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
    }
  }

  .calgano-hamilton-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 5px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
    }
  }
  .global-luxury-cb-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    // margin-bottom: 5px;
    img {
      max-height: 80px;
      max-width: 80px;
      width: auto;
      height: auto;
    }
  }
  .vait-cover-info {
    width: 73%;

    margin-top: -10px;
    .vait-transparent-inner-container {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 95%;
      height: 80%;
    }
    .vait-agent-info-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 0 auto 0 -2%;
      width: 90%;

      height: 80px;
      &.team {
        height: 104px;
      }
      .vait-agent-info-col {
        width: 100%;
        height: 100%;
        margin-bottom: -5px;
        div {
          display: flex;
        }
        .vait-agent-and-phone {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .vait-designation-and-dre {
          color: white;
          display: flex;
          align-items: center;
          margin-top: 9px;
        }
      }
      .bhhs-stacked-cover-logo {
        max-height: 122px;
        // margin-top: -35px;
        margin-top: 21px;
        margin-left: -15px;
        margin-right: 30px;
        max-width: 120px;
        &.team-logo {
          margin-top: 19px;
        }
        &.douglas-specialized-logo {
          margin-top: -26px;
        }
      }
    }
    .vait-agent-name {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 19px;
      text-transform: uppercase;
      color: white;
    }
    .vait-designation {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 500;
      font-style: normal;

      font-size: 19px;
      color: white;

      &.caps {
        text-transform: uppercase;
      }
    }
    .vait-designation-span {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 500;
      font-style: normal;

      font-size: 19px;
      color: white;
      margin: 0 3px;
    }
    .vait-designation-and-dre {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 16px;
      color: white;
      display: flex;
      align-items: center;
      margin-top: 9px;
      .vait-dre-word {
      }
      .vait-dre-num {
      }
    }
    .vait-agent-phone-number {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 24px;
      color: white;
      .phone-num-row {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .phone-circle-divider {
        background-color: white;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        margin: 0px 3px 0px 3px;
      }
    }
    .vait-agent-info-box-logo {
      height: 100%;
      width: 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 80%;
      }
    }

    .bhhs-stacked-cover-logo {
      max-height: 122px;
      // margin-top: -35px;
      margin-top: -5px;
      margin-left: -15px;
      margin-right: 30px;
      max-width: 120px;
      &.team-logo {
        margin-top: 19px;
      }
      &.douglas-specialized-logo {
        margin-top: -26px;
      }
    }
  }
  .vait-cover-logo {
    width: 140px;
    height: auto;
    margin-bottom: -14px;
    margin-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 190px;
      height: auto;
      margin: auto;
    }
  }

  .qv4-cover-page-info {
    width: 100%;
    &.larger-cover-page-info-styles {
      .transparent-inner-container {
        .agent-info-section {
          .agent-info-col {
            .agent-and-phone {
              .agent-name,
              .agent-phone {
                font-size: 29px;
                .phone-num-row {
                  font-size: 29px;
                }
              }
            }
          }
        }
      }
    }
    &.team {
      height: 160px;
      bottom: 22px;
    }

    &.cover-info-three-team {
      height: 202px;
    }
    &.qv4-gummow-cover-page-info {
      width: 70%;
    }

    .transparent-inner-container {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 700px;
      height: 80%;
    }
    .phone-num-row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .phone-circle-divider {
      background-color: white;
      height: 3px;
      width: 3px;
      border-radius: 50%;
      margin: 0px 3px 0px 3px;
    }
    .agent-info-presented-by {
      width: 690px;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: white;
      margin-bottom: 10px;
      &.stephanie-sills {
        width: 694px;
      }
      &.scar,
      &.dawn-thomas {
        width: 699px;
      }
      &.uhrig {
        width: 699px;
      }

      &.three-agent-presented {
        margin-bottom: 56px;
        margin-top: -20px;
      }
      &.higher-presented-by-1 {
        width: 683px;
      }
      &.maurry-vano-presented-credit {
        margin-top: -90px;
        margin-bottom: 100px;
        font-weight: 600;
        text-transform: uppercase;
        width: 683px;
      }

      // &.albanese {
      //   width: 699px;
      // }
    }
    .agent-info-section {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 0 auto;
      width: 700px;
      height: 80px;
      &.team {
        height: 104px;
      }
      .agent-info-col,
      .bigger-bolder-agent-info-col {
        width: 74%;
        height: 100%;
        margin-bottom: -5px;
        div {
          display: flex;
        }
        .agent-and-phone {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .agent-name,
          .agent-phone,
          .phone-num-row {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 24px;
            color: white;

            &.smaller-name-and-phone {
              font-size: 22px;
            }
            &.gummow-name-and-phone,
            .phone-num-row {
              font-size: 28px;
              font-weight: 300;
            }
          }
          .vertical-divider {
            padding: 0 10px;
          }
        }
        .designation-and-dre {
          color: white;
          display: flex;
          align-items: center;
          margin-top: 9px;
          &.column-designation-dre {
            flex-direction: column;

            align-items: flex-start;
            .dre-row {
              margin-top: 2px;
            }
            .xs-agent-designation {
              font-size: 13px;
            }
          }
          &.designation-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          &.des-and-dre-col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .custom-dre-row {
              margin-top: 5px;
            }
            .mx-6 {
              margin: 1px 6px 0 6px;
              font-size: 12px;
            }
          }
          .agent-designation {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 14.5px;
            text-transform: uppercase;
            margin-right: 15px;
            letter-spacing: 0.075em;
            &.smaller-agent-designation {
              font-size: 14px;
            }
            &.capitalized-agent-designation {
              text-transform: none;
            }
            &.agent-designation-mb-10 {
              margin-bottom: 10px;
            }
          }
          .dre-word,
          .dre-num {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 12px;
            letter-spacing: 0.075em;
            text-transform: uppercase;
          }
          .name-before-dre {
            margin-right: 15px;
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
          }
        }

        .special-single-agent-under-name-text {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
          margin-top: 7px;
        }
      }
      .bigger-bolder-agent-info-col {
        .agent-and-phone {
          .agent-name {
            font-size: 34px;
            font-weight: 400;
          }
        }
        .cover-photo-tagline {
          font-size: 27px;
          margin-bottom: 10px;
          margin-top: 3px;
          font-weight: 500;
        }
        .agent-phone {
          font-size: 22px;
          font-weight: 400;
          .phone-circle-divider {
            height: 5px;
            width: 5px;
          }
        }
      }
      .team-info-col {
        margin: 0.5em 0 -1em 0;
        width: 74.5%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .team-agent-separator {
          height: 1px;
          background-color: white;
          width: 200px;
          margin: auto;
        }
        .team-agent-container:nth-child(2) {
          margin-top: 4px;
        }
        .team-agent-container {
          display: flex;
          justify-content: flex-start;
          .team-agent-col {
            width: auto;
            .team-sub-info {
              margin-top: -5px;
              display: flex;
              flex-direction: column;
              height: 31px;
              justify-content: center;
            }
          }
        }
        .team-agent-and-phone {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 31px;
          color: white;
          .phone-circle-divider {
            background-color: white;
            height: 3px;
            width: 3px;
            border-radius: 50%;
            margin: 0px 3px 0px 3px;
          }
          .team-agent-name {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 20px;
            &.larger-agent-name {
              font-size: 24px;
            }
          }
          .team-agent-phone {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 20px;
            &.larger-phone-num {
              font-size: 24px;
            }
          }
          .smaller-name-and-phone {
            font-size: 20px;
          }
          .team-vertical-divider {
            background-color: white;
            margin: 0 10px;
            font-family: mr-eaves-xl-modern, sans-serif;
            font-weight: 400;
            font-style: normal;
          }
        }
        .team-designation-and-dre {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: white;
          &.column-designation-dre {
            flex-direction: column;

            align-items: flex-start;
            height: 44px;
            margin-top: 20px;
            margin-bottom: 14px;
            .dre-row {
              margin-top: 3px;
              margin-bottom: -3px;
            }
            .xs-agent-designation {
              font-size: 13px;
            }
          }
          .designation-text {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 14.5px;
            text-transform: uppercase;
            margin-right: 15px;
            letter-spacing: 0.075em;
            //max-width: 300px;
            max-height: 27px;
            &.xs-agent-designation {
              font-size: 13px;
            }
            &.xxs-agent-designation {
              font-size: 13px;
              letter-spacing: none;
              text-transform: none;
            }
          }
          .dre-row {
            display: flex;
            align-items: flex-end;
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 19px;
            text-transform: uppercase;
            .dre-word {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 12px;
              letter-spacing: 0.075em;
              &.larger-dre-word {
                font-size: 15px;
              }
            }
            .dre-num {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 12px;
              &.larger-dre-num {
                font-size: 15px;
              }
            }
          }
        }
        .team-email {
          font-family: mr-eaves-xl-modern, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 11px;
          color: white;
        }
      }
      .maurry-cover-info-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 600px;
        margin-top: 15px;
        .maurry-cover-info-brokerage-logo {
          height: 24px;
          width: auto;
          margin-bottom: 10px;
        }
        .maurry-cover-info-agent-logo {
          height: 67px;
          width: auto;
        }
      }
    }
    .agent-info-box-logo {
      height: 100%;
      width: 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 15px;

      &.longer-agent-info-box-logo {
        width: 45%;
      }
      &.longer-agent-info-box-logo-38 {
        width: 38%;
      }
      &.agent-logo-mr {
        margin-right: 40px;
      }
      &.larger-agent-info-box-width {
        width: 33%;
      }
      &.agent-info-box-65-perc {
        width: 65%;
      }

      &.lower-cover-logo-1 {
        margin-bottom: -75px;
      }
      .cover-info-agent-photo-houy {
        margin-bottom: 20px;
        margin-top: -140px;
        height: 230px;
        width: auto;
      }
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 80%;
      }

      &.michael-noh-logo-and-portrait {
        flex-direction: column-reverse;
        margin-bottom: 72px;
        .michael-noh-cover-photo-headshot {
          width: 104px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: -20px;
          margin-top: -10px;
          margin-left: 15px;
          img {
            height: 147px;
            max-width: 145px;
            width: auto;
            margin-bottom: 23px;
          }
        }
      }
      .compass-sv-info-box {
        margin-bottom: -30px;
        max-height: 115%;
        max-width: 110%;
      }
      .cb-stacked-brokerage-logo {
        max-height: 145%;
        margin-bottom: 3px;
      }
      .alemann-brokerage-logo {
        width: auto;
        max-width: 125%;
        height: auto;
        max-height: 89%;
      }
      .intero-cover-logo {
        // height: 130%;
        width: 100%;
        height: auto;
        margin-bottom: -14px;
      }

      .cb-luxury-brokerage-logo {
        max-height: 100%;
      }
      .long-bhhs-logo {
        max-height: 135%;
        margin-top: -50px;
        max-width: 140%;
        &.team-logo {
          margin-top: 104px;
        }
        &.douglas-specialized-logo {
          margin-top: 46px;
          margin-right: -35px;
        }
      }
      .bhhs-stacked-cover-logo {
        max-height: 122px;
        margin-top: 60px;
        margin-left: -15px;
        margin-right: 30px;
        max-width: 120px;
        &.team-logo {
          margin-top: 25px;
        }
        &.douglas-specialized-logo {
          margin-top: -26px;
        }
      }
      .Horizontal_White_Compass {
        width: auto;
        max-width: 115%;
        height: auto;
        max-height: 84%;
        &.team-logo {
          max-height: 152px;
          margin-bottom: -110px;
          max-width: 205px;
        }
        &.larger-compass-logo {
          max-width: 133%;
        }
        &.raised-up-compass-logo {
          margin-bottom: 59px;
        }
      }
      .berkshire-hathaway-long-brokerage-logo {
        max-height: 135%;
        margin-top: -40px;
        max-width: 140%;
        &.team-logo {
          margin-top: 15px;
        }
      }
      .pres_circle_bronze_500x500px {
        max-width: 110%;
        max-height: 110%;
      }
      .luxury-berkshire-logo {
        max-height: 175%;
        margin-top: -40px;
        max-width: 140%;
        &.team-logo {
          margin-top: 15px;
        }
      }
      .BHHS-LUXcollection-White_500px300ppi {
        max-height: 176%;
        max-width: 123%;
        margin-right: -12px;
      }
      .logo_cbgl_white_EDIT_2_021523-01-1 {
        max-height: 123%;
        max-width: 116%;
        margin-bottom: 20px;
      }
      .above-beyond-transparent-white {
        max-width: 130%;
      }
      .village-properties-logo2 {
        max-width: 90%;
        margin-right: 13px;
        max-height: 100%;
      }
      .over-the-moon-all-white-logo {
        width: auto;
        max-width: 348px;
        height: auto;
        max-height: 100px;
      }
      .cb-gay-dales-brokerage-logo {
        max-height: 108%;
      }
    }
    &.douglas-info {
      .agent-info-section {
        height: 55px;
      }

      .team-agent-name {
        font-size: 26px;
      }
      .dre-row {
        margin-top: 4px;
      }
    }
  }
  .custom-sam-and-gina-cover-info {
    .agent-info-presented-by {
      width: 462px;
      margin-top: -30px;
      margin-bottom: 60px;
      &.sam-and-gina-custom-presented {
      }
    }

    .small-team-info-col {
      margin: 0.5em 0 -1em 0;
      width: 74.5%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .small-team-agent-separator {
        height: 1px;
        background-color: white;
        width: 200px;
        margin: auto;
      }
      .small-team-agent-container:nth-child(2) {
        margin-top: 9px;
      }
      .small-team-agent-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .small-team-headshot-container {
          height: 96px;
          width: 96px;
          margin-right: 15px;
          .small-team-headshot-img {
            height: 96px;
            width: 96px;
          }
        }
        .small-team-agent-col {
          width: auto;
          .small-team-sub-info {
            margin-top: -5px;
            display: flex;
            flex-direction: column;
            height: 31px;
            justify-content: center;
          }
        }
      }
      .small-team-agent-and-phone {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 31px;
        color: white;
        .small-phone-circle-divider {
          background-color: white;
          height: 3px;
          width: 3px;
          border-radius: 50%;
          margin: 0px 3px 0px 3px;
        }
        .small-team-agent-name {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 18px;
          letter-spacing: 0.025em;
          &.small-larger-agent-name {
            font-size: 24px;
          }
        }
        .small-team-agent-phone {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 19px;
          letter-spacing: 0.025em;
          margin-left: -7px;
          &.small-larger-phone-num {
            font-size: 24px;
          }
        }
        .small-smaller-name-and-phone {
          font-size: 20px;
        }
        .small-team-vertical-divider {
          background-color: white;
          margin: 0 10px;
          font-family: mr-eaves-xl-modern, sans-serif;
          font-weight: 400;
          font-style: normal;
        }
      }
      .small-team-designation-and-dre {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: white;
        .small-designation-text {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 13.5px;
          text-transform: uppercase;
          margin-right: 15px;
          letter-spacing: 0.04em;
          //max-width: 300px;
          max-height: 27px;
          line-height: 23px;
        }
        .small-dre-row {
          display: flex;
          align-items: flex-end;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 19px;
          text-transform: uppercase;
          .small-dre-word {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            letter-spacing: 0.025em;
            &.small-larger-dre-word {
              font-size: 15px;
            }
          }
          .small-dre-num {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            &.small-larger-dre-num {
              font-size: 15px;
            }
          }
        }
      }
      .team-email {
        font-family: mr-eaves-xl-modern, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 11px;
        color: white;
      }
    }
  }
  .gummow-qv4-cover-page-info-row {
    position: absolute;
    bottom: 92px;
    right: 0;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .gummow-cover-info-portrait {
      width: 183px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 35px;

      img {
        height: 180px;
        width: auto;
      }
    }
    .gummow-qv4-cover-page-info {
      width: 70%;
      margin-left: 10px;
      margin-right: -10px;
      &.team {
        height: 160px;
        bottom: 22px;
      }

      .gummow-transparent-inner-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 591px;
        height: 80%;
      }
      .phone-num-row {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .phone-circle-divider {
        background-color: white;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        margin: 0px 3px 0px 3px;
      }
      .gummow-agent-info-presented-by {
        width: 578px;
        margin: auto;
        display: flex;
        justify-content: flex-start;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: white;
        margin-bottom: 10px;
      }
      .gummow-agent-info-section {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin: 0 auto;
        width: 591px;
        height: 80px;
        &.team {
          height: 104px;
        }
        .gummow-agent-info-col {
          width: 74%;
          height: 100%;
          margin-bottom: -5px;
          div {
            display: flex;
          }
          .gummow-agent-and-phone {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .gummow-agent-name,
            .gummow-agent-phone,
            .gummow-phone-num-row {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 24px;
              color: white;

              &.gummow-name-and-phone,
              .phone-num-row {
                font-size: 28px;
                font-weight: 300;
              }
            }
            .vertical-divider {
              padding: 0 10px;
            }
          }
          .gummow-designation-and-dre {
            color: white;
            display: flex;
            align-items: center;
            margin-top: 9px;
            &.gummow-des-and-dre-col {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              .gummow-custom-dre-row {
                margin-top: 5px;
              }
              .gummow-mx-6 {
                margin: -3px 6px 0 6px;
                font-size: 16px;
              }
            }
            .gummow-agent-designation {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 14.5px;
              text-transform: uppercase;
              margin-right: 15px;
              letter-spacing: 0.075em;
            }
            .gummow-dre-word,
            .gummow-dre-num {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 12px;
              letter-spacing: 0.075em;
              text-transform: uppercase;
            }
          }
        }
      }
      .gummow-agent-info-box-logo {
        height: 100%;
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 15px;

        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 80%;
        }
        .cb-stacked-brokerage-logo {
          max-height: 145%;
          margin-bottom: -57px;

          margin-right: 20px;
        }

        .cb-luxury-brokerage-logo {
          max-height: 100%;
        }
      }
    }
    .transparent-inner-container {
      display: flex;
      justify-content: flex-start;
    }
  }
}
