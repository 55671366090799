// material UI light shadow
@mixin shadow {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

// material UI heavy shadow
@mixin shadow-heavy {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

// centers content in div
@mixin centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

// makes invisible
@mixin hidden {
  opacity: 0;
  pointer-events: none;
}

// dark transparent overlay on div
@mixin fade-background {
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
  }
}

@mixin clickable-pill($color) {
  transition: background $duration;
  border: 2px solid $color;

  &:not(.inactive).clickable:hover {
    background: lighten($color, 10%);
  }

  &.inactive {
    color: $dark;
    background: $transparent;
  }
}

// hover animation for lists
@mixin background-slide($color, $bg1, $bg2, $hover-color, $outline-color) {
  color: $color;
  background-image: linear-gradient(45deg, $bg1 50%, $bg2 50%);
  background-size: 250%;
  background-position: 0%;
  outline: 2px solid $outline-color;
  transition: $duration;
  cursor: pointer;

  a {
    color: $color;
    text-decoration: none;
  }

  &:hover {
    color: $hover-color;
    background-position: 100%;
    a {
      color: $hover-color
    }
  }
}

// floating label
@mixin text-input-animation($content) {
  &:after {
    content: $content;
    width: 100%;
    height: 100%;

    position: absolute;
    display: block;
    bottom: 0;
    left: 0;

    font-size: 1.3rem;
    color: $medium;
    transition: $duration;
  }

  &.filled:after {
    bottom: 100%;
    font-size: 1.1rem;
  }

  &:focus-within:after {
    bottom: 100%;
    font-size: 1.1rem;
  }
}
//small text
@mixin small {
  color: gray;
  font-size: 9px;
  margin-top: -.2em;
}
