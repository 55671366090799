@charset "UTF-8";
/* Variables

 */
@font-face {
  font-family: "usps4cb";
  src: url("usps4cb.eot");
  src: local("☺"), url("usps4cb.woff") format("woff"), url("usps4cb.ttf") format("truetype"), url("usps4cb.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sitka";
  src: url(Sitka.ttc) format("ttc");
}
.quarterly-print .print-page-container,
.quarterly-print .qv4-print-page-container,
.quarterly-print .bubble-compare-print-page-container,
.quarterly-print .mq3-print-page-container,
.quarterly-print .ye2-print-page-container,
.bubble-compare-print .print-page-container,
.bubble-compare-print .qv4-print-page-container,
.bubble-compare-print .bubble-compare-print-page-container,
.bubble-compare-print .mq3-print-page-container,
.bubble-compare-print .ye2-print-page-container,
.ye-print .print-page-container,
.ye-print .qv4-print-page-container,
.ye-print .bubble-compare-print-page-container,
.ye-print .mq3-print-page-container,
.ye-print .ye2-print-page-container {
  position: relative;
  width: 1698px;
  height: 1140px;
  margin: 0;
  background: white;
  background-size: contain;
}
.quarterly-print .print-page-container .return-address,
.quarterly-print .qv4-print-page-container .return-address,
.quarterly-print .bubble-compare-print-page-container .return-address,
.quarterly-print .mq3-print-page-container .return-address,
.quarterly-print .ye2-print-page-container .return-address,
.bubble-compare-print .print-page-container .return-address,
.bubble-compare-print .qv4-print-page-container .return-address,
.bubble-compare-print .bubble-compare-print-page-container .return-address,
.bubble-compare-print .mq3-print-page-container .return-address,
.bubble-compare-print .ye2-print-page-container .return-address,
.ye-print .print-page-container .return-address,
.ye-print .qv4-print-page-container .return-address,
.ye-print .bubble-compare-print-page-container .return-address,
.ye-print .mq3-print-page-container .return-address,
.ye-print .ye2-print-page-container .return-address {
  position: absolute;
  top: 30px;
  left: 412px;
  width: 220px;
  display: flex;
  flex-direction: column;
}
.quarterly-print .print-page-container .return-address p,
.quarterly-print .qv4-print-page-container .return-address p,
.quarterly-print .bubble-compare-print-page-container .return-address p,
.quarterly-print .mq3-print-page-container .return-address p,
.quarterly-print .ye2-print-page-container .return-address p,
.bubble-compare-print .print-page-container .return-address p,
.bubble-compare-print .qv4-print-page-container .return-address p,
.bubble-compare-print .bubble-compare-print-page-container .return-address p,
.bubble-compare-print .mq3-print-page-container .return-address p,
.bubble-compare-print .ye2-print-page-container .return-address p,
.ye-print .print-page-container .return-address p,
.ye-print .qv4-print-page-container .return-address p,
.ye-print .bubble-compare-print-page-container .return-address p,
.ye-print .mq3-print-page-container .return-address p,
.ye-print .ye2-print-page-container .return-address p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: black;
  line-height: 14px;
  letter-spacing: 0.01em;
}
.quarterly-print .print-page-container .postage,
.quarterly-print .qv4-print-page-container .postage,
.quarterly-print .bubble-compare-print-page-container .postage,
.quarterly-print .mq3-print-page-container .postage,
.quarterly-print .ye2-print-page-container .postage,
.bubble-compare-print .print-page-container .postage,
.bubble-compare-print .qv4-print-page-container .postage,
.bubble-compare-print .bubble-compare-print-page-container .postage,
.bubble-compare-print .mq3-print-page-container .postage,
.bubble-compare-print .ye2-print-page-container .postage,
.ye-print .print-page-container .postage,
.ye-print .qv4-print-page-container .postage,
.ye-print .bubble-compare-print-page-container .postage,
.ye-print .mq3-print-page-container .postage,
.ye-print .ye2-print-page-container .postage {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  right: 47px;
  width: 180px;
  align-items: flex-end;
}
.quarterly-print .print-page-container .postage p,
.quarterly-print .qv4-print-page-container .postage p,
.quarterly-print .bubble-compare-print-page-container .postage p,
.quarterly-print .mq3-print-page-container .postage p,
.quarterly-print .ye2-print-page-container .postage p,
.bubble-compare-print .print-page-container .postage p,
.bubble-compare-print .qv4-print-page-container .postage p,
.bubble-compare-print .bubble-compare-print-page-container .postage p,
.bubble-compare-print .mq3-print-page-container .postage p,
.bubble-compare-print .ye2-print-page-container .postage p,
.ye-print .print-page-container .postage p,
.ye-print .qv4-print-page-container .postage p,
.ye-print .bubble-compare-print-page-container .postage p,
.ye-print .mq3-print-page-container .postage p,
.ye-print .ye2-print-page-container .postage p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: black;
  line-height: 14px;
  letter-spacing: 0.01em;
}
.quarterly-print .print-page-container .qv4-photo-section-container,
.quarterly-print .qv4-print-page-container .qv4-photo-section-container,
.quarterly-print .bubble-compare-print-page-container .qv4-photo-section-container,
.quarterly-print .mq3-print-page-container .qv4-photo-section-container,
.quarterly-print .ye2-print-page-container .qv4-photo-section-container,
.bubble-compare-print .print-page-container .qv4-photo-section-container,
.bubble-compare-print .qv4-print-page-container .qv4-photo-section-container,
.bubble-compare-print .bubble-compare-print-page-container .qv4-photo-section-container,
.bubble-compare-print .mq3-print-page-container .qv4-photo-section-container,
.bubble-compare-print .ye2-print-page-container .qv4-photo-section-container,
.ye-print .print-page-container .qv4-photo-section-container,
.ye-print .qv4-print-page-container .qv4-photo-section-container,
.ye-print .bubble-compare-print-page-container .qv4-photo-section-container,
.ye-print .mq3-print-page-container .qv4-photo-section-container,
.ye-print .ye2-print-page-container .qv4-photo-section-container {
  position: absolute;
  top: 329px;
  left: 0px;
  width: 100%;
}
.quarterly-print .print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.quarterly-print .qv4-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.quarterly-print .bubble-compare-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.quarterly-print .mq3-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.quarterly-print .ye2-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.bubble-compare-print .print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.bubble-compare-print .qv4-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.bubble-compare-print .bubble-compare-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.bubble-compare-print .mq3-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.bubble-compare-print .ye2-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.ye-print .print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.ye-print .qv4-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.ye-print .bubble-compare-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.ye-print .mq3-print-page-container .qv4-photo-section-container .qv4-photo-section-title,
.ye-print .ye2-print-page-container .qv4-photo-section-container .qv4-photo-section-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14.5px;
  color: black;
  text-transform: uppercase;
  margin-bottom: 0px;
  height: 20px;
}
.quarterly-print .print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.quarterly-print .qv4-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.quarterly-print .bubble-compare-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.quarterly-print .mq3-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.quarterly-print .ye2-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.bubble-compare-print .print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.bubble-compare-print .qv4-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.bubble-compare-print .bubble-compare-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.bubble-compare-print .mq3-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.bubble-compare-print .ye2-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.ye-print .print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.ye-print .qv4-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.ye-print .bubble-compare-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.ye-print .mq3-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title,
.ye-print .ye2-print-page-container .qv4-photo-section-container .qv4-photo-section-title.gail-photo-section-title {
  font-weight: 500;
  margin-bottom: 4px;
}
.quarterly-print .print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.quarterly-print .qv4-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.quarterly-print .bubble-compare-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.quarterly-print .mq3-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.quarterly-print .ye2-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.bubble-compare-print .print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.bubble-compare-print .qv4-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.bubble-compare-print .bubble-compare-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.bubble-compare-print .mq3-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.bubble-compare-print .ye2-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.ye-print .print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.ye-print .qv4-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.ye-print .bubble-compare-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.ye-print .mq3-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood,
.ye-print .ye2-print-page-container .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood {
  text-transform: uppercase;
  margin-left: 6px;
  margin-bottom: -1px;
}
.quarterly-print .print-page-container .qv4-photo-section-container .mls-listings,
.quarterly-print .qv4-print-page-container .qv4-photo-section-container .mls-listings,
.quarterly-print .bubble-compare-print-page-container .qv4-photo-section-container .mls-listings,
.quarterly-print .mq3-print-page-container .qv4-photo-section-container .mls-listings,
.quarterly-print .ye2-print-page-container .qv4-photo-section-container .mls-listings,
.bubble-compare-print .print-page-container .qv4-photo-section-container .mls-listings,
.bubble-compare-print .qv4-print-page-container .qv4-photo-section-container .mls-listings,
.bubble-compare-print .bubble-compare-print-page-container .qv4-photo-section-container .mls-listings,
.bubble-compare-print .mq3-print-page-container .qv4-photo-section-container .mls-listings,
.bubble-compare-print .ye2-print-page-container .qv4-photo-section-container .mls-listings,
.ye-print .print-page-container .qv4-photo-section-container .mls-listings,
.ye-print .qv4-print-page-container .qv4-photo-section-container .mls-listings,
.ye-print .bubble-compare-print-page-container .qv4-photo-section-container .mls-listings,
.ye-print .mq3-print-page-container .qv4-photo-section-container .mls-listings,
.ye-print .ye2-print-page-container .qv4-photo-section-container .mls-listings {
  width: 100%;
  margin: 15px auto 0 auto;
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
}
.quarterly-print .print-page-container .qr-photo-section-title,
.quarterly-print .qv4-print-page-container .qr-photo-section-title,
.quarterly-print .bubble-compare-print-page-container .qr-photo-section-title,
.quarterly-print .mq3-print-page-container .qr-photo-section-title,
.quarterly-print .ye2-print-page-container .qr-photo-section-title,
.bubble-compare-print .print-page-container .qr-photo-section-title,
.bubble-compare-print .qv4-print-page-container .qr-photo-section-title,
.bubble-compare-print .bubble-compare-print-page-container .qr-photo-section-title,
.bubble-compare-print .mq3-print-page-container .qr-photo-section-title,
.bubble-compare-print .ye2-print-page-container .qr-photo-section-title,
.ye-print .print-page-container .qr-photo-section-title,
.ye-print .qv4-print-page-container .qr-photo-section-title,
.ye-print .bubble-compare-print-page-container .qr-photo-section-title,
.ye-print .mq3-print-page-container .qr-photo-section-title,
.ye-print .ye2-print-page-container .qr-photo-section-title {
  margin-top: -13px;
  margin-bottom: 13px;
  text-transform: none;
}
.quarterly-print .print-page-container .qr-photo-section-title .qr-text-new,
.quarterly-print .qv4-print-page-container .qr-photo-section-title .qr-text-new,
.quarterly-print .bubble-compare-print-page-container .qr-photo-section-title .qr-text-new,
.quarterly-print .mq3-print-page-container .qr-photo-section-title .qr-text-new,
.quarterly-print .ye2-print-page-container .qr-photo-section-title .qr-text-new,
.bubble-compare-print .print-page-container .qr-photo-section-title .qr-text-new,
.bubble-compare-print .qv4-print-page-container .qr-photo-section-title .qr-text-new,
.bubble-compare-print .bubble-compare-print-page-container .qr-photo-section-title .qr-text-new,
.bubble-compare-print .mq3-print-page-container .qr-photo-section-title .qr-text-new,
.bubble-compare-print .ye2-print-page-container .qr-photo-section-title .qr-text-new,
.ye-print .print-page-container .qr-photo-section-title .qr-text-new,
.ye-print .qv4-print-page-container .qr-photo-section-title .qr-text-new,
.ye-print .bubble-compare-print-page-container .qr-photo-section-title .qr-text-new,
.ye-print .mq3-print-page-container .qr-photo-section-title .qr-text-new,
.ye-print .ye2-print-page-container .qr-photo-section-title .qr-text-new {
  font-weight: 600;
  margin-right: 10px;
  color: rgb(0, 79, 172);
}
.quarterly-print .print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.quarterly-print .qv4-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.quarterly-print .bubble-compare-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.quarterly-print .mq3-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.quarterly-print .ye2-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.bubble-compare-print .print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.bubble-compare-print .qv4-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.bubble-compare-print .bubble-compare-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.bubble-compare-print .mq3-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.bubble-compare-print .ye2-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.ye-print .print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.ye-print .qv4-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.ye-print .bubble-compare-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.ye-print .mq3-print-page-container .qr-photo-section-title .qr-code-link-for-photos,
.ye-print .ye2-print-page-container .qr-photo-section-title .qr-code-link-for-photos {
  color: rgb(0, 79, 172);
  font-style: italic;
  font-weight: 500;
  animation: none;
}
.quarterly-print .print-page-container .qv4-mapped-listings,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings,
.bubble-compare-print .print-page-container .qv4-mapped-listings,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings,
.ye-print .print-page-container .qv4-mapped-listings,
.ye-print .qv4-print-page-container .qv4-mapped-listings,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings,
.ye-print .mq3-print-page-container .qv4-mapped-listings,
.ye-print .ye2-print-page-container .qv4-mapped-listings {
  margin: -3px auto 0 auto;
  width: 786px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 13px;
  grid-row-gap: 12px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .bold,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .bold,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .bold,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .bold,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .bold,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .bold,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .bold,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .bold,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .bold,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .bold,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .bold,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .bold,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .bold,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .bold,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .bold {
  font-weight: bold;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container {
  margin: auto;
  width: 373px;
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg {
  width: 30px;
  height: 182px;
  background-color: #a6ab93;
  color: white;
  align-self: flex-start;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 0.015em;
  font-variant: small-caps;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container .sold-by {
  font-weight: bold;
  text-align: center;
  color: rgb(0, 79, 172);
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-img-container p {
  text-align: center;
  margin-bottom: 0.1em;
  color: white;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container {
  position: relative;
  top: 0;
  left: 0;
  height: 182px;
  width: 100%;
  overflow: hidden;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img {
  height: auto;
  min-height: 191px;
  width: 100%;
  position: relative;
  top: 47%;
  transform: translateY(-50%);
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 3px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  padding-right: 4px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price {
  display: flex;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status {
  font-weight: bold;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold {
  color: rgb(184, 37, 37);
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active {
  color: #019091;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary, .quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending, .quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent {
  color: rgb(0, 79, 172);
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary {
  color: rgb(0, 34, 90);
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  margin-left: 10px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container {
  width: 97%;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row {
  width: 102.25%;
  margin-left: -0.75%;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented {
  margin-top: 0.75em;
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8.5px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml {
  margin-left: 20px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: black;
  margin-top: 3px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom {
  background-color: rgba(204, 233, 233, 0.6);
  color: black;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.smaller-guest-text {
  font-size: 8.3px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols {
  width: 97%;
  margin: 5px auto 0 auto;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child {
  width: 31%;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2),
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2) {
  width: 26%;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.ye-print .print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3),
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3) {
  width: 25%;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container {
  margin-left: 4px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row {
  flex-direction: row;
  justify-content: flex-start;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner {
  justify-content: flex-start;
  margin-left: 8px;
}
.quarterly-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.quarterly-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.quarterly-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.quarterly-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.quarterly-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.bubble-compare-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.bubble-compare-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.bubble-compare-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.bubble-compare-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.bubble-compare-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.ye-print .print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.ye-print .qv4-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.ye-print .bubble-compare-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.ye-print .mq3-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container,
.ye-print .ye2-print-page-container .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container {
  flex-direction: row-reverse;
}
.quarterly-print .print-page-container .qv4-greetings-ps,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps,
.bubble-compare-print .print-page-container .qv4-greetings-ps,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps,
.ye-print .print-page-container .qv4-greetings-ps,
.ye-print .qv4-print-page-container .qv4-greetings-ps,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps,
.ye-print .mq3-print-page-container .qv4-greetings-ps,
.ye-print .ye2-print-page-container .qv4-greetings-ps {
  position: absolute;
  top: 80px;
  right: 0px;
  width: 100%;
}
.quarterly-print .print-page-container .qv4-greetings-ps .johnsons-emoji,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .johnsons-emoji,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .johnsons-emoji,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .johnsons-emoji,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .johnsons-emoji,
.bubble-compare-print .print-page-container .qv4-greetings-ps .johnsons-emoji,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .johnsons-emoji,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .johnsons-emoji,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .johnsons-emoji,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .johnsons-emoji,
.ye-print .print-page-container .qv4-greetings-ps .johnsons-emoji,
.ye-print .qv4-print-page-container .qv4-greetings-ps .johnsons-emoji,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .johnsons-emoji,
.ye-print .mq3-print-page-container .qv4-greetings-ps .johnsons-emoji,
.ye-print .ye2-print-page-container .qv4-greetings-ps .johnsons-emoji {
  height: 20px;
  width: auto;
  margin: 0 10px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container {
  width: auto;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 26px;
  margin-left: 68px;
  color: black;
  letter-spacing: 0.025em;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text {
  font-size: 18px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text {
  font-size: 15px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle {
  padding: 3px 3px 0 0;
  margin: 6px 0px 0 0;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  text-align: right;
  line-height: 15px;
  z-index: 3;
  width: 580px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.smaller-area-styles {
  font-size: 14px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle.medium-area-styles {
  font-size: 16px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-title-container .greetings-ps-divider {
  margin-top: 1px;
  height: 1px;
  width: 580px;
  background-color: black;
  z-index: 1;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box {
  width: 96%;
  height: 180px;
  margin: 16px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .greetings-ps-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-ps-text .agentSignoff {
  font-size: 17px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box {
  width: 100%;
  margin: 10px auto 0 auto;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .greetings-ps-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .agent-signoff,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box.larger-greetings-ps-box .qv4-ps-line {
  font-size: 13.7px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text {
  margin: 0 32px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  text-justify: inter-word;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-1 {
  font-size: 16.2px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.larger-ps-text-20 {
  font-size: 20px;
  line-height: 30px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-15 {
  font-size: 14.9px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-ps-14 {
  font-size: 14px;
  line-height: 22px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .ye2-ps-line,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line {
  margin: 9px 25px 0 25px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  text-justify: inter-word;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff .johnsons-emoji,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name .johnsons-emoji,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line .johnsons-emoji,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text .johnsons-emoji {
  height: 20px;
  width: auto;
  margin: 0 10px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.smaller-note-height,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.smaller-note-height,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.smaller-note-height,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.smaller-note-height {
  font-size: 16px;
  line-height: 24px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.medium-note-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.medium-note-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.medium-note-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.medium-note-text {
  font-size: 16px;
  line-height: 24px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .signoff.large-note-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name.large-note-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line.large-note-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .greetings-ps-text.large-note-text {
  font-size: 16px;
  line-height: 24px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .qv4-ps-line {
  margin: 20px 32px 0 32px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  text-justify: inter-word;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff {
  display: flex;
  justify-content: flex-end;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  margin-right: 65px;
  margin-top: 5px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.no-mt-agent-signoff {
  margin-top: -1px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.less-mt-signoff {
  margin-top: 2px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.larger-ps-text-20 {
  font-size: 20px;
  line-height: 30px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff {
  margin-top: 18px;
  flex-direction: column;
  align-items: flex-end;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.line-above-signoff .agent-name {
  margin-top: 7px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.smaller-ps-14 {
  font-size: 14px;
  line-height: 16px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-signoff.moved-up-ps {
  margin-top: -17px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .small-note-text {
  font-size: 13px;
  line-height: 15px;
}
.quarterly-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.quarterly-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.quarterly-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.quarterly-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.quarterly-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.bubble-compare-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.bubble-compare-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.bubble-compare-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.bubble-compare-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.bubble-compare-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.ye-print .print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.ye-print .qv4-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.ye-print .bubble-compare-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.ye-print .mq3-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name,
.ye-print .ye2-print-page-container .qv4-greetings-ps .greetings-ps-box .agent-name {
  margin-left: 5px;
}
.quarterly-print .print-page-container .missing-logo,
.quarterly-print .qv4-print-page-container .missing-logo,
.quarterly-print .bubble-compare-print-page-container .missing-logo,
.quarterly-print .mq3-print-page-container .missing-logo,
.quarterly-print .ye2-print-page-container .missing-logo,
.bubble-compare-print .print-page-container .missing-logo,
.bubble-compare-print .qv4-print-page-container .missing-logo,
.bubble-compare-print .bubble-compare-print-page-container .missing-logo,
.bubble-compare-print .mq3-print-page-container .missing-logo,
.bubble-compare-print .ye2-print-page-container .missing-logo,
.ye-print .print-page-container .missing-logo,
.ye-print .qv4-print-page-container .missing-logo,
.ye-print .bubble-compare-print-page-container .missing-logo,
.ye-print .mq3-print-page-container .missing-logo,
.ye-print .ye2-print-page-container .missing-logo {
  border: 3px dotted red;
  height: 40px;
  min-width: 150px;
  max-width: 180px;
  background-color: white;
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 0.5em;
}
@font-face {
  font-family: "KeplerStdSemiboldItalic";
  src: url("Kepler_Std_Semibold_Italic.otf");
}
@font-face {
  font-family: "MrsEavesXLModOT-Reg";
  src: url("MrsEavesXLModOT-Reg.ttf");
}
.quarterly-print .print-page-container .mail-info-container-print,
.quarterly-print .qv4-print-page-container .mail-info-container-print,
.quarterly-print .bubble-compare-print-page-container .mail-info-container-print,
.quarterly-print .mq3-print-page-container .mail-info-container-print,
.quarterly-print .ye2-print-page-container .mail-info-container-print,
.bubble-compare-print .print-page-container .mail-info-container-print,
.bubble-compare-print .qv4-print-page-container .mail-info-container-print,
.bubble-compare-print .bubble-compare-print-page-container .mail-info-container-print,
.bubble-compare-print .mq3-print-page-container .mail-info-container-print,
.bubble-compare-print .ye2-print-page-container .mail-info-container-print,
.ye-print .print-page-container .mail-info-container-print,
.ye-print .qv4-print-page-container .mail-info-container-print,
.ye-print .bubble-compare-print-page-container .mail-info-container-print,
.ye-print .mq3-print-page-container .mail-info-container-print,
.ye-print .ye2-print-page-container .mail-info-container-print {
  position: absolute;
  top: 118px;
  left: 506px;
  height: auto;
  width: 370px;
}
.quarterly-print .print-page-container .disclaimer-container,
.quarterly-print .qv4-print-page-container .disclaimer-container,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container,
.quarterly-print .mq3-print-page-container .disclaimer-container,
.quarterly-print .ye2-print-page-container .disclaimer-container,
.bubble-compare-print .print-page-container .disclaimer-container,
.bubble-compare-print .qv4-print-page-container .disclaimer-container,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container,
.bubble-compare-print .mq3-print-page-container .disclaimer-container,
.bubble-compare-print .ye2-print-page-container .disclaimer-container,
.ye-print .print-page-container .disclaimer-container,
.ye-print .qv4-print-page-container .disclaimer-container,
.ye-print .bubble-compare-print-page-container .disclaimer-container,
.ye-print .mq3-print-page-container .disclaimer-container,
.ye-print .ye2-print-page-container .disclaimer-container {
  height: 38px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 216px;
  left: -95px;
  width: 392px;
}
.quarterly-print .print-page-container .disclaimer-container.team-solicitation,
.quarterly-print .qv4-print-page-container .disclaimer-container.team-solicitation,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container.team-solicitation,
.quarterly-print .mq3-print-page-container .disclaimer-container.team-solicitation,
.quarterly-print .ye2-print-page-container .disclaimer-container.team-solicitation,
.bubble-compare-print .print-page-container .disclaimer-container.team-solicitation,
.bubble-compare-print .qv4-print-page-container .disclaimer-container.team-solicitation,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container.team-solicitation,
.bubble-compare-print .mq3-print-page-container .disclaimer-container.team-solicitation,
.bubble-compare-print .ye2-print-page-container .disclaimer-container.team-solicitation,
.ye-print .print-page-container .disclaimer-container.team-solicitation,
.ye-print .qv4-print-page-container .disclaimer-container.team-solicitation,
.ye-print .bubble-compare-print-page-container .disclaimer-container.team-solicitation,
.ye-print .mq3-print-page-container .disclaimer-container.team-solicitation,
.ye-print .ye2-print-page-container .disclaimer-container.team-solicitation {
  top: 200px;
}
.quarterly-print .print-page-container .disclaimer-container.annette-disclaimer-container,
.quarterly-print .qv4-print-page-container .disclaimer-container.annette-disclaimer-container,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container.annette-disclaimer-container,
.quarterly-print .mq3-print-page-container .disclaimer-container.annette-disclaimer-container,
.quarterly-print .ye2-print-page-container .disclaimer-container.annette-disclaimer-container,
.bubble-compare-print .print-page-container .disclaimer-container.annette-disclaimer-container,
.bubble-compare-print .qv4-print-page-container .disclaimer-container.annette-disclaimer-container,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container.annette-disclaimer-container,
.bubble-compare-print .mq3-print-page-container .disclaimer-container.annette-disclaimer-container,
.bubble-compare-print .ye2-print-page-container .disclaimer-container.annette-disclaimer-container,
.ye-print .print-page-container .disclaimer-container.annette-disclaimer-container,
.ye-print .qv4-print-page-container .disclaimer-container.annette-disclaimer-container,
.ye-print .bubble-compare-print-page-container .disclaimer-container.annette-disclaimer-container,
.ye-print .mq3-print-page-container .disclaimer-container.annette-disclaimer-container,
.ye-print .ye2-print-page-container .disclaimer-container.annette-disclaimer-container {
  top: 192px;
}
.quarterly-print .print-page-container .disclaimer-container .solicitation-disclaimer,
.quarterly-print .print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .print-page-container .disclaimer-container .reliable-text,
.quarterly-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer,
.quarterly-print .qv4-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .qv4-print-page-container .disclaimer-container .reliable-text,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .reliable-text,
.quarterly-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer,
.quarterly-print .mq3-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .mq3-print-page-container .disclaimer-container .reliable-text,
.quarterly-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer,
.quarterly-print .ye2-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .ye2-print-page-container .disclaimer-container .reliable-text,
.bubble-compare-print .print-page-container .disclaimer-container .solicitation-disclaimer,
.bubble-compare-print .print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .print-page-container .disclaimer-container .reliable-text,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .reliable-text,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .reliable-text,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .reliable-text,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .reliable-text,
.ye-print .print-page-container .disclaimer-container .solicitation-disclaimer,
.ye-print .print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .print-page-container .disclaimer-container .reliable-text,
.ye-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer,
.ye-print .qv4-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .qv4-print-page-container .disclaimer-container .reliable-text,
.ye-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer,
.ye-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .bubble-compare-print-page-container .disclaimer-container .reliable-text,
.ye-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer,
.ye-print .mq3-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .mq3-print-page-container .disclaimer-container .reliable-text,
.ye-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer,
.ye-print .ye2-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .ye2-print-page-container .disclaimer-container .reliable-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 7.25px;
  letter-spacing: -0.025em;
  margin: 0px auto 0 auto;
  color: black;
}
.quarterly-print .print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.quarterly-print .print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.quarterly-print .print-page-container .disclaimer-container .reliable-text.one-trees,
.quarterly-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.quarterly-print .qv4-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.quarterly-print .qv4-print-page-container .disclaimer-container .reliable-text.one-trees,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .reliable-text.one-trees,
.quarterly-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.quarterly-print .mq3-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.quarterly-print .mq3-print-page-container .disclaimer-container .reliable-text.one-trees,
.quarterly-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.quarterly-print .ye2-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.quarterly-print .ye2-print-page-container .disclaimer-container .reliable-text.one-trees,
.bubble-compare-print .print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.bubble-compare-print .print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.bubble-compare-print .print-page-container .disclaimer-container .reliable-text.one-trees,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .reliable-text.one-trees,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .reliable-text.one-trees,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .reliable-text.one-trees,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .reliable-text.one-trees,
.ye-print .print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.ye-print .print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.ye-print .print-page-container .disclaimer-container .reliable-text.one-trees,
.ye-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.ye-print .qv4-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.ye-print .qv4-print-page-container .disclaimer-container .reliable-text.one-trees,
.ye-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.ye-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.ye-print .bubble-compare-print-page-container .disclaimer-container .reliable-text.one-trees,
.ye-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.ye-print .mq3-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.ye-print .mq3-print-page-container .disclaimer-container .reliable-text.one-trees,
.ye-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees,
.ye-print .ye2-print-page-container .disclaimer-container .cover-photo-credit.one-trees,
.ye-print .ye2-print-page-container .disclaimer-container .reliable-text.one-trees {
  font-size: 9px;
  color: black;
  margin-top: 11px;
}
.quarterly-print .print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.quarterly-print .print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.quarterly-print .print-page-container .disclaimer-container .reliable-text.one-trees p,
.quarterly-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.quarterly-print .qv4-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.quarterly-print .qv4-print-page-container .disclaimer-container .reliable-text.one-trees p,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .reliable-text.one-trees p,
.quarterly-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.quarterly-print .mq3-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.quarterly-print .mq3-print-page-container .disclaimer-container .reliable-text.one-trees p,
.quarterly-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.quarterly-print .ye2-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.quarterly-print .ye2-print-page-container .disclaimer-container .reliable-text.one-trees p,
.bubble-compare-print .print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.bubble-compare-print .print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.bubble-compare-print .print-page-container .disclaimer-container .reliable-text.one-trees p,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .reliable-text.one-trees p,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .reliable-text.one-trees p,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .reliable-text.one-trees p,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .reliable-text.one-trees p,
.ye-print .print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.ye-print .print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.ye-print .print-page-container .disclaimer-container .reliable-text.one-trees p,
.ye-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.ye-print .qv4-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.ye-print .qv4-print-page-container .disclaimer-container .reliable-text.one-trees p,
.ye-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.ye-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.ye-print .bubble-compare-print-page-container .disclaimer-container .reliable-text.one-trees p,
.ye-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.ye-print .mq3-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.ye-print .mq3-print-page-container .disclaimer-container .reliable-text.one-trees p,
.ye-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer.one-trees p,
.ye-print .ye2-print-page-container .disclaimer-container .cover-photo-credit.one-trees p,
.ye-print .ye2-print-page-container .disclaimer-container .reliable-text.one-trees p {
  text-align: center;
}
.quarterly-print .print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.quarterly-print .print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.quarterly-print .print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.quarterly-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.quarterly-print .qv4-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.quarterly-print .qv4-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.quarterly-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.quarterly-print .mq3-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.quarterly-print .mq3-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.quarterly-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.quarterly-print .ye2-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.quarterly-print .ye2-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.bubble-compare-print .print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.bubble-compare-print .print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.bubble-compare-print .print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.ye-print .print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.ye-print .print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.ye-print .print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.ye-print .qv4-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.ye-print .qv4-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.ye-print .qv4-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.ye-print .bubble-compare-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.ye-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.ye-print .bubble-compare-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.ye-print .mq3-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.ye-print .mq3-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.ye-print .mq3-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer,
.ye-print .ye2-print-page-container .disclaimer-container .solicitation-disclaimer.updated-2024-disclaimer,
.ye-print .ye2-print-page-container .disclaimer-container .cover-photo-credit.updated-2024-disclaimer,
.ye-print .ye2-print-page-container .disclaimer-container .reliable-text.updated-2024-disclaimer {
  margin-bottom: 10px;
}
.quarterly-print .print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .qv4-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .mq3-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .ye2-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .qv4-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .mq3-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .ye2-print-page-container .disclaimer-container .cover-photo-credit {
  font-size: 9.5px;
}
.quarterly-print .print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .qv4-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .mq3-print-page-container .disclaimer-container .cover-photo-credit,
.quarterly-print .ye2-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .cover-photo-credit,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .qv4-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .bubble-compare-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .mq3-print-page-container .disclaimer-container .cover-photo-credit,
.ye-print .ye2-print-page-container .disclaimer-container .cover-photo-credit {
  text-align: center;
  width: 412px;
  align-self: center;
  margin-top: 5px;
}
.quarterly-print .print-page-container .disclaimer-container .mls-disclaimer,
.quarterly-print .qv4-print-page-container .disclaimer-container .mls-disclaimer,
.quarterly-print .bubble-compare-print-page-container .disclaimer-container .mls-disclaimer,
.quarterly-print .mq3-print-page-container .disclaimer-container .mls-disclaimer,
.quarterly-print .ye2-print-page-container .disclaimer-container .mls-disclaimer,
.bubble-compare-print .print-page-container .disclaimer-container .mls-disclaimer,
.bubble-compare-print .qv4-print-page-container .disclaimer-container .mls-disclaimer,
.bubble-compare-print .bubble-compare-print-page-container .disclaimer-container .mls-disclaimer,
.bubble-compare-print .mq3-print-page-container .disclaimer-container .mls-disclaimer,
.bubble-compare-print .ye2-print-page-container .disclaimer-container .mls-disclaimer,
.ye-print .print-page-container .disclaimer-container .mls-disclaimer,
.ye-print .qv4-print-page-container .disclaimer-container .mls-disclaimer,
.ye-print .bubble-compare-print-page-container .disclaimer-container .mls-disclaimer,
.ye-print .mq3-print-page-container .disclaimer-container .mls-disclaimer,
.ye-print .ye2-print-page-container .disclaimer-container .mls-disclaimer {
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11.9px;
}
.quarterly-print .print-page-container .endorsement,
.quarterly-print .qv4-print-page-container .endorsement,
.quarterly-print .bubble-compare-print-page-container .endorsement,
.quarterly-print .mq3-print-page-container .endorsement,
.quarterly-print .ye2-print-page-container .endorsement,
.bubble-compare-print .print-page-container .endorsement,
.bubble-compare-print .qv4-print-page-container .endorsement,
.bubble-compare-print .bubble-compare-print-page-container .endorsement,
.bubble-compare-print .mq3-print-page-container .endorsement,
.bubble-compare-print .ye2-print-page-container .endorsement,
.ye-print .print-page-container .endorsement,
.ye-print .qv4-print-page-container .endorsement,
.ye-print .bubble-compare-print-page-container .endorsement,
.ye-print .mq3-print-page-container .endorsement,
.ye-print .ye2-print-page-container .endorsement {
  display: flex;
  width: auto;
  position: absolute;
  top: 58px;
  left: 0px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  color: black;
}
.quarterly-print .print-page-container .endorsement p:first-child,
.quarterly-print .qv4-print-page-container .endorsement p:first-child,
.quarterly-print .bubble-compare-print-page-container .endorsement p:first-child,
.quarterly-print .mq3-print-page-container .endorsement p:first-child,
.quarterly-print .ye2-print-page-container .endorsement p:first-child,
.bubble-compare-print .print-page-container .endorsement p:first-child,
.bubble-compare-print .qv4-print-page-container .endorsement p:first-child,
.bubble-compare-print .bubble-compare-print-page-container .endorsement p:first-child,
.bubble-compare-print .mq3-print-page-container .endorsement p:first-child,
.bubble-compare-print .ye2-print-page-container .endorsement p:first-child,
.ye-print .print-page-container .endorsement p:first-child,
.ye-print .qv4-print-page-container .endorsement p:first-child,
.ye-print .bubble-compare-print-page-container .endorsement p:first-child,
.ye-print .mq3-print-page-container .endorsement p:first-child,
.ye-print .ye2-print-page-container .endorsement p:first-child {
  margin-right: 1em;
}
.quarterly-print .print-page-container .endorsement p:nth-child(2),
.quarterly-print .qv4-print-page-container .endorsement p:nth-child(2),
.quarterly-print .bubble-compare-print-page-container .endorsement p:nth-child(2),
.quarterly-print .mq3-print-page-container .endorsement p:nth-child(2),
.quarterly-print .ye2-print-page-container .endorsement p:nth-child(2),
.bubble-compare-print .print-page-container .endorsement p:nth-child(2),
.bubble-compare-print .qv4-print-page-container .endorsement p:nth-child(2),
.bubble-compare-print .bubble-compare-print-page-container .endorsement p:nth-child(2),
.bubble-compare-print .mq3-print-page-container .endorsement p:nth-child(2),
.bubble-compare-print .ye2-print-page-container .endorsement p:nth-child(2),
.ye-print .print-page-container .endorsement p:nth-child(2),
.ye-print .qv4-print-page-container .endorsement p:nth-child(2),
.ye-print .bubble-compare-print-page-container .endorsement p:nth-child(2),
.ye-print .mq3-print-page-container .endorsement p:nth-child(2),
.ye-print .ye2-print-page-container .endorsement p:nth-child(2) {
  margin-right: 0.4em;
}
.quarterly-print .print-page-container .endorsement p:nth-child(3),
.quarterly-print .qv4-print-page-container .endorsement p:nth-child(3),
.quarterly-print .bubble-compare-print-page-container .endorsement p:nth-child(3),
.quarterly-print .mq3-print-page-container .endorsement p:nth-child(3),
.quarterly-print .ye2-print-page-container .endorsement p:nth-child(3),
.bubble-compare-print .print-page-container .endorsement p:nth-child(3),
.bubble-compare-print .qv4-print-page-container .endorsement p:nth-child(3),
.bubble-compare-print .bubble-compare-print-page-container .endorsement p:nth-child(3),
.bubble-compare-print .mq3-print-page-container .endorsement p:nth-child(3),
.bubble-compare-print .ye2-print-page-container .endorsement p:nth-child(3),
.ye-print .print-page-container .endorsement p:nth-child(3),
.ye-print .qv4-print-page-container .endorsement p:nth-child(3),
.ye-print .bubble-compare-print-page-container .endorsement p:nth-child(3),
.ye-print .mq3-print-page-container .endorsement p:nth-child(3),
.ye-print .ye2-print-page-container .endorsement p:nth-child(3) {
  margin-right: 0.4em;
}
.quarterly-print .print-page-container .endorsement p:nth-child(4),
.quarterly-print .qv4-print-page-container .endorsement p:nth-child(4),
.quarterly-print .bubble-compare-print-page-container .endorsement p:nth-child(4),
.quarterly-print .mq3-print-page-container .endorsement p:nth-child(4),
.quarterly-print .ye2-print-page-container .endorsement p:nth-child(4),
.bubble-compare-print .print-page-container .endorsement p:nth-child(4),
.bubble-compare-print .qv4-print-page-container .endorsement p:nth-child(4),
.bubble-compare-print .bubble-compare-print-page-container .endorsement p:nth-child(4),
.bubble-compare-print .mq3-print-page-container .endorsement p:nth-child(4),
.bubble-compare-print .ye2-print-page-container .endorsement p:nth-child(4),
.ye-print .print-page-container .endorsement p:nth-child(4),
.ye-print .qv4-print-page-container .endorsement p:nth-child(4),
.ye-print .bubble-compare-print-page-container .endorsement p:nth-child(4),
.ye-print .mq3-print-page-container .endorsement p:nth-child(4),
.ye-print .ye2-print-page-container .endorsement p:nth-child(4) {
  margin-right: 0.4em;
}
.quarterly-print .print-page-container .endorsement p:nth-child(5),
.quarterly-print .qv4-print-page-container .endorsement p:nth-child(5),
.quarterly-print .bubble-compare-print-page-container .endorsement p:nth-child(5),
.quarterly-print .mq3-print-page-container .endorsement p:nth-child(5),
.quarterly-print .ye2-print-page-container .endorsement p:nth-child(5),
.bubble-compare-print .print-page-container .endorsement p:nth-child(5),
.bubble-compare-print .qv4-print-page-container .endorsement p:nth-child(5),
.bubble-compare-print .bubble-compare-print-page-container .endorsement p:nth-child(5),
.bubble-compare-print .mq3-print-page-container .endorsement p:nth-child(5),
.bubble-compare-print .ye2-print-page-container .endorsement p:nth-child(5),
.ye-print .print-page-container .endorsement p:nth-child(5),
.ye-print .qv4-print-page-container .endorsement p:nth-child(5),
.ye-print .bubble-compare-print-page-container .endorsement p:nth-child(5),
.ye-print .mq3-print-page-container .endorsement p:nth-child(5),
.ye-print .ye2-print-page-container .endorsement p:nth-child(5) {
  margin-right: 0.4em;
}
.quarterly-print .print-page-container .electronic-service,
.quarterly-print .qv4-print-page-container .electronic-service,
.quarterly-print .bubble-compare-print-page-container .electronic-service,
.quarterly-print .mq3-print-page-container .electronic-service,
.quarterly-print .ye2-print-page-container .electronic-service,
.bubble-compare-print .print-page-container .electronic-service,
.bubble-compare-print .qv4-print-page-container .electronic-service,
.bubble-compare-print .bubble-compare-print-page-container .electronic-service,
.bubble-compare-print .mq3-print-page-container .electronic-service,
.bubble-compare-print .ye2-print-page-container .electronic-service,
.ye-print .print-page-container .electronic-service,
.ye-print .qv4-print-page-container .electronic-service,
.ye-print .bubble-compare-print-page-container .electronic-service,
.ye-print .mq3-print-page-container .electronic-service,
.ye-print .ye2-print-page-container .electronic-service {
  position: absolute;
  top: 0;
  left: 25px;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  letter-spacing: -0.025em;
  color: black;
}
.quarterly-print .print-page-container .barcode,
.quarterly-print .qv4-print-page-container .barcode,
.quarterly-print .bubble-compare-print-page-container .barcode,
.quarterly-print .mq3-print-page-container .barcode,
.quarterly-print .ye2-print-page-container .barcode,
.bubble-compare-print .print-page-container .barcode,
.bubble-compare-print .qv4-print-page-container .barcode,
.bubble-compare-print .bubble-compare-print-page-container .barcode,
.bubble-compare-print .mq3-print-page-container .barcode,
.bubble-compare-print .ye2-print-page-container .barcode,
.ye-print .print-page-container .barcode,
.ye-print .qv4-print-page-container .barcode,
.ye-print .bubble-compare-print-page-container .barcode,
.ye-print .mq3-print-page-container .barcode,
.ye-print .ye2-print-page-container .barcode {
  font-family: "usps4cb";
  font-size: 20px;
  height: 30px;
  position: absolute;
  top: 30px;
  left: 0px;
}
.quarterly-print .print-page-container .mq-ho-address,
.quarterly-print .qv4-print-page-container .mq-ho-address,
.quarterly-print .bubble-compare-print-page-container .mq-ho-address,
.quarterly-print .mq3-print-page-container .mq-ho-address,
.quarterly-print .ye2-print-page-container .mq-ho-address,
.bubble-compare-print .print-page-container .mq-ho-address,
.bubble-compare-print .qv4-print-page-container .mq-ho-address,
.bubble-compare-print .bubble-compare-print-page-container .mq-ho-address,
.bubble-compare-print .mq3-print-page-container .mq-ho-address,
.bubble-compare-print .ye2-print-page-container .mq-ho-address,
.ye-print .print-page-container .mq-ho-address,
.ye-print .qv4-print-page-container .mq-ho-address,
.ye-print .bubble-compare-print-page-container .mq-ho-address,
.ye-print .mq3-print-page-container .mq-ho-address,
.ye-print .ye2-print-page-container .mq-ho-address {
  position: absolute;
  top: 80px;
  left: 0px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  color: black;
}
.quarterly-print .print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.quarterly-print .qv4-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.quarterly-print .bubble-compare-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.quarterly-print .mq3-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.quarterly-print .ye2-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.bubble-compare-print .print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.bubble-compare-print .qv4-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.bubble-compare-print .bubble-compare-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.bubble-compare-print .mq3-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.bubble-compare-print .ye2-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.ye-print .print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.ye-print .qv4-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.ye-print .bubble-compare-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.ye-print .mq3-print-page-container .mq-ho-address .ho-mailing-info .ho-name,
.ye-print .ye2-print-page-container .mq-ho-address .ho-mailing-info .ho-name {
  font-weight: 600;
  font-size: 13.5px;
}
.quarterly-print .print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.quarterly-print .qv4-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.quarterly-print .bubble-compare-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.quarterly-print .mq3-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.quarterly-print .ye2-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.bubble-compare-print .print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.bubble-compare-print .qv4-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.bubble-compare-print .bubble-compare-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.bubble-compare-print .mq3-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.bubble-compare-print .ye2-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.ye-print .print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.ye-print .qv4-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.ye-print .bubble-compare-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.ye-print .mq3-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name,
.ye-print .ye2-print-page-container .mq-ho-address .ho-mailing-info .ho-second-name {
  font-weight: 600;
  font-size: 13.5px;
}
.quarterly-print .print-page-container .mq-ho-address p,
.quarterly-print .qv4-print-page-container .mq-ho-address p,
.quarterly-print .bubble-compare-print-page-container .mq-ho-address p,
.quarterly-print .mq3-print-page-container .mq-ho-address p,
.quarterly-print .ye2-print-page-container .mq-ho-address p,
.bubble-compare-print .print-page-container .mq-ho-address p,
.bubble-compare-print .qv4-print-page-container .mq-ho-address p,
.bubble-compare-print .bubble-compare-print-page-container .mq-ho-address p,
.bubble-compare-print .mq3-print-page-container .mq-ho-address p,
.bubble-compare-print .ye2-print-page-container .mq-ho-address p,
.ye-print .print-page-container .mq-ho-address p,
.ye-print .qv4-print-page-container .mq-ho-address p,
.ye-print .bubble-compare-print-page-container .mq-ho-address p,
.ye-print .mq3-print-page-container .mq-ho-address p,
.ye-print .ye2-print-page-container .mq-ho-address p {
  line-height: 22px;
}
.quarterly-print .print-page-container .mq-ho-address .row,
.quarterly-print .qv4-print-page-container .mq-ho-address .row,
.quarterly-print .bubble-compare-print-page-container .mq-ho-address .row,
.quarterly-print .mq3-print-page-container .mq-ho-address .row,
.quarterly-print .ye2-print-page-container .mq-ho-address .row,
.bubble-compare-print .print-page-container .mq-ho-address .row,
.bubble-compare-print .qv4-print-page-container .mq-ho-address .row,
.bubble-compare-print .bubble-compare-print-page-container .mq-ho-address .row,
.bubble-compare-print .mq3-print-page-container .mq-ho-address .row,
.bubble-compare-print .ye2-print-page-container .mq-ho-address .row,
.ye-print .print-page-container .mq-ho-address .row,
.ye-print .qv4-print-page-container .mq-ho-address .row,
.ye-print .bubble-compare-print-page-container .mq-ho-address .row,
.ye-print .mq3-print-page-container .mq-ho-address .row,
.ye-print .ye2-print-page-container .mq-ho-address .row {
  display: flex;
  flex-wrap: wrap;
}
.quarterly-print .print-page-container .mq-ho-address .row p:first-child,
.quarterly-print .qv4-print-page-container .mq-ho-address .row p:first-child,
.quarterly-print .bubble-compare-print-page-container .mq-ho-address .row p:first-child,
.quarterly-print .mq3-print-page-container .mq-ho-address .row p:first-child,
.quarterly-print .ye2-print-page-container .mq-ho-address .row p:first-child,
.bubble-compare-print .print-page-container .mq-ho-address .row p:first-child,
.bubble-compare-print .qv4-print-page-container .mq-ho-address .row p:first-child,
.bubble-compare-print .bubble-compare-print-page-container .mq-ho-address .row p:first-child,
.bubble-compare-print .mq3-print-page-container .mq-ho-address .row p:first-child,
.bubble-compare-print .ye2-print-page-container .mq-ho-address .row p:first-child,
.ye-print .print-page-container .mq-ho-address .row p:first-child,
.ye-print .qv4-print-page-container .mq-ho-address .row p:first-child,
.ye-print .bubble-compare-print-page-container .mq-ho-address .row p:first-child,
.ye-print .mq3-print-page-container .mq-ho-address .row p:first-child,
.ye-print .ye2-print-page-container .mq-ho-address .row p:first-child {
  margin-right: 5px;
}
.quarterly-print .print-page-container .mq-ho-address .row p:nth-child(2),
.quarterly-print .qv4-print-page-container .mq-ho-address .row p:nth-child(2),
.quarterly-print .bubble-compare-print-page-container .mq-ho-address .row p:nth-child(2),
.quarterly-print .mq3-print-page-container .mq-ho-address .row p:nth-child(2),
.quarterly-print .ye2-print-page-container .mq-ho-address .row p:nth-child(2),
.bubble-compare-print .print-page-container .mq-ho-address .row p:nth-child(2),
.bubble-compare-print .qv4-print-page-container .mq-ho-address .row p:nth-child(2),
.bubble-compare-print .bubble-compare-print-page-container .mq-ho-address .row p:nth-child(2),
.bubble-compare-print .mq3-print-page-container .mq-ho-address .row p:nth-child(2),
.bubble-compare-print .ye2-print-page-container .mq-ho-address .row p:nth-child(2),
.ye-print .print-page-container .mq-ho-address .row p:nth-child(2),
.ye-print .qv4-print-page-container .mq-ho-address .row p:nth-child(2),
.ye-print .bubble-compare-print-page-container .mq-ho-address .row p:nth-child(2),
.ye-print .mq3-print-page-container .mq-ho-address .row p:nth-child(2),
.ye-print .ye2-print-page-container .mq-ho-address .row p:nth-child(2) {
  margin-right: 5px;
}
.quarterly-print .print-page-container .breakMarkContainer,
.quarterly-print .qv4-print-page-container .breakMarkContainer,
.quarterly-print .bubble-compare-print-page-container .breakMarkContainer,
.quarterly-print .mq3-print-page-container .breakMarkContainer,
.quarterly-print .ye2-print-page-container .breakMarkContainer,
.bubble-compare-print .print-page-container .breakMarkContainer,
.bubble-compare-print .qv4-print-page-container .breakMarkContainer,
.bubble-compare-print .bubble-compare-print-page-container .breakMarkContainer,
.bubble-compare-print .mq3-print-page-container .breakMarkContainer,
.bubble-compare-print .ye2-print-page-container .breakMarkContainer,
.ye-print .print-page-container .breakMarkContainer,
.ye-print .qv4-print-page-container .breakMarkContainer,
.ye-print .bubble-compare-print-page-container .breakMarkContainer,
.ye-print .mq3-print-page-container .breakMarkContainer,
.ye-print .ye2-print-page-container .breakMarkContainer {
  display: flex;
  height: 209px;
  width: 10px;
  margin-top: 7.3em;
  margin-left: -2em;
  left: 0 !important;
}
.quarterly-print .print-page-container .breakMarkBlue,
.quarterly-print .qv4-print-page-container .breakMarkBlue,
.quarterly-print .bubble-compare-print-page-container .breakMarkBlue,
.quarterly-print .mq3-print-page-container .breakMarkBlue,
.quarterly-print .ye2-print-page-container .breakMarkBlue,
.bubble-compare-print .print-page-container .breakMarkBlue,
.bubble-compare-print .qv4-print-page-container .breakMarkBlue,
.bubble-compare-print .bubble-compare-print-page-container .breakMarkBlue,
.bubble-compare-print .mq3-print-page-container .breakMarkBlue,
.bubble-compare-print .ye2-print-page-container .breakMarkBlue,
.ye-print .print-page-container .breakMarkBlue,
.ye-print .qv4-print-page-container .breakMarkBlue,
.ye-print .bubble-compare-print-page-container .breakMarkBlue,
.ye-print .mq3-print-page-container .breakMarkBlue,
.ye-print .ye2-print-page-container .breakMarkBlue {
  background-color: #0f53a4;
}
.quarterly-print .print-page-container .breakMarkLightGreen,
.quarterly-print .qv4-print-page-container .breakMarkLightGreen,
.quarterly-print .bubble-compare-print-page-container .breakMarkLightGreen,
.quarterly-print .mq3-print-page-container .breakMarkLightGreen,
.quarterly-print .ye2-print-page-container .breakMarkLightGreen,
.bubble-compare-print .print-page-container .breakMarkLightGreen,
.bubble-compare-print .qv4-print-page-container .breakMarkLightGreen,
.bubble-compare-print .bubble-compare-print-page-container .breakMarkLightGreen,
.bubble-compare-print .mq3-print-page-container .breakMarkLightGreen,
.bubble-compare-print .ye2-print-page-container .breakMarkLightGreen,
.ye-print .print-page-container .breakMarkLightGreen,
.ye-print .qv4-print-page-container .breakMarkLightGreen,
.ye-print .bubble-compare-print-page-container .breakMarkLightGreen,
.ye-print .mq3-print-page-container .breakMarkLightGreen,
.ye-print .ye2-print-page-container .breakMarkLightGreen {
  background-color: #3fa5b9;
}
.quarterly-print .print-page-container .breakMarkHeightWithEqual,
.quarterly-print .qv4-print-page-container .breakMarkHeightWithEqual,
.quarterly-print .bubble-compare-print-page-container .breakMarkHeightWithEqual,
.quarterly-print .mq3-print-page-container .breakMarkHeightWithEqual,
.quarterly-print .ye2-print-page-container .breakMarkHeightWithEqual,
.bubble-compare-print .print-page-container .breakMarkHeightWithEqual,
.bubble-compare-print .qv4-print-page-container .breakMarkHeightWithEqual,
.bubble-compare-print .bubble-compare-print-page-container .breakMarkHeightWithEqual,
.bubble-compare-print .mq3-print-page-container .breakMarkHeightWithEqual,
.bubble-compare-print .ye2-print-page-container .breakMarkHeightWithEqual,
.ye-print .print-page-container .breakMarkHeightWithEqual,
.ye-print .qv4-print-page-container .breakMarkHeightWithEqual,
.ye-print .bubble-compare-print-page-container .breakMarkHeightWithEqual,
.ye-print .mq3-print-page-container .breakMarkHeightWithEqual,
.ye-print .ye2-print-page-container .breakMarkHeightWithEqual {
  width: 100%;
  height: 100%;
}
.quarterly-print .print-page-container .breakMarkHeightWithHalfEqual,
.quarterly-print .qv4-print-page-container .breakMarkHeightWithHalfEqual,
.quarterly-print .bubble-compare-print-page-container .breakMarkHeightWithHalfEqual,
.quarterly-print .mq3-print-page-container .breakMarkHeightWithHalfEqual,
.quarterly-print .ye2-print-page-container .breakMarkHeightWithHalfEqual,
.bubble-compare-print .print-page-container .breakMarkHeightWithHalfEqual,
.bubble-compare-print .qv4-print-page-container .breakMarkHeightWithHalfEqual,
.bubble-compare-print .bubble-compare-print-page-container .breakMarkHeightWithHalfEqual,
.bubble-compare-print .mq3-print-page-container .breakMarkHeightWithHalfEqual,
.bubble-compare-print .ye2-print-page-container .breakMarkHeightWithHalfEqual,
.ye-print .print-page-container .breakMarkHeightWithHalfEqual,
.ye-print .qv4-print-page-container .breakMarkHeightWithHalfEqual,
.ye-print .bubble-compare-print-page-container .breakMarkHeightWithHalfEqual,
.ye-print .mq3-print-page-container .breakMarkHeightWithHalfEqual,
.ye-print .ye2-print-page-container .breakMarkHeightWithHalfEqual {
  width: 100%;
  height: 50%;
}
.quarterly-print .print-page-container .flexContainer,
.quarterly-print .qv4-print-page-container .flexContainer,
.quarterly-print .bubble-compare-print-page-container .flexContainer,
.quarterly-print .mq3-print-page-container .flexContainer,
.quarterly-print .ye2-print-page-container .flexContainer,
.bubble-compare-print .print-page-container .flexContainer,
.bubble-compare-print .qv4-print-page-container .flexContainer,
.bubble-compare-print .bubble-compare-print-page-container .flexContainer,
.bubble-compare-print .mq3-print-page-container .flexContainer,
.bubble-compare-print .ye2-print-page-container .flexContainer,
.ye-print .print-page-container .flexContainer,
.ye-print .qv4-print-page-container .flexContainer,
.ye-print .bubble-compare-print-page-container .flexContainer,
.ye-print .mq3-print-page-container .flexContainer,
.ye-print .ye2-print-page-container .flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.quarterly-print .print-page-container .soldMMYY,
.quarterly-print .qv4-print-page-container .soldMMYY,
.quarterly-print .bubble-compare-print-page-container .soldMMYY,
.quarterly-print .mq3-print-page-container .soldMMYY,
.quarterly-print .ye2-print-page-container .soldMMYY,
.bubble-compare-print .print-page-container .soldMMYY,
.bubble-compare-print .qv4-print-page-container .soldMMYY,
.bubble-compare-print .bubble-compare-print-page-container .soldMMYY,
.bubble-compare-print .mq3-print-page-container .soldMMYY,
.bubble-compare-print .ye2-print-page-container .soldMMYY,
.ye-print .print-page-container .soldMMYY,
.ye-print .qv4-print-page-container .soldMMYY,
.ye-print .bubble-compare-print-page-container .soldMMYY,
.ye-print .mq3-print-page-container .soldMMYY,
.ye-print .ye2-print-page-container .soldMMYY {
  margin-left: 0px;
  color: #b82425;
}
.quarterly-print .print-page-container .noteWeightBold,
.quarterly-print .qv4-print-page-container .noteWeightBold,
.quarterly-print .bubble-compare-print-page-container .noteWeightBold,
.quarterly-print .mq3-print-page-container .noteWeightBold,
.quarterly-print .ye2-print-page-container .noteWeightBold,
.bubble-compare-print .print-page-container .noteWeightBold,
.bubble-compare-print .qv4-print-page-container .noteWeightBold,
.bubble-compare-print .bubble-compare-print-page-container .noteWeightBold,
.bubble-compare-print .mq3-print-page-container .noteWeightBold,
.bubble-compare-print .ye2-print-page-container .noteWeightBold,
.ye-print .print-page-container .noteWeightBold,
.ye-print .qv4-print-page-container .noteWeightBold,
.ye-print .bubble-compare-print-page-container .noteWeightBold,
.ye-print .mq3-print-page-container .noteWeightBold,
.ye-print .ye2-print-page-container .noteWeightBold {
  font-weight: 500 !important;
}
.quarterly-print .print-page-container .nowrapLine,
.quarterly-print .qv4-print-page-container .nowrapLine,
.quarterly-print .bubble-compare-print-page-container .nowrapLine,
.quarterly-print .mq3-print-page-container .nowrapLine,
.quarterly-print .ye2-print-page-container .nowrapLine,
.bubble-compare-print .print-page-container .nowrapLine,
.bubble-compare-print .qv4-print-page-container .nowrapLine,
.bubble-compare-print .bubble-compare-print-page-container .nowrapLine,
.bubble-compare-print .mq3-print-page-container .nowrapLine,
.bubble-compare-print .ye2-print-page-container .nowrapLine,
.ye-print .print-page-container .nowrapLine,
.ye-print .qv4-print-page-container .nowrapLine,
.ye-print .bubble-compare-print-page-container .nowrapLine,
.ye-print .mq3-print-page-container .nowrapLine,
.ye-print .ye2-print-page-container .nowrapLine {
  white-space: nowrap !important;
}
.quarterly-print .print-page-container .leftAlign,
.quarterly-print .qv4-print-page-container .leftAlign,
.quarterly-print .bubble-compare-print-page-container .leftAlign,
.quarterly-print .mq3-print-page-container .leftAlign,
.quarterly-print .ye2-print-page-container .leftAlign,
.bubble-compare-print .print-page-container .leftAlign,
.bubble-compare-print .qv4-print-page-container .leftAlign,
.bubble-compare-print .bubble-compare-print-page-container .leftAlign,
.bubble-compare-print .mq3-print-page-container .leftAlign,
.bubble-compare-print .ye2-print-page-container .leftAlign,
.ye-print .print-page-container .leftAlign,
.ye-print .qv4-print-page-container .leftAlign,
.ye-print .bubble-compare-print-page-container .leftAlign,
.ye-print .mq3-print-page-container .leftAlign,
.ye-print .ye2-print-page-container .leftAlign {
  text-align: left !important;
}
.quarterly-print .print-page-container .rightAlign,
.quarterly-print .qv4-print-page-container .rightAlign,
.quarterly-print .bubble-compare-print-page-container .rightAlign,
.quarterly-print .mq3-print-page-container .rightAlign,
.quarterly-print .ye2-print-page-container .rightAlign,
.bubble-compare-print .print-page-container .rightAlign,
.bubble-compare-print .qv4-print-page-container .rightAlign,
.bubble-compare-print .bubble-compare-print-page-container .rightAlign,
.bubble-compare-print .mq3-print-page-container .rightAlign,
.bubble-compare-print .ye2-print-page-container .rightAlign,
.ye-print .print-page-container .rightAlign,
.ye-print .qv4-print-page-container .rightAlign,
.ye-print .bubble-compare-print-page-container .rightAlign,
.ye-print .mq3-print-page-container .rightAlign,
.ye-print .ye2-print-page-container .rightAlign {
  text-align: right !important;
}
.quarterly-print .qv4-mail-info-container-print,
.bubble-compare-print .qv4-mail-info-container-print,
.ye-print .qv4-mail-info-container-print {
  position: absolute;
  top: 118px;
  left: 506px;
  height: auto;
  width: 370px;
}
.quarterly-print .disclaimer-container,
.bubble-compare-print .disclaimer-container,
.ye-print .disclaimer-container {
  height: 34px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 231px;
  left: -115px;
  width: 413px;
}
.quarterly-print .disclaimer-container .qv4-solicitation-disclaimer,
.quarterly-print .disclaimer-container .qv4-cover-photo-credit,
.quarterly-print .disclaimer-container .qv4-reliable-text,
.bubble-compare-print .disclaimer-container .qv4-solicitation-disclaimer,
.bubble-compare-print .disclaimer-container .qv4-cover-photo-credit,
.bubble-compare-print .disclaimer-container .qv4-reliable-text,
.ye-print .disclaimer-container .qv4-solicitation-disclaimer,
.ye-print .disclaimer-container .qv4-cover-photo-credit,
.ye-print .disclaimer-container .qv4-reliable-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8px;
  letter-spacing: -0.025em;
  margin-top: 3px;
  color: #53626c;
  text-align: center;
}
.quarterly-print .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.quarterly-print .disclaimer-container .qv4-cover-photo-credit.one-trees,
.quarterly-print .disclaimer-container .qv4-reliable-text.one-trees,
.bubble-compare-print .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.bubble-compare-print .disclaimer-container .qv4-cover-photo-credit.one-trees,
.bubble-compare-print .disclaimer-container .qv4-reliable-text.one-trees,
.ye-print .disclaimer-container .qv4-solicitation-disclaimer.one-trees,
.ye-print .disclaimer-container .qv4-cover-photo-credit.one-trees,
.ye-print .disclaimer-container .qv4-reliable-text.one-trees {
  font-size: 9px;
  color: #1e453e;
  margin-top: 7px;
}
.quarterly-print .disclaimer-container .qv4-cover-photo-credit,
.bubble-compare-print .disclaimer-container .qv4-cover-photo-credit,
.ye-print .disclaimer-container .qv4-cover-photo-credit {
  font-size: 9.5px;
}
.quarterly-print .disclaimer-container .cover-photo-credit,
.bubble-compare-print .disclaimer-container .cover-photo-credit,
.ye-print .disclaimer-container .cover-photo-credit {
  text-align: center;
  width: 412px;
  align-self: center;
  margin-top: 5px;
}
.quarterly-print .disclaimer-container .mls-disclaimer,
.bubble-compare-print .disclaimer-container .mls-disclaimer,
.ye-print .disclaimer-container .mls-disclaimer {
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11.9px;
}