//Photo Page at the top right
.editor-agent-logo-header {
  position: absolute;
  top: 22px;
  right: 50px;
  width: 80%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  &.reduced-agent-logo-header {
    top: 10px;
  }
  &.extra-reduced-agent-logo-header {
    top: 11px;
  }
  &.down-left-agent-logo-header {
    top: 20px;
    right: 70px;
  }
  &.less-margin-agent-logo-header {
    top: 17px;
    height: 60px;
    img {
      max-height: 55px;
      max-width: 250px;
      height: 55px;
      width: auto;
    }
  }
  &.xxl-agent-logo-header {
    max-height: 75px;
    max-width: 250px;
    height: 66px;
    width: auto;
  }

  .agent-logo-header-img {
    max-height: 60px;
    max-width: 250px;
    height: auto;
    width: auto;
    &.larger-logo {
      max-height: 65px;
      max-width: 290px;
      height: 60px;
      width: auto;
    }
    &.reduce-height-logo {
      max-height: 45px;
      height: 36px;
    }
    &.agent-logo-height-85 {
      max-height: 75px;
      max-width: 290px;
      height: 75px;
      width: auto;
    }
    &.agent-logo-height-25 {
      max-height: 20px;
      max-width: 290px;
      height: 20px;
      width: auto;
    }
    &.medium-height-logo {
      max-height: 50px;
      max-width: 290px;
      height: 45px;
      width: auto;
    }
    &.width-275-height-60 {
      max-height: 55px;
      max-width: 235px;
      height: auto;

      width: auto;
    }
    &.width-200-height-60 {
      max-height: 52px;
      max-width: 180px;
      height: auto;
      width: auto;
    }
    &.agent-logo-header-height-45 {
      max-height: 40px;
      max-width: 290px;
      height: 40px;
      width: auto;
    }
    &.m1-height-logo {
      max-height: 60px;
      max-width: 320px;
      height: 55px;
      width: auto;
    }
    &.xl-agent-logo {
      max-height: 70px;
      max-width: 250px;
      height: auto;
      width: auto;
    }
    &.xxl-agent-logo {
      max-height: 70px;
      max-width: 250px;
      height: auto;
      width: auto;
    }
    &.triple-x-agent-logo {
      max-height: 90px;
      max-width: 250px;
      height: 90px;
      width: auto;
    }
    &.reduced-width-agent-logo-header {
      max-height: 65px;
      max-width: 215px;
      height: auto;
      width: auto;
    }
  }
  .uhrig-agent-logo-header {
    max-height: 60px;
    max-width: 250px;
    height: auto;
    width: auto;
    margin-left: 10px;
  }
  .amber-russell-agent-logo-header {
    max-height: 51px;
    max-width: 220px;
    height: auto;
    width: auto;
    margin-top: -10px;
    margin-right: 20px;
  }

  .sheldon-rilliet-logo-header {
    max-height: 50px;
    max-width: 250px;
    height: auto;
    width: auto;
    margin-left: 20px;
    margin-top: -2px;
  }

  .mr-2-agent-logo-header {
    margin-right: 20px;
    margin-left: 0px;
  }
  .dianne-johnson-agent-logo-header {
    max-height: 60px;
    max-width: 330px;
    height: auto;
    width: auto;
    margin-left: 10px;
  }
  .long-agent-logo-header-v1 {
    max-height: 80px;
    max-width: 260px;
    height: auto;
    width: auto;
    margin-left: 10px;
  }
  .kristin-arntz-agent-logo-header {
    max-height: 80px;
    max-width: 300px;
    height: auto;
    width: auto;
    margin-left: 10px;
  }
  .village-prop-and-forbes-logo-header {
    max-height: 60px;
    max-width: 300px;
    height: auto;
    width: auto;
    margin-left: 10px;
  }
}
