/* Variables

 */
.midquarter-print .mq3-print-page .mq3-print-page-container {
  position: relative;
  width: 1698px;
  height: 1140px;
  margin: 0;
  background: white;
  background-size: contain;
  display: flex;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-right,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-right {
  width: 50%;
  position: relative;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-donut-label {
  position: absolute;
  left: 197px;
  top: 237px;
  width: 120px;
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice1-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice2-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice3-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice4-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice5-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice6-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice7-text {
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice1-text {
  left: 135px;
  bottom: 70px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice2-text {
  left: 137px;
  bottom: 128px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice3-text {
  left: 174px;
  bottom: 175px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice4-text {
  left: 235px;
  bottom: 192px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice5-text {
  left: 296px;
  bottom: 172px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice6-text {
  left: 331px;
  bottom: 130px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice7-text {
  left: 335px;
  bottom: 73px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice-count,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .slice-sold {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 17px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom1-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom2-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom3-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom4-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom5-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom6-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom7-text {
  position: absolute;
  width: 23px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 11px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom1-text {
  left: 177px;
  bottom: 85px;
  text-align: left;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom2-text {
  left: 181px;
  bottom: 119px;
  text-align: left;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom3-text {
  left: 207px;
  bottom: 148px;
  text-align: left;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom4-text {
  left: 238px;
  bottom: 158px;
  text-align: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom5-text {
  left: 273px;
  bottom: 148px;
  text-align: right;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom6-text {
  left: 297px;
  bottom: 119px;
  text-align: right;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .dom7-text {
  left: 299px;
  bottom: 85px;
  text-align: right;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price1-box,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price2-box,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price3-box,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price4-box,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price5-box,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price6-box,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price7-box {
  position: absolute;
  font-family: Arial;
  color: black;
  text-align: center;
  font-weight: bold;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price1-box {
  left: 35px;
  bottom: 78px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price2-box {
  left: 45px;
  bottom: 147px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price3-box {
  left: 87px;
  bottom: 200px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price4-box {
  left: 208px;
  bottom: 230px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price5-box {
  left: 330px;
  bottom: 200px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price6-box {
  left: 371px;
  bottom: 147px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price7-box {
  left: 385px;
  bottom: 78px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price7-align {
  text-align: right;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-donut-container {
  position: absolute;
  bottom: 55px;
  left: 365px;
  padding: 0 60px;
  display: inline-block;
  height: 320px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-donut-container .donut-bar-image {
  position: absolute;
  top: 90px;
  left: 126px;
  width: 248px;
  height: auto;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-donut-container .donut-header {
  width: 330px;
  align-self: center;
  position: absolute;
  top: 0px;
  left: 85px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-donut-container .donut-header .donut-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 25px;
  color: black;
  text-align: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-donut-container .donut-header .donut-subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  margin: 5px 0 15px 0px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-donut-container .donut-header .donut-subtitle .donut-subtitle-circle {
  margin: 0 5px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price-range-row {
  width: 80px;
  display: flex;
  font-size: 12px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  text-transform: uppercase;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price-range-row.price1-align, .midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price-range-row.price2-align, .midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price-range-row.price3-align {
  justify-content: flex-end;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price-range-row.price4-align {
  justify-content: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price-range-row.price5-align, .midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price-range-row.price6-align, .midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .price-range-row.price7-align {
  justify-content: flex-start;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart {
  position: absolute;
  bottom: 5px;
  left: 0px;
  height: 370px;
  width: 435px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-price-bar-chart-header {
  align-self: center;
  align-items: center;
  justify-content: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-price-bar-chart-header .avg-price-bar-chart-inner-header-content {
  width: 80%;
  margin-left: 15%;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-price-bar-chart-header .avg-price-bar-chart-inner-header-content .avg-price-bar-chart-title {
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 25px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-price-bar-chart-header .avg-price-bar-chart-inner-header-content .avg-price-bar-chart-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  text-align: right;
  margin-bottom: 15px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-price-bar-chart-header .avg-price-bar-chart-inner-header-content .avg-price-bar-chart-subtitle .avg-price-bar-chart-subtitle-circle {
  margin: 0 5px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-price-bar-chart-not-shown {
  margin-top: 10px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8.5px;
  text-align: left;
  margin-left: 20px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-price-bar-chart-not-shown p {
  text-align: left;
  color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 46px;
  margin-left: 4px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  max-width: 165px;
  min-width: 165px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-area-text {
  color: black;
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  width: auto;
  max-width: 151px;
  display: flex;
  flex-direction: column;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .shorter-bar-text-width {
  max-width: 145px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .subdivision-star {
  color: #dcb23e;
  width: 15px;
  margin-right: 5px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-data {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  width: 140px;
  margin-left: 5px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar-container {
  width: 410px;
  display: flex;
  justify-content: flex-start;
  margin: 5px 10px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar {
  background-color: blue;
  height: 8px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.color-0 {
  background-color: #a36a71;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.color-1 {
  background-color: #7d7322;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.color-2 {
  background-color: #b99e45;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.color-3 {
  background-color: #f9de9f;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.color-4 {
  background-color: #a6ab93;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.color-5 {
  background-color: #976e66;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.color-6 {
  background-color: #7b798b;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.color-7 {
  background-color: #a8a19d;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.color-8 {
  background-color: #424141;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.bar-length-0 {
  width: 170px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.bar-length-1 {
  width: 150px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.bar-length-2 {
  width: 130px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.bar-length-3 {
  width: 110px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.bar-length-4 {
  width: 90px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .qv3-avg-price-bar-chart .avg-sale-price-row .avg-sale-price-bar.bar-length-5 {
  width: 70px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .neighborhood-trends-title-container {
  position: absolute;
  top: 700px;
  left: 0;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .neighborhood-trends-title-container .neighborhood-trends-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 26px;
  color: black;
  margin-left: 50px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider {
  height: 1px;
  background-color: black;
  width: 400px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial2-container {
  position: absolute;
  top: 410px;
  left: 0px;
  z-index: 2;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial2-container .mq3-testimonial2-title-container .mq3-testimonial2-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 26px;
  color: black;
  margin-left: 50px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial2-container .mq3-testimonial2-title-container .mq3-testimonial2-title-divider {
  height: 1px;
  background-color: black;
  width: 400px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial2-container .mq3-testimonial-quotation {
  display: inline;
  font-family: alex-brush, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 146px;
  height: 5px;
  align-self: flex-start;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial2-container .mq3-testimonial-quotation.start-quote {
  line-height: 100px;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: 15px;
  transform: scale(-1, 1);
  color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial2-container .mq3-testimonial-quotation.end-quote {
  margin-bottom: -18px;
  margin-left: 10px;
  margin-right: -10px;
  align-self: flex-end;
  color: black;
  line-height: 0.1em;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial {
  height: 270px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial .testimonial-top-layer {
  width: 100%;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial .testimonial-container {
  width: 87.9%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial .testimonial-container .testimonial-content-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial .testimonial-container .testimonial-content-row .add-testimonial-text {
  color: red;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 28px;
  line-height: 25px;
  text-align: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial .testimonial-container .testimonial-text,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial .testimonial-container .testimonial-signoff {
  color: black;
  line-height: 24.5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  text-align: justify;
  text-justify: inter-word;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial .testimonial-container .larger-testimonial-mission-text {
  font-size: 21px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial .testimonial-container .medium-testimonial-mission-text {
  font-size: 17.2px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-first-left .mq3-testimonial .testimonial-container .testimonial-signoff {
  margin-top: 12px;
  text-align: right;
  align-self: flex-end;
  margin-right: 6%;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table {
  width: 100%;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 30px 0 30px 41px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: black;
  margin: -8px 8px 0 8px;
  height: 5px;
  width: 5px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 5px;
  height: 5px;
  width: 5px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: black;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 26px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 31px;
  line-height: 26px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 28px;
  line-height: 26px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 25px;
  line-height: 26px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 30px;
  line-height: 26px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .golden-divider {
  height: 1px;
  margin-top: 2px;
  margin-left: 23px;
  width: 477px;
  background-color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long {
  margin-left: 23px;
  width: 826px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 319px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 430px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 7px;
  display: flex;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-table-containers {
  margin-top: -1px;
  min-height: 500px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 552px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr th {
  font-size: 11px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr th p {
  line-height: 13px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 23px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 33px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 18.5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 64px;
  text-align: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 59px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 120px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 159px;
  text-align: left;
  font-weight: 400;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address {
  font-size: 8.5px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  width: 92px;
  font-weight: 400;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 73px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 41px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 100px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 63px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 10px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .sold-overflow-tb,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .sales-dates p {
  font-size: 8px;
  color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .sales-legend {
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 9px;
  color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 21px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-second-left-middle-section {
  height: 935px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators {
  width: 100%;
  height: 165px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: 23px;
  height: 1px;
  width: 477px;
  background-color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -4px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section {
  font-size: 13px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.2);
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.35);
  height: 50px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -8px;
  width: 100px;
  height: 1.5px;
  background-color: black;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  color: black;
  text-align: center;
  margin-right: 20px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up {
  width: 35px;
  height: 35px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down {
  width: 35px;
  height: 35px;
}
.midquarter-print .mq3-print-page .mq3-print-page-container .mq3-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: black;
  margin-bottom: -4px;
  text-align: center;
}