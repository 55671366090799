.qv4-pma-editor-page,
.mq3-pma-editor-page,
.qv3-pma-editor-page,
.qv2-pma-editor-page,
.ye2-pma-editor-page,
.bubble-compare-editor-page-container,
.mq2-editor-page-container,
.mq3-editor-page-container,
.table-quadrants-pma-editor-page {
  .qv4-first-left,
  .bubble-compare-first-left {
    width: 50%;
  }
  .gummow-listing-packages {
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    .gummow-listing-packages-title-container {
      background-color: black;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      .gummow-listing-packages-title-text {
        font-family: "objektiv-mk2", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 32px;
        letter-spacing: 0.05em;
        color: white;

        text-transform: uppercase;
      }
    }
    .gummow-listing-body {
      margin: 18px 0 18px 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .gummow-listing-body-col {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .gummow-listing-body-col-title {
          width: 90%;
          .gummow-listing-body-col-title-text {
            font-family: "objektiv-mk2", sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 17px;
            letter-spacing: 0.075em;
            text-transform: uppercase;
          }
          .gummow-listing-body-col-title-divider {
            width: 270px;
            height: 2px;
            background-color: black;
          }
        }
        .gummow-listing-packages-container {
          width: 90%;
          margin-top: 4px;
          p {
            align-self: flex-start;
            font-family: "objektiv-mk2", sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            line-height: 21.5px;
          }
          .gummow-listing-package-bolder {
            font-weight: 500;
          }
        }
      }
    }
    .gummow-listing-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e6ddd1;
      height: 136px;
      .gummow-listing-footer-inner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .gummow-listing-footer-title {
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 13px;
          letter-spacing: 0.05em;
          line-height: 19px;
        }
        .gummow-listing-footer-divider {
          height: 2px;
          width: 590px;
          background-color: black;
          margin: 12px 0;
        }
        .gummow-listing-footer-disclaimer {
          font-family: "objektiv-mk2", sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 8px;
          line-height: 13px;
        }
      }
    }
  }
  .johnsons-case-study {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .johnsons-case-study-inner-container {
      display: flex;
      flex-direction: row;

      width: 100%;
      margin: 0px 35px 75px 35px;
      .johnsons-case-study-img-col {
        width: 20%;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .johnsons-case-study-body-col {
        margin-left: 3%;
        width: 77%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .johnsons-case-study-logo {
          margin-bottom: 40px;
          img {
            width: 42px;
            height: 42px;
          }
        }
        .johnsons-case-study-title {
          display: flex;
          margin-bottom: 15px;
          .johnsons-case-study-caps-title {
            color: #76848f;
            font-family: "playfair-display", serif;
            font-weight: 400;
            font-style: normal;
            font-size: 27.5px;
            letter-spacing: 0.075em;
          }
          .johnsons-case-study-noncaps-title {
            color: #76848f;
            font-family: "playfair-display", serif;
            font-weight: 400;
            font-style: italic;
            font-size: 27.5px;
            letter-spacing: 0.01em;
            margin-left: 10px;
          }
        }
        .johnsons-case-study-body-text {
          margin-top: 20px;
          p {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 12.5px;
            line-height: 20px;
            text-align: justify;
            text-justify: inter-word;
          }
        }
        .johnsons-case-study-bolded-text {
          margin-top: 20px;
          font-weight: 500;
          letter-spacing: -0.02em;
          font-size: 11.75px;
        }
        .johnsons-case-study-bullet-points {
          margin-top: 15px;
          .johnsons-case-study-bullet-points-row {
            display: flex;
            flex-direction: row;

            .johnsons-case-study-bullet-point {
              align-self: center;
              height: 5px;
              width: 5px;
              background-color: black;
              border-radius: 50%;
              margin: 0 30px 0 0;
            }
            .johnsons-case-study-bullet-point-text {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 12px;
              line-height: 23px;
            }
            .bolded-bullet-point-text {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .annette-quadrants-image-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 500px;
    width: 100%;

    .annette-quadrants-image {
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 670px;
      width: 100%;
    }
  }
  .annette-quadrants {
    position: absolute;
    top: 345px;
    left: 0px;
    height: 424px;
    width: 100%;

    .annette-quadrants-body {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;

      // background-color: #bfbdb8;
      .annette-body-title-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 5px;
      }
      .annette-quadrants-body-title {
        font-family: playfair-display, serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.01em;
        text-transform: uppercase;

        font-size: 31px;

        color: black;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .annette-section-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 21px;

        .annette-section-row {
          display: flex;
          justify-content: space-between;

          width: 90%;
          margin: auto;
        }
      }
      .annette-quadrants-divider-section {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        height: 30px;
        width: 47%;
        .annette-quadrant-divider {
          height: 1px;
          background-color: black;
          width: 110px;
        }
      }
      .annette-quadrants-section {
        width: 47%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .annette-quadrants-section-subtitle {
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: italic;
          font-size: 19px;
          color: black;
          text-align: center;
          margin-bottom: 12px;
        }
        .annette-quadrants-section-text {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 13px;
          line-height: 20px;
          p {
            text-align: center;
          }
        }
      }
    }
    .annette-quadrants-logo-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: -189px;
      z-index: 4;
      position: relative;
      img {
        height: 90px;
        width: auto;
      }
    }
  }

  .annette-testimonial {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 260px;
    background-color: #231f20;
    .annette-testimonial-img {
      width: 100%;
      height: 100%;
    }
    .annette-testimonial-inner {
      height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .annette-testimonial-description {
        font-family: playfair-display, serif;
        font-weight: 400;
        font-style: normal;
        font-size: 23px;
        letter-spacing: 0.05em;
        color: white;
        text-align: center;
        margin-bottom: 10px;
      }
      .annette-testimonial-description-0 {
        margin-bottom: 8px;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: italic;
        font-size: 22px;
        color: white;
        letter-spacing: 0em;
        span {
          font-size: 25.5px;
          font-weight: 500;
          letter-spacing: 0.025em;
          font-style: normal;
        }
      }
      .annette-testimonial-description-1 {
        margin-top: 4px;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        color: white;
        leter-spacing: 0.05em;
      }
      .annette-testimonial-description-2 {
        margin-top: 4px;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 19px;
        letter-spacing: 0;
        color: white;
      }
      .annette-testimonial-description-3 {
        margin-top: 7px;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 500;
        font-style: italic;
        font-size: 14px;
        letter-spacing: 0;
        color: white;
      }
      .annette-testimonial-description-4 {
        margin-top: 4px;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: italic;
        font-size: 14px;
        letter-spacing: 0;
        color: white;
      }
    }
  }

  .brett-note {
    position: absolute;
    bottom: 0px;
    left: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .brett-note-inner-container {
      width: 85%;
      .brett-note-title {
        font-family: "playfair-display", serif;
        font-weight: 400;
        font-style: normal;
        font-size: 30.5px;
        letter-spacing: 0.02em;
      }
      .brett-note-text {
        font-family: "objektiv-mk2", sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-align: left;
        margin-top: 5px;
        text-align: justify;
      }
      .brett-note-text-2 {
        font-family: "objektiv-mk2", sans-serif;
        font-weight: 400;
        font-style: italic;
        font-size: 16px;
        margin-top: 5px;
        line-height: 11.5px;
      }
      .brett-note-signoff {
        font-family: "objektiv-mk2", sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 16px;
        letter-spacing: 0.02em;
        margin-top: 11px;
        text-align: right;
      }
    }
    .brett-note-img-container {
      margin-top: 10px;
      display: flex;
      .brett-note-img {
        height: auto;
        width: 72%;
        height: auto;
        margin: 25px auto 20px auto;
      }
    }
  }
  .johnsons-back-to-school {
    position: absolute;
    top: 375px;
    left: 0px;
    height: 430px;
    width: 100%;
    background-color: #e3e1d8;

    .johnsons-back-to-school-body {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      border-top: 1px solid #e3e1d8;

      // background-color: #bfbdb8;
      .johnsons-back-to-school-body-title-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 40px;
      }
      .johnsons-back-to-school-body-title {
        font-family: playfair-display, serif;
        font-weight: 400;
        font-style: normal;

        font-size: 30px;
        line-height: 32px;
        color: #0b1717;

        letter-spacing: 0.1em;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .johnsons-back-to-school-subtitle-text {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 500;
        font-style: normal;
        color: #f57e20;

        font-size: 13px;
        text-transform: uppercase;
        margin-top: 4px;
      }

      .section-container {
        // border: 1px solid red;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 13px;
        .section-row {
          display: flex;
          justify-content: space-between;
          //border: 1px solid red;
          width: 100%;
          margin: auto;
        }
      }
      .johnsons-back-to-school-section {
        width: 47%;
        //border: 1px solid yellow;
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .johnsons-back-to-school-section-title {
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          color: #0b1717;
          line-height: 21px;
          font-size: 22px;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 14px;
        }

        .johnsons-back-to-school-section-text {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 13px;
          line-height: 28px;
          .extra-mt {
            margin-top: 14px;
          }
          &.first-section {
            line-height: 32px;
          }
          &.second-section {
            line-height: 35px;
          }
          p {
            text-align: center;
          }
        }
      }
    }
    .johnsons-back-to-school-logo-container {
      display: flex;
      justify-content: center;
      width: 100%;

      margin-top: -165px;
      z-index: 4;
      position: relative;
      img {
        height: 91px;
        width: auto;
      }
    }
  }
  .johnsons-teacher-fund {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    height: 330px;
    background-color: white;

    .teacher-fund-img-container {
      margin-top: 25px;
      img {
        width: 160px;
        height: auto;
      }
    }
    .teacher-fund-title-container {
      margin-bottom: 0px;
      margin-top: 15px;
      .teacher-fund-title {
        font-family: playfair-display, serif;
        font-weight: 400;
        font-style: normal;
        color: #0b1717;
        line-height: 21px;
        font-size: 22px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 8px;
      }
    }
    .teacher-fund-body,
    .teacher-wishlist {
      color: #0b1717;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 515px;
      // p {
      font-family: objektiv-mk2, sans-serif;
      font-weight: 300;
      font-style: normal;
      line-height: 18px;
      text-align: justify;
      text-justify: inter-word;
      font-size: 11.8px;
      // }
    }
    .teacher-wishlist {
      margin-top: 16px;
    }
    .teacher-community {
      font-family: objektiv-mk2, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 10px;
      letter-spacing: 0.02em;
      text-align: center;
      margin-top: 18px;
      color: #0b1717;
    }
  }
  .johnsons-education-fund {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 700px;
    width: 100%;
    border-top: 1px solid darkgray;
    .johnsons-education-body {
      display: flex;
      flex-direction: column;
      flex-direction: space-between;
      height: 100%;
      color: black;

      .johnsons-education-top-row {
        display: flex;
        height: 35%;
        width: 94%;
        margin: auto;

        .johnsons-education-top-row-first-col {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 25%;
          height: 97%;
          .teacher-education-apple-icon {
            width: 100%;
            height: auto;
          }
          .teacher-education-qr-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .teacher-education-qr-code {
              height: 115px;
              width: 115px;
            }
            .johnsons-education-fund-subtitle-1 {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-top: 10px;
              p {
                font-family: objektiv-mk2, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 10.25px;
                line-height: 20px;
              }
            }
          }
        }
        .johnsons-education-top-row-second-col {
          display: flex;
          flex-direction: column;
          width: 70%;
          margin-left: 5%;
          // margin: 0 auto 0 2%;
          .johnsons-education-top-row-title {
            margin-top: 5px;
            p {
              font-family: playfair-display, serif;
              font-weight: 400;
              font-style: normal;
              font-size: 27px;
              letter-spacing: 0.075em;
              text-transform: uppercase;
            }
          }
          .johnsons-education-top-row-body {
            p {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 300;
              font-style: normal;
              font-size: 13px;
              line-height: 21.5px;
              text-align: justify;
              margin-top: 10px;
            }
            .supplies-link {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 600;
              font-style: normal;
              font-size: 13px;
            }
          }
        }
      }
      .johnsons-education-bottom-row {
        display: flex;
        height: 60%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .johnsons-logo-quadrants {
    position: absolute;
    top: 359px;
    left: 0px;
    height: 500px;
    width: 100%;

    .johnsons-quadrants-body {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      border-top: 1px solid #e3e1d8;

      // background-color: #bfbdb8;
      .body-title-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
      }
      .johnsons-quadrants-body-title {
        font-family: playfair-display, serif;
        font-weight: 400;
        font-style: normal;

        font-size: 32px;
        line-height: 37px;
        color: black;

        letter-spacing: 0.1em;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .johnsons-quadrants-body-subtitle-text {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 700;
        font-style: normal;
        color: #f57e20;

        font-size: 13px;
        text-transform: uppercase;
        margin-top: 15px;
        letter-spacing: 0.02em;
      }
      .johnsons-quadrants-details {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 11px;
        text-align: center;
        margin-top: 10px;
      }

      .section-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        justify-content: flex-start;
        align-items: flex-start;
        height: 200px;
        margin: 20px auto 0 auto;
        .section-row {
          display: flex;
          justify-content: center;

          width: 90%;
          height: 200px;
          margin: auto;

          .forbes-logo {
            height: auto;
            width: 130px;
          }
          .lpi-logo {
            height: auto;
            width: 170px;
          }
          .leading-logo {
            height: auto;
            width: 180px;
          }
          .mayfair-logo {
            height: auto;
            width: 170px;
          }
        }
      }
      .johnsons-quadrants-section {
        width: 47%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -30px;

        .johnsons-quadrants-section-subtitle {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 11px;
          text-transform: uppercase;
          color: #143e45;
          text-align: center;
        }
        .johnsons-quadrants-section-title {
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          color: black;
          line-height: 28px;
          font-size: 32px;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 20px;
        }

        .johnsons-quadrants-section-text {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 15px;
          line-height: 21px;

          p {
            text-align: center;
          }
        }
      }
    }
    .johnsons-quadrants-logo-container {
      display: flex;
      justify-content: center;
      width: 100%;

      margin-top: -142px;
      z-index: 4;
      position: relative;
      img {
        height: 55px;
        width: auto;
      }
    }
  }
  .johnsons-quadrants-two {
    position: absolute;
    top: 380px;
    left: 0px;
    height: 500px;
    width: 100%;

    .johnsons-quadrants-body-two {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      border-top: 1px solid #e3e1d8;

      // background-color: #bfbdb8;
      .body-title-section-two {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 17px;
      }
      .johnsons-quadrants-body-title-two {
        font-family: playfair-display, serif;
        font-weight: 400;
        font-style: normal;

        font-size: 27px;
        line-height: 30px;
        color: black;

        letter-spacing: 0.1em;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .johnsons-quadrants-body-subtitle-text-two {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 500;
        font-style: normal;
        color: #f57e20;

        font-size: 13px;
        text-transform: uppercase;
        margin-top: 13px;
      }

      .section-container-two {
        // border: 1px solid red;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        .section-row-two {
          display: flex;
          justify-content: space-between;
          //border: 1px solid red;
          width: 93%;
          margin: auto;
        }
      }
      .johnsons-quadrants-section-two {
        width: 47%;
        //border: 1px solid yellow;
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        &.last-paragraph-reduced-mt {
          margin-top: 20px;
          // border: 1px solid red;
        }

        .johnsons-quadrants-section-subtitle-two {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 600;
          font-style: normal;
          font-size: 10px;
          text-transform: uppercase;
          color: #143e45;
          text-align: center;
        }
        .johnsons-quadrants-section-title-two {
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          color: black;
          line-height: 24px;
          font-size: 25px;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 17px;
        }

        .johnsons-quadrants-section-text-two {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 300;
          font-style: normal;
          font-size: 11.25px;
          line-height: 17px;
          &.last-paragraph-high-mt {
            // border: 1px solid green;
            margin-top: 15px;
            margin-bottom: 15px;
          }
          p {
            text-align: center;
          }
        }
      }
    }
    .johnsons-quadrants-logo-container-two {
      display: flex;
      justify-content: center;
      width: 100%;

      margin-top: -180px;
      z-index: 4;
      position: relative;
      img {
        height: 55px;
        width: auto;
      }
    }
  }
  .johnsons-testimonial-two {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    height: 235px;
    background-color: #e3e1d8;
    .johnsons-testimonial-inner-two {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-top: 50px;
      padding: 0 40px;
      p {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 11px;
        letter-spacing: 0.1em;
        line-height: 18px;
        text-align: center;
        color: #143e45;
      }
      .johnsons-testimonial-two-signoff-container {
        width: 92%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .johnsons-quadrants {
    position: absolute;
    top: 380px;
    left: 0px;
    height: 500px;
    width: 100%;

    .johnsons-quadrants-body {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      border-top: 1px solid #e3e1d8;

      // background-color: #bfbdb8;
      .body-title-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 17px;
      }
      .johnsons-quadrants-body-title {
        font-family: playfair-display, serif;
        font-weight: 400;
        font-style: normal;

        font-size: 30px;
        line-height: 32px;
        color: black;

        letter-spacing: 0.1em;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .johnsons-quadrants-body-subtitle-text {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 500;
        font-style: normal;
        color: #f57e20;

        font-size: 13px;
        text-transform: uppercase;
        margin-top: 13px;
      }

      .section-container {
        // border: 1px solid red;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        .section-row {
          display: flex;
          justify-content: space-between;
          //border: 1px solid red;
          width: 90%;
          margin: auto;
        }
      }
      .johnsons-quadrants-section {
        width: 47%;
        //border: 1px solid yellow;
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        .johnsons-quadrants-section-subtitle {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 10px;
          text-transform: uppercase;
          color: #143e45;
          text-align: center;
        }
        .johnsons-quadrants-section-title {
          font-family: playfair-display, serif;
          font-weight: 400;
          font-style: normal;
          color: black;
          line-height: 24px;
          font-size: 28px;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 17px;
        }

        .johnsons-quadrants-section-text {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 12px;
          line-height: 17px;

          p {
            text-align: center;
          }
        }
      }
    }
    .johnsons-quadrants-logo-container {
      display: flex;
      justify-content: center;
      width: 100%;

      margin-top: -150px;
      z-index: 4;
      position: relative;
      img {
        height: 55px;
        width: auto;
      }
    }
  }
  .johnsons-testimonial {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    height: 200px;
    background-color: #e3e1d8;
    .johnsons-testimonial-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      padding: 0 30px;
      p {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12.8px;
        //letter-spacing: 0.2em;
        line-height: 18px;
        text-align: center;
        color: #143e45;
      }
    }
  }
  .deb-bg {
    height: 692px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #bfbdb8;
  }
  .senior-relocation-img {
    height: 691px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
  }
  .senior-relocation {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 728px;
    width: 100%;
    z-index: 2;

    .senior-relocation-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #761137;
      border-top: 4px solid #f3d3ae;
      border-bottom: 4px solid #f3d3ae;
      .senior-relocation-title-text {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 19px;
        padding: 2px 0px;
        color: #ffffff;
      }
    }
    .senior-relocation-body {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      // background-color: #bfbdb8;
      .body-title-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      .senior-relocation-body-title {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 20px;
        color: black;
        text-transform: uppercase;
        letter-spacing: 0.075em;
        margin-top: 30px;
      }
      .senior-relocation-body-subtitle-text {
        color: #761137;
        font-family: mencken-std-head, sans-serif;
        font-weight: 400;
        font-style: italic;
        font-size: 23px;
        letter-spacing: 0.025em;
        margin-top: 10px;
      }
      .senior-relocation-phone-number {
        color: black;
        font-family: mencken-std-head, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 26px;
        letter-spacing: 0.025em;
        display: flex;
        align-items: center;
        margin-top: 5px;
        .phone-dot-separator {
          height: 5px;
          width: 5px;
          background-color: black;
          border-radius: 50%;
          margin: 1px 4px 0 4px;
        }
      }
      .section-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        .section-row {
          display: flex;
          justify-content: space-between;
          width: 93%;
          margin: auto;
        }
      }
      .senior-relocation-section {
        width: 44%;
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        .senior-relocation-section-title {
          color: white;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: regular;
          font-size: 24px;
          text-transform: uppercase;
          text-align: center;
        }

        .senior-relocation-section-text {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 300;
          font-style: normal;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          p {
            text-align: center;
          }
        }
      }
    }

    .senior-relocation-logo-container {
      display: flex;
      justify-content: center;
      width: 100%;

      margin-top: -195px;
      z-index: 4;
      position: relative;
      img {
        height: 115px;
        width: auto;
      }
    }
  }
  .v2-deb-testimonial-qr {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 250px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // background-color: #bfbdb8;
    width: 100%;
    .qr-container {
      width: 23%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 100px;
        width: auto;
      }
      .sbos-link {
        font-family: mencken-std-head, sans-serif;
        font-weight: 400;
        font-style: italic;
        color: #686d6c;
        font-size: 14px;
        letter-spacing: 0.075em;
        line-height: 24px;
        // margin-top: 147px;
        // margin-right: -75px;
      }
    }
    .ds-testimonial {
      // position: absolute;
      // top: 990px;
      width: 75%;
      // width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;
      .ds-testimonial-text-container {
        margin: auto;
        width: 90%;
        .ds-testimonial-title {
          color: black;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 300;
          font-style: italic;
          font-size: 19px;
        }
        .ds-testimonial-divider {
          height: 2.66px;
          width: 492px;
          background-color: #f3d3ae;
        }
        .ds-testimonial-text {
          margin-top: 10px;
        }
        .ds-testimonial-text,
        .ds-testimonial-homeowner {
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: mencken-std-head, sans-serif;
            font-weight: 400;
            font-style: italic;
            color: #686d6c;

            font-size: 14px;
            letter-spacing: 0.075em;
            line-height: 24px;
          }
        }
        .ds-testimonial-homeowner {
          margin-top: 15px;
          align-items: center;
        }
      }
    }
  }
  .kateryna-september-2024-container {
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 635px;
    width: 100%;
    .kateryna-september-img {
      width: 100%;
      height: 100%;
    }
  }
  .kateryna-and-trish-september-2024-container {
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 700px;
    width: 100%;
    .kateryna-and-trish-september-img {
      width: 100%;
      height: 100%;
    }
  }
}
