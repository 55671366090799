/* Variables

 */
.qv2-greetings-ps {
  width: 100%;
}
.qv2-greetings-ps .greetings-ps-title-container {
  width: auto;
}
.qv2-greetings-ps .greetings-ps-title-container .greetings-ps-title {
  color: #3c4c55;
  margin-left: 68px;
  width: auto;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.16em;
}
.qv2-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text {
  font-size: 13px;
}
.qv2-greetings-ps .greetings-ps-title-container .greetings-ps-subtitle {
  padding: 3px 3px 0 0;
  margin: 6px 0px 0 0;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.01em;
  text-transform: lowercase;
  text-align: right;
  line-height: 15px;
  z-index: 3;
  width: 580px;
}
.qv2-greetings-ps .greetings-ps-title-container .greetings-ps-divider {
  margin-top: -3px;
  height: 2.333px;
  width: 580px;
  background-color: #f3d3ae;
  z-index: 1;
}
.qv2-greetings-ps .greetings-ps-box {
  width: 90%;
  height: 170px;
  margin: 5px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.qv2-greetings-ps .greetings-ps-box .greetings-ps-text {
  margin: 0 25px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: justify;
  text-justify: inter-word;
}
.qv2-greetings-ps .greetings-ps-box .qv2-ps-line {
  margin: 20px 25px 0 25px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: justify;
  text-justify: inter-word;
}
.qv2-greetings-ps .greetings-ps-box .agent-signoff {
  display: flex;
  justify-content: flex-end;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 21px;
  margin-right: 86px;
}
.qv2-greetings-ps .greetings-ps-box .agent-name {
  margin-left: 5px;
}

.qv2-pma-editor-page .qv2-pma-editor-page-container {
  width: 1365px;
  height: 1070px;
  margin: 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv2-first-left,
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv2-first-right,
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv2-second-left,
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv2-second-right {
  width: 50%;
  position: relative;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv2-first-left .neighborhood-trends-title-container {
  position: absolute;
  top: 645px;
  left: 0;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv2-first-left .neighborhood-trends-title-container .neighborhood-trends-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 23px;
  color: black;
  margin-left: 40px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv2-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider {
  height: 1px;
  background-color: black;
  width: 360px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left {
  width: 50%;
  position: relative;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .dashboard-bg {
  position: absolute;
  margin: auto;
  left: 5%;
  right: 5%;
  top: 9.6%;
  width: 90%;
  height: 849px;
  z-index: 0;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 70px;
  margin-top: 69px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-header .db-header-main {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: 0.3em;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body {
  position: absolute;
  top: 133px;
  width: 100%;
  height: 910px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-body-header {
  width: 68%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px auto 5px auto;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-body-header .db-body-header-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  text-align: center;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-body-subheader {
  width: 280px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box {
  right: 0px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider {
  right: 0px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation {
  width: 85%;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container {
  width: 85%;
  margin-top: 10px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box {
  left: 0px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider {
  left: 0px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation {
  width: 85%;
  text-align: right;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container {
  width: 85%;
  margin-top: 10px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage {
  display: flex;
  justify-content: flex-end;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container {
  clear: both;
  display: inline-block;
  width: 100%;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator {
  height: 189px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .keep-hyphen {
  white-space: nowrap;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 1.3333px;
  width: 512px;
  position: absolute;
  top: 48.5px;
  z-index: 3;
  background-color: black;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
  height: 210px;
  z-index: 1;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .indicator-header-num {
  width: 70px;
  color: #fff2c4;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 42px;
  line-height: 28px;
  margin-top: -196px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 625px;
  height: 82%;
  position: absolute;
  z-index: 2;
  background-color: rgba(241, 251, 246, 0.9);
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .db-box-inner-container {
  width: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .db-box-inner-container.box-inner-odd {
  margin-left: 45px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .db-box-inner-container.box-inner-even {
  margin-left: -15px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
  height: 40px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  color: #3e71a0;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  line-height: 37px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  color: rgb(47.7567567568, 87.0405405405, 123.2432432432);
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 11px;
  line-height: 17px;
  text-align: justify;
  text-justify: inter-word;
  margin-left: 4px;
  display: inline-block;
  float: right;
  letter-spacing: 0.025em;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage {
  margin-top: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12.8px;
  line-height: 15px;
  text-align: justify;
  text-justify: inter-word;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong {
  font-weight: normal !important;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: 19px;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-weight: 700;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-bottom-line .db-bottom-line-text,
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 16.5px;
  line-height: 18px;
  letter-spacing: 0.075em;
  color: #636d7b;
  width: 90%;
  margin: auto;
}
.qv2-pma-editor-page .qv2-pma-editor-page-container .qv3-second-left .db-body .db-bottom-line .db-signoff {
  display: inline-block;
  float: right;
  margin-right: 30px;
  width: auto;
}