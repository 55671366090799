.start-container {
  margin: auto;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  .start-inner-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .new-agent-title {
      font-size: 35px;
    }
    .start-button-container {
      display: flex;

      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      width: 30%;
    }
  }
}

.calendar {
  minwidth: 320px;
  height: 700px;
}
.schedule-demo {
}
