/* Variables

 */

// COLORS
$primary: rgb(0, 79, 172);
$primary-light: lighten($primary, 40%);
$primary-lighter: rgb(26, 167, 255);
$primary-medium: #7A91B4;

$gray-lightest: rgb(240,240,241);

$secondary: rgb(0, 34, 90);
$secondary-light: lighten($secondary, 10%);
$secondary-border: rgb(21, 73,113);
$secondary-title: #2E3F99;
$secondary-darker: #0F2944;
$medium-blue: #17548E;

$aqua: #3C9193;
$aqua-green: #DCEEEE;
$dark-navy: rgb(9,34,76);
$tertiary: rgb(255, 166, 38);

$active: rgb(56, 122, 9);
$active-light: rgb(143, 208, 70);
$active-lightest: #E4F0E5;
$listing: rgb(102, 205, 0);
$limegreen: rgb(142, 194, 121); //used on graph
$pending: rgb(232, 117, 52);
$sold: rgb(184, 37, 37);
$blood-red: rgb(153,23,31);
$dark-sold: #b71c1c;
$light-red: #e57373;
$cool-blue: rgb(20,66,124);
$team-member: rgb(237, 115, 247);
$dark: rgb(45, 45, 45);
$medium: #172B4D;//rgb(102, 102, 102);
$light: rgb(212, 212, 212);
$light-light: rgb(240, 240, 240);

$transparent: rgba(0, 0, 0, 0);
$modalBg: rgb(194, 199, 206);
$warning: rgb(220, 20, 60);

$bar-red: #AC2628;
$silver: #454646;
$silver-dark: darken($silver, 10%);

$silver-bullet: #939395;
$silver-banner: #76848F;

$beige: #F2F3F4;
// Z-INDEX
$full-nav: 500;
$modal: 400;
$modal-overlay: 300;
$above-overlay: 100;
$negative: -100;

// TRANSITIONS
$duration-fast: .1s;
$duration: .3s;
$duration-slow: .5s;

// PMA RATIO
$pma-ratio: calc(11 / 17);

//Theme Colors
$MailPro-Green-Main: #85cdc5;
$MailPro-Green-Light: #ecfbfb;

//Latest Primary Medium Blue
$MediumBlue: #4A7396
