//Cover Page/Front Page
.editor-qv4-cover-page-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  // z-index: -2;
  &.dim {
    filter: brightness(50%);
  }
  .cover-page-bg-photo {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-size: cover;
    //background-size: auto;
    background-repeat: round;
    // z-index: -1;
    &.full-photo {
      background-size: 100% 100%;
    }
  }
  .dwyer-bottom-cover-disclaimer-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
    .dwyer-bottom-cover-disclaimer-text {
      font-size: 12px;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 300;
      font-style: normal;
    }
  }
  .cover-page-color-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    // z-index: 2;
  }
  .cover-page-edition {
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
    color: white;
    text-transform: uppercase;
    align-self: flex-end;
    position: absolute;
    top: 47px;
    right: 56px;
    &.left-justified-cover-date {
      left: 56px;
    }
    .cover-page-small-caps {
      text-transform: lowercase;
      font-variant: small-caps;
    }
  }
  .special-year-end-edition {
    position: absolute;
    top: 55px;
    right: 56px;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }
  .amin-cover-monogram {
    position: absolute;
    top: 47px;
    right: 76px;
    img {
      height: 70px;
      width: auto;
    }
  }

  .special-vait-above-cover-title {
    position: absolute;
    top: 37px;

    // font-family: "Sitka";

    .vait-logo-container {
      display: flex;
      justify-content: center;
      img {
        height: 100px;
        width: auto;
      }
    }
    .vait-presents-container {
      display: flex;
      justify-content: center;
      .vait-presents-text {
        font-family: "playfair-display", serif;
        font-weight: 400;
        font-style: italic;
        //
        font-size: 24px;
        text-align: center;
      }
    }
  }
  .matt-t-cover-logo-container {
    position: absolute;
    top: 25px;
    left: 22px;
    z-index: 2;

    .matt-t-cover-logo-img {
      height: auto;
      width: auto;
      max-height: 105px;
    }
  }
  .special-kj-team-logo-container {
    position: absolute;
    top: 35px;
    left: 60px;
    z-index: 2;
    .kj-team-logo-img {
      height: auto;
      width: auto;
      max-height: 70px;
    }
  }
  .special-kazemi-team-photo-container {
    position: absolute;
    top: 52px;
    left: 90px;
    z-index: 2;
    .kazemi-team-photo-img {
      height: auto;
      width: auto;
      max-height: 165px;
    }
  }
  .cover-page-title-container {
    width: 92%;
    max-width: 92%;
    position: absolute;
    top: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 330px;
    .high-patio-text-1 {
      margin-top: -120px;
    }
    .black-patio-text-on-wall {
      margin-top: 80px;
      color: black;
    }
    .dwyer-cover-text-margin {
      margin-top: -20px;
      div {
        //color: black;
      }
    }
    .galli-cover-text-margin {
      margin-top: -46px;
    }
    .cover-text-top-100 {
      margin-top: -120px;
    }
    .high-cover-text-127 {
      margin-top: -189px;
    }

    .double-line-high-patio-text {
      margin-top: -189px;
      .cover-photo-subtitle {
        margin-top: 15px;
      }
    }
    &.sideways-title-container {
      right: 70px;
      top: 240px;
      height: 80px;
      max-width: 395px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      .sideways-cover-text {
        font-size: 27px;
        line-height: 32px;
      }
    }
    &.top-left-cover-info {
      top: 37px;
      width: 500px;
      height: auto;
      left: 60px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .qv4-subdivision-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .subdivision-title {
          font-size: 33px;
          line-height: 48px;
        }
      }
    }
    &.higher-cover-text-20-top {
      top: 5px;
    }
    &.higher-cover-text-40-top {
      top: 20px;
    }
    &.lower-cover-text-240 {
      top: 220px;
      height: 140px;
    }
    &.lower-cover-text-375 {
      top: 345px;
      height: 160px;
    }
    &.vait-width {
      width: 100%;
      max-width: 100%;
      margin-top: -106px;
    }
    &.small-title-height {
      // height: 189px;
      margin-top: 40px;
    }
  }
  .cover-page-city-area {
    width: auto;
    max-width: 90%;
    text-align: center;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 200;
    font-style: normal;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    flex-wrap: wrap;
    > div {
      font-weight: 300;
    }
    .cover-photo-subtitle {
      font-weight: 500;
    }
  }
  .cover-photo-line-separator {
    height: 2px;
    background-color: white;
    margin: 12px auto 12px auto;
    width: 300px;
  }

  .dwyer-above-cover-container {
    height: 40px;
    margin: 36px auto 20px auto;
    &.black-text-dwyer-cover {
      div,
      p {
        //  color: black;
        font-weight: 400;
      }
    }
    .dwyer-above-cover-text {
      font-family: objektiv-mk2, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 28px;
      text-transform: none;
      &.smaller-dwyer-text {
        font-size: 20px;
      }
    }
  }
  .dwyer-below-cover-container {
    height: 40px;
    margin: 20px auto 0px auto;
    &.black-text-dwyer-cover {
      div,
      p {
        //  color: black;
        font-weight: 400;
      }
    }
    .dwyer-below-cover-text {
      font-family: objektiv-mk2, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 17px;
      text-transform: none;
      line-height: 24px;
    }
  }

  .qv4-subdivision-container {
    .capitalized-title {
      text-transform: uppercase;
      font-size: 58px;
    }
    .subdivision-title {
      font-size: 40px;
      line-height: 65px;
      &.cover-page-area-text-37 {
        font-size: 30px;
      }
      &.bolded-cover-photo-text {
        font-weight: 400;
      }
    }
    .cover-page-text-font-39 {
      font-size: 31px;
      line-height: 45px;
    }
    .subdivision-title-single-line {
      font-size: 30px;
      line-height: 65px;
    }
    .smaller-city-title {
      font-size: 33px;
      margin-top: -105px;
      margin-bottom: 24px;
    }
    &.higher-cover-page-text {
      margin-top: -160px;
      .subdivision-title {
        font-size: 33px;
        line-height: 47px;
      }
    }
    &.higher-cover-page-text-2 {
      margin-top: -170px;
      div {
        line-height: 54px;
      }
    }

    .subdivision-subtitle-container {
      display: flex;
      font-size: 24px;
      justify-content: flex-end;
      align-self: flex-end;
      text-align: right;
      .including-subtitle {
        font-style: italic;
        margin-right: 7px;
        text-transform: none;
      }
    }
  }

  .vait-cover-title-container {
    width: 100%;
    max-width: 100%;
    margin-top: 145px;

    .vait-cover-title {
      font-family: "Sitka";
      font-size: 29px;
      text-transform: capitalize;
    }
    .vait-market-title {
      font-family: "Sitka";
      font-size: 29px;
      text-transform: capitalize;
      margin-top: 5px;
    }
  }
  .gummow-brothers-above-cover-logo {
    margin: -60px auto 40px auto;

    img {
      height: 54px;
      width: auto;
      margin-top: -121px;
      margin-bottom: 100px;
    }
  }
  .one-cover-page-area {
    font-size: 40px;
    line-height: 65px;
    &.lower-cover-page-top-190 {
      margin-top: 180px;
    }
    &.larger-one-cover-area {
      font-size: 65px;
      font-weight: 200;
    }
    &.xxs-cover-page-area-text {
      font-size: 35px;
    }

    &.higher-cover-page-text {
      margin-top: -100px;
    }
    &.cover-page-area-text-32 {
      font-size: 28px;
      line-height: 47px;
    }
    &.bolded-cover-photo-text {
      font-weight: 400;
    }
    &.cover-page-text-40 {
      font-size: 32px;
    }
    &.monte-sereno-higher-cover-text {
      margin-top: 15px;
    }
    &.bolded-cover-photo-text {
      font-weight: 400;
    }
    &.cover-page-area-text-47 {
      font-size: 38px;
    }
    &.lower-cover-page-text-255 {
      margin-top: 195px;
    }
  }
  .area-text-capitalized {
    text-transform: none;
  }
  .nomita-cover-subtitle-positioning {
    position: absolute;
    top: 590px;

    right: 5px;
    width: 370px;

    .larger-nomita-subtitle {
      font-size: 27px;
      font-weight: 500;
    }
    .smaller-nomita-subtitle {
      font-size: 19px;
      font-weight: 500;
      text-transform: none;
    }
  }
  .cover-photo-subtitle {
    font-size: 24px;
    font-weight: 400;
    font-weight: 500;
    text-transform: none;
    margin-top: 5px;
    &.subtitle-row {
      display: flex;
      justify-content: center;
      .capitalized-subtitle {
        text-transform: capitalize;
      }
    }
    &.small-cover-subtitle {
      font-size: 18px;
    }
    &.extra-mt {
      margin-top: 10px;
    }
    .divider-space {
      margin: 0px 5px;
    }
    &.annette-cover-subtext {
      margin-bottom: -40px;
      margin-top: 10px;
      font-size: 14px;
      font-style: italic;
    }
    &.annette-cover-subtext {
      margin-bottom: -170px;
      margin-top: 37px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-style: italic;
        font-family: "Sitka";
        font-weight: 500;
        font-style: normal;
      }
      .annette-cover-text-1 {
        font-size: 43px;
      }
      .annette-cover-text-2 {
        margin-top: 26px;
      }
      .annette-cover-text-2,
      .annette-cover-text-3 {
        font-size: 21px;
      }
    }
  }
  .cover-photo-extra-description-info {
    position: absolute;
    top: 370px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: rgba(128, 128, 128, 0.5);
    //background-color: rgba(255, 0, 0, 0.3);
  }
  .two-cover-page-area {
    font-size: 40px;
    line-height: 65px;
  }
  .three-cover-page-area {
    font-size: 40px;
    line-height: 65px;
  }
  .medium-cover-page-area-text {
    font-size: 40px;
    line-height: 65px;
  }

  .cover-description-text {
    text-transform: none;
    margin-top: 30px;
    margin-bottom: -50px;
    p {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 19px;
      line-height: 35px;
    }
  }

  .cover-page-text-container {
    color: white;
    width: 500px;
    position: absolute;
    top: 375px;
    left: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &.higher-up-cover-info {
      top: 330px;
    }
    .cover-page-body {
      height: 452px;
      width: 413px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .cover-page-body-sideways-text {
        writing-mode: vertical-lr;
        text-orientation: mixed;
        transform: rotate(180deg);
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 13.5px;
        text-transform: uppercase;
        letter-spacing: -0.005em;
        &.small-cp-comparison {
          font-size: 12px;
        }
        .sideways-lowercase {
          text-transform: lowercase;
        }
      }
    }
    .cover-page-body-data-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 285px;
      margin-top: 5px;
      .clickable-hover {
        &:hover {
          cursor: pointer;
        }
      }
      .cover-page-body-data-section {
        margin-left: 20px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-around;
        align-items: space-around;
        flex-direction: column;
        .data-section-top-row {
          display: flex;
          align-items: flex-end;
          height: 27px;
          .data-section-data-point {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 31px;
            line-height: 19px;
            margin-right: 5px;
            .small-dollar-sign {
              font-size: 27px;
            }
          }
          .data-section-top-row-triangle {
            width: 0;
            height: 0;
            margin: 0 5px;
            &.up {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 10px solid white;
            }
            &.down {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 10px solid white;
            }
          }
          .data-section-top-row-balanced {
            color: white;
            font-family: mr-eaves-xl-modern, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 17px;
            margin: 20px 5px -2px 10px;
            line-height: 17px;
          }
          .data-section-top-row-perc-diff {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 20px;
            margin-bottom: -5px;
            margin-left: 5px;
          }
        }
        .data-section-bottom-row-category {
          margin-bottom: 11px;
          display: flex;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 17px;
        }
      }
    }
    .see-data-verification {
      border: 3px solid gray;
      position: absolute;
      left: 300px;
      top: 100px;
      width: 200px;
      height: 200px;
      background-color: beige;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 37px;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
    &.bolded-cover-data-text {
      .cover-page-body-sideways-text,
      .cover-page-body-data-container
        .data-section-top-row
        .data-section-data-point,
      .cover-page-body
        .cover-page-body-data-section
        .data-section-top-row
        .data-section-top-row-perc-diff {
        font-weight: 600;
      }
    }
  }
  .special-bottom-cover-photo-text {
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
    color: white;
    width: 552px;
    position: absolute;
    bottom: 55px;
    margin-left: 4px;
    p {
      font-weight: 500;
    }
  }
  .corner-cover-photo-credit {
    font-family: objektiv-mk2, sans-serif;
    font-style: normal;
    font-size: 11px;
    color: white;
    width: 547px;
    position: absolute;
    bottom: 25px;

    p {
      text-align: left;
      font-weight: 500;
    }
    &.scar,
    &.uhrig,
    &.mccollum,
    &.arntz,
    &.albanese {
      left: 125px;
    }
    &.kaufman-credit {
      left: 127px;
    }
    &.smaller-size-credit {
      font-size: 8.5px;
    }
    &.bolded-cover-credit {
      p {
        font-weight: 600;
      }
    }
    &.right-aligned-credit {
      p {
        text-align: right;
      }
    }
    &.medium-size-credit {
      font-size: 10.5px;
    }
    &.larger-credit-size-1 {
      font-size: 12px;
    }
    &.dianne-johnson-credit {
      left: 107px;
      font-size: 12px;
    }
    &.gummow-credit-style {
      left: 23px;
      width: 600px;
    }
    &.italicized-credit {
      font-style: italic;
    }
  }
  .vait-cover-info {
    width: 73%;

    margin-top: -10px;
    .vait-transparent-inner-container {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 93%;
      height: 80%;
    }
    .vait-agent-info-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 0 auto 0 -2%;
      width: 92%;

      height: 80px;

      .vait-agent-info-col {
        width: 100%;
        height: 100%;
        margin-bottom: -5px;
        div {
          display: flex;
        }
        .vait-agent-and-phone {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .vait-designation-and-dre {
          color: white;
          display: flex;
          align-items: center;
          margin-top: 9px;
        }
      }
    }
    .vait-agent-name {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
      text-transform: uppercase;
      color: white;
    }
    .vait-designation {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 500;
      font-style: normal;

      font-size: 16px;
      color: white;

      &.caps {
        text-transform: uppercase;
      }
    }
    .vait-designation-span {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 500;
      font-style: normal;

      font-size: 16px;
      color: white;
      margin: 0 3px;
    }
    .vait-designation-and-dre {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 16px;
      color: white;
      display: flex;
      align-items: center;
      margin-top: 7px;
      .vait-dre-word {
      }
      .vait-dre-num {
      }
    }
    .vait-agent-phone-number {
      font-family: "objektiv-mk2", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 21px;
      color: white;
      .phone-num-row {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .phone-circle-divider {
        background-color: white;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        margin: 0px 3px 0px 3px;
      }
    }
    .vait-agent-info-box-logo {
      height: 100%;
      width: 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 80%;
      }
    }
    .bhhs-stacked-cover-logo {
      max-height: 122px;
      // margin-top: -35px;
      margin-top: 10px;

      margin-right: 20px;
      max-width: 86px;
      &.team-logo {
        margin-top: 19px;
      }
      &.douglas-specialized-logo {
        margin-top: -26px;
      }
    }
  }
  .vait-cover-logo {
    width: 120px;
    height: auto;
    margin-bottom: -14px;
    margin-left: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 180px;
      height: auto;
      margin: auto;
    }
  }
  //Bottom of cover page, agent information
  .qv4-cover-page-info-row {
    position: absolute;
    bottom: 100px;
    right: 0;
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    &.bottom-135-cover-page-info {
      bottom: 130px;
    }
    &.higher-cover-info-1 {
      bottom: 145px;
    }
    &.lower-cover-info-bottom-55 {
      bottom: 50px;
    }

    &.higher-cover-info-2 {
      bottom: 110px;
    }
    .douglas-cover-info {
      height: 135px;
      margin-top: -20px;
    }
  }
  //Custom agent logos on the bottom of cover page
  .scar-logo,
  .uhrig-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
      margin-bottom: 26px;
    }
  }

  .katherine-hunt-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
      margin-bottom: 26px;
    }
  }

  .uhrig-logo {
    img {
      height: 79px;
      max-width: 100px;
      width: auto;
      margin-bottom: 23px;
    }
  }
  // .gummow-cover-info-portrait {
  //   width: 104px;
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: center;
  //   margin-right: -17px;
  //   margin-top: -3px;
  //   margin-left: 0px;
  //   img {
  //     height: 69px;
  //
  //     width: auto;
  //     margin-bottom: 23px;
  //   }
  // }
  .johnsons-qr-code {
    width: 104px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -17px;
    margin-top: -3px;
    margin-left: 0px;
    img {
      height: 69px;
      max-width: 100px;
      width: auto;
      margin-bottom: 23px;
    }
  }
  .lori-b-cover-photo-logo {
    height: 80px;
    width: 96%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: 1px;
    img {
      height: 90%;
      width: auto;
    }
  }
  .kristen-wallace-cover-qr-code {
    width: 404px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0px;
    margin-top: 20px;
    margin-left: 0px;
    .kristen-wallace-qr-container {
      max-height: 92px;
      max-width: 64px;
      overflow: hidden;
      img {
        height: 80px;
        width: 80px;
        margin: -8px 0px -11px -8px;
      }
    }

    .kristen-wallace-cover-text-container {
      margin-left: 22px;
      .kristen-wallace-cover-text {
        font-family: objektiv-mk2, sans-serif;
        font-weight: 600;
        font-style: normal;
        color: white;
        font-size: 14px;
      }
    }
  }
  .gary-palacios-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 12px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
      margin-bottom: 26px;
    }
  }
  .kristin-arntz-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-left: -10px;
    img {
      max-width: 80px;
      width: auto;
      height: auto;
      margin-bottom: 26px;
    }
  }
  .david-kim-cover-logo {
    width: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -50px;
    margin-left: 20px;
    img {
      max-width: 50px;
      width: auto;
      height: auto;
    }
  }
  .lisa-mccollum-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-bottom: -17px;
    img {
      max-width: 80px;
      width: auto;
      height: auto;
    }
  }
  .stephanie-sills-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 0px;
    img {
      max-width: 96px;
      width: auto;
      height: auto;
    }
  }
  .dawn-thomas-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    img {
      max-width: 80px;
      width: auto;
      height: auto;
    }
  }
  .calgano-hamilton-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-bottom: -6px;
    img {
      max-width: 90px;
      width: auto;
      height: auto;
    }
  }
  .global-luxury-cb-cover-logo {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    // margin-bottom: 5px;
    img {
      max-height: 70px;
      max-width: 70px;
      width: auto;
      height: auto;
    }
  }
  .qv4-cover-page-info {
    width: 100%;
    &.larger-cover-page-info-styles {
      .transparent-inner-container {
        .agent-info-section {
          .agent-info-col {
            .agent-and-phone {
              .agent-name,
              .agent-phone {
                font-size: 26px;

                .phone-num-row {
                  font-size: 26px;
                }
              }
            }
          }
        }
      }
    }
    &.team {
      height: 160px;
      bottom: 22px;
    }
    &.cover-info-three-team {
      height: 180px;
    }
    .transparent-inner-container {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 550px;
      height: 80%;
    }
    .phone-num-row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .phone-circle-divider {
      background-color: white;
      height: 3px;
      width: 3px;
      border-radius: 50%;
      margin: 0px 3px 0px 3px;
    }
    .agent-info-presented-by {
      width: 546px;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      font-family: objektiv-mk2, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
      color: white;
      margin-bottom: 10px;
      &.stephanie-sills {
        width: 546px;
      }
      &.dawn-thomas {
        width: 550px;
      }
      &.three-agent-presented {
        margin-bottom: 56px;
        margin-top: -26px;
      }
      &.maurry-vano-presented-credit {
        width: 538px;
        margin-top: -70px;
        margin-bottom: 75px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .agent-info-section {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 0 auto;
      width: 552px;
      height: 80px;
      &.team {
        height: 104px;
      }
      .agent-info-col,
      .bigger-bolder-agent-info-col {
        width: 75%;
        height: 100%;
        margin-bottom: -5px;
        div {
          display: flex;
        }
        .agent-and-phone {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .agent-name,
          .agent-phone,
          .phone-num-row {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 22px;
            color: white;
            &.smaller-name-and-phone,
            &.small {
              font-size: 17px;
            }
            &.gummow-name-and-phone {
              font-size: 16px;
            }
          }
          .vertical-divider {
            padding: 0 7px;
          }
        }
        .designation-and-dre {
          color: white;
          display: flex;
          align-items: center;
          margin-top: 9px;
          &.column-designation-dre {
            flex-direction: column;

            align-items: flex-start;
            .dre-row {
              margin-top: 2px;
            }
            .xs-agent-designation {
              font-size: 9.5px;
            }
          }
          &.designation-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          &.des-and-dre-col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .custom-dre-row {
              margin-top: 5px;
            }
            .agent-name {
              font-size: 21.5px;
            }
            .mx-6 {
              margin: 0px 6px 0 6px;
              font-size: 11px;
            }
          }
          .agent-designation {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 12.5px;
            text-transform: uppercase;
            margin-right: 15px;
            letter-spacing: 0.075em;
            // margin-top: 5px;
            &.smaller-agent-designation {
              font-size: 11px;
            }
            &.capitalized-agent-designation {
              text-transform: none;
            }
            &.xs-agent-designation {
              font-size: 10px;
            }
            &.agent-designation-mb-10 {
              margin-bottom: 8px;
            }
          }
          .dre-word,
          .dre-num {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 10px;
            letter-spacing: 0.075em;
            text-transform: uppercase;
          }
          .name-before-dre {
            margin-right: 15px;
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 12px;
          }
        }
        .special-single-agent-under-name-text {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 13px;
        }
      }
      .bigger-bolder-agent-info-col {
        .agent-and-phone {
          .agent-name {
            font-size: 29px;
            font-weight: 400;
          }
        }
        .cover-photo-tagline {
          font-size: 22px;
          margin-bottom: 10px;
          margin-top: 3px;
          font-weight: 500;
        }
        .agent-phone {
          font-size: 17px;
          font-weight: 400;
          .phone-circle-divider {
            height: 4.5px;
            width: 4.5px;
          }
        }
      }
      .team-info-col {
        margin: 0.5em 0 -1em 0;
        width: 74.5%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .team-agent-separator {
          height: 1px;
          background-color: white;
          width: 200px;
          margin: auto;
        }
        .team-agent-container:nth-child(2) {
          margin-top: 4px;
        }
        .team-agent-container {
          display: flex;
          justify-content: flex-start;
          &.smaller-height {
            height: 53px;
          }
          .team-agent-col {
            width: auto;
            .team-sub-info {
              margin-top: -5px;
              display: flex;
              flex-direction: column;
              height: 31px;
              justify-content: center;
              &.reduced-team-sub-info-height {
                height: 22px;
              }
            }
          }
        }
        .team-agent-and-phone {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 31px;
          color: white;
          .phone-circle-divider {
            background-color: white;
            height: 3px;
            width: 3px;
            border-radius: 50%;
            margin: 0px 3px 0px 3px;
          }
          .team-agent-name {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 17px;
            &.larger-agent-name {
              font-size: 20px;
            }
          }
          .team-agent-phone {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 17px;
            &.larger-phone-num {
              font-size: 21px;
            }
          }
          .smaller-name-and-phone {
            font-size: 16px;
          }
          .team-vertical-divider {
            background-color: white;
            margin: 0 7px;
            font-family: mr-eaves-xl-modern, sans-serif;
            font-weight: 400;
            font-style: normal;
          }
        }
        .team-designation-and-dre {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: white;
          .designation-text {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 10px;
            text-transform: uppercase;
            margin-right: 15px;
            letter-spacing: 0.075em;
            // max-width: 270px;
            // max-height: 24px;
          }
          .dre-row {
            display: flex;
            align-items: flex-end;
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 17px;
            text-transform: uppercase;
            .dre-word {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 10px;
              letter-spacing: 0.075em;
              &.larger-dre-word {
                font-size: 13px;
              }
            }
            .dre-num {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 10px;
              letter-spacing: 0.075em;
              &.larger-dre-num {
                font-size: 13px;
              }
            }
          }
        }
        .team-email {
          font-family: mr-eaves-xl-modern, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 9px;
          color: white;
        }
      }
      .maurry-cover-info-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 450px;
        margin-top: 15px;
        .maurry-cover-info-brokerage-logo {
          height: 20px;
          width: auto;
          margin-bottom: 10px;
        }
        .maurry-cover-info-agent-logo {
          height: 51px;
          width: auto;
        }
      }
    }
    .agent-info-box-logo {
      height: 100%;
      width: 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 15px;
      &.longer-agent-info-box-logo {
        width: 45%;
      }
      &.longer-agent-info-box-logo-38 {
        width: 38%;
      }
      &.agent-logo-mr {
        margin-right: 40px;
      }
      &.centered-box-logo {
        justify-content: center;
      }
      &.lower-cover-logo-1 {
        margin-bottom: -60px;
      }
      &.agent-info-box-65-perc {
        width: 65%;
      }
      .cover-info-agent-photo-houy {
        margin-bottom: 20px;
        margin-top: -100px;
        height: 130px;
        width: auto;
        display: flex;
        justify-content: center;
        align-self: center;
        margin: 0 auto 20px auto;
      }
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 80%;
      }
      .SkylightHomes_rgb_Logo_Horiz_White {
        margin-top: -14px;
      }
      &.michael-noh-logo-and-portrait {
        flex-direction: column-reverse;
        margin-bottom: 72px;
        .michael-noh-cover-photo-headshot {
          width: 104px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: -20px;
          margin-top: 5px;
          margin-left: 15px;
          img {
            height: 127px;
            max-width: 125px;
            width: auto;
            margin-bottom: 23px;
          }
        }
      }
      .cb-stacked-brokerage-logo {
        max-height: 125%;
        //margin-bottom: 30px;
      }
      .compass-sv-info-box {
        margin-bottom: -10px;
        max-height: 115%;
        max-width: 110%;
      }

      .cb-gay-dales-brokerage-logo {
        max-height: 95%;
      }
      .cb-luxury-brokerage-logo {
        // max-height: 100%;
        height: 70px;
        width: 70px;
        margin-left: -30px;
        justify-content: center;
      }
      .village-properties-logo2 {
        max-width: 94%;
        margin-right: 19px;
      }
      .long-bhhs-logo {
        max-height: 120%;
        margin-top: -27px;
        max-width: 130%;
        &.team-logo {
          margin-top: 104px;
        }
        &.douglas-specialized-logo {
          margin-top: 47px;
          margin-right: -35px;
        }
      }
      .alemann-brokerage-logo {
        width: auto;
        max-width: 115%;
        height: auto;
        max-height: 89%;
      }
      .bhhs-stacked-cover-logo {
        max-height: 92px;
        margin-top: 30px;
        margin-right: 20px;
        max-width: 86px;
        &.team-logo {
          margin-top: 14px;
        }
      }
      .Horizontal_White_Compass {
        width: auto;
        max-width: 115%;
        height: auto;
        max-height: 84%;
        &.team-logo {
          max-height: 152px;
          margin-bottom: -110px;
          max-width: 165px;
        }
        &.larger-compass-logo {
          max-width: 133%;
        }
        &.raised-up-compass-logo {
          margin-bottom: 39px;
        }
      }
      .berkshire-hathaway-long-brokerage-logo {
        max-height: 110%;
        margin-top: -7px;
        max-width: 120%;
        &.team-logo {
          margin-top: 75px;
        }
      }
      .pres_circle_bronze_500x500px {
        max-width: 100%;
        max-height: 100%;
      }
      .luxury-berkshire-logo {
        max-height: 120%;
        margin-top: -37px;
        max-width: 130%;
        &.team-logo {
          margin-top: 75px;
        }
      }
      .logo_cbgl_white_EDIT_2_021523-01-1 {
        max-height: 98%;
        max-width: 113%;
        margin-bottom: 16px;
      }
      .BHHS-LUXcollection-White_500px300ppi {
        max-height: 159%;
        max-width: 118%;
        margin-right: -21px;
      }
      .above-beyond-transparent-white {
        max-width: 130%;
      }
      .over-the-moon-all-white-logo {
        max-height: 90%;
        max-width: 131%;
      }
    }
    &.douglas-info {
      .agent-info-section {
        height: 48px;
      }
      .team-agent-name {
        font-size: 26px;
      }
      .dre-row {
        margin-top: 4px;
      }
    }
  }
  .custom-sam-and-gina-cover-info {
    .agent-info-presented-by {
      width: 356px;
      margin-top: 9px;
      margin-bottom: 34px;
      &.sam-and-gina-custom-presented {
      }
    }

    .small-team-info-col {
      margin: 0.5em 0 -1em 0;
      width: 74.5%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .small-team-agent-separator {
        height: 1px;
        background-color: white;
        width: 200px;
        margin: auto;
      }
      .small-team-agent-container:nth-child(2) {
        margin-top: 9px;
      }
      .small-team-agent-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .small-team-headshot-container {
          height: 80px;
          width: 80px;
          margin-right: 15px;
          .small-team-headshot-img {
            height: 70px;
            width: 70px;
          }
        }
        .small-team-agent-col {
          width: auto;
          .small-team-sub-info {
            margin-top: -5px;
            display: flex;
            flex-direction: column;
            height: 31px;
            justify-content: center;
          }
        }
      }
      .small-team-agent-and-phone {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 31px;
        color: white;
        .small-phone-circle-divider {
          background-color: white;
          height: 3px;
          width: 3px;
          border-radius: 50%;
          margin: 0px 3px 0px 3px;
        }
        .small-team-agent-name {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          letter-spacing: 0.025em;
          &.small-larger-agent-name {
            font-size: 21px;
          }
        }
        .small-team-agent-phone {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 15px;
          letter-spacing: 0.025em;
          margin-left: -7px;
          &.small-larger-phone-num {
            font-size: 21px;
          }
        }
        .small-smaller-name-and-phone {
          font-size: 17px;
        }
        .small-team-vertical-divider {
          background-color: white;
          margin: 0 10px;
          font-family: mr-eaves-xl-modern, sans-serif;
          font-weight: 400;
          font-style: normal;
        }
      }
      .small-team-designation-and-dre {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: white;
        .small-designation-text {
          font-family: objektiv-mk2, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 11.5px;
          text-transform: uppercase;
          margin-right: 15px;
          letter-spacing: 0.04em;
          //max-width: 300px;
          max-height: 27px;
          line-height: 23px;
        }
        .small-dre-row {
          display: flex;
          align-items: flex-end;
          font-family: objektiv-mk2, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
          text-transform: uppercase;
          .small-dre-word {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 11px;
            letter-spacing: 0.025em;
            &.small-larger-dre-word {
              font-size: 12px;
            }
          }
          .small-dre-num {
            font-family: objektiv-mk2, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 11px;
            &.small-larger-dre-num {
              font-size: 12px;
            }
          }
        }
      }
      .team-email {
        font-family: mr-eaves-xl-modern, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 8px;
        color: white;
      }
    }
  }
  .gummow-qv4-cover-page-info-row {
    position: absolute;
    bottom: 92px;
    right: 0;
    height: 163px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .gummow-cover-info-portrait {
      width: 163px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 42px;

      img {
        height: 160px;
        width: auto;
      }
    }
    .gummow-qv4-cover-page-info {
      width: 70%;
      margin-left: 10px;
      margin-right: -10px;

      .gummow-transparent-inner-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 476px;
        height: 80%;
      }
      .phone-num-row {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .phone-circle-divider {
        background-color: white;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        margin: 0px 3px 0px 3px;
      }
      .gummow-agent-info-presented-by {
        width: 442px;
        margin: auto;
        display: flex;
        justify-content: flex-start;
        font-family: objektiv-mk2, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: white;
        margin-bottom: 10px;
      }
      .gummow-agent-info-section {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin: 0 auto;
        width: 451px;
        height: 80px;
        &.team {
          height: 104px;
        }
        .gummow-agent-info-col {
          width: 74%;
          height: 100%;
          margin-bottom: -5px;
          div {
            display: flex;
          }
          .gummow-agent-and-phone {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .gummow-agent-name,
            .gummow-agent-phone,
            .gummow-phone-num-row {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 22px;
              color: white;

              &.gummow-name-and-phone,
              .phone-num-row {
                font-size: 26px;
                font-weight: 300;
              }
            }
            .vertical-divider {
              padding: 0 10px;
            }
          }
          .gummow-designation-and-dre {
            color: white;
            display: flex;
            align-items: center;
            margin-top: 9px;
            &.gummow-des-and-dre-col {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              .gummow-custom-dre-row {
                margin-top: 5px;
              }
              .gummow-mx-6 {
                margin: -3px 6px 0 6px;
                font-size: 16px;
              }
            }
            .gummow-agent-designation {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 12.5px;
              text-transform: uppercase;
              margin-right: 12px;
              letter-spacing: 0.075em;
            }
            .gummow-dre-word,
            .gummow-dre-num {
              font-family: objektiv-mk2, sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 11px;
              letter-spacing: 0.075em;
              text-transform: uppercase;
            }
          }
        }
      }
      .gummow-agent-info-box-logo {
        height: 100%;
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 15px;

        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 80%;
        }
        .cb-stacked-brokerage-logo {
          max-height: 145%;
          margin-bottom: -30px;
        }

        .cb-luxury-brokerage-logo {
          max-height: 100%;
        }
      }
    }
    .transparent-inner-container {
      display: flex;
      justify-content: flex-start;
    }
  }
}
