/* Variables

 */
@font-face {
  font-family: "AfrahBold";
  src: url("AfrahBold.ttf");
}
@font-face {
  font-family: "AfrahRegular";
  src: url("AfrahRegular.ttf");
}
.error-sticky-note-container {
  position: absolute;
  top: 35%;
  right: 20%;
  background-color: yellow;
  height: 400px;
  width: 350px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-sticky-note-container .error-sticky-note-inner-container {
  height: 80%;
  width: 80%;
}
.ye2-print-page-container {
  position: relative;
  width: 1698px;
  height: 1140px;
  margin: 0;
  background: white;
  background-size: contain;
  display: flex;
}
.ye2-print-page-container.flipbook {
  width: 849px;
}
.ye2-print-page-container.regular-print-page {
  width: 1698px;
}
.ye2-print-page-container .ye2-first-left,
.ye2-print-page-container .ye2-first-right,
.ye2-print-page-container .ye2-second-left,
.ye2-print-page-container .ye2-second-right,
.ye2-print-page-container .table-quadrants-first-left,
.ye2-print-page-container .table-quadrants-second-left {
  width: 50%;
  position: relative;
}
.ye2-print-page-container .ye2-property-highlight {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 690px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ye2-print-page-container .ye2-property-highlight.gail-highlight {
  height: 718px;
}
.ye2-print-page-container .ye2-property-highlight.xl-highlights {
  height: 766px;
}
.ye2-print-page-container .ye2-property-highlight.xl-highlights .alt-ye2-property-highlight-title-container {
  height: 215px;
}
.ye2-print-page-container .ye2-property-highlight.xl-highlights .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle {
  width: 91%;
  height: 187px;
}
.ye2-print-page-container .ye2-property-highlight.xl-highlights .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p {
  font-size: 14.25px;
  line-height: 17px;
}
.ye2-print-page-container .ye2-property-highlight.one-prop-with-testimonial-container .alt-ye2-property-highlight-title-container {
  height: 48px;
}
.ye2-print-page-container .ye2-property-highlight.taller-higlights-bg {
  height: 730px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg {
  height: 100%;
  width: 100%;
  background-color: #dde5e7;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg {
  top: 0px;
  height: 127px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg {
  height: 495px;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg {
  height: 100%;
  width: 100%;
  background-color: #231f20;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-body {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container {
  margin: 10px auto 0px auto;
  position: relative;
  height: 135px;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr {
  position: absolute;
  right: 45px;
  top: -5px;
  width: 90px;
  height: 90px;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img {
  width: 90px;
  height: 90px;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow {
  position: absolute;
  right: 137px;
  top: 33px;
  width: 90px;
  height: 90px;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img {
  width: 60px;
  height: 60px;
  transform: rotate(-25deg);
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text {
  color: white;
  font-size: 30.5px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 40px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small {
  padding-left: 10px;
  font-size: 36px;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: white;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  width: 70%;
  display: flex;
  justify-content: flex-end;
  margin-top: 7px;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78%;
  margin: 5px auto 0 auto;
  height: 95px;
}
.ye2-print-page-container .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p {
  letter-spacing: 0.05em;
  font-size: 16.25px;
  line-height: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: white;
  text-align: center;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container {
  margin: -5px auto 0px auto;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text {
  color: black;
  font-size: 30.5px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 40px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small {
  padding-left: 10px;
  font-size: 36px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle {
  height: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 681px;
  margin: 5px auto 0 auto;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p {
  letter-spacing: 0.05em;
  font-size: 16.25px;
  line-height: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: black;
  text-align: center;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text {
  width: 87%;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p {
  font-size: 14.8px;
  line-height: 21px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p {
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child {
  font-weight: 500;
  font-size: 28px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2) {
  margin-top: 10px;
  margin-bottom: -5px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 {
  width: 87%;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p {
  font-size: 14.8px;
  line-height: 21px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text {
  color: rgb(239.7, 0, 0);
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 18px;
  left: 0px;
  height: 157px;
  width: 100%;
  z-index: 1;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg {
  height: 315px;
  width: 93%;
  background-color: #e3e9dd;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container {
  margin: -5px auto 0px auto;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 26px;
  text-align: center;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 15.25px;
  width: 90%;
  margin: 6px auto 0 auto;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1 {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  width: 90%;
  margin: 40px auto 0 auto;
  font-family: "utopia-std", serif;
  font-weight: 400;
  font-style: italic;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2 {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  width: 90%;
  margin: 10px auto 0 auto;
  font-family: "utopia-std", serif;
  font-weight: 400;
  font-style: italic;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text {
  color: black;
  font-size: 30.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 40px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  width: 90%;
  margin: 8px auto 0 auto;
  letter-spacing: 0.1em;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container {
  margin: 0px auto 10px auto;
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text {
  color: white;
  font-size: 25px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title {
  height: 14px;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  height: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text {
  font-size: 10.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text {
  width: 65%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 8px;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text {
  color: white;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div {
  height: 13px;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 10.5px;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 419px;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text {
  position: absolute;
  width: 100%;
  bottom: 29px;
  left: 0;
  height: 33px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text.lower-banner-text {
  bottom: 25px;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2) {
  bottom: 24px;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text .bolded-listing-price {
  font-weight: 600;
  text-transform: uppercase;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text .no-bold-regular-case {
  font-weight: 400;
  text-transform: none;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case {
  font-weight: 600;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text .extra-status-row {
  display: flex;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text .extra-status-row p {
  font-weight: 600;
  font-size: 12px;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text.lowered-banner {
  bottom: 3px;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text div {
  font-size: 11px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text .capitalize-city {
  text-transform: capitalize;
}
.ye2-print-page-container .ye2-property-highlight .row-banner-text .reversed-text {
  color: white;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
  margin-top: 5px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text .representation-row {
  display: flex;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text .representation-row.representation-column {
  align-items: center;
  flex-direction: column;
  margin-top: 1px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2) {
  margin-top: 5px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text .representation-row p {
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text .representation-row .pending-representation {
  color: green;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text .representation-row .active-representation {
  color: #3d85c6;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text.no-mt-banner-text {
  margin-top: 0px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text.white-text {
  color: white;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text > div {
  height: 13px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 11.5px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2) {
  margin-top: 6px;
  margin-bottom: -4px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin {
  margin-top: 0px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.ye2-print-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p {
  line-height: 11px;
  height: 11px;
  margin-top: 3px;
  font-size: 11.5px;
  text-align: center;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child {
  margin-top: 4px;
}
.ye2-print-page-container .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line {
  padding-top: 5px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-row-1 {
  width: 95%;
  margin: auto;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col {
  position: relative;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img {
  height: 180px;
  width: 240px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-row-2 {
  padding-top: 15px;
  width: 95%;
  margin: auto;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col {
  position: relative;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img {
  height: 238px;
  width: 370px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout {
  margin-top: 20px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text {
  font-size: 11.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto 10px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text {
  width: 72%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
  margin: 2px 10px 0 10px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div {
  height: 13px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 11.5px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 459px;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial {
  margin-top: 0px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col > div:nth-child(2) {
  display: flex;
  justify-content: center;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col > div:nth-child(2) .one-prop-banner-row {
  left: 12%;
  width: 76%;
  bottom: 0px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col > div:nth-child(2) .one-prop-banner-row .one-prop-photo-footer-text {
  width: 47%;
  margin: -3px 10px 0 10px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col > div:nth-child(2) .one-prop-banner-row .one-prop-photo-footer-text div {
  line-height: 13px;
}
.ye2-print-page-container .ye2-property-highlight .prop-1-layout.one-prop-with-testimonial .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 390px;
  width: auto;
}
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col {
  width: 367px;
}
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 191px;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height {
  height: 210px;
}
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.extra-reduced-image-height,
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.extra-reduced-image-height {
  height: 190px;
}
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
}
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin {
  margin-top: 32px;
}
.ye2-print-page-container .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col {
  width: 367px;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text {
  height: 72px;
  bottom: 3px;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .no-status-height,
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .no-status-height {
  height: 30px;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text > div:first-child,
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text > div:first-child {
  margin-top: 2px;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text {
  margin-top: 0px;
  height: auto;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container {
  height: 20px;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container p,
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container p {
  height: 11px;
  margin-top: 3px;
  font-size: 11.5px;
  text-align: center;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(1),
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 11.5px;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(2),
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0 {
  height: 242px;
  width: 100%;
  max-width: 371px;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
}
.ye2-print-page-container .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .prop-2-layout {
  margin-top: 30px;
}
.ye2-print-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.ye2-print-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 49%;
}
.ye2-print-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  width: 375px;
  height: 240px;
  max-height: 240px;
}
.ye2-print-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position {
  object-fit: cover;
  object-position: 6% 34%;
}
.ye2-print-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ye2-print-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  width: 375px;
  height: auto;
  max-height: 240px;
}
.ye2-print-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position {
  object-fit: cover;
  object-position: 70% 50%;
}
.ye2-print-page-container .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position {
  object-fit: cover;
  object-position: 0% 34%;
}
.ye2-print-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.ye2-print-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ye2-print-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: auto;
  width: auto;
  max-height: 194px;
  max-width: 367px;
}
.ye2-print-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain {
  object-fit: contain;
}
.ye2-print-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  width: 96%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px auto 0 auto;
}
.ye2-print-page-container .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 194px;
  width: 100%;
  max-width: 297px;
}
.ye2-print-page-container .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer {
  display: flex;
  flex-direction: column;
  margin: 40px auto 0 auto;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: 8px;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text {
  color: black;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1 {
  font-size: 19px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  line-height: 32px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 93%;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text {
  font-size: 14.5px;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  line-height: 29px;
  margin-top: 10px;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 {
  color: white;
  font-size: 19px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  line-height: 32px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 93%;
  margin-top: 18px;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title {
  height: 14px;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 {
  color: white;
  font-size: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  width: 100%;
  letter-spacing: 0.025em;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title {
  height: 12px;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  padding-top: 20px;
}
.ye2-print-page-container .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text {
  color: white;
  font-size: 20px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.125em;
  margin-top: 8px;
}
.ye2-print-page-container .ye2-property-highlight .ye2-property-highlight-inner-photo-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin: auto;
}
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 {
  height: 620px;
  border: 1px solid pink;
}
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg {
  height: 315px;
}
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2 {
  margin-top: 0px;
}
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text {
  bottom: 10px;
}
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col {
  height: 325px;
}
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0 {
  height: 300px;
}
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text {
  height: 40px;
}
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text {
  margin-top: 12px;
  margin-bottom: -12px;
  height: 26px;
}
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.ye2-print-page-container .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm {
  object-fit: contain;
  height: 256px;
}
.ye2-print-page-container .quadrant-container {
  position: absolute;
  bottom: 0px;
  height: 680px;
  width: 100%;
}
.ye2-print-page-container .quadrant-container .quadrant-inner-container {
  width: 85%;
  margin: auto;
}
.ye2-print-page-container .quadrant-container .quadrant-testimonial {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-print-page-container .quadrant-container .quadrant-testimonial .testimonial-bg-image-container {
  height: 240px;
  width: 100%;
  position: absolute;
  width: 85%;
  z-index: 0;
}
.ye2-print-page-container .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image {
  height: 100%;
  width: 100%;
}
.ye2-print-page-container .quadrant-container .quadrant-testimonial .add-testimonial-text {
  color: red;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 28px;
  line-height: 27px;
  text-align: center;
  width: 100%;
}
.ye2-print-page-container .quadrant-container .quadrant-testimonial .testimonial-content-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ye2-print-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin: auto;
  position: absolute;
  z-index: 1;
}
.ye2-print-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.ye2-print-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff {
  padding: 0 10px;
  color: #424142;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.2em;
  text-align: center;
}
.ye2-print-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  width: 90%;
}
.ye2-print-page-container .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p {
  text-align: right;
}
.ye2-print-page-container .quadrant-container .quadrant-btn {
  height: 59px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4e85af;
  position: absolute;
  z-index: 5;
  border-radius: 10px;
}
.ye2-print-page-container .quadrant-container .quadrant-btn:hover {
  cursor: pointer;
}
.ye2-print-page-container .quadrant-container .quadrant-btn div {
  color: white;
}
.ye2-print-page-container .quadrant-container .quadrant-btn.apply-all-areas-btn {
  left: 260px;
  top: 210px;
  width: 150px;
}
.ye2-print-page-container .quadrant-container .quadrant-btn.quadrant-title-btn {
  top: 45px;
  left: 5px;
  width: 150px;
}
.ye2-print-page-container .quadrant-container .quadrant-btn.quadrant-btn-0 {
  top: 130px;
  left: 5px;
}
.ye2-print-page-container .quadrant-container .quadrant-btn.quadrant-btn-1 {
  top: 235px;
  left: 5px;
}
.ye2-print-page-container .quadrant-container .quadrant-btn.quadrant-btn-2 {
  top: 340px;
  left: 5px;
}
.ye2-print-page-container .quadrant-container .quadrant-btn.quadrant-btn-3 {
  top: 445px;
  left: 5px;
}
.ye2-print-page-container .quadrant-container .quadrant-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 50px;
}
.ye2-print-page-container .quadrant-container .quadrant-title-container .quadrant-title-text {
  padding: 8px 0px 7px 0px;
  align-self: flex-start;
  margin-right: 5px;
  text-transform: uppercase;
}
.ye2-print-page-container .quadrant-container .quadrant-title-container .quadrant-title-text p {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 59px;
  color: #424142;
  letter-spacing: 0.14em;
  line-height: 26px;
}
.ye2-print-page-container .quadrant-container .quadrant-title-container .quadrant-subtitle-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  padding: 8px 0px 7px 0px;
  color: #424142;
  align-self: flex-end;
  margin-left: 5px;
  line-height: 20px;
}
.ye2-print-page-container .quadrant-container .quadrant-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 440px;
  flex-wrap: wrap;
}
.ye2-print-page-container .quadrant-container .quadrant-body .section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
.ye2-print-page-container .quadrant-container .quadrant-body .section-container .section-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5px;
  height: 185px;
  width: 45%;
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-number {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 40.5px;
  color: #424142;
  margin-right: 15px;
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section {
  background-color: rgb(222.4311111111, 221.4133333333, 218.8688888889);
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section:hover {
  background-color: rgb(222.4311111111, 221.4133333333, 218.8688888889);
  cursor: pointer;
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title {
  width: 100%;
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #424142;
  text-align: left;
  font-size: 17px;
  text-transform: uppercase;
  padding-top: 10px;
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter {
  text-transform: capitalize;
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  color: #424142;
  width: 100%;
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p {
  text-align: center;
}
.ye2-print-page-container .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter {
  text-transform: capitalize;
}
.ye2-print-page-container .quadrant-container .quadrant-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -230px;
  z-index: 4;
  position: relative;
}
.ye2-print-page-container .quadrant-container .quadrant-logo-container img {
  height: 145px;
  width: auto;
}
.ye2-print-page-container .ye2-first-left .no-property-highlights {
  display: none;
}
.ye2-print-page-container .ye2-first-left .ye2-backpage-bg {
  height: 722px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
}
.ye2-print-page-container .ye2-first-left .ye2-backpage-bg img {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}
.ye2-print-page-container .qv4-second-left {
  width: 50%;
  position: relative;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-background-img {
  height: 370px;
  width: 99.5%;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container {
  position: absolute;
  top: 94px;
  left: 40px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-title {
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-left: 4px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle {
  color: #333333;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 33px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle.speedometer-subtitle-col {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .small-speedometer-area-text {
  font-size: 31px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text {
  font-size: 17px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xxs-speedometer-area-text {
  font-size: 22px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-img {
  position: absolute;
  top: 45px;
  right: 80px;
  height: auto;
  width: 320px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .buyers-advantage-text {
  position: absolute;
  top: 209px;
  right: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .sellers-advantage-text {
  position: absolute;
  top: 209px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container {
  position: absolute;
  top: 300px;
  right: 20px;
  height: 50px;
  width: 491px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title {
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 21px;
  text-transform: uppercase;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics {
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  line-height: 27.5px;
  letter-spacing: 0.025em;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-background-img {
  height: 370px;
  width: 99.5%;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 {
  position: absolute;
  top: 240px;
  right: 80px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-title {
  text-align: left;
  color: black;
  font-family: "Playfair Display SC";
  font-weight: 400;
  font-style: normal;
  font-size: 29px;
  margin-left: 4px;
  font-feature-settings: "lnum", "pnum";
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 19px;
  letter-spacing: 0.075em;
  line-height: 31px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 19px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.speedometer-subtitle-col {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .small-speedometer-area-text {
  font-size: 31px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xs-speedometer-area-text {
  font-size: 17px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xxs-speedometer-area-text {
  font-size: 22px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-img {
  position: absolute;
  top: 45px;
  right: 80px;
  height: auto;
  width: 320px;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .buyers-advantage-text {
  position: absolute;
  top: 209px;
  right: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .sellers-advantage-text {
  position: absolute;
  top: 209px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 {
  position: absolute;
  top: 94px;
  left: 40px;
  height: 90px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-market-title-v2 {
  color: black;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 33.5px;
  line-height: 41px;
  letter-spacing: 0.1em;
}
.ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-statistics-v2 {
  color: black;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 33.5px;
  line-height: 41px;
  letter-spacing: 0.1em;
}
.ye2-print-page-container .qv4-second-left .quiz-chartTip {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 50;
  max-width: 250px;
}
.ye2-print-page-container .qv4-second-left .quiz-graph {
  height: 200px;
  width: 350px;
  margin-left: 10px;
}
.ye2-print-page-container .qv4-second-left .quiz-graph .graph-line {
  stroke: rgba(235, 242, 242, 0.4);
}
.ye2-print-page-container .qv4-second-left .quiz-graph .x-labels {
  text-anchor: middle;
}
.ye2-print-page-container .qv4-second-left .quiz-graph .y-labels {
  text-anchor: end;
}
.ye2-print-page-container .qv4-second-left .quiz-graph .quiz-graph-grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}
.ye2-print-page-container .qv4-second-left .label-title {
  text-anchor: middle;
  text-transform: uppercase;
  font-size: 12px;
  fill: gray;
}
.ye2-print-page-container .qv4-second-left.firstBg {
  fill: rgba(121, 127, 155, 0.8);
}
.ye2-print-page-container .qv4-second-left.secondBg {
  fill: rgba(165, 182, 144, 0.8);
}
.ye2-print-page-container .qv4-second-left.thirdBg {
  fill: rgba(163, 107, 113, 0.8);
}
.ye2-print-page-container .qv4-second-left .quiz-graph-dot.firstBg,
.ye2-print-page-container .qv4-second-left .quiz-graph-start-dot.firstBg {
  fill: rgba(121, 127, 155, 0.8);
}
.ye2-print-page-container .qv4-second-left .quiz-graph-dot.secondBg,
.ye2-print-page-container .qv4-second-left .quiz-graph-start-dot.secondBg {
  fill: rgba(165, 182, 144, 0.8);
}
.ye2-print-page-container .qv4-second-left .quiz-graph-dot.thirdBg,
.ye2-print-page-container .qv4-second-left .quiz-graph-start-dot.thirdBg {
  fill: rgba(163, 107, 113, 0.8);
}
.ye2-print-page-container .qv4-second-left .svg-cover {
  width: 350px;
}
.ye2-print-page-container .qv4-second-left .bottom-x-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  letter-spacing: -0.075em;
}
.ye2-print-page-container .qv4-second-left .data-labels,
.ye2-print-page-container .qv4-second-left .data-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11.5px;
  width: 30px;
  text-align: center;
  text-anchor: middle;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container {
  position: absolute;
  top: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section {
  display: flex;
  height: 200px;
  width: 99.5%;
  margin-bottom: 40px;
  box-shadow: 0 12px 18px -9px rgb(204.5, 204.5, 204.5);
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row {
  display: flex;
  justify-content: space-between;
  height: 200px;
  width: 100%;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph {
  width: 33.3%;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section {
  width: 57%;
  display: flex;
  flex-direction: column;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row {
  font-weight: bold;
  width: 100%;
  height: 25px;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  line-height: 15px;
  text-transform: uppercase;
  color: black;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider {
  height: 80px;
  width: 1px;
  align-self: center;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0 5px;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 90%;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section.data-inner-bg-1 {
  background-color: #eef0f5;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section.data-inner-bg-2 {
  background-color: #edf0ea;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section.data-inner-bg-3 {
  background-color: #efe8e8;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 46%;
  height: 120px;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  margin-top: -6px;
  height: 22px;
  letter-spacing: 0.05em;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
  height: 18px;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 7px;
  text-transform: uppercase;
  color: black;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text {
  margin-top: 27px;
  margin-bottom: -31px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  text-transform: uppercase;
  color: black;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 47px;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: black;
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow {
  height: 30px;
  width: 56px;
}
.ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  margin-top: 20px;
  height: 22px;
}
.ye2-print-page-container .qv4-second-left .dashboard-bg {
  position: absolute;
  margin: auto;
  left: 5%;
  right: 5%;
  top: 8.2%;
  width: 672px;
  height: 922px;
  z-index: 0;
}
.ye2-print-page-container .qv4-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 70px;
  margin-top: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ye2-print-page-container .qv4-second-left .db-header .db-header-main {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 27.5px;
  line-height: 26px;
  letter-spacing: 0.35em;
}
.ye2-print-page-container .qv4-second-left .db-body {
  position: absolute;
  top: 140px;
  width: 100%;
}
.ye2-print-page-container .qv4-second-left .db-body .db-body-header {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px auto 10px auto;
}
.ye2-print-page-container .qv4-second-left .db-body .db-body-header .db-body-header-title {
  color: #838b75;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23px;
  text-align: center;
}
.ye2-print-page-container .qv4-second-left .db-body .db-body-subheader {
  width: 280px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box {
  right: 0px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider {
  right: 0px;
  width: 616px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header {
  width: 604px;
  flex-direction: row-reverse;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category {
  text-align: left;
  margin-left: 15%;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation {
  width: 65%;
  margin-left: 13%;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 13%;
  margin-top: 10px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage {
  display: flex;
  justify-content: flex-start;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box {
  left: 0px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider {
  left: 0px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header {
  width: 575px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category {
  text-align: right;
  margin-right: -6.5%;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation {
  width: 65%;
  margin-left: 22%;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 22%;
  margin-top: 10px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage {
  display: flex;
  justify-content: flex-end;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container {
  clear: both;
  display: inline-block;
  width: 100%;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator {
  height: 203px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 1.3px;
  width: 612px;
  position: absolute;
  top: 48px;
  z-index: 3;
  background-color: black;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
  height: 210px;
  z-index: 1;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 707px;
  height: 91%;
  position: absolute;
  z-index: 2;
  background-color: rgba(251, 251, 246, 0.9);
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  height: 40px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num {
  width: 125px;
  text-align: right;
  color: #a36b71;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 37px;
  line-height: 32px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  width: 280px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: justify;
  text-justify: inter-word;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation {
  margin-top: 10px;
  color: #424142;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  line-height: 19px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage {
  color: #8f555c;
  font-weight: 400;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-weight: 400;
  color: black;
  margin-left: 4px;
  display: inline-block;
  float: right;
}
.ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: -86px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
}
.ye2-print-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-weight: 700;
}
.ye2-print-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-text,
.ye2-print-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.075em;
  color: #8f555c;
  width: 90%;
  margin: auto;
}
.ye2-print-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  display: inline-block;
  margin-left: 10px;
  width: auto;
}
.ye2-print-page-container .qv4-second-left .qv4-second-left-middle-section {
  height: 872px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators {
  width: 100%;
  height: 165px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: -7px;
  height: 1.33px;
  width: 500px;
  background-color: #abbc87;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -4px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section {
  font-size: 13px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.2);
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.35);
  height: 50px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -8px;
  width: 100px;
  height: 1.5px;
  background-color: #abbc87;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 30.5px;
  color: #53626c;
  text-align: center;
  margin-right: 20px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up {
  width: 35px;
  height: 35px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down {
  width: 35px;
  height: 35px;
}
.ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: #53626c;
  margin-bottom: -4px;
  text-align: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table {
  width: 100%;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 30px 0 30px 41px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: black;
  margin: -8px 8px 0 8px;
  height: 5px;
  width: 5px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 5px;
  height: 5px;
  width: 5px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: black;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 26px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 31px;
  line-height: 26px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 28px;
  line-height: 26px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 25px;
  line-height: 26px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 30px;
  line-height: 26px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .golden-divider {
  height: 1px;
  margin-top: 2px;
  margin-left: 23px;
  width: 477px;
  background-color: black;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long {
  margin-left: 23px;
  width: 826px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 319px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 430px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 7px;
  display: flex;
  align-items: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 14px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-table-containers {
  margin-top: -1px;
  min-height: 500px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 552px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr th {
  font-size: 11px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: black;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr th p {
  line-height: 13px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 23px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 33px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 18.5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: black;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 64px;
  text-align: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 59px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 120px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 159px;
  text-align: left;
  font-weight: 400;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address {
  font-size: 8.5px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  width: 92px;
  font-weight: 400;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 73px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 41px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 100px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 63px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 10px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .sold-overflow-tb,
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .sales-dates p {
  font-size: 8px;
  color: black;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .sales-legend {
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 9px;
  color: black;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 21px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.ye2-print-page-container .mq3-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.ye2-print-page-container .mq3-second-left .mq3-second-left-middle-section {
  height: 878px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.ye2-print-page-container .ye2-second-left .mq3-second-left-middle-section {
  height: 878px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.ye2-print-page-container .ye2-second-left .mq3-second-left-middle-section.compact-middle-section {
  height: 780px;
  margin-top: 30px;
}