body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body::-webkit-scrollbar-track {
  box-shadow: 'inset 0 0 6px #012159';
  -webkit-box-shadow: 'inset 0 0 6px #012159';
  -webkit-border-radius: '8px';
  border-radius: '8px';
}

body::-webkit-scrollbar {
  width: '8px';
}

body::-webkit-scrollbar-thumb {
  border-radius: '8px';
  background-color: '#012159';
  box-shadow: 'inset 0 0 0';
  -webkit-box-shadow: 'inset 0 0 0';
} */

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #012159 #d4e0ec;
  scrollbar-face-color: #012159;
  scrollbar-track-color: #d4e0ec;
}

/* {
  overflow-y: scroll;
  scrollbar-color: #0A4C95 #f3f6fa;
} */

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: none !important;
  -webkit-appearance: none;
}

*::-webkit-scrollbar-track {
  background: #d4e0ec;
}

*::-webkit-scrollbar-thumb {
  background-color: #012159;
  border-radius: 20px;
  border: 6px solid #012159;
  box-shadow: "inset 0 0 0";
  -webkit-box-shadow: "inset 0 0 0";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiSnackbar-root {
  top: 68px !important;
}

.noInternetMainDivOuter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.noInternetMainDivContainer {
  height: 50px;
  position: fixed;
  bottom: 24px;
  background-color: #e60023;
  color: white;
  font-weight: 500;
  font-size: 15px;
  transition: "height 4s";
  z-index: 193333;
  padding: 24px;
  border-radius: 999px;
  display: flex;
  align-items: center;
}
.noInternetMainDivContainer span {
  padding-left: 8px;
}
.sessionSwitchFABContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
}

@media screen and (max-height: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

/* //carousel overrides begin */
/* //defualt img min height */

.carousel .slide img {
  min-height: 100px;
}

/* //to increase opacity and bg color without hover */
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.2);
}

/* //to show next arrow bigger */
.carousel .control-next.control-arrow:before {
  border-left: 16px solid #fff;
}

/* //to show previous arrow bigger */
.carousel .control-prev.control-arrow:before {
  border-right: 16px solid #fff;
}

/* //to increase opacity and bg color with hover */
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow:hover {
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.4);
}

/* //added bg to photos count */
.carousel .carousel-status {
  background: green;
  z-index: 10;
}

/* //lg-property-slider max height */
.lg-property-slider .carousel .slide img {
  max-height: 225px;
}

/* //g-image-slider max height */
.lg-image-slider .carousel .slide img {
  max-height: 600px;
}

/* //carousel overrides end */

div.public-DraftStyleDefault-block {
  font-size: 16px !important;
  /* line-height: 8px !important; */
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

/* .richTextEditorGlobalCss {
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: "roboto" !important;
  direction: ltr !important;
  text-align: left !important;
  line-height: 21px !important;
}

.richTextEditorGlobalCss title {
  font-family: "roboto" !important;
  font-size: 1.2em !important;
}

.richTextEditorGlobalCss > b {
  font-family: "roboto" !important;
  font-weight: 500 !important;
}

.richTextEditorGlobalCss > u {
  font-family: "roboto" !important;
}

.richTextEditorGlobalCss > em {
  font-family: "roboto" !important;
  font-style: italic !important;
}

.richTextEditorGlobalCss code {
  font-family: "roboto" !important;
  font-size: 1.2em !important;
  letter-spacing: normal !important;
} */

/*Rich editor CSS*/
.DraftEditor-editorContainer > .public-DraftEditor-content {
  color: #a6b1bb !important;
}
.DraftEditor-editorContainer > .notranslate.public-DraftEditor-content {
  color: #66788a !important;
}
.DraftEditor-editorContainer
  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  font-size: 14px !important;
}
