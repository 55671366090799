.print-qv4-email-website-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 100%;
  // background-color: #424142;
  background-color: black;
  &.black-compiled-by {
    background-color: black;
  }
  &.berk-compiled-presented {
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12.5px;
    color: white;
    display: flex;
    justify-content: space-around;
    div {
      display: flex;
      align-items: center;
    }
    .berk-email {
      font-size: 12.5px;
    }
    .produced-by-divider {
      margin: 0 10px;
      width: 1px;
      font-weight: 200;
      font-style: normal;
      font-size: 8px;
      color: white;
    }
  }
  .compiled-by-footer-inner-container {
    width: 90%;
    display: flex;
    justify-content: center;
    color: white;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    .larger-email-website-1 {
      font-size: 18px;
    }
    .compiled-by-colon-padding {
      padding: 0 3px;
    }
    .compiled-by-footer-space {
      width: 105px;
    }
    &.center {
      justify-content: center;
    }
  }
}
