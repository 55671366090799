/* Variables

 */
.dimmed-editor {
  position: absolute;
  background-color: black;
  height: 156%;
  width: 100%;
  opacity: 0.5;
  z-index: 6;
}

.table-quadrants-pma-editor-page .qv4-pma-editor-page-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container {
  width: 1365px;
  height: 1070px;
  margin: 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-right,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-right,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-right,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-right,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-right,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-right {
  width: 50%;
  position: relative;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container {
  position: absolute;
  bottom: 0px;
  height: 690px;
  width: 100%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title {
  position: absolute;
  top: -5px;
  left: 327px;
  width: 460px;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text {
  color: #424142;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.15em;
  font-size: 50px;
  text-transform: uppercase;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container {
  position: absolute;
  top: 67px;
  left: 408px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 11px;
  margin-bottom: 15px;
  letter-spacing: 0.1em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 102px;
  left: 401px;
  height: 76%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: #424142;
  font-size: 22px;
  position: absolute;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1 {
  top: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2 {
  top: 207px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3 {
  top: 364px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container {
  position: absolute;
  top: 63px;
  left: 0px;
  height: 150px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 56%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-text-container p {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.025em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-container {
  z-index: 1;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-image-container .experts-trends-image {
  height: 797px;
  max-width: 100%;
  max-height: 100%;
  width: 56%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section {
  display: flex;
  width: 100%;
  position: absolute;
  left: 408px;
  top: 111px;
  z-index: 2;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container {
  width: 80%;
  height: 86%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section {
  margin-bottom: 16px;
  width: 74%;
  margin-left: 40px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 6px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  line-height: 16px;
  margin-bottom: 9px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer {
  display: flex;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column {
  display: flex;
  flex-direction: column;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 8.5px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml {
  margin-left: 3px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource {
  font-weight: 500;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .backpage-bg,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .backpage-bg,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .backpage-bg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .backpage-bg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .backpage-bg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .backpage-bg {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 760px;
  z-index: 0;
  background-color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .neighborhood-trends-title-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .neighborhood-trends-title-container {
  position: absolute;
  top: 645px;
  left: 0;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container .neighborhood-trends-title,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container .neighborhood-trends-title,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .neighborhood-trends-title-container .neighborhood-trends-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container .neighborhood-trends-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container .neighborhood-trends-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .neighborhood-trends-title-container .neighborhood-trends-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 23px;
  color: black;
  margin-left: 40px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .ye2-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-first-left .neighborhood-trends-title-container .neighborhood-trends-title-divider {
  height: 1px;
  background-color: black;
  width: 360px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table {
  width: 100%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 10px 0 30px 24px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: black;
  margin: -3px 8px 0 8px;
  height: 4px;
  width: 4px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 4px;
  height: 4px;
  width: 4px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: black;
  text-transform: uppercase;
  font-size: 33px;
  line-height: 26px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 25px;
  line-height: 18px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 24px;
  line-height: 18px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 18px;
  line-height: 26px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 23px;
  line-height: 26px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider {
  height: 1px;
  margin-top: 2px;
  width: 406px;
  margin-left: 19px;
  background-color: black;
  margin-bottom: 1px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .golden-divider.extra-long {
  margin-left: 19px;
  width: 663px;
  height: 0.7px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
  z-index: 0;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 245px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 355px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  text-transform: uppercase;
  text-align: right;
  line-height: 17px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 12px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 21px;
  display: flex;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 12px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8px;
  text-transform: uppercase;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers {
  margin-top: -3px;
  min-height: 500px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 512px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th {
  font-size: 9px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th p {
  line-height: 11px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 20px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 30px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 17px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8.4px;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 68px;
  text-align: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 46px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 74px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 189px;
  text-align: left;
  font-weight: 400;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) .smaller-row-address {
  font-size: 7.5px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  font-weight: 400;
  width: 82px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 63px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 31px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 90px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 53px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 8px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8px;
  line-height: 8px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p {
  font-size: 6px;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend {
  border: 1px solid gray;
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 7px;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 19px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-second-left-middle-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-second-left-middle-section {
  height: 855px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators {
  width: 100%;
  height: 165px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row {
  display: flex;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row p:nth-child(2),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row p:nth-child(2) {
  width: 88px;
  text-align: right;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row .market-indicators-footer-compare,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row .market-indicators-footer-compare {
  width: 105px;
  display: flex;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row .market-indicators-footer-compare.six-month-indicator {
  width: 118px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row .market-indicators-footer-compare.twelve-month-indicator {
  width: 122px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .market-indicators-footer-row p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 13px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
  padding-top: 1px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  text-transform: uppercase;
  text-align: right;
  line-height: 17px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: 19px;
  margin-top: 1px;
  padding-top: 1px;
  width: 404px;
  background-color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: -4px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #b8b8b8;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.35);
  height: 50px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -4px;
  width: 100px;
  height: 1.5px;
  background-color: #b8b8b8;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  color: black;
  text-align: center;
  margin-right: 20px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up {
  width: 30px;
  height: 30px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down {
  width: 30px;
  height: 30px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .table-quadrants-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: black;
  margin-bottom: -4px;
  text-align: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left {
  width: 50%;
  position: relative;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer {
  position: absolute;
  top: 0;
  left: 0;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-background-img,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-background-img {
  height: 370px;
  width: 100%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container {
  position: absolute;
  top: 25px;
  left: 60px;
  text-transform: uppercase;
  width: 85%;
  display: flex;
  flex-direction: column;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle {
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: black;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-title {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xl-speedometer-area-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xl-speedometer-area-text {
  font-size: 22px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .large-speedometer-area-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .large-speedometer-area-text {
  font-size: 22px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .medium-speedometer-area-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .medium-speedometer-area-text {
  font-size: 22px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .small-speedometer-area-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .small-speedometer-area-text {
  font-size: 22px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text {
  font-size: 16px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xxs-speedometer-area-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xxs-speedometer-area-text {
  font-size: 15px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: #475862;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-img,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-img {
  position: absolute;
  top: 110px;
  right: 80px;
  height: 150px;
  width: 250px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .buyers-advantage-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .buyers-advantage-text {
  position: absolute;
  top: 250px;
  right: 255px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.05em;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .sellers-advantage-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .sellers-advantage-text {
  position: absolute;
  top: 250px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.05em;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container {
  position: absolute;
  top: 300px;
  right: 0px;
  height: 50px;
  width: 547px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title {
  text-align: left;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 21px;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics {
  position: absolute;
  top: 38px;
  right: 20px;
  text-align: right;
  z-index: 4;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-chartTip,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-chartTip {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 50;
  max-width: 250px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph {
  height: 200px;
  width: 350px;
  margin-left: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph .graph-line,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph .graph-line {
  stroke: rgba(235, 242, 242, 0.4);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph .x-labels,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph .x-labels {
  text-anchor: middle;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph .y-labels,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph .y-labels {
  text-anchor: end;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph .quiz-graph-grid,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph .quiz-graph-grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .label-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .label-title {
  text-anchor: middle;
  text-transform: uppercase;
  font-size: 12px;
  fill: gray;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left.firstBg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left.firstBg {
  fill: rgba(185, 158, 66, 0.8);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left.secondBg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left.secondBg {
  fill: rgba(163, 107, 113, 0.8);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left.thirdBg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left.thirdBg {
  fill: rgba(123, 121, 139, 0.8);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-dot.firstBg,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.firstBg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph-dot.firstBg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.firstBg {
  fill: rgba(185, 158, 66, 0.8);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-dot.secondBg,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.secondBg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph-dot.secondBg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.secondBg {
  fill: rgba(163, 107, 113, 0.8);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-dot.thirdBg,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.thirdBg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph-dot.thirdBg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .quiz-graph-start-dot.thirdBg {
  fill: rgba(123, 121, 139, 0.8);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .svg-cover,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .svg-cover {
  width: 350px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .bottom-x-labels text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .bottom-x-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 9px;
  letter-spacing: -0.075em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .data-labels,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .data-labels text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .data-labels,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .data-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10.5px;
  width: 30px;
  text-align: center;
  text-anchor: middle;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container {
  position: absolute;
  top: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section {
  display: flex;
  height: 180px;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 12px 18px -9px rgb(204.5, 204.5, 204.5);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row {
  display: flex;
  justify-content: space-between;
  height: 200px;
  width: 100%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph {
  width: 33.3%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section {
  width: 57%;
  height: 90%;
  display: flex;
  flex-direction: column;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row {
  font-weight: bold;
  width: 100%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: black;
  line-height: 12px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider {
  height: 80px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0 5px;
  align-self: center;
  display: flex;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section {
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 238, 230);
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 83%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 46%;
  height: 120px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  margin-top: -15px;
  letter-spacing: 0.05em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 9.6px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 7px;
  text-transform: uppercase;
  color: black;
  margin-top: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text {
  margin-top: 8px;
  margin-bottom: 10px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  color: black;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow {
  height: 25px;
  width: 45px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .qv4-sellers-adv-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .qv4-sellers-adv-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 13px;
  margin-top: 17px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .dashboard-bg,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .dashboard-bg {
  position: absolute;
  margin: auto;
  left: 5%;
  right: 5%;
  top: 9.6%;
  width: 572px;
  height: 849px;
  z-index: 0;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-header,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 70px;
  margin-top: 69px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-header .db-header-main,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-header .db-header-main {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: 0.35em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body {
  position: absolute;
  top: 133px;
  width: 100%;
  height: 910px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-body-header,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-body-header {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px auto 10px auto;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-body-header .db-body-header-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-body-header .db-body-header-title {
  color: #838b75;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-align: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-body-subheader,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-body-subheader {
  width: 280px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box {
  right: 0px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider {
  right: 0px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header {
  width: 490px;
  flex-direction: row-reverse;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category {
  text-align: left;
  margin-left: 15%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation {
  width: 65%;
  margin-left: 13%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 13%;
  margin-top: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage {
  display: flex;
  justify-content: flex-start;
  color: #8f555c;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box {
  left: 0px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider {
  left: 0px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header {
  width: 471px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category {
  text-align: right;
  margin-right: -6.5%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation {
  width: 65%;
  margin-left: 22%;
  text-align: right;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 22%;
  margin-top: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage {
  display: flex;
  justify-content: flex-end;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container {
  clear: both;
  display: inline-block;
  width: 100%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator {
  height: 189px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 1.3333px;
  width: 502px;
  position: absolute;
  top: 48.5px;
  z-index: 3;
  background-color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
  height: 210px;
  z-index: 1;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 575px;
  height: 91%;
  position: absolute;
  z-index: 2;
  background-color: rgba(251, 251, 246, 0.9);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  height: 40px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num {
  width: 125px;
  text-align: right;
  color: #a36b71;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 34px;
  line-height: 28px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  width: 280px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 13px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  line-height: 17px;
  text-align: justify;
  text-justify: inter-word;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation {
  margin-top: 10px;
  color: #424142;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  line-height: 17px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage {
  color: #8f555c;
  font-weight: 400;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-weight: 400;
  color: black;
  margin-left: 4px;
  display: inline-block;
  float: right;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: 19px;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-weight: 700;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-text,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.075em;
  color: #8f555c;
  width: 90%;
  margin: auto;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  display: inline-block;
  margin-left: 10px;
  width: auto;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal {
  position: absolute;
  z-index: 8;
  background-color: white;
  top: 0px;
  width: 100%;
  left: -100%;
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border: 3px solid gray;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-modal-title,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-modal-title {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container {
  display: flex;
  justify-content: flex-end;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .update-changes-button,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .update-changes-button {
  align-self: flex-end;
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 79, 172);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .update-changes-button:hover,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .update-changes-button:hover {
  cursor: pointer;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .update-changes-button p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .update-changes-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .close-photo-modal-button,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .close-photo-modal-button {
  align-self: flex-end;
  width: 178px;
  height: 32px;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .close-photo-modal-button:hover,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .close-photo-modal-button:hover {
  cursor: pointer;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .close-photo-modal-button p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .update-changes-button-container .close-photo-modal-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-modal-legend,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-modal-legend {
  width: 40%;
  margin-left: 20px;
  margin-bottom: 40px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-modal-legend .photo-modal-legend-row,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-modal-legend .photo-modal-legend-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-modal-legend .photo-modal-legend-row .photo-modal-my-listing-dot,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-modal-legend .photo-modal-legend-row .photo-modal-my-listing-dot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  margin-right: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table .listing-row-gap,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table .listing-row-gap {
  height: 30px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table thead tr,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table thead tr {
  align-items: flex-end;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table thead tr th,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table thead tr th {
  font-size: 9px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table thead tr th p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table thead tr th p {
  line-height: 11px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table thead tr th:last-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table thead tr th:last-child {
  padding-right: 30px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr.prop-row-checked,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr.prop-row-checked {
  background-color: rgb(50, 182, 51);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 17px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8.4px;
  color: black;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text.sold,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text.contingent, .table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text.pending,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text.contingent,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text.active,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .photo-row-checkbox,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .photo-row-checkbox {
  height: 25px;
  width: 25px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .photo-row-checkbox:hover,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr .photo-row-checkbox:hover {
  cursor: pointer;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr > td:nth-child(2),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr > td:nth-child(2) {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr > td:nth-child(2) p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr > td:nth-child(2) p {
  width: 68px;
  text-align: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(3),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(3) {
  width: 46px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(3) .date-td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(3) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(4),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(4) {
  width: 74px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(5),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(5) {
  width: 189px;
  text-align: left;
  font-weight: 400;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(5) .smaller-row-address,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(5) .smaller-row-address {
  font-size: 7.5px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(6),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(6) {
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(6) div,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(6) div {
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(6) .off-centered-dash,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(7),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(7) {
  font-weight: 400;
  width: 82px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(7) div,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(7) div {
  margin: auto;
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(7) .centered-td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(7) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(7) .off-centered-dash,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(7) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(8),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(8) {
  width: 63px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(9),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(9) {
  width: 31px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(10),
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(11),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(10),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(11) {
  width: 45px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(10) p,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(11) p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(10) p,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(11) p {
  width: 43px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(12),
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(12) {
  width: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(12) .my-listing-dot,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody tr td:nth-child(12) .my-listing-dot {
  height: 8px;
  width: 8px;
  background-color: green;
  border-radius: 50%;
  margin-right: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .presolds-row:last-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 8px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .recent-row:first-child,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .presolds-row:first-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .recent-row:first-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .recent-row:first-child td,
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .presolds-row:first-child td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .recent-row:first-child td,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.table-quadrants-pma-editor-page .qv4-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .single-table-row:last-child,
.table-quadrants-pma-editor-page .table-quadrants-pma-editor-page-container .qv4-second-right .photo-modal .photo-sales-table tbody .single-table-row:last-child {
  border: none;
}

.qv4-pma-editor-page-container,
.table-quadrants-pma-editor-page-container {
  width: 1465px;
  height: 1070px;
  margin: 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.qv4-pma-editor-page-container .qv4-first-left,
.qv4-pma-editor-page-container .qv4-first-right,
.qv4-pma-editor-page-container .qv4-second-left,
.qv4-pma-editor-page-container .qv4-second-right,
.table-quadrants-pma-editor-page-container .qv4-first-left,
.table-quadrants-pma-editor-page-container .qv4-first-right,
.table-quadrants-pma-editor-page-container .qv4-second-left,
.table-quadrants-pma-editor-page-container .qv4-second-right {
  width: 50%;
  position: relative;
}
.qv4-pma-editor-page-container .MuiDialog-paper,
.table-quadrants-pma-editor-page-container .MuiDialog-paper {
  background-color: green;
}
.qv4-pma-editor-page-container .MuiPaper-root,
.table-quadrants-pma-editor-page-container .MuiPaper-root {
  border: 15px solid green;
}
.qv4-pma-editor-page-container .quadrant-dialog,
.table-quadrants-pma-editor-page-container .quadrant-dialog {
  border: 12px solid red;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal {
  height: 320px;
  width: 370px;
  background-color: gray;
  position: absolute;
  z-index: 3;
  left: 125%;
  bottom: 5%;
  border-radius: 20px;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-title,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-title,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-title,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-title {
  width: 80%;
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
  overflow-y: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section .area-selection-row,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section .area-selection-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row input,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section .area-selection-row input,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row input,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section .area-selection-row input {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row input:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section .area-selection-row input:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row input:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section .area-selection-row input:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row p,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section .area-selection-row p,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .area-list-section .area-selection-row p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .area-list-section .area-selection-row p {
  font-size: 24px;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 90%;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button p,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button p,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button {
  width: 178px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button p,
.qv4-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button p,
.table-quadrants-pma-editor-page-container .qv4-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .add-areas-modal .add-areas-button-row .edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.qv4-pma-editor-page-container .qv4-first-left .first-left-dim,
.qv4-pma-editor-page-container .table-quadrants-first-left .first-left-dim,
.table-quadrants-pma-editor-page-container .qv4-first-left .first-left-dim,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .first-left-dim {
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.5;
  height: 375px;
  with: 100%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor {
  width: 1080px;
  position: absolute;
  right: 10px;
  top: 50%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .return-to-pma-btn,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .return-to-pma-btn,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .return-to-pma-btn,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .return-to-pma-btn {
  position: absolute;
  top: 100px;
  right: 100px;
  z-index: 4;
  background-color: gray;
  height: 25px;
  padding: 5px 10px;
  border-radius: 10px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .return-to-pma-btn div,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .return-to-pma-btn div,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .return-to-pma-btn div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .return-to-pma-btn div {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .return-to-pma-btn div:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .return-to-pma-btn div:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .return-to-pma-btn div:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .return-to-pma-btn div:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-select-instructions,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-select-instructions,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .area-select-instructions,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-select-instructions {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  color: red;
  animation: blinker 1s step-start 10;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-title-instructions,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-title-instructions,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-title-instructions,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-title-instructions {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list {
  display: flex;
  justify-content: center;
  width: 60%;
  margin: 40px auto 0 auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button {
  border: 2px solid gray;
  width: auto;
  height: 40px;
  padding: 0 10px;
  margin: 0 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button div,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button div,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button div {
  color: black;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button.highlighted-btn,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button.highlighted-btn,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button.highlighted-btn,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button.highlighted-btn {
  border: none;
  background-color: #3d85c6;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button.highlighted-btn div,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button.highlighted-btn div,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .area-list .area-button.highlighted-btn div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .area-list .area-button.highlighted-btn div {
  color: white;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-body,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-body,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-body,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-body {
  margin: 20px auto 0 auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row div:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row div:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row div:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row div:hover {
  background-color: rgb(115.25, 115.25, 115.25);
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button {
  background-color: #3d85c6;
  height: 30px;
  width: 150px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button div,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button div,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .select-quadrant-button-container .select-quadrant-button div {
  color: white;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .empty-div,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .empty-div,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selection-row .empty-div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selection-row .empty-div {
  width: 30%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-btn,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-btn,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-btn,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-btn {
  background-color: #3d85c6;
  height: 25px;
  width: 185px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-btn:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-btn:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-btn:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-btn:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-btn div,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-btn div,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-btn div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-btn div {
  color: white;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-title,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-title,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-title,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-title {
  right: 170px;
  top: 50px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .edit-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .edit-text {
  top: 50px;
  right: 270px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .restore-btn,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .restore-btn,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .restore-btn,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .restore-btn {
  height: 35px;
  width: 80px;
  left: 270px;
  top: 60px;
  position: absolute;
  color: #3d85c6;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .restore-btn:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .restore-btn:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .selected-quadrant-row .restore-btn:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .selected-quadrant-row .restore-btn:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .submit-btn-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .submit-btn-container {
  display: flex;
  justify-content: center;
  height: 25px;
  margin: 25px auto 0 auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container .submit-btn,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .submit-btn-container .submit-btn,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container .submit-btn,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .submit-btn-container .submit-btn {
  background-color: #3d85c6;
  height: 25px;
  width: 280px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container .submit-btn:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .submit-btn-container .submit-btn:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container .submit-btn:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .submit-btn-container .submit-btn:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container .submit-btn div,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .submit-btn-container .submit-btn div,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .submit-btn-container .submit-btn div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .submit-btn-container .submit-btn div {
  color: white;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section {
  background-color: #bfbdb8;
  width: 320px;
  height: 130px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-title,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section .quadrant-section-title,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-title,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section .quadrant-section-title {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: regular;
  font-size: 30.5px;
  text-transform: uppercase;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section .quadrant-section-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section .quadrant-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-text p,
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-text div,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section .quadrant-section-text p,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section .quadrant-section-text div,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-text p,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-section .quadrant-section-text div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section .quadrant-section-text p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-section .quadrant-section-text div {
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-divider,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-divider,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-divider,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-divider {
  width: 100%;
  height: 2px;
  background-color: gray;
  margin: 30px auto 0 auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selector-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selector-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-editor .quadrant-selector-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-editor .quadrant-selector-text {
  text-align: center;
  margin: 30px auto 0 auto;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container {
  background-color: white;
  position: absolute;
  z-index: 2;
  left: 101%;
  width: 98%;
  bottom: 5%;
  height: 515px;
  border-radius: 20px;
  overflow-y: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #3c4c55;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title-divider,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title-divider,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title-divider,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-title-divider {
  margin-top: 0px;
  width: 70%;
  background-color: #4e85af;
  height: 3px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text {
  margin-top: 5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 17px;
  color: #00558f;
  display: flex;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text {
  flex-direction: column;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text {
  margin-top: 5px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button {
  margin: 0 7px;
  height: 20px;
  width: 60px;
  border-radius: 10px;
  border: 1px solid #00558f;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #4e85af;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form {
  border-radius: 15px;
  height: auto;
  padding: 20px 0;
  width: 90%;
  margin: 30px auto 0 auto;
  border: 2px solid #4e85af;
  border-radius: 15px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text {
  margin-left: 5%;
  margin-top: 15px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #475862;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider {
  width: 90%;
  background-color: #4e85af;
  height: 2px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  color: #00558f;
  margin-left: 5%;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle {
  margin-top: 15px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body {
  display: flex;
  width: 89%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter {
  text-transform: capitalize;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body {
  flex-direction: row;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col {
  width: 45%;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea {
  max-width: 265px;
  min-width: 265px;
  height: 160px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  line-height: 19px;
  color: #424142;
  resize: none;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead {
  display: flex;
  flex-direction: row;
  width: 70%;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead {
  margin-top: 15px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.025em;
  line-height: 19px;
  text-transform: uppercase;
  color: #00305e;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error {
  color: rgb(184, 37, 37);
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label {
  width: 155px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter {
  text-transform: capitalize;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input {
  width: 100%;
  height: 30px;
  border: 0;
  border-bottom: 3px solid #4e85af;
  outline: 0;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter {
  text-transform: capitalize;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus {
  outline: none !important;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #414042;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col {
  width: 50%;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input {
  font-family: playfair-display, serif;
  font-weight: 600;
  font-style: italic;
  font-size: 13px;
  color: #414042;
  letter-spacing: 0.05em;
  align-self: flex-start;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter {
  text-transform: capitalize;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col {
  width: 80%;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input {
  letter-spacing: 0.025em;
  font-size: 13px;
  font-family: playfair-display, serif;
  font-weight: 600;
  font-style: italic;
  color: #414042;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter {
  text-transform: capitalize;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter {
  text-transform: capitalize;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col {
  width: 90%;
  margin: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section {
  width: 900px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section label,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section label,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section label,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section label {
  margin-left: 20px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container span,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container span,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container span,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container span {
  font-weight: 700;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  margin-left: 5px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input {
  width: 700px;
  height: 25px;
  margin: auto;
  border: 0;
  outline: 0;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus {
  outline: none !important;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section:nth-child(2),
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section:nth-child(2),
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section:nth-child(2),
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .title-dialog-body-col .title-column .title-section:nth-child(2) {
  margin-top: 50px;
  margin-bottom: 50px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 20px auto 15px auto;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .button-col,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .button-col,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .button-col,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .button-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .restore-default,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .restore-default,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .restore-default,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .restore-default {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .restore-default:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .restore-default:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .restore-default:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .restore-default:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .restore-default p,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .restore-default p,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .restore-default p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .restore-default p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button p,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button p,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button {
  width: 208px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button p,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button p,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .edit-form-button-container .edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.qv4-pma-editor-page-container .qv4-first-left .edit-quadrant-container .saving-message,
.qv4-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .saving-message,
.table-quadrants-pma-editor-page-container .qv4-first-left .edit-quadrant-container .saving-message,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .edit-quadrant-container .saving-message {
  color: green;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  letter-spacing: 0.05em;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container {
  position: absolute;
  bottom: 0px;
  height: 736px;
  width: 100%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-inner-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-inner-container {
  width: 85%;
  margin: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container {
  height: 180px;
  width: 100%;
  position: absolute;
  width: 85%;
  z-index: 0;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-bg-image-container .testimonial-bg-image {
  height: 100%;
  width: 100%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .add-testimonial-text {
  color: red;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
  line-height: 23px;
  text-align: center;
  width: 100%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .testimonial-content-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin: auto;
  position: absolute;
  z-index: 1;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff {
  padding: 0 10px;
  color: #424142;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.2em;
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.small-size-testimonial,
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.small-size-testimonial,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.small-size-testimonial,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.small-size-testimonial,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.small-size-testimonial,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.small-size-testimonial,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-text.small-size-testimonial,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff.small-size-testimonial {
  font-size: 12px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  width: 90%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-testimonial .qv4-testimonial-text-container .qv4-testimonial-signoff p {
  text-align: right;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn {
  height: 36px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4e85af;
  position: absolute;
  z-index: 5;
  border-radius: 10px;
  font-size: 11.5px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn:hover {
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn div,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn div {
  color: white;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.apply-all-areas-btn {
  left: 260px;
  top: 210px;
  width: 150px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-title-btn,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-title-btn {
  top: 5px;
  left: 405px;
  width: 150px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-0,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-0 {
  top: 173px;
  left: 5px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-1,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-1 {
  top: 285px;
  left: 155px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-2,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-2 {
  top: 285px;
  left: 455px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-3,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-3 {
  top: 665px;
  left: 155px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-4,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-4,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-btn.quadrant-btn-4,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-btn.quadrant-btn-4 {
  top: 665px;
  left: 455px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-title-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 38px;
  margin-top: 50px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  padding: 8px 0px 7px 0px;
  color: #424142;
  align-self: flex-start;
  text-transform: uppercase;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-title-text p {
  font-size: 41px;
  line-height: 10px;
  letter-spacing: 0.1em;
  margin-right: 5px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-title-container .quadrant-subtitle-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  padding: 8px 0px 7px 0px;
  color: #424142;
  align-self: flex-end;
  margin-left: 5px;
  line-height: 20px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 400px;
  flex-wrap: wrap;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 46px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .section-container .section-row,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .section-container .section-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  height: 165px;
  width: 43%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-number {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  color: #424142;
  margin-right: 15px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section.selected-quadrant-section {
  background-color: rgb(222.4311111111, 221.4133333333, 218.8688888889);
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section:hover,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section:hover {
  background-color: rgb(222.4311111111, 221.4133333333, 218.8688888889);
  cursor: pointer;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title {
  width: 100%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #424142;
  text-align: left;
  font-size: 14px;
  text-transform: uppercase;
  padding-top: 10px;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-title:first-letter {
  text-transform: capitalize;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  line-height: 17px;
  color: #424142;
  width: 100%;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text p {
  text-align: center;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-body .quadrant-section .quadrant-section-text:first-letter {
  text-transform: capitalize;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-logo-container,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -210px;
  z-index: 4;
  position: relative;
}
.qv4-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.qv4-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-pma-editor-page-container .qv4-first-left .quadrant-container .quadrant-logo-container img,
.table-quadrants-pma-editor-page-container .table-quadrants-first-left .quadrant-container .quadrant-logo-container img {
  height: 125px;
  width: auto;
}