/* Variables

 */
.quarterly-print .qv4-print-page .qv4-print-page-container,
.quarterly-print .qv4-print-page .ye2-print-page-container {
  position: relative;
  width: 1698px;
  height: 1140px;
  margin: 0;
  background: white;
  background-size: contain;
  display: flex;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-right,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-right,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-right,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-right,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-right,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-right {
  width: 50%;
  position: relative;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 690px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.gail-highlight,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.gail-highlight,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.gail-highlight,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.gail-highlight {
  height: 718px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alt-ye2-property-highlight,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alt-ye2-property-highlight,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alt-ye2-property-highlight,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alt-ye2-property-highlight {
  height: 751px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container .ye2-property-highlight-bg {
  height: 100%;
  width: 100%;
  background-color: #dde5e7;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg {
  top: 0px;
  height: 127px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-bg-container.two-prop-bg .ye2-property-highlight-bg {
  height: 495px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-bg-container .alt-ye2-property-highlight-bg {
  height: 100%;
  width: 100%;
  background-color: #231f20;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-body,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-body {
  position: absolute;
  top: 15px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 97.5%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container {
  margin: 30px auto 0px auto;
  position: relative;
  height: 160px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr {
  position: absolute;
  right: 45px;
  top: -5px;
  width: 90px;
  height: 90px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-qr img {
  width: 90px;
  height: 90px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow {
  position: absolute;
  right: 137px;
  top: 33px;
  width: 90px;
  height: 90px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .gummow-arrow img {
  width: 60px;
  height: 60px;
  transform: rotate(-25deg);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .alt-y2-property-highlight-title-text {
  color: white;
  font-size: 30.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 40px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small {
  padding-left: 10px;
  font-size: 36px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-scan-here {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: white;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  width: 70%;
  display: flex;
  justify-content: flex-end;
  margin-top: 7px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76%;
  margin: 16px auto 0 auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .alt-ye2-property-highlight-title-container .alt-ye2-property-subtitle p {
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container {
  margin: -5px auto 0px auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-text {
  color: black;
  font-size: 30.5px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 40px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-highlight-title-top-row .y2-property-highlight-title-year.small {
  padding-left: 10px;
  font-size: 36px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle {
  height: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 681px;
  margin: 5px auto 0 auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle p {
  letter-spacing: 0.05em;
  font-size: 16.25px;
  line-height: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: black;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text {
  width: 87%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.smaller-ye2-subtitle-text p {
  font-size: 14.8px;
  line-height: 21px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p {
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:first-child {
  font-weight: 500;
  font-size: 28px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.gail-ye2-prop-subtitle-styles p:nth-child(2) {
  margin-top: 10px;
  margin-bottom: -5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.column-subtitle-styles,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.column-subtitle-styles,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.column-subtitle-styles,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.column-subtitle-styles {
  flex-direction: column;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 {
  width: 87%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle.alt-prop-highlight-subtitle-4-8 p {
  font-size: 14.8px;
  line-height: 21px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-title-container .ye2-property-subtitle .red-prop-highlight-subtitle-text {
  color: rgb(239.7, 0, 0);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 18px;
  left: 0px;
  height: 157px;
  width: 100%;
  z-index: 1;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-bg-container .gail-ye2-property-highlight-bg {
  height: 315px;
  width: 93%;
  background-color: #e3e9dd;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container {
  margin: -5px auto 0px auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-title {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 26px;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 15.25px;
  width: 90%;
  margin: 6px auto 0 auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-subtitle.gail-m1 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-1 {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  width: 90%;
  margin: 40px auto 0 auto;
  font-family: "utopia-std", serif;
  font-weight: 400;
  font-style: italic;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-highlight-footer-2 {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  width: 90%;
  margin: 10px auto 0 auto;
  font-family: "utopia-std", serif;
  font-weight: 400;
  font-style: italic;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-text {
  color: black;
  font-size: 30.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-highlight-title-top-row .gail-y2-property-highlight-title-year {
  padding-left: 10px;
  color: black;
  font-size: 40px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-title-container .gail-ye2-property-subtitle {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  width: 90%;
  margin: 8px auto 0 auto;
  letter-spacing: 0.1em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container {
  margin: 0px auto 10px auto;
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text {
  color: white;
  font-size: 25px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-ye2-property-highlight-photo-title-container .gail-ye2-property-highlight-photo-title-text .no-title {
  height: 14px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  height: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-row-banner-text {
  font-size: 10.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 14px;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text {
  width: 65%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 8px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text.white-text {
  color: white;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text > div {
  height: 13px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 10.5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .gail-one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .gail-prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 419px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text {
  position: absolute;
  width: 100%;
  bottom: 29px;
  left: 0;
  height: 33px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lower-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lower-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lower-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lower-banner-text {
  bottom: 25px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lower-banner-text:nth-child(2) {
  bottom: 24px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price {
  font-weight: 600;
  text-transform: uppercase;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .no-bold-regular-case,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .no-bold-regular-case,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .no-bold-regular-case,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .no-bold-regular-case {
  font-weight: 400;
  text-transform: none;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .bolded-listing-price-regular-case {
  font-weight: 600;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row {
  display: flex;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .extra-status-row p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .extra-status-row p {
  font-weight: 600;
  font-size: 12px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lowered-banner,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lowered-banner,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text.lowered-banner,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text.lowered-banner {
  bottom: 3px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text div,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text div,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text div,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text div {
  font-size: 11px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 13.5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .capitalize-city,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .capitalize-city,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .capitalize-city,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .capitalize-city {
  text-transform: capitalize;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .reversed-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .reversed-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .row-banner-text .reversed-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .row-banner-text .reversed-text {
  color: white;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
  margin-top: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row {
  display: flex;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column {
  align-items: center;
  flex-direction: column;
  margin-top: 1px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row.representation-column div:nth-child(2) {
  margin-top: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row p {
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row .pending-representation,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row .pending-representation,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row .pending-representation,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row .pending-representation {
  color: green;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row .active-representation,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row .active-representation,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .representation-row .active-representation,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .representation-row .active-representation {
  color: #3d85c6;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.no-mt-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.no-mt-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.no-mt-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.no-mt-banner-text {
  margin-top: 0px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.white-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.white-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.white-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.white-text {
  color: white;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text > div,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text > div {
  height: 13px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text div:nth-child(1),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text div:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text div:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 11.5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text div:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text div:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text div:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text .brokerage-courtesy:nth-child(2) {
  margin-top: 6px;
  margin-bottom: -4px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin {
  margin-top: 0px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .representation-row div,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p {
  line-height: 11px;
  height: 11px;
  margin-top: 3px;
  font-size: 11.5px;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-highlight-prop-line-container p:first-child {
  margin-top: 4px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .photo-footer-text.less-photo-footer-margin .extra-mt-highlight-prop-line {
  padding-top: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 {
  width: 95%;
  margin: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col {
  position: relative;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-1 .row1-photo-col img {
  height: 180px;
  width: 240px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 {
  padding-top: 15px;
  width: 95%;
  margin: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col {
  position: relative;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-row-2 .row2-photo-col img {
  height: 238px;
  width: 370px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout {
  margin-top: 20px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-row-banner-text {
  font-size: 11.5px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 14px;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text {
  width: 65%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
  margin: 2px 10px 0 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text.white-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text.white-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text.white-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text.white-text {
  color: white;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text > div {
  height: 13px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 11.5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .one-prop-banner-row .one-prop-photo-footer-text div:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-1-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: 459px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col {
  width: 367px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 222px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.reduced-image-height {
  height: 210px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin {
  margin-top: 32px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .quadrant-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text {
  height: 72px;
  bottom: 3px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text > div:first-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text > div:first-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text > div:first-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text > div:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text > div:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text > div:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text > div:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text > div:first-child {
  margin-top: 2px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .photo-footer-text {
  margin-top: 0px;
  height: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container {
  height: 32px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line-container p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line-container p {
  height: 11px;
  margin-top: 3px;
  font-size: 11.5px;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(1),
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(1),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(1),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(1),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(1) {
  line-height: 9.6px;
  height: 9.5px;
  margin-top: 2px;
  font-size: 11.5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text .extra-highlight-prop-line:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text .extra-highlight-prop-line:nth-child(2) {
  line-height: 9.6px;
  height: 8px;
  margin-top: 2px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0 {
  height: 252px;
  width: 100%;
  max-width: 371px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .property-highlight-photo-0.reduced-image-height,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .property-highlight-photo-0.reduced-image-height {
  height: 210px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  margin-top: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2.taller-col-2-margin {
  margin-top: 32px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .galli-prop-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout {
  margin-top: 30px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  width: 49%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  width: 375px;
  height: 240px;
  max-height: 240px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.park-870-img-position {
  object-fit: cover;
  object-position: 6% 34%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  width: 375px;
  height: auto;
  max-height: 240px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.seventy-fifty-img-position {
  object-fit: cover;
  object-position: 70% 50%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0.cliff-200-img-position {
  object-fit: cover;
  object-position: 0% 34%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0 {
  height: auto;
  width: auto;
  max-height: 459px;
  max-width: 367px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0.highlight-img-contain {
  object-fit: contain;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 {
  width: 96%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px auto 0 auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 198px;
  width: 100%;
  max-width: 297px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .prop-3-layout.using-4-prop-layout .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0 {
  height: 132px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer {
  display: flex;
  flex-direction: column;
  margin: 40px auto 0 auto;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: 8px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text {
  color: black;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1 {
  font-size: 19px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  line-height: 32px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 93%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-1.smaller-footer-1-text {
  font-size: 14.5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .prop-highlight-footer-text-2 {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  line-height: 29px;
  margin-top: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 {
  color: white;
  font-size: 19px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.025em;
  line-height: 32px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 93%;
  margin-top: 18px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-1 .no-title {
  height: 14px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 {
  color: white;
  font-size: 24px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  width: 100%;
  letter-spacing: 0.025em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-custom-footer-text .alt-ye2-property-highlight-footer-text-2 .no-title {
  height: 12px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .ye2-property-highlight-photo-title-text-fixed {
  color: white;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  padding-top: 20px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .y2-property-highlight-footer .y2-property-highlight-footer-text {
  color: white;
  font-size: 20px;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.125em;
  margin-top: 8px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight .ye2-property-highlight-inner-photo-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight .ye2-property-highlight-inner-photo-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 {
  height: 620px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .ye2-property-highlight-bg {
  height: 315px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-prop-highlights-col-2 {
  margin-top: 0px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .row-banner-text {
  bottom: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col {
  height: 325px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row2-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row1-photo-col .property-highlight-photo-0,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row2-photo-col .property-highlight-photo-0 {
  height: 300px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .row-banner-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .row-banner-text {
  height: 40px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .photo-footer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .photo-footer-text {
  margin-top: 12px;
  margin-bottom: -12px;
  height: 26px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-1 .highlight-img-contain-lm,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .ye2-property-highlight.alternate-property-highlight-container-1 .prop-2-layout .ye2-property-highlight-inner-photo-container .ye2-prop-highlights-col-2 .highlight-img-contain-lm {
  object-fit: contain;
  height: 256px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container {
  background-color: white;
  position: absolute;
  z-index: 2;
  left: 101%;
  width: 98%;
  bottom: 11%;
  height: 515px;
  border-radius: 20px;
  overflow-y: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #3c4c55;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-title-divider,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-title-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-title-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-title-divider {
  margin-top: 0px;
  width: 70%;
  background-color: #4e85af;
  height: 3px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text {
  margin-top: 5px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 19px;
  color: #00558f;
  display: flex;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text.column-text {
  flex-direction: column;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .click-submit-text {
  margin-top: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-title-container .edit-quadrant-subtitle-text .subtitle-edit-button {
  margin: 0 7px;
  height: 20px;
  width: 60px;
  border-radius: 10px;
  border: 1px solid #00558f;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #4e85af;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form {
  border-radius: 15px;
  height: auto;
  padding: 20px 0;
  width: 90%;
  margin: 30px auto 0 auto;
  border: 2px solid #4e85af;
  border-radius: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-text {
  margin-left: 5%;
  margin-top: 15px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #475862;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-title-divider {
  width: 90%;
  background-color: #4e85af;
  height: 2px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  color: #00558f;
  margin-left: 5%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-title-container .edit-quadrant-form-subtitle-text.mt-subtitle {
  margin-top: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body {
  display: flex;
  width: 89%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .input-uppercase,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .input-uppercase,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .input-uppercase,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .input-uppercase {
  text-transform: uppercase;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .title-input:first-letter {
  text-transform: capitalize;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body.row-body,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body.row-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body.row-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body.row-body {
  flex-direction: row;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body.row-body .edit-form-body-col {
  width: 45%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .quadrant-textarea {
  max-width: 265px;
  min-width: 265px;
  height: 160px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  color: #424142;
  resize: none;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead {
  display: flex;
  flex-direction: row;
  width: 70%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead.subtitle-thead {
  margin-top: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-thead > div p {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.025em;
  line-height: 19px;
  text-transform: uppercase;
  color: #00305e;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col .character-count-error {
  color: rgb(184, 37, 37);
  font-size: 13px;
  font-weight: 500;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col label {
  width: 155px;
  font-size: 13px;
  width: 100%;
  font-weight: 500;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input .address-input-col:first-letter {
  text-transform: capitalize;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input {
  width: 100%;
  height: 30px;
  border: 0;
  border-bottom: 3px solid #4e85af;
  outline: 0;
  font-size: 16px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:first-letter {
  text-transform: capitalize;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input input:focus {
  outline: none !important;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input > div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #414042;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input .address-input-col {
  width: 50%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input input {
  font-family: playfair-display, serif;
  font-weight: 600;
  font-style: italic;
  font-size: 13px;
  color: #414042;
  letter-spacing: 0.05em;
  align-self: flex-start;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.main-title-input:first-letter {
  text-transform: capitalize;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input .address-input-col {
  width: 80%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input {
  letter-spacing: 0.025em;
  font-size: 13px;
  font-family: playfair-display, serif;
  font-weight: 600;
  font-style: italic;
  color: #414042;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input input:first-letter {
  text-transform: capitalize;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-quadrant-form .edit-quadrant-form-body .edit-form-body-col .edit-form-input.subtitle-input:first-letter {
  text-transform: capitalize;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col {
  width: 90%;
  margin: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section {
  width: 900px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section label,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section label,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section label,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section label {
  margin-left: 20px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container span,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container span,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container span,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container span {
  font-weight: 700;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .old-text-container .actual-title-text {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
  margin-left: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .extra-long-input,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .extra-long-input,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .extra-long-input,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .extra-long-input {
  width: 700px;
  height: 25px;
  margin: auto;
  border: 0;
  outline: 0;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section .extra-long-input:focus {
  outline: none !important;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .title-dialog-body-col .title-column .title-section:nth-child(2) {
  margin-top: 50px;
  margin-bottom: 50px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 20px auto 15px auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .button-col,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .button-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .button-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .button-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .restore-default,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .restore-default,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .restore-default,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .restore-default {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .restore-default:hover,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .restore-default:hover,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .restore-default:hover,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .restore-default:hover {
  cursor: pointer;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .restore-default p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .restore-default p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .restore-default p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .restore-default p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button {
  width: 178px;
  height: 32px;
  border: 1px solid #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button:hover,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button:hover,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button:hover,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button:hover {
  cursor: pointer;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-cancel-button p {
  color: #00305e;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button {
  width: 178px;
  height: 32px;
  background-color: #4e85af;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button:hover,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button:hover,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button:hover,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button:hover {
  cursor: pointer;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .edit-form-button-container .edit-form-submit-button p {
  color: white;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .mission-statement-modal-container .saving-message,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .mission-statement-modal-container .saving-message,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .mission-statement-modal-container .saving-message,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .mission-statement-modal-container .saving-message {
  color: green;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  letter-spacing: 0.05em;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .qv4-mission-statement,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .qv4-mission-statement,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .qv4-mission-statement,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .qv4-mission-statement {
  position: absolute;
  top: 450px;
  height: 350px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .qv4-mission-statement .qv4-mission-statement-title-container .qv4-mission-statement-title-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .qv4-mission-statement .qv4-mission-statement-title-container .qv4-mission-statement-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .qv4-mission-statement .qv4-mission-statement-title-container .qv4-mission-statement-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .qv4-mission-statement .qv4-mission-statement-title-container .qv4-mission-statement-title-text {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 36px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn {
  height: 36px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4e85af;
  position: absolute;
  z-index: 5;
  border-radius: 10px;
  font-size: 11.5px;
  font-weight: 700;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn:hover,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn:hover,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn:hover,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn:hover {
  cursor: pointer;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn div,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn div,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn div,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn div {
  color: white;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn.mission-statement-title-btn,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn.mission-statement-title-btn,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn.mission-statement-title-btn,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn.mission-statement-title-btn {
  top: -32px;
  left: 435px;
  width: 150px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn.mission-statement-btn-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn.mission-statement-btn-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .qv4-mission-statement .mission-statement-btn.mission-statement-btn-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .qv4-mission-statement .mission-statement-btn.mission-statement-btn-1 {
  top: 117px;
  left: 25px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container {
  height: 690px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-bg-image-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-bg-image-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-bg-image-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-bg-image-container {
  position: absolute;
  left: 0px;
  top: 79px;
  z-index: 0;
  height: 72%;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-bg-image-container .tax-rate-bg-image,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-bg-image-container .tax-rate-bg-image,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-bg-image-container .tax-rate-bg-image,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-bg-image-container .tax-rate-bg-image {
  width: 58%;
  height: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-inner-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-inner-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-inner-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-inner-container {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-title-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-title-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-title-container .tax-rate-title-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-title-container .tax-rate-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-title-container .tax-rate-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-title-container .tax-rate-title-text {
  align-self: flex-start;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30.5px;
  text-transform: uppercase;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-title-container .tax-rate-subtitle-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-title-container .tax-rate-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-title-container .tax-rate-subtitle-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-title-container .tax-rate-subtitle-text {
  align-self: flex-end;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 22px;
  letter-spacing: 0.025em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section {
  margin-top: 34px;
  height: 200px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(246, 247, 247);
  box-shadow: 0px 15px 9px 0px rgb(191.75, 191.75, 191.75);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner {
  width: 90%;
  margin: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-title {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 19px;
  text-align: center;
  text-align: left;
  margin-bottom: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section .tax-rate-body-section-inner .tax-rate-body-section-text {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 22px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section:first-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-body-container .tax-rate-body-section:first-child {
  margin-top: 54px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-disclaimer-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-disclaimer-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-disclaimer-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-disclaimer-container {
  margin-top: 25px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-disclaimer-container .tax-rate-disclaimer-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-disclaimer-container .tax-rate-disclaimer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-disclaimer-container .tax-rate-disclaimer-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-disclaimer-container .tax-rate-disclaimer-text {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 11px;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-footer-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-footer-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-footer-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-footer-container {
  margin-top: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-1 {
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 19px;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .tax-rate-footer-container .tax-rate-footer-2 {
  margin-top: 7px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 23.5px;
  letter-spacing: 0.025em;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .tax-rate-qr-code .mccaw-qr-logo-and-avatar,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container .tax-rate-qr-code .mccaw-qr-logo-and-avatar,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .tax-rate-qr-code .mccaw-qr-logo-and-avatar,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container .tax-rate-qr-code .mccaw-qr-logo-and-avatar {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-bold,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-bold,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-bold,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-bold {
  margin-top: 0px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  letter-spacing: 0.025em;
  text-align: center;
  line-height: 21px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-thin,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-thin,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-thin,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .tax-rate-container .alt-tax-rate-footer-container .alt-tax-rate-footer-1 .alt-tax-text-thin {
  margin-top: 5px;
  font-family: "playfair-display", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 10px;
  letter-spacing: 0.025em;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .placeholder,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .placeholder,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .placeholder,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .placeholder {
  position: absolute;
  bottom: 0px;
  height: 750px;
  width: 60%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container {
  position: absolute;
  bottom: 0px;
  height: 750px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title {
  position: absolute;
  top: 35px;
  left: 386px;
  width: 460px;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-main-title .experts-trends-main-title-text {
  color: #424142;
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.15em;
  font-size: 64px;
  text-transform: uppercase;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container {
  position: absolute;
  top: 99px;
  left: 487px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-subtitle-container .experts-trends-main-subtitle {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 15px;
  margin-bottom: 15px;
  letter-spacing: 0.1em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  left: 486px;
  height: 82%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
  color: #424142;
  font-size: 25.5px;
  position: absolute;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-1 {
  top: 6px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-2 {
  top: 216px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-number-section-column .number-section.num-3 {
  top: 390px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container {
  position: absolute;
  top: 95px;
  left: 0px;
  height: 150px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 457px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image-text-container p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image-text-container p {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.025em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-image,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-image {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 730px;
  width: 457px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section {
  display: flex;
  width: 100%;
  position: absolute;
  left: 503px;
  top: 139px;
  z-index: 2;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container {
  width: 80%;
  height: 86%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section {
  margin-bottom: 16px;
  width: 74%;
  margin-left: 40px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-title {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-text {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11.5px;
  line-height: 17px;
  margin-bottom: 11px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer {
  display: flex;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer.column {
  display: flex;
  flex-direction: column;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource {
  color: #424142;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-author.ml,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource.ml {
  margin-left: 3px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .experts-trends-trends-container .experts-trends-content-section .experts-trends-text-container .experts-trends-section .experts-trends-section-footer .experts-trends-resource {
  font-weight: 500;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .backpage-bg,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .backpage-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .backpage-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .backpage-bg {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 760px;
  z-index: 0;
  background-color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-bg-img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-bg-img,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 270px;
  width: 100%;
  z-index: 1;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-body,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-body {
  position: absolute;
  bottom: 50px;
  margin: auto;
  width: 100%;
  z-index: 2;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-header,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-header,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header {
  width: 96%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-title {
  margin-left: 70px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 28.25px;
  letter-spacing: 0.16em;
  color: #475862;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-divider {
  height: 2.66px;
  width: 645px;
  background-color: rgba(246, 204, 108, 0.8);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-header .home-value-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-header .home-value-subtitle {
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.05em;
  color: #53626c;
  margin-top: 6px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto 0 auto;
  table-layout: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table thead th,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #8a927c;
  font-size: 15px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  line-height: 18px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:first-child {
  padding-left: 90px;
  text-align: left;
  width: 320px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:nth-child(3),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(2),
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:nth-child(3) {
  width: 130px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table thead th:last-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table thead th:last-child {
  padding-right: 70px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table tbody tr,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody tr,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody tr {
  border-bottom: 1px solid rgb(205.4655172414, 214.7896551724, 220.0344827586);
  height: 27px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table tbody td,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #53626c;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:first-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:first-child {
  padding-left: 90px;
  text-align: left;
  font-size: 16px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 52%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p {
  width: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child {
  padding-right: 70px;
  font-size: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-table tbody td:last-child p,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-table tbody td:last-child p {
  margin: 0 0 0 30%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-first-left .home-value .home-value-source,
.quarterly-print .qv4-print-page .qv4-print-page-container .ye2-first-left .home-value .home-value-source,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-first-left .home-value .home-value-source,
.quarterly-print .qv4-print-page .ye2-print-page-container .ye2-first-left .home-value .home-value-source {
  width: 90%;
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  position: relative;
  z-index: 4;
  top: 15px;
  color: white;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left {
  width: 50%;
  position: relative;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-background-img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-background-img {
  height: 370px;
  width: 99.5%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container {
  position: absolute;
  top: 94px;
  left: 40px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-title {
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-left: 4px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle {
  color: #333333;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 33px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle.speedometer-subtitle-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-subtitle.speedometer-subtitle-col {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .small-speedometer-area-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .small-speedometer-area-text {
  font-size: 31px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xs-speedometer-area-text {
  font-size: 17px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xxs-speedometer-area-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .xxs-speedometer-area-text {
  font-size: 22px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-title-container .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-img {
  position: absolute;
  top: 45px;
  right: 80px;
  height: auto;
  width: 320px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .buyers-advantage-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .buyers-advantage-text {
  position: absolute;
  top: 209px;
  right: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .sellers-advantage-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .sellers-advantage-text {
  position: absolute;
  top: 209px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container {
  position: absolute;
  top: 300px;
  right: 20px;
  height: 50px;
  width: 491px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-market-title {
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 21px;
  text-transform: uppercase;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer .speedometer-market-container .speedometer-statistics {
  text-align: center;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  line-height: 27.5px;
  letter-spacing: 0.025em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-bg-gradient,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-bg-gradient {
  background: linear-gradient(163deg, rgb(222, 185, 201) 0%, rgb(255, 255, 255) 60%);
  height: 370px;
  width: 99.5%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-background-img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-background-img {
  height: 370px;
  width: 99.5%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 {
  position: absolute;
  top: 245px;
  right: 80px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2.raise-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2.raise-title {
  margin-top: -55px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2.raise-title .speedometer-column-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2.raise-title .speedometer-column-subtitle {
  margin-top: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-title {
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: black;
  font-size: 15px;
  letter-spacing: 0.05em;
  margin-left: 4px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: -46px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle div,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-column-subtitle div {
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: #333333;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 0.075em;
  line-height: 40px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.xxs-single-line-speedometer-area-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.xxs-single-line-speedometer-area-text {
  font-size: 20px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.xs-single-line-speedometer-area-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.xs-single-line-speedometer-area-text {
  font-size: 22px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.small-single-line-speedometer-area-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.small-single-line-speedometer-area-text {
  font-size: 24px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.speedometer-subtitle-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-subtitle.speedometer-subtitle-col {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .small-speedometer-area-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .small-speedometer-area-text {
  font-size: 31px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xs-speedometer-area-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xs-speedometer-area-text {
  font-size: 17px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xxs-speedometer-area-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .xxs-speedometer-area-text {
  font-size: 22px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-circle-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-title-container-v2 .speedometer-circle-divider {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-img {
  position: absolute;
  top: 45px;
  right: 80px;
  height: auto;
  width: 320px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .buyers-advantage-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .buyers-advantage-text {
  position: absolute;
  top: 209px;
  right: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .sellers-advantage-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .sellers-advantage-text {
  position: absolute;
  top: 209px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 {
  position: absolute;
  top: 94px;
  left: 40px;
  height: 90px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-market-title-v2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-market-title-v2 {
  color: black;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  line-height: 41px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-statistics-v2,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-speedometer-v2 .speedometer-market-container-v2 .speedometer-statistics-v2 {
  color: black;
  font-family: "objektiv-mk2", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  line-height: 41px;
  letter-spacing: 0.1em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-chartTip,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-chartTip {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 50;
  max-width: 250px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph {
  height: 200px;
  width: 350px;
  margin-left: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph .graph-line,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph .graph-line {
  stroke: rgba(235, 242, 242, 0.4);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph .x-labels,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph .x-labels {
  text-anchor: middle;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph .y-labels,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph .y-labels {
  text-anchor: end;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph .quiz-graph-grid,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph .quiz-graph-grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .label-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .label-title {
  text-anchor: middle;
  text-transform: uppercase;
  font-size: 12px;
  fill: gray;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left.firstBg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left.firstBg {
  fill: rgba(217, 173, 118, 0.8);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left.secondBg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left.secondBg {
  fill: rgba(229, 213, 178, 0.8);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left.thirdBg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left.thirdBg {
  fill: rgba(171, 170, 168, 0.8);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph-dot.firstBg,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph-start-dot.firstBg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph-dot.firstBg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph-start-dot.firstBg {
  fill: rgba(210, 157, 92, 0.8);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph-dot.secondBg,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph-start-dot.secondBg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph-dot.secondBg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph-start-dot.secondBg {
  fill: rgba(219, 196, 148, 0.8);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph-dot.thirdBg,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .quiz-graph-start-dot.thirdBg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph-dot.thirdBg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .quiz-graph-start-dot.thirdBg {
  fill: rgba(154, 153, 151, 0.8);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .svg-cover,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .svg-cover {
  width: 350px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .bottom-x-labels text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .bottom-x-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  letter-spacing: -0.075em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .bottom-x-labels text.graph-quarter-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .bottom-x-labels text.graph-quarter-text {
  font-weight: 400;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .data-labels,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .data-labels text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .data-labels,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .data-labels text {
  fill: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11.5px;
  width: 30px;
  text-align: center;
  text-anchor: middle;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container {
  position: absolute;
  top: 370px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section {
  display: flex;
  height: 200px;
  width: 99.5%;
  margin-bottom: 40px;
  box-shadow: 0 12px 18px -9px rgb(204.5, 204.5, 204.5);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-adv-row {
  display: flex;
  justify-content: space-between;
  height: 200px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-graph {
  width: 33.3%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section {
  width: 57%;
  display: flex;
  flex-direction: column;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row {
  font-weight: bold;
  width: 100%;
  height: 25px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-category-row .seller-data-section-category-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  line-height: 15px;
  text-transform: uppercase;
  color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-section-divider {
  height: 80px;
  width: 1px;
  align-self: center;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-two-box-row {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section {
  display: flex;
  flex-direction: column;
  background-color: #f2eee4;
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 90%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 46%;
  height: 120px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-year-and-data-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-year {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  height: 20px;
  margin-top: -6px;
  letter-spacing: 0.05em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data.zero-units-sold,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-data.zero-units-sold {
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-inner-section-col .seller-data-description {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  color: black;
  margin-top: 21px;
  margin-bottom: -21px;
  height: 18px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-data-section .seller-data-inner-section .seller-data-fine-print {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 7px;
  text-transform: uppercase;
  color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-description-text {
  margin-top: 27px;
  margin-bottom: -31px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  text-transform: uppercase;
  color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 47px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-diff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 26px;
  text-transform: uppercase;
  color: black;
  margin-top: 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-no-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  color: black;
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-perc-prev-change {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .seller-perc-diff-container .seller-change-arrow-container .seller-graph-arrow {
  height: 30px;
  width: 56px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-seller-graph-container .seller-adv-section .seller-description-perc .qv4-sellers-adv-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  margin-top: 20px;
  height: 22px;
  color: rgb(0, 34, 90);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-bg,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-bg {
  position: absolute;
  margin: auto;
  left: 5%;
  right: 5%;
  top: 8.2%;
  width: 672px;
  height: 922px;
  z-index: 0;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-header,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-header {
  position: absolute;
  width: 96%;
  height: 70px;
  margin-top: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-header .db-header-main,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-header .db-header-main {
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 27.5px;
  line-height: 26px;
  letter-spacing: 0.35em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body {
  position: absolute;
  top: 140px;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-body-header,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-body-header {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px auto 10px auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-body-header .db-body-header-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-body-header .db-body-header-title {
  color: #838b75;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23px;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-body-subheader,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-body-subheader {
  width: 280px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-floating-box {
  right: 0px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .db-divider {
  right: 0px;
  width: 616px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header {
  width: 604px;
  flex-direction: row-reverse;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-header .indicator-header-category {
  text-align: left;
  margin-left: 15%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-explanation {
  width: 65%;
  margin-left: 13%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 13%;
  margin-top: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(even) .indicator-advantage {
  display: flex;
  justify-content: flex-start;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-floating-box {
  left: 0px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .db-divider {
  left: 0px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header {
  width: 575px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-header .indicator-header-category {
  text-align: right;
  margin-right: -6.5%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-explanation {
  width: 65%;
  margin-left: 22%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage-and-benefit-container {
  width: 65%;
  margin-left: 22%;
  margin-top: 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator:nth-child(odd) .indicator-advantage {
  display: flex;
  justify-content: flex-end;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .indicator-advantage-and-benefit-container {
  clear: both;
  display: inline-block;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator {
  height: 203px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-divider {
  height: 1.3px;
  width: 612px;
  position: absolute;
  top: 48px;
  z-index: 3;
  background-color: black;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .white-box-img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .white-box-img {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
  height: 210px;
  z-index: 1;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box {
  width: 707px;
  height: 91%;
  position: absolute;
  z-index: 2;
  background-color: rgba(251, 251, 246, 0.9);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  height: 40px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-num {
  width: 125px;
  text-align: right;
  color: #a36b71;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 37px;
  line-height: 32px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-header .indicator-header-category {
  width: 280px;
  color: black;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: justify;
  text-justify: inter-word;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation strong,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage strong,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit strong {
  font-weight: normal !important;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-explanation {
  margin-top: 10px;
  color: #424142;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  line-height: 19px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-advantage {
  color: #8f555c;
  font-weight: 400;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit {
  font-weight: 400;
  color: black;
  margin-left: 4px;
  display: inline-block;
  float: right;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-indicator-container .db-indicator .db-floating-box .indicator-benefit.no-mt-indicator-benefit {
  margin-top: 1px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-bottom-line,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-bottom-line {
  position: absolute;
  bottom: -86px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-title {
  font-weight: 700;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-bottom-line .db-bottom-line-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  text-align: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.075em;
  color: #8f555c;
  width: 90%;
  margin: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .db-body .db-bottom-line .db-signoff {
  display: inline-block;
  margin-left: 10px;
  width: auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-second-left-middle-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-second-left-middle-section {
  height: 935px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators {
  width: 100%;
  height: 165px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .left-market-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  text-align: right;
  line-height: 19px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: -7px;
  height: 1.33px;
  width: 500px;
  background-color: #abbc87;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .right-market-title-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -4px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .right-market-title-section.smaller-market-title-section {
  font-size: 13px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .inner-indicator-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.2);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.35);
  height: 50px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -8px;
  width: 100px;
  height: 1.5px;
  background-color: #abbc87;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 30.5px;
  color: #53626c;
  text-align: center;
  margin-right: 20px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-up {
  width: 35px;
  height: 35px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .qv4-indicator-arrow-down {
  width: 35px;
  height: 35px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .qv4-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  color: #53626c;
  margin-bottom: -4px;
  text-align: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-palmtree-img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-palmtree-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 97%;
  width: 54%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header {
  position: absolute;
  top: 100px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-header-content,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-header-content {
  width: 89%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 37px;
  letter-spacing: 0.16em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-title .dashboard-title-light,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-title .dashboard-title-light {
  color: white;
  width: 53.5%;
  text-align: right;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-title .dashboard-title-dark,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-title .dashboard-title-dark {
  color: #3c4c55;
  width: 44.5%;
  text-align: left;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container {
  width: 61%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header {
  display: flex;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.2em;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  margin-right: 20px;
  color: white;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark {
  color: #3c4c55;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info {
  margin-top: 15px;
  color: rgb(70.2098765432, 76.5925925926, 64.4074074074);
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  width: 315px;
  text-align: justify;
  text-justify: inter-word;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body {
  position: absolute;
  top: 300px;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range {
  height: 186px;
  display: flex;
  position: relative;
  width: 100%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider {
  height: 2.66px;
  min-height: 2.66px;
  width: 570px;
  background-color: #f3d3ae;
  position: absolute;
  top: 80px;
  left: 0px;
  z-index: 1;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .price-range-col,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .price-range-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 37%;
  z-index: 2;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container {
  width: 82%;
  margin: -7px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 37%;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text {
  margin: -20px 0 10px 0;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 29.5px;
  color: white;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 15px;
  color: white;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-img,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-img {
  display: flex;
  width: 64%;
  height: 105%;
  justify-content: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image {
  display: flex;
  width: 64%;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 35%;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 21px;
  color: #3c4c55;
  margin-top: -25px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes {
  display: flex;
  flex-direction: column;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 5px;
  font-size: 15px;
  color: #3c4c55;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #3c4c55;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 11px;
  text-align: center;
  color: rgb(28.1479289941, 34.8875739645, 38.8520710059);
  margin-top: 8px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-valuation,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-valuation {
  position: absolute;
  bottom: 30px;
  right: 0;
  height: 170px;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.8);
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-valuation .valuation-title,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-valuation .valuation-title {
  display: flex;
  justify-content: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #3c4c55;
  margin: 20px 0 0 0;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-valuation .valuation-section-row,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-valuation .valuation-section-row {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 8px auto 0 auto;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-valuation .valuation-section,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-valuation .valuation-section {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-number,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-number {
  line-height: 90px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-number.light, .quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-number.medium, .quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-number.dark,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-number.light,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-number.medium,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-number.dark {
  color: #939d87;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-data,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-left .dashboard-valuation .valuation-section .valuation-data {
  margin-top: 10px;
  width: 83%;
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
  line-height: 24px;
  color: #3c4c55;
}
.quarterly-print .qv4-print-page .qv4-print-page-container .qv4-second-right .global-ps-container,
.quarterly-print .qv4-print-page .ye2-print-page-container .qv4-second-right .global-ps-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}