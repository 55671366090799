@font-face {
  font-family: "FormationSansRegular";
  src: url("../YearEndStatement/FormationSansRegular2.ttf");
}
.agent-performance {
  border: 1px solid gray;
  background-color: #f9f7ec;
  width: 1080px;
  margin: auto;
}
.agent-performance .ap-header {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  background-color: #4a7396;
}
.agent-performance .ap-header .ap-header-inner {
  height: 70%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.agent-performance .ap-header .ap-header-inner .ap-header-left {
  width: 25%;
}
.agent-performance .ap-header .ap-header-inner .ap-header-left div {
  font-family: FormationSansRegular;
  font-size: 48px;
  letter-spacing: 0.05em;
  color: white;
}
.agent-performance .ap-header .ap-header-inner .ap-header-center {
  width: 50%;
  display: flex;
  justify-content: center;
}
.agent-performance .ap-header .ap-header-inner .ap-header-center div {
  font-family: FormationSansRegular;
  font-size: 48px;
  letter-spacing: 0.05em;
  color: white;
}
.agent-performance .ap-header .ap-header-inner .ap-header-right {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.agent-performance .ap-header .ap-header-inner .ap-header-right div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  text-transform: uppercase;
  color: white;
}
.agent-performance .ap-header .ap-header-inner .ap-header-right div:hover {
  cursor: pointer;
  font-size: 19px;
}
.agent-performance .ap-body {
  width: 90%;
  margin: auto;
}
.agent-performance .ap-body .ap-body-header {
  flex-direction: column;
  justify-content: flex end;
}
.agent-performance .ap-body .ap-body-header .available-areas {
  display: flex;
  justify-content: flex-end;
  border: 1px solid gray;
  color: lightblue;
}
.agent-performance .ap-body .ap-body-header .download-link {
  display: flex;
  justify-content: flex-end;
}
.agent-performance .ap-body .invoice-table {
  margin: 20px auto 0 auto;
  width: 100%;
}
.agent-performance .ap-body .invoice-table .invoice-table-header {
  display: flex;
  border-bottom: 1px solid #4a7396;
}
.agent-performance .ap-body .invoice-table .invoice-table-header div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  line-height: 13px;
  color: #00305e;
}
.agent-performance .ap-body .invoice-table .invoice-table-header div:nth-child(1) {
  width: 100px;
}
.agent-performance .ap-body .invoice-table .invoice-table-header div:nth-child(2) {
  width: 100px;
}
.agent-performance .ap-body .invoice-table .invoice-table-header div:nth-child(3) {
  width: 150px;
}
.agent-performance .ap-body .invoice-table .invoice-table-header div:nth-child(4) {
  width: 150px;
}
.agent-performance .ap-body .invoice-table .invoice-table-header div:nth-child(5) {
  width: 150px;
}
.agent-performance .ap-body .invoice-table .invoice-table-header div:nth-child(6) {
  width: 200px;
}
.agent-performance .ap-body .invoice-table .invoice-table-header div:nth-child(7) {
  width: 210px;
}
.agent-performance .ap-body .invoice-table .invoice-table-header div:nth-child(7) > div {
  width: 210px;
}
.agent-performance .ap-body .invoice-table .invoice-table-body {
  display: flex;
  flex-direction: column;
  margin: 20px auto 0 auto;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid darkgray;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(1) {
  width: 100px;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(1) > div {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
  color: #4e85af;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(1) > div:hover {
  cursor: pointer;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(2) {
  width: 100px;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(3) {
  width: 150px;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(4) {
  width: 150px;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(5) {
  width: 150px;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(6) {
  width: 200px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(6) > div {
  line-height: 14.4px;
  color: #414042;
}
.agent-performance .ap-body .invoice-table .invoice-table-body .invoice-table-row div:nth-child(7) {
  width: 210px;
}