/* Variables

 */
.midquarter-editor .dashboard-data-pending {
  border: 1px solid black;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  position: absolute;
  top: 200px;
  left: 100px;
  padding: 200px;
  text-align: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container {
  width: 1365px;
  height: 1070px;
  margin: 0 auto;
  background: white;
  background-size: contain;
  position: relative;
  box-shadow: 0 0 6px 2px rgb(212, 212, 212);
  display: flex;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-right,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right {
  width: 50%;
  position: relative;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .backpage-bg {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 680px;
  z-index: 0;
  background-color: black;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles {
  position: absolute;
  bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubble-footnote {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 6px;
  color: #657460;
  position: absolute;
  bottom: -10px;
  right: 100px;
  width: 240px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubble-img {
  height: 200px;
  width: 200px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubble-img-container {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-title-container {
  margin-left: 100px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-title-container .fact-bubbles-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: white;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-subtitle-container {
  margin-left: 30px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-subtitle-container .fact-bubbles-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
  color: white;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubble-dark-highlight {
  background-color: #e5e9d2;
  width: 40px;
  height: 330px;
  position: absolute;
  left: 0px;
  top: 130px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubble-light-highlight {
  background-color: #bfc9b3;
  width: 40px;
  height: 340px;
  position: absolute;
  right: 0px;
  top: -30px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubble-top-line {
  background-color: #abbc87;
  height: 1.75px;
  width: 495px;
  position: absolute;
  top: 120px;
  left: 0px;
  z-index: 3;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubble-bottom-line {
  background-color: #abbc87;
  height: 1.75px;
  width: 505px;
  position: absolute;
  top: 325px;
  right: 0px;
  z-index: 3;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body {
  height: 410px;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .bubble-content {
  position: relative;
  width: 220px;
  height: 157px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  margin-top: 15px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .dark-bubble {
  position: relative;
  width: 45%;
  height: 200px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  text-transform: uppercase;
  color: #475862;
  height: 55px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 23px;
  letter-spacing: -0.025em;
  color: #3c4c55;
  height: 60px;
  display: flex;
  align-items: flex-end;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-subdata-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-subdata-container .dark-bubble-subdata {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #3c4c55;
  text-transform: capitalize;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-subdata-container .dark-bubble-subdata.medium-address {
  font-size: 9px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .dark-bubble .dark-bubble-subdata-container .dark-bubble-subdata .lowercase {
  text-transform: lowercase;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .light-bubble {
  position: relative;
  width: 45%;
  height: 200px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  text-transform: uppercase;
  color: white;
  height: 55px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-data {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 35px;
  letter-spacing: -0.025em;
  color: white;
  height: 60px;
  display: flex;
  align-items: flex-end;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-data.smaller-data {
  font-size: 23px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-subdata-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-subdata-container .light-bubble-subdata {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: white;
  text-transform: capitalize;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .fact-bubbles .fact-bubbles-body .light-bubble .light-bubble-subdata-container .light-bubble-subdata.medium-address {
  font-size: 9px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .mq2-testimonial {
  position: absolute;
  top: 10px;
  left: 0px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .mq2-testimonial .mq2-testimonial-title-container {
  margin-left: 100px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .mq2-testimonial .mq2-testimonial-title-container .mq2-testimonial-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: white;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .mq2-testimonial .mq2-testimonial-subtitle-container {
  margin-left: 50px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .mq2-testimonial .mq2-testimonial-subtitle-container .mq2-testimonial-subtitle-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
  color: white;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .mq2-testimonial .mq2-testimonial-text-container {
  width: 90%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .mq2-testimonial .mq2-testimonial-text-container .mq2-testimonial-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
  line-height: 21px;
  color: white;
  text-align: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .mq2-testimonial .mq2-testimonial-text-container .mq2-testimonial-text.testimonial-alt-spacing {
  width: 87%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .mq2-testimonial .mq2-testimonial-signoff {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 15px;
  color: white;
  width: 75%;
  display: flex;
  margin-top: 5px;
  justify-content: flex-end;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 210px;
  width: 100%;
  z-index: 0;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-body {
  position: absolute;
  bottom: 50px;
  margin: auto;
  width: 100%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-header {
  width: 96%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-header .home-value-title {
  margin-left: 70px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  letter-spacing: 0.16em;
  color: #475862;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-header .home-value-divider {
  height: 2.66px;
  width: 545px;
  background-color: rgba(246, 204, 108, 0.8);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-header .home-value-subtitle {
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #53626c;
  margin-top: 6px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto 0 auto;
  table-layout: auto;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table thead th {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #8a927c;
  font-size: 13px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  line-height: 18px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table thead th:first-child {
  padding-left: 90px;
  text-align: left;
  width: 320px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table thead th:nth-child(2),
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table thead th:nth-child(3) {
  width: 130px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table thead th:last-child {
  padding-right: 70px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table tbody tr {
  border-bottom: 1px solid rgb(205.4655172414, 214.7896551724, 220.0344827586);
  height: 27px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table tbody td {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #53626c;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table tbody td:first-child {
  padding-left: 90px;
  text-align: left;
  font-size: 13px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 52%;
  font-size: 13px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table tbody td:nth-child(2) .inner-table-data p,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table tbody td:nth-child(3) .inner-table-data p {
  width: auto;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table tbody td:last-child {
  padding-right: 70px;
  font-size: 13px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-table tbody td:last-child p {
  margin: 0 0 0 30%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-first-left .home-value .home-value-source {
  width: 90%;
  text-align: right;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  position: relative;
  z-index: 4;
  top: 15px;
  color: white;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table {
  width: 100%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container {
  width: 97%;
  margin: 10px 0 30px 24px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-inside-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator {
  border-radius: 50%;
  background-color: #3c4c55;
  margin: -3px 8px 0 8px;
  height: 4px;
  width: 4px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.medium-market-sales-title {
  margin: 0 4px;
  height: 4px;
  width: 4px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-non-sub-container .market-sales-title-separator.small-market-sales-title {
  margin: 0 3px;
  height: 3px;
  width: 3px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.025em;
  color: #3c4c55;
  text-transform: uppercase;
  font-size: 33px;
  line-height: 26px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.regular-market-sales-title {
  font-size: 25px;
  line-height: 18px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.small-market-sales-title {
  font-size: 24px;
  line-height: 18px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.xs-market-sales-title {
  font-size: 18px;
  line-height: 26px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-title-text.medium-market-sales-title {
  font-size: 23px;
  line-height: 26px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-sales-title-container .market-sales-subtitle-text {
  display: flex;
  justify-content: center;
  width: 102%;
  color: #657460;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: right;
  margin-left: -30px;
  margin-top: 20px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  flex-direction: column;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row .golden-divider {
  height: 1px;
  margin-top: 2px;
  width: 425px;
  background-color: #abbc87;
  margin-bottom: 1px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
  z-index: 0;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text {
  width: 245px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 60px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text.overflow-subtitle-text {
  width: 355px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .left-subtitle-text .left-subtitle-text-title {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  text-transform: uppercase;
  text-align: right;
  line-height: 17px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row .left-subtitle-section .small-left-subtitle {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 12px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section {
  margin-right: 7px;
  display: flex;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section p {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 12px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .subtitle-row .right-subtitle-section .right-subtitle-divider {
  margin: 0 7px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8px;
  text-transform: uppercase;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-table-containers {
  margin-top: -3px;
  min-height: 500px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .market-table-containers .market-table-bg-image {
  position: absolute;
  width: 100%;
  height: 512px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .both-market-tables {
  position: relative;
  z-index: 2;
  padding-top: 10px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table {
  width: 97%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table thead tr {
  align-items: flex-end;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table thead tr th {
  font-size: 9px;
  text-align: center;
  vertical-align: bottom;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #3c4c55;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table thead tr th p {
  line-height: 11px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table thead tr th:first-child {
  padding-left: 20px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table thead tr th:last-child {
  padding-right: 30px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr {
  border-bottom: 1px solid rgb(217.25, 217.25, 217.25);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td {
  vertical-align: middle;
  text-align: center;
  height: 17px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8.4px;
  color: #3c4c55;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text {
  font-weight: 500;
  text-transform: uppercase;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.sold {
  color: rgb(153, 23, 31);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.contingent, .midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.pending {
  color: rgb(0, 79, 172);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr .status-text.active {
  color: rgb(56, 122, 9);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child {
  width: 83px;
  display: flex;
  justify-content: flex-end;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:first-child p {
  width: 68px;
  text-align: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) {
  width: 46px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr > td:nth-child(2) .date-td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(3) {
  width: 74px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(4) {
  width: 189px;
  text-align: left;
  font-weight: 400;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) {
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) div {
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(5) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 20px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) {
  font-weight: 400;
  width: 82px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) div {
  margin: auto;
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .centered-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(6) .off-centered-dash {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  margin-left: 40px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(7) {
  width: 63px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(8) {
  width: 31px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) {
  width: 90px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody tr td:nth-child(9) p {
  width: 53px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:last-child {
  border: none;
  margin-bottom: 8px;
  vertical-align: top;
  border-spacing: 0 0 30px 0;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child {
  vertical-align: bottom;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody .recent-row:first-child td,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody .presolds-row:first-child td {
  padding-top: 10px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .regular-sales-table tbody .single-table-row:last-child {
  border: none;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .overflow-message-table {
  width: 97%;
  margin-top: 5px;
  margin-left: 3%;
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .sold-overflow-tb,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .active-overflow-tb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .sold-overflow-tb .sales-table-row,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .active-overflow-tb .sales-table-row {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8px;
  line-height: 8px;
  color: #475862;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .high-sold .sold-homes {
  padding: 0 2px;
  color: rgb(184, 37, 37);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .high-sold .standout-active {
  text-transform: uppercase;
  color: #019091;
  padding: 0 2px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .sales-dates {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-weight: bold;
  min-width: 405px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .sales-dates p {
  font-size: 6px;
  color: black;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .sales-dates p:first-child {
  color: rgb(184, 37, 37);
  padding-right: 1em;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .sales-legend {
  border: 1px solid gray;
  margin: 0.75em 0;
  padding: 0.25em;
  width: 37%;
  margin-left: 2.5em;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .sales-legend .row p {
  text-align: left;
  width: 130px;
  font-size: 7px;
  color: black;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .sales-legend .row p:nth-child(2) {
  width: 80px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .sales-legend .row p:last-child {
  width: 75px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .previous-sales-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: mr-eaves-xl-modern-narrow, sans-serif;
  font-weight: 300;
  font-variant: small-caps;
  font-size: 19px;
  font-feature-settings: normal;
  margin: 5px 0;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-sales-table .previous-sales-title .previous-sales-title-divider {
  margin: 0 5px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-second-left-middle-section {
  height: 855px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators {
  width: 100%;
  height: 165px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .mini-market-title {
  display: flex;
  justify-content: space-between;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .mini-market-title .mini-market-title-divider {
  margin: 0 10px;
  padding-top: 1px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .mini-market-title .left-market-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .left-market-title-section {
  margin-left: 35px;
  display: flex;
  padding-left: 5px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  text-transform: uppercase;
  text-align: right;
  line-height: 17px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .mini-market-title .left-market-title-container .mini-market-title-divider {
  margin-left: -1px;
  margin-top: 1px;
  padding-top: 1px;
  width: 425px;
  background-color: #abbc87;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .mini-market-title .right-market-title-section {
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: -4px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .mini-market-title .right-market-title-section .lc-market-title {
  text-transform: lowercase;
  margin: 0 5px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .inner-indicator-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section {
  width: 23.25%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.2);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191, 0.35);
  height: 50px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-category {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-value {
  text-transform: uppercase;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
  letter-spacing: 0.025em;
  line-height: 17px;
  color: #3c4c55;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-title-container .indicator-title-bottom-border {
  margin-bottom: -8px;
  width: 100px;
  height: 1.5px;
  background-color: #abbc87;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 56px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-perc {
  margin-bottom: -4px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 23.5px;
  color: #53626c;
  text-align: center;
  margin-right: 20px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-up {
  width: 30px;
  height: 30px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section .mq3-indicator-arrow-down {
  width: 30px;
  height: 30px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .mq3-market-indicators .indicator-container .indicator-section .indicator-balanced {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #53626c;
  margin-bottom: -4px;
  text-align: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-palmtree-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 97%;
  width: 54%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header {
  position: absolute;
  top: 80px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-header-content {
  width: 89%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 34px;
  letter-spacing: 0.16em;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-title .dashboard-title-light {
  color: white;
  width: 53.5%;
  text-align: right;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-title .dashboard-title-dark {
  color: #3c4c55;
  width: 44.5%;
  text-align: left;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-subtitle-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container {
  width: 63%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header {
  display: flex;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.2em;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-light {
  margin-right: 22px;
  color: white;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-subtitle-container .dashboard-subtitle-header .dashboard-subtitle-dark {
  color: #3c4c55;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-header .dashboard-subtitle-section .dashboard-info {
  margin-top: 15px;
  color: rgb(70.2098765432, 76.5925925926, 64.4074074074);
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11.5px;
  line-height: 15px;
  width: 255px;
  text-align: justify;
  text-justify: inter-word;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body {
  position: absolute;
  top: 270px;
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range {
  height: 176px;
  display: flex;
  position: relative;
  width: 100%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .dashboard-body-divider {
  height: 2.66px;
  min-height: 2.66px;
  width: 470px;
  background-color: rgba(246, 204, 108, 0.8);
  position: absolute;
  top: 80px;
  left: 0px;
  z-index: 1;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .price-range-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  z-index: 2;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container {
  width: 80%;
  margin: -7px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 37%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-text {
  margin: -20px 0 10px 0;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 23px;
  color: white;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .price-range-col .price-range-inner-container .price-range-number {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 13px;
  color: white;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-img {
  display: flex;
  width: 64%;
  height: 105%;
  justify-content: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image {
  display: flex;
  width: 64%;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image {
  display: flex;
  width: 60%;
  justify-content: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col .range-details-inner-container,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-details-inner-container {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col .range-property-details,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .range-property-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 31%;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  color: #3c4c55;
  margin-top: -17px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes {
  display: flex;
  flex-direction: column;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-title,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-title {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 5px;
  font-size: 12px;
  color: #3c4c55;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-text,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  line-height: 19px;
  text-align: center;
  color: #3c4c55;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col .property-attributes .property-attributes-disclaimer,
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-body .dashboard-body-range .range-details-col-with-image .property-attributes .property-attributes-disclaimer {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 9px;
  text-align: center;
  color: rgb(28.1479289941, 34.8875739645, 38.8520710059);
  margin-top: 8px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-valuation {
  position: absolute;
  bottom: 30px;
  right: 0;
  height: 140px;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.8);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-valuation .valuation-title {
  display: flex;
  justify-content: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12.5px;
  letter-spacing: 0.01em;
  color: #3c4c55;
  margin: 15px 0 0 0;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-valuation .valuation-section-row {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 8px auto 0 auto;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-valuation .valuation-section {
  width: 31%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-valuation .valuation-section .valuation-number {
  line-height: 55px;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-valuation .valuation-section .valuation-number.light, .midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-valuation .valuation-section .valuation-number.medium, .midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-valuation .valuation-section .valuation-number.dark {
  color: #939d87;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-left .dashboard-valuation .valuation-section .valuation-data {
  margin-top: 10px;
  width: 83%;
  text-align: left;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 11px;
  line-height: 17px;
  color: #3c4c55;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps {
  position: absolute;
  top: 90px;
  right: 0px;
  width: 100%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-title-container {
  width: auto;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-title-container .greetings-ps-title {
  text-transform: uppercase;
  color: #657460;
  margin-left: 48px;
  width: auto;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.15em;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-title-container .greetings-ps-title.smaller-greetings-ho-text {
  font-size: 14px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-title-container .greetings-ps-title.xs-greetings-ho-text {
  font-size: 14px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-title-container .greetings-ps-divider {
  margin-top: -3px;
  height: 2.666px;
  width: 580px;
  background-color: #abbc87;
  z-index: 1;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-box {
  width: 90%;
  height: 170px;
  margin: 15px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-box .greetings-ps-text {
  margin: 0 20px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12.5px;
  line-height: 18px;
  text-align: justify;
  text-justify: inter-word;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-box .qv4-ps-line {
  margin: 15px 25px 0 25px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: justify;
  text-justify: inter-word;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-box .ye2-ps-line {
  margin: 10px 25px 0 25px;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: justify;
  text-justify: inter-word;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-box .agent-signoff {
  display: flex;
  justify-content: flex-end;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 13px;
  line-height: 21px;
  margin-right: 65px;
  margin-top: 8px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .mq2-greetings-ps .greetings-ps-box .agent-name {
  margin-left: 5px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-photo-section-container .qv4-photo-section-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  color: #3c4c55;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-photo-section-container .qv4-photo-section-title .photo-section-title-neighborhood {
  text-transform: uppercase;
  margin-left: 6px;
  margin-bottom: -1px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-photo-section-container .mls-listings {
  width: 100%;
  margin: 15px auto 0 auto;
  text-align: center;
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 9px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings {
  margin: 1px auto 0 auto;
  width: 630px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 9px;
  grid-row-gap: 9px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .bold {
  font-weight: bold;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-img-container {
  margin: auto;
  width: 305px;
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-img-container .banner-text-bg {
  width: 32px;
  height: 169px;
  background-color: #bd8471;
  color: white;
  align-self: flex-start;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 0.015em;
  font-variant: small-caps;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-img-container .sold-by {
  font-weight: bold;
  text-align: center;
  color: rgb(0, 79, 172);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-img-container p {
  text-align: center;
  margin-bottom: 0.1em;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container {
  position: relative;
  top: 0;
  left: 0;
  height: 169px;
  width: 100%;
  overflow: hidden;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .listing-img {
  height: auto;
  width: 116%;
  position: relative;
  top: 47%;
  transform: translateY(-50%);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 26px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 3px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .list-address {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price {
  display: flex;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status {
  font-weight: bold;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-sold {
  color: rgb(184, 37, 37);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-active {
  color: #019091;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-primary, .midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-pending, .midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-contingent {
  color: rgb(0, 79, 172);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .status.list-secondary {
  color: rgb(0, 34, 90);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .list-photo-and-banner-container .status-address-row .status-address-row-inner .status-and-sales-price .list-sale-price {
  color: #3c4c55;
  font-family: objektiv-mk2, sans-serif;
  font-style: normal;
  font-size: 9px;
  text-transform: uppercase;
  margin-left: 10px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container {
  width: 97%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row {
  width: 100%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented {
  margin-top: 0.75em;
  text-align: center;
  color: #53626c;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 6.8px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container .full-row .presented .credit-ml {
  margin-left: 17px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  color: black;
  margin-top: 3px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  color: #475862;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 8px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container .list-row .list-col.highlight-dom {
  background-color: rgba(204, 233, 233, 0.6);
  color: rgb(28.1479289941, 34.8875739645, 38.8520710059);
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols {
  width: 97%;
  margin: 5px auto 0 auto;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:first-child {
  width: 31%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(2) {
  width: 25%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item .active-listing-text-container.three-active-cols .list-col:nth-child(3) {
  width: 25%;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item:nth-child(even) .active-listing-text-container {
  margin-left: 4px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row {
  flex-direction: row;
  justify-content: flex-start;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item:nth-child(even) .status-address-row-inner {
  justify-content: flex-start;
  margin-left: 8px;
}
.midquarter-editor .mq2-editor-page .mq2-editor-page-container .mq2-second-right .qv4-mapped-listings .list-map-item:nth-child(even) .list-img-container {
  flex-direction: row-reverse;
}